<template>
    <div class="matching-display-question">
        <fieldset>
            <legend>Matching (one-to-one)</legend>

            <div v-if="!isMobileView" ref="matchContainer" class="flexSpaceBetween position-relative zIndex2 gap10rem">
                <div ref="leftCol" class="width40">
                    <div class="textAlignCenter">
                        <label class="control-label">{{ question.options.labels.prompt }}</label>
                    </div>

                    <div
                        v-for="(prompt, idx) in filteringOptions(question.options.options, 'prompt')"
                        :key="idx"
                        :ref="promptsColumn"
                        class="match-options match-correct"
                    >
                        <label class="position-relative">
                            {{ prompt.content }}
                            <span class="position-absolute circle">
                                <span class="visible-hidden capitalize">{{ question.options.labels.prompt }}</span>
                                <b> {{ idx + 1 }} </b>
                            </span>
                        </label>
                    </div>
                </div>
                <div ref="rightCol" class="width40">
                    <div class="textAlignCenter">
                        <label class="control-label">{{ question.options.labels.answer }}</label>
                    </div>

                    <div
                        v-for="(answer, idx) in filteringOptions(question.options.options, 'answer')"
                        :key="idx"
                        :ref="answersColumn"
                        class="match-options right match-correct"
                    >
                        <label class="position-relative">
                            {{ answer.content }}

                            <span class="position-absolute circle">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </span>
                        </label>
                    </div>
                </div>
                <canvas ref="matchCanvas"></canvas>
            </div>

            <template v-else>
                <template v-for="(option, option_idx) in question.options.options">
                    <div class="sequencing-div-student">
                        <div>
                            <label :id="'matching-one-to-many-instructor-mobile-prompt-label-' + index" class="control-label capitalize">
                                {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                            </label>
                            <p>
                                {{ computedOptions[option_idx].promptVal }}
                            </p>
                        </div>

                        <div class="marginTop20">
                            <dl class="margin0">
                                <dt class="control-label capitalize">
                                    {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                                </dt>
                                <dd class="flexOnly categorize-option categorize-correct">
                                    <i class="fa fa-check-circle marginRight8"></i>
                                    <p>{{ computedOptions[option_idx].answerVal }}</p>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </template>
            </template>
        </fieldset>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, computed } from 'vue';

const props = defineProps({
    question: {
        type: Object,
        default: null,
    },
});

const filteringOptions = (options, type) => {
    let arr = [];
    for (var i = 0; i < options.length; i++) {
        Object.keys(options[i]).forEach((key) => {
            if (options[i][key].type == type) {
                arr.push({ ...options[i][key], key: key });
            }
        });
    }
    return arr;
};

const promptsGroupRefs = ref([]);
const answersGroupRefs = ref([]);

const promptsColumn = (el) => promptsGroupRefs.value.push(el);
const answersColumn = (el) => answersGroupRefs.value.push(el);

const matchCanvas = ref(null);
const matchContainer = ref(null);

const drawLine = (ctx, startX, startY, endX, endY, style) => {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.strokeStyle = '#2d8659';
    ctx.lineWidth = 2;

    switch (style) {
        case 'solid':
            ctx.setLineDash([]);
            break;
        case 'dotted':
            ctx.setLineDash([2, 4]);
            break;
        case 'dashed':
            ctx.setLineDash([8, 4]);
            break;
    }

    ctx.stroke();
};

const drawLines = () => {
    const ctx = matchCanvas.value.getContext('2d');
    ctx.clearRect(0, 0, matchCanvas.value.width, matchCanvas.value.height);

    const matchContainerRect = matchContainer.value.getBoundingClientRect();

    promptsGroupRefs.value.forEach((prompt, index) => {
        const answer = answersGroupRefs.value[index];

        if (prompt && answer) {
            const rectA = prompt.getBoundingClientRect();
            const rectB = answer.getBoundingClientRect();

            const startX = rectA.right - matchContainerRect.left;
            const startY = rectA.top + rectA.height / 2 - matchContainerRect.top;

            const endX = rectB.left - matchContainerRect.left;
            const endY = rectB.top + rectB.height / 2 - matchContainerRect.top;

            const style = index % 3 === 0 ? 'solid' : index % 3 === 1 ? 'dotted' : 'dashed';

            drawLine(ctx, startX, startY, endX, endY, style);
        }
    });
};

const canvasDimension = () => {
    const matchContainerElement = matchContainer.value;
    matchCanvas.value.width = matchContainerElement.offsetWidth;
    matchCanvas.value.height = matchContainerElement.offsetHeight;
};

onMounted(() => {
    nextTick(() => {
        canvasDimension();
        drawLines();
    });
});

watch([promptsGroupRefs, answersGroupRefs], () => {
    nextTick(() => {
        drawLines();
    });
});

watch(
    () => props.question,
    (question) => {
        nextTick(() => {
            canvasDimension();
            drawLines();
        });
    }
);

window.addEventListener('resize', () => {
    nextTick(() => {
        canvasDimension();
        drawLines();
    });
});

const computedOptions = computed(() => {
    const arr = props.question.options.options.map((option) => {
        const compile = { promptVal: '', answerVal: '' };

        Object.values(option).forEach((opt) => {
            if (opt.type === 'prompt') {
                compile.promptVal = opt.content;
            } else if (opt.type === 'answer') {
                compile.answerVal = opt.content;
            }
        });

        return compile;
    });

    return arr;
});
</script>
