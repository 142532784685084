<template>
    <div class="create row flex">
        <div class="profileImg">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }"></kr-file-picture>
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.accounts.superadmins.edit.firstnameLabel')"
                        :form="form"
                        name="firstname"
                        aria-label="first name"
                        :options="{ placeholder: 'e.g. John', required: true }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.accounts.superadmins.edit.lastnameLabel')"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{ placeholder: 'e.g. Doe', required: true }"
                    ></kr-field>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label class="control-label">{{ $t('modules.accounts.superadmins.edit.emailLabel') }}</label>
                    <p class="form-control-static">{{ form.model.email }}</p>
                </div>
            </div>
            <kr-toggle
                v-if="auth.user().roleId < 3 && auth.user().uuid != $route.params.id"
                name="canAddTeacher"
                :display="$t('modules.accounts.superadmins.edit.canAddTeacherLabel')"
                :form="form"
                class="marginBottom15 flexSpaceBetween"
                :options="{
                    setWidth: true,
                }"
            >
            </kr-toggle>
            <kr-toggle
                v-if="(auth.user().roleId < 3 || auth.user().canUseExpressSignIn || auth.user().canAddTeacher) && auth.user().uuid != $route.params.id"
                name="canUseExpressSignIn"
                :display="$t('modules.accounts.superadmins.edit.canUseExpressSigninLabel')"
                :form="form"
                class="marginBottom15 flexSpaceBetween"
            >
            </kr-toggle>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['form'],
    data() {
        return {
            auth: new KrAuth(),
        };
    },
};
</script>
<style scoped></style>
