<template>
    <fieldset class="marginBottom30">
        <legend>
            <b>Sequence Choices</b>
        </legend>

        <template v-for="(option, idx) of options">
            <div
                :id="'match-group-' + (idx + 1)"
                :draggable="true"
                class="optionDiv"
                :class="{ marginBottom30: idx != options.length - 1 }"
                tabIndex="-1"
                :aria-label="'Match group ' + (idx + 1) + ' of ' + options.length"
                @drop="dropToOption($event, idx)"
                @dragover="allowDropToOption"
                @dragleave="leaveDragFromOption"
                @dragstart="dragOption($event, idx)"
                @drag="dragFunction"
                @dragend="stopScroll = true"
            >
                <div class="flexOnly alignFlexStart">
                    <button
                        v-if="!isMobileView"
                        v-tooltip="'Drag ' + toOrdinal(idx + 1) + ' sequence'"
                        class="vertical6DotsButton"
                        :aria-label="'Drag ' + toOrdinal(idx + 1) + ' sequence'"
                    >
                        <i class="fas fa-grip-vertical" aria-hidden="true"></i>
                    </button>
                    <div class="width100">
                        <div class="paddingTop5" :class="{ flexOnly: !isBelow768px }">
                            <div class="form-group">
                                <label class="control-label capitalize" :for="'sequencing_details_' + idx"> {{ toOrdinal(idx + 1) }} Sequence </label>
                                <input
                                    :id="'sequencing_details_' + idx"
                                    v-model="option.content"
                                    type="text"
                                    :name="'sequencing_details_' + idx"
                                    class="form-control"
                                />
                            </div>

                            <div class="minWidth110px" :class="{ 'marginLeft20 paddingTop25': !isBelow768px, marginTop30: isBelow768px }">
                                <template v-if="options.length > 2">
                                    <button
                                        v-tooltip="'Delete ' + toOrdinal(idx + 1) + ' sequence'"
                                        class="btn btn-outline-danger"
                                        :class="{ 'width45px height45px': !isBelow768px }"
                                        :aria-label="'Delete ' + toOrdinal(idx + 1) + ' sequence'"
                                        @click.prevent="removeMessage(idx)"
                                    >
                                        <i class="fa fa-trash" :class="{ marginRight8: isBelow768px }" aria-hidden="true"></i>
                                        <template v-if="isBelow768px"> Delete Sequence </template>
                                    </button>
                                </template>

                                <button
                                    v-if="idx == options.length - 1"
                                    v-tooltip="'Add ' + toOrdinal(idx + 2) + ' sequence'"
                                    class="btn btn-outline-default"
                                    :class="{ 'width45px height45px': !isBelow768px }"
                                    :aria-label="'Add ' + toOrdinal(idx + 2) + ' sequence'"
                                    @click.prevent="addOption()"
                                >
                                    <i class="fa fa-plus" :class="{ marginRight8: isBelow768px }" aria-hidden="true"></i>
                                    <template v-if="isBelow768px"> New Sequence </template>
                                </button>
                            </div>
                        </div>

                        <div v-if="idx == match_group_idx" :id="'match-group-' + (idx + 1) + '-warning-dialog'" class="marginTop10" tabindex="-1">
                            <p>
                                Are you sure you want to delete
                                <b class="capitalize">{{ toOrdinal(idx + 1) }} Sequence</b>?
                            </p>
                            <div class="marginTop10">
                                <button class="btn btn-outline-default" @click.prevent="match_group_idx = -1">No, Cancel</button>
                                <button
                                    class="btn btn-danger"
                                    @click.prevent="
                                        removeOption(match_group_idx);
                                        match_group_idx = -1;
                                    "
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </fieldset>
</template>

<script setup>
defineEmits(['update:options']);
import { ref, nextTick, computed } from 'vue';
const currentDraggedItem = ref(null);
const stopScroll = ref(true);
const match_group_idx = ref();

const props = defineProps({
    options: {
        type: Array,
        default: function () {
            return [
                { order: 1, content: '' },
                { order: 2, content: '' },
                { order: 3, content: '' },
                { order: 4, content: '' },
            ];
        },
    },
    modal_idx: {
        type: String,
        default: null,
    },
});
const options = computed({
    // Use computed to wrap the object
    get: () => props.options,
    set: (value) => emit('update:options', value),
});

function dragFunction(e) {
    var scroll = function (step) {
        var scrollY = $(`#${modal_idx} .modal`).scrollTop();
        $(`#${modal_idx} .modal`).scrollTop(scrollY + step);
        if (!stopScroll.value) {
            setTimeout(function () {
                scroll(step);
            }, 20);
        }
    };

    stopScroll.value = true;

    if (e.clientY < 150) {
        stopScroll.value = false;
        scroll(-1);
    }
    if (e.clientY > $(window).height() - 150) {
        stopScroll.value = false;
        scroll(1);
    }
}

function leaveDragFromOption(event) {
    var target = event.target.closest('.optionDiv');
    if ($(currentDraggedItem.value).hasClass('optionDiv')) {
        $(target).removeClass('selected-bottom');
        $(target).removeClass('selected-top');
    }
}

function dropToOption(event, idx) {
    var data = JSON.parse(event.dataTransfer.getData('text'));
    if (data.from == 'option') {
        var target = event.target.closest('.optionDiv');
        $(target).removeClass('selected-bottom');
        $(target).removeClass('selected-top');
        array_move(options.value, data.idx, idx);

        for (var i = 0; i < options.value.length; i++) {
            options.value[i].order = i + 1;
        }
    }

    currentDraggedItem.value = null;
}

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

function allowDropToOption(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    if ($(currentDraggedItem.value).hasClass('optionDiv')) {
        var target = event.target.closest('.optionDiv');
        if (!target.isSameNode(currentDraggedItem.value)) {
            var bounding = target.getBoundingClientRect();
            var offset = bounding.y + bounding.height / 2;
            if (event.clientY - offset > 0) {
                $(target).removeClass('selected-top');
                $(target).addClass('selected-bottom');
            } else {
                $(target).removeClass('selected-bottom');
                $(target).addClass('selected-top');
            }
        }
    }
}

function dragOption(event, idx) {
    event.dataTransfer.setData('text', JSON.stringify({ from: 'option', idx: idx }));
    event.dataTransfer.dropEffect = 'move';

    currentDraggedItem.value = event.target.closest('.optionDiv');
}

function removeMessage(idx) {
    $('#sr-message-manage-question').text('');

    const message = 'Match Group ' + (idx + 1) + ' dialog appear';

    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    match_group_idx.value = idx;

    nextTick(() => {
        $('#match-group-' + (idx + 1) + '-warning-dialog').focus();
    });
}

function removeOption(idx) {
    $('#sr-message-manage-question').text('');

    const message = 'Removed match group ' + (idx + 1);

    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    options.value.splice(idx, 1);
    for (var i = 0; i < options.value.length; i++) {
        options.value[i].order = i + 1;
    }
}

function addOption() {
    let length = options.value.length == 0 ? 1 : options.value.length + 1;
    options.value.push({
        order: length,
        content: '',
    });

    $('#sr-message-manage-question').text('');
    const message = 'Added new match group ' + options.value.length;
    setTimeout(() => {
        $('#sr-message-manage-question').text(message);
    }, 100);

    nextTick(() => {
        $('#choice-' + options.value.length + '-group').focus();
    });
}
const canSubmitAnswer = computed(() => {
    for (var i = 0; i < options.value.length; i++) {
        if (!options.value[i].content) {
            return false;
        }
    }
    return true;
});

// expose data and method thingy
defineExpose({
    canSubmitAnswer,
});
</script>
