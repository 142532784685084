<template>
    <div v-if="component_done_loading" id="superuser-view" class="col-xs-12 right-container">
        <!-- <div class="page-title">
	        <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
	        	<router-link :to="{name:'superusers.index'}">Superusers</router-link>
	            / View Superuser
	            <h3 style="margin-bottom:0;"></h3>
	        </div>
	        <div class="col-xs-12 col-md-6 listingHeaderBtns">
                <router-link :to="{name:'superusers.index'}" style="color:#222;" class="btn btn-action btn-default"><i class="fa fa-chevron-left"></i> Back to Superuser List</router-link>
            </div>   
    	</div>
    	<div class="clearfix"></div> -->
        <div class="links">
            <ul>
                <li class="active">
                    <a class="nav-link" data-toggle="tab" href="#superuser" aria-label="Overview">{{ $t('modules.superusers.view.overviewTabLabel') }}</a>
                </li>
                <!--<li>
					<a class="nav-link" data-toggle="tab" href="#log">ACTIVITY LOG</a>
				</li>-->
            </ul>
        </div>
        <div class="row tab-content">
            <div id="superuser" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6 text-uppercase">{{ $t('modules.superusers.view.overviewTabLabel') }}</div>
                        <template v-if="(!auth.user().isDeletable || auth.user().uuid == superuser.uuid) && !superuser.isTrashed">
                            <div class="col-xs-6 buttonGroup flexRight">
                                <div>
                                    <div class="hidden-xs hidden-sm">
                                        <router-link
                                            v-if="(!auth.user().isDeletable || auth.user().uuid == superuser.uuid) && !superuser.isTrashed"
                                            v-slot="{ navigate }"
                                            :to="{
                                                name: 'superusers.edit',
                                                params: $route.params.id,
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i
                                                >{{ $t('modules.superusers.view.editButtonLabel') }}
                                            </button>
                                        </router-link>
                                        <button
                                            v-if="!auth.user().isDeletable && !superuser.isSuspended"
                                            id="top-reset-pwd-btn"
                                            class="btn btn-default mainBtn"
                                            aria-label="Reset Password"
                                            @click.prevent="openResetPasswordModal()"
                                        >
                                            <i class="fa fa-key marginRight8" aria-hidden="true"></i
                                            >{{ $t('modules.superusers.view.resetPasswordButtonLabel') }}
                                        </button>
                                        <router-link v-slot="{ navigate }" :to="{ name: 'superusers.index' }" custom>
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-caret-left" aria-hidden="true"></i> {{ $t('modules.superusers.view.backButtonLabel') }}
                                            </button>
                                        </router-link>
                                    </div>
                                    <div class="hidden-md hidden-lg">
                                        <router-link
                                            v-if="(!auth.user().isDeletable || auth.user().uuid == superuser.uuid) && !superuser.isTrashed"
                                            v-slot="{ navigate }"
                                            v-tooltip="{
                                                content: $t('modules.superusers.view.editButtonLabel'),
                                            }"
                                            :to="{
                                                name: 'superusers.edit',
                                                params: $route.params.id,
                                            }"
                                            custom
                                        >
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                            </button>
                                        </router-link>
                                        <button
                                            v-if="!auth.user().isDeletable && !superuser.isSuspended"
                                            id="top-mobile-reset-pwd-button"
                                            v-tooltip="{
                                                content: $t('modules.superusers.view.resetPasswordButtonLabel'),
                                            }"
                                            class="btn btn-default mainBtn"
                                            aria-label="Reset Password"
                                            @click.prevent="openResetPasswordModal()"
                                        >
                                            <i class="fa fa-key" aria-hidden="true"></i>
                                        </button>
                                        <router-link v-slot="{ navigate }" v-tooltip="{ content: 'Back' }" :to="{ name: 'superusers.index' }" custom>
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-caret-left" aria-hidden="true"></i>
                                            </button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-xs-6 flexRight">
                                <div class="col-xs-3 buttonGroup">
                                    <div class="hidden-xs hidden-sm">
                                        <router-link v-slot="{ navigate }" :to="{ name: 'superusers.index' }" custom>
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i
                                                >{{ $t('modules.superusers.view.backButtonLabel') }}
                                            </button>
                                        </router-link>
                                    </div>
                                    <div class="hidden-md hidden-lg">
                                        <router-link
                                            v-slot="{ navigate }"
                                            v-tooltip="$t('modules.superusers.view.backButtonLabel')"
                                            :to="{ name: 'superusers.index' }"
                                            custom
                                        >
                                            <button class="btn btn-default" @click="navigate">
                                                <i class="fa fa-caret-left8" aria-hidden="true"></i>
                                            </button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template #content>
                        <div class="col-xs-12 col-md-6 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.fullnameLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ superuser.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.emailLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ superuser.email }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.statusLabel') }}</label>
                                        <p class="form-control-static align-items">
                                            <template v-if="!superuser.isSuspended">
                                                <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                                            </template>
                                            <template v-else> <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.createdOnLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="superuser.dateCreated">
                                                {{ convertToReadableDate(superuser.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(superuser.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.activatedOnLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="superuser.dateActivated">
                                                {{ convertToReadableDate(superuser.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(superuser.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.accountLastLoginLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="superuser.dateLastLogin">
                                                {{ convertToReadableDate(superuser.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(superuser.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.accountLastModifiedLabel') }}</label>
                                        <p class="form-control-static">
                                            <template v-if="superuser.lastModified">
                                                {{ convertToReadableDate(superuser.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(superuser.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="superuser.isSuspended" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label text-uppercase">{{ $t('modules.superusers.view.suspendedOnLabel') }}</label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(superuser.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(superuser.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xs-6">
				        		<div class="form-group">
		                        	<label class="control-label text-uppercase">IDENTITY</label>
		                        	<p class="form-control-static">{{superuser.identity}}</p>
		                    	</div>
				        	</div> 
				        	<div class="col-xs-6">
				        		<div class="form-group">
		                        	<label class="control-label text-uppercase">CREATED BY</label>
		                        	<p class="form-control-static">{{superuser.createBy}}</p>
		                    	</div>
				        	</div>
				        	<div class="col-xs-6">
				        		<div class="form-group">
		                        	<label class="control-label text-uppercase">LAST UPDATED BY</label>
		                        	<p class="form-control-static">{{superuser.lastUpdatedBy}}</p>
		                    	</div>
				        	</div>-->
                        </div>
                    </template>
                </kr-panel>

                <div v-if="!auth.user().isDeletable && auth.user().uuid != superuser.uuid" class="flexRight" @click.prevent="toggle">
                    <div class="dropup col-xs-2 open">
                        <button
                            id="viewDropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            class="mainBtn width100 flexSpaceBetween align-items"
                        >
                            More Actions
                            <i
                                aria-hidden="true"
                                class="marginLeft8"
                                :class="{
                                    'fa fa-caret-up': isOpened,
                                    'fa fa-caret-down': !isOpened,
                                }"
                            ></i>
                        </button>
                        <div aria-labelledby="viewModuleDropdownMenuButton" class="dropdown-menu dropdown-menu-right dropdown-menu-new">
                            <ul>
                                <li v-if="!superuser.isSuspended">
                                    <a href="#" class="danger-state has-tooltip" @click.prevent="openSuspendModal()">
                                        <span> <i aria-hidden="true" class="fa fa-archive marginRight8"></i>Suspend Account </span>
                                    </a>
                                </li>
                                <li v-else>
                                    <a href="#" class="success-state has-tooltip" @click.prevent="openSuspendModal()">
                                        <span> <i aria-hidden="true" class="fa fa-refresh marginRight8"></i>Reinstate </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- <div
                    v-if="
                        !auth.user().isDeletable &&
                        auth.user().uuid != superuser.uuid
                    "
                    class="viewDropdown"
                    @click.prevent="toggle"
                >
                    <div class="dropdown">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn drop-down"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span class="marginRight10"> More Actions </span>
                            <span>
                                <i
                                    :class="{
                                        'fa fa-caret-up': isOpened,
                                        'fa fa-caret-down': !isOpened,
                                    }"
                                ></i>
                            </span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
                            <button
                                v-if="!superuser.isSuspended"
                                id="dropdown-suspend-account-btn"
                                class="btn btn-danger subBtn"
                                aria-label="Suspend Account"
                                @click.prevent="openSuspendModal()"
                            >
                                <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend Account
                            </button>
                            <button
                                v-else
                                id="dropdown-reinstate-btn"
                                class="btn btn-success subBtn"
                                aria-label="Reinstate"
                                @click.prevent="openSuspendModal()"
                            >
                                <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
            <div v-if="false" id="log" class="tab-pane fade in">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">ACTIVITY LOG</div>
                    </template>
                    <template #content>
                        <kr-search
                            name="log_listing"
                            :url="{ url: '/users/activity-log/index' }"
                            :columns="{
                                user: 'display:NAME|sortable:false|width:35%|mobile:hide',
                                event: 'display:ACTION|sortable:false|width:30%',
                                dateCreated: 'display:TIMESTAMP|sortable:false|width:45%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: false,
                                query_fields: query_fields,
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link v-slot="{ navigate }" :to="{ name: 'superusers.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg marginLeft20">
                                    <router-link v-slot="{ navigate }" v-tooltip="'Back'" :to="{ name: 'superusers.index' }" custom>
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left8" aria-hidden="true"></i>
                                        </button>
                                    </router-link>
                                </div>
                            </template>
                            <!-- <template slot="top-display-from-to" slot-scope="props">
			                    <div class="displayNo">
			                        {{props.model.from}}-{{props.model.to}} of {{props.model.meta.paginator.total}}
			                    </div>
			                </template> -->
                            <template #table-title="props">
                                <th colspan="6">All Logs ( {{ props.model.from }} - {{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }}-{{ props.model.to }}
                                    of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>
                                <td>
                                    {{ props.model.event }}
                                </td>
                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>
        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!superuser.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                            {{ superuser.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!superuser.isSuspended" class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to suspend
                                    <b>{{ superuser.displayName }}</b>
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>
                                    You are about to reinstate
                                    <b>{{ superuser.displayName }}</b>
                                </div>
                                <div>Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="!superuser.isSuspended" class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-suspend-btn"
                                        class="btn btn-danger mainBtn"
                                        style="width: 100%"
                                        aria-label="Suspend"
                                        @click.prevent="suspend()"
                                    >
                                        <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-reinstate-btn"
                                        class="btn btn-success mainBtn"
                                        style="width: 100%"
                                        aria-label="Reinstate"
                                        @click.prevent="unsuspend()"
                                    >
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                        <div class="col-xs-12" style="text-align:center">
                           <template v-if="!superuser.isSuspended">
                           		Suspending..
                           </template>
                           <template v-else>
                           		Reinstating
                           </template>
                        </div>
                    </div>-->
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            superuser: null,
            auth: auth,
            query_fields: { dateFrom: '', dateTo: '' },
            isOpened: false,
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-crown"></i>&nbsp;' + this.$t('modules.superusers.moduleLabel'),
            breadcrumb: [
                { name: 'Home' },
                { name: this.$t('modules.superusers.listing.breadcrumbLabel'), link: 'superusers.index' },
                { name: this.$t('modules.superusers.view.breadcrumbLabel') },
            ],
        });
        this.fetch();
    },
    methods: {
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        suspend() {
            var that = this;
            $('#suspendModal').modal('hide');
            var success = function () {
                //this.superuser.isSuspended = true;
                that.fetch();
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.superuser.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.superuser.uuid + '/suspend', {})
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function () {
                var that = this;
                this.superuser.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.superuser.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.superuser.uuid + '/reinstate', {})
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal() {
            Events.fire('open-reset-password-modal', {
                user: this.superuser,
                organisation: 'superusers',
            });
        },
        fetch() {
            let that = this;
            axios.get('/users/' + this.$route.params.id).then(function (response) {
                that.superuser = response.data.data;
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: that.$t('modules.superusers.moduleLabel'), link: 'superusers.index' },
                        { name: that.superuser.displayName },
                    ],
                });
                that.component_done_loading = true;
            });
        },
        toggle(event) {
            this.isOpened = !this.isOpened;
        },
    },
};
</script>
<style scoped>
@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}
</style>
