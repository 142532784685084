<template>
    <div class="col-xs-12 col-md-9 paddingRight10 question marginBottom20">
        <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>
    </div>

    <fieldset class="matching-display-question">
        <legend>Matching (one-to-many)</legend>

        <template v-if="!isMobileView">
            <div class="two-grid">
                <label class="control-label capitalize">{{ question.options.labels.prompt }}</label>
                <label v-if="question.displayAnswer" class="control-label capitalize">{{ question.options.labels.answer }}</label>
            </div>

            <template v-for="(option, option_idx) in question.optionKeys">
                <div class="discussionOption">
                    <div class="two-grid categorize-container">
                        <div>
                            <label class="control-label capitalize">{{ question.options.labels.prompt }} {{ option_idx + 1 }}</label>
                            <template v-for="prompt in filteringOptions(question.options.options, 'prompt', option_idx)">
                                <p>{{ prompt.content }}</p>
                            </template>
                        </div>

                        <div v-if="question.displayAnswer">
                            <dl class="margin0">
                                <dt class="control-label capitalize">{{ question.options.labels.answer }} {{ option_idx + 1 }}</dt>
                                <dd
                                    v-for="answer in filteringOptions(question.options.options, 'answer', option_idx)"
                                    class="flexOnly categorize-option categorize-correct"
                                >
                                    <i class="fa fa-check-circle marginRight8"></i>
                                    <p>{{ answer.content }}</p>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div v-if="question.displayAnswerStatistics" class="marginTop10">
                        <label
                            :id="'percentage-qns-' + getQuestionNo(question.group - 1, question.order - 1) + '-case-' + (option_idx + 1)"
                            class="control-label"
                        >
                            Percentage of team/s that got it correctly {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                        </label>
                        <div
                            class="discussion-bar"
                            role="progressbar"
                            :aria-labelledby="'percentage-qns-' + getQuestionNo(question.group - 1, question.order - 1) + '-case-' + (option_idx + 1)"
                            :aria-valuenow="option.percent.toFixed(0)"
                        >
                            <template v-if="option.percent.toFixed(0) >= 10">
                                <div class="barProgress" :style="'width:' + option.percent.toFixed(0) + '%'">{{ option.percent.toFixed(0) }}%</div>
                            </template>
                            <template v-else>
                                <div class="col-xs-12">{{ option.percent.toFixed(0) }}%</div>
                            </template>
                        </div>

                        <div class="marginTop10">
                            <label class="control-label">
                                {{ option.answersPerOption.length }}
                                <template v-if="option.answersPerOption.length <= 1"> Response </template>
                                <template v-else> Responses </template> got it correct
                            </label>
                            <div class="discussion-buttons">
                                <template v-for="(model, model_idx) in option.answersPerOption">
                                    <template v-if="model.student">
                                        <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown marginRight20">
                                            <button
                                                id="popOver"
                                                class="btn subBtn marginBottom10"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="model.student.fullname"
                                            >
                                                {{ truncate(model.student.fullname) }}&nbsp;<i class="far fa-comment-dots" />
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold">Elaboration</span>
                                                    </div>
                                                    <div v-if="model.comment">
                                                        <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                        <template v-if="model.comment.length > 400">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                    </div>
                                                    <div>
                                                        <template v-if="model.attachments && model.attachments.length != 0">
                                                            <div v-for="(file, idx) in model.attachments" class="flex maxHeight100px">
                                                                <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>
                                                            <template v-if="model.attachments.length > 4">
                                                                <button
                                                                    class="btn btn-link marginTop10 fw-normal"
                                                                    @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                                >
                                                                    View More
                                                                </button>
                                                            </template>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ul v-for="(student, idx) in model.team.members">
                                                            <li>
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <template v-if="model.team.members.length > 4">
                                                        <button
                                                            class="btn btn-link marginTop10 fw-normal"
                                                            @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                                        >
                                                            View More
                                                        </button>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <button
                                                class="btn subBtn marginBottom10 cursor-norm"
                                                :aria-label="model.student.fullname"
                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'student', testObj)"
                                            >
                                                {{ truncate(model.student.fullname) }}
                                            </button>
                                        </template>
                                    </template>

                                    <template v-else-if="model.team">
                                        <div v-if="(model.attachments && model.attachments.length != 0) || model.comment" class="dropdown marginRight20">
                                            <button
                                                id="popOver"
                                                class="btn subBtn marginBottom10"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="model.team.name"
                                            >
                                                {{ truncate(model.team.name) }}&nbsp;<i class="far fa-comment-dots" />
                                            </button>
                                            <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                <div>
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-comments marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold"> Elaboration </span>
                                                    </div>
                                                    <div v-if="model.comment">
                                                        <span class="whiteSpacePreWrap">{{ truncate(model.comment, 400) }}</span>
                                                        <template v-if="model.comment.length > 400">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                    <div v-else>-</div>
                                                </div>
                                                <div class="marginTop20">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-paperclip marginRight5 greyText" />
                                                        <span class="lineHeight08 fontBold"> Attachment(s) </span>
                                                    </div>
                                                    <div>
                                                        <template v-if="model.attachments && model.attachments.length != 0">
                                                            <div
                                                                v-for="(file, idx) in model.attachments"
                                                                :key="'attachment_file_' + idx"
                                                                class="flex maxHeight100px"
                                                            >
                                                                <span class="marginRight5"> {{ idx + 1 }}. </span>
                                                                <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{
                                                                    file.filename
                                                                }}</a>
                                                            </div>
                                                            <template v-if="model.attachments.length > 4">
                                                                <button
                                                                    class="btn btn-link marginTop10 fw-normal"
                                                                    @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                                >
                                                                    View More
                                                                </button>
                                                            </template>
                                                        </template>
                                                        <template v-else> - </template>
                                                    </div>
                                                </div>
                                                <div v-if="testObj.others.applicationType == 'team'" class="marginTop20 previewModelTeam">
                                                    <div class="flexOnly marginBottom5 align-items">
                                                        <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                        <span class="lineHeight08 fontBold">Team Members</span>
                                                    </div>
                                                    <div class="maxHeight100px">
                                                        <ul v-for="(student, idx) in model.team.members" :key="'team_members_' + idx">
                                                            <li>
                                                                {{ student.displayName }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <template v-if="model.team.members.length > 4">
                                                        <button
                                                            class="btn btn-link marginTop10 fw-normal"
                                                            @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                        >
                                                            View More
                                                        </button>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-else>
                                            <div class="dropdown marginRight20">
                                                <button
                                                    id="popOver"
                                                    class="btn subBtn marginBottom10"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    :aria-label="model.team.name"
                                                >
                                                    {{ truncate(model.team.name) }}
                                                </button>
                                                <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                    <div v-if="testObj.others.applicationType == 'team'" class="previewModelTeam">
                                                        <div class="flexOnly marginBottom5 align-items">
                                                            <i class="fas fa-users marginRight8 greyText" />
                                                            <span class="lineHeight08 fontBold"> Team Members </span>
                                                        </div>
                                                        <div class="maxHeight100px">
                                                            <ul v-for="(student, idx) in model.team.members">
                                                                <li>
                                                                    {{ student.displayName }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <template v-if="model.team.members.length > 4">
                                                            <button
                                                                class="btn btn-link marginTop10 fw-normal"
                                                                @click.prevent="$refs['teammatesInfoComponent'].read(question, model, 'team', testObj)"
                                                            >
                                                                View More
                                                            </button>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>

        <template v-else>
            <template v-for="(option, option_idx) in question.optionKeys">
                <div class="discussionOption">
                    <div class="sequencing-div-student">
                        <div>
                            <label :id="'matching-one-to-many-instructor-mobile-prompt-label-' + index" class="control-label capitalize">
                                {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                            </label>
                            <template v-for="prompt in filteringOptions(question.options.options, 'prompt', option_idx)">
                                <p>{{ prompt.content }}</p>
                            </template>
                        </div>

                        <div v-if="question.displayAnswer" class="marginTop20">
                            <dl class="margin0">
                                <dt class="control-label capitalize">
                                    {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                                </dt>
                                <dd
                                    v-for="answer in filteringOptions(question.options.options, 'answer', option_idx)"
                                    class="flexOnly categorize-option categorize-correct"
                                >
                                    <i class="fa fa-check-circle marginRight8"></i>
                                    <p>{{ answer.content }}</p>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </fieldset>

    <TeammatesInfoComponent ref="teammatesInfoComponent" />
</template>

<script setup>
import TeammatesInfoComponent from '../actions/teammatesInfo.vue';

const props = defineProps({
    testObj: {
        type: Object,
        default: null,
    },
    question: {
        type: Object,
        default: null,
    },
});

const filteringOptions = (options, type, idx) => {
    let arr = [];

    Object.keys(options[idx]).forEach((key) => {
        if (options[idx][key].type == type) {
            arr.push({ ...options[idx][key], key: key });
        }
    });

    return arr;
};

const truncate = (str, characters = 10) => {
    return _.truncate(str, {
        length: characters,
        omission: '...',
    });
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += props.testObj.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};
</script>
