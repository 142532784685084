import axios from 'axios';
export function useDownload() {
    function download(filename = '') {
        axios({
            url: 'downloadables/templates',
            method: 'POST',
            data: {
                filename: filename,
            },
            responseType: 'blob', // important
        })
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(function (errors) {});
    }
    return { download };
}
