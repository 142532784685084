<template>
    <aside class="statusBar no-print sidebar-stepper">
        <div class="panel">
            <div class="panel-heading flexSpaceBetween align-items">
                <div class="hidden-xs hidden-sm">
                    <button
                        class="btn marginLeft0"
                        :aria-expanded="sideBarClosedValue ? 'false' : 'true'"
                        aria-label="Question Status"
                        @click.prevent="getPanelHeight()"
                    >
                        <template v-if="sideBarClosedValue"> Qns Status </template>
                        <template v-else> Question Status </template>
                        <i id="toggleIcon" class="fas fa-angles-right marginLeft8" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden-md hidden-lg">
                    <button class="btn" aria-label="Close Sidebar" @click="closeTestSidebar">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>
                </div>
            </div>

            <div class="panel-body test-sideBar-panel-body" :class="{ 'panel-body-closed': sideBarClosedValue }">
                <div class="sidebar-filtering">
                    <label id="filtering-question-v2" class="control-label">
                        Filtering Question
                        <span class="visible-hidden"> &nbsp;{{ sort }} </span>
                    </label>
                    <div v-if="!sideBarClosedValue" class="dropdown menuText width100">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn width100 flexSpaceBetween alignBaseline capitalize margin0"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            aria-labelledby="filtering-question-v2"
                        >
                            {{ sort }}
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                        </button>
                        <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                            <ul>
                                <li>
                                    <a
                                        :class="{
                                            'active disabled': sort == 'all',
                                        }"
                                        href="#"
                                        @click="
                                            sort = 'all';
                                            getNumberOfQns();
                                        "
                                    >
                                        All
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :class="{
                                            'active disabled': sort == 'answered',
                                        }"
                                        href="#"
                                        @click="
                                            sort = 'answered';
                                            getNumberOfQns();
                                        "
                                    >
                                        Answered
                                    </a>

                                    <ul>
                                        <li v-if="testObj.type == 'trat'">
                                            <i class="fa-solid fa-turn-up fa-rotate-90" aria-hidden="true" />
                                            <a
                                                :class="{
                                                    'active disabled': sort == 'wrong answer',
                                                }"
                                                href="#"
                                                @click="
                                                    sort = 'wrong answer';
                                                    getNumberOfQns();
                                                "
                                            >
                                                Wrong Answer
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a
                                        :class="{
                                            'active disabled': sort == 'not answered',
                                        }"
                                        href="#"
                                        @click="
                                            sort = 'not answered';
                                            getNumberOfQns();
                                        "
                                    >
                                        Not Answered
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <template v-else>
                        <div>
                            <template v-if="sort == 'all'">
                                <div class="tableStatusTag whiteSpaceNowrap default">
                                    <p>All</p>
                                </div>
                            </template>
                            <template v-if="sort == 'not answered'">
                                <div class="tableStatusTag whiteSpaceNowrap danger">
                                    <p>Not Answered</p>
                                </div>
                            </template>
                            <template v-if="sort == 'answered'">
                                <div class="tableStatusTag whiteSpaceNowrap answered">
                                    <p>Answered</p>
                                </div>
                            </template>
                            <template v-if="sort == 'wrong answer'">
                                <div class="tableStatusTag whiteSpaceNowrap danger">
                                    <p>Wrong Answer</p>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>

                <div class="test-sideBar-panel-body-content">
                    <template v-if="getNumbers == 0">
                        <template v-if="sort == 'not answered'">
                            <p>You have <b>Answered</b> all the questions.</p>
                        </template>
                        <template v-if="sort == 'answered'">
                            <p>You have <b>Not Answered</b> any question.</p>
                        </template>
                        <template v-if="sort == 'wrong answer'">
                            <p>You have <b>Not Get Wrong Answer</b> for any question.</p>
                        </template>
                    </template>

                    <nav class="sidebar-navigator-V2" aria-label="Sidebar Navigator">
                        <ul>
                            <template v-for="(qGroup, qGroupIdx) in testObj.questions">
                                <template
                                    v-if="
                                        parseInt(qGroupIdx) <= parseInt(questionBreakIdx) ||
                                        !qGroup[0].settings.answerQuestionsForEachTeammate ||
                                        qGroup[0].type == 'pointDistribution'
                                    "
                                >
                                    <li
                                        v-if="
                                            (qGroup[0].type == 'pointDistribution' && canDisplayStem(qGroup[0])) ||
                                            (qGroup[0].type != 'pointDistribution' && countQuestionLeft('stems', qGroup[0].questionStems).sort > 0)
                                        "
                                        :id="
                                            activateActiveId(testSettings.currentQuestionGroupUuid == qGroup[0].uuid && testSettings.currentStudentIdx == null)
                                        "
                                        :class="{ active: testSettings.currentQuestionGroupUuid == qGroup[0].uuid && testSettings.currentStudentIdx == null }"
                                    >
                                        <template v-if="qGroup[0].type != 'pointDistribution'">
                                            <a
                                                :id="'header_' + parseInt(qGroupIdx) + '_standalone'"
                                                href="#"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click="toggleSubMenu(parseInt(qGroupIdx) + '_standalone', $event)"
                                            >
                                                <div :class="{ withCounter: accumulateCriteria(qGroup[0]) > 0 }">
                                                    <div
                                                        class="step-counter withDynamicMenuHeight"
                                                        :class="{
                                                            'step-counter-current':
                                                                testSettings.currentQuestionGroupUuid == qGroup[0].uuid &&
                                                                testSettings.currentStudentIdx == null,
                                                            'step-counter-incomplete':
                                                                accumulateCriteria(qGroup[0]) > 0 &&
                                                                accumulateCriteria(qGroup[0]) < Object.keys(qGroup[0].questionStems).length,
                                                            'step-counter-completed material-icons':
                                                                (!allQnsNotRequired(qGroup[0]) && isQuestionAnswered(testObj.questions[qGroupIdx][0])) ||
                                                                (allNotRequiredAnswered(qGroup[0]) && allQnsNotRequired(qGroup[0])),
                                                        }"
                                                        :aria-hidden="isQuestionAnswered(testObj.questions[qGroupIdx][0])"
                                                    >
                                                        <template
                                                            v-if="
                                                                (!allQnsNotRequired(qGroup[0]) && isQuestionAnswered(testObj.questions[qGroupIdx][0])) ||
                                                                (allNotRequiredAnswered(qGroup[0]) && allQnsNotRequired(qGroup[0]))
                                                            "
                                                        >
                                                            check
                                                        </template>
                                                        <span v-else aria-hidden="true">
                                                            {{ globalInitials(qGroup[0].question.name) }}
                                                            <span v-if="accumulateCriteria(qGroup[0]) > 0" class="alert-icon"></span>
                                                        </span>
                                                    </div>

                                                    <label>{{ qGroup[0].question.name }}</label>
                                                </div>

                                                <div>
                                                    <span v-if="accumulateCriteria(qGroup[0]) > 0 && sort != 'answered'" class="fs-14px marginRight8">
                                                        <b>
                                                            {{ accumulateCriteria(qGroup[0]) }}
                                                            Not Answered
                                                        </b>
                                                    </span>
                                                    <i
                                                        class="fa fa-caret-up"
                                                        :class="{ 'fa-caret-down': selectedSubmenu.includes(parseInt(qGroupIdx) + '_standalone') }"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <a
                                                href="#"
                                                @click="
                                                    testSettings.currentQuestionGroupIdx = qGroupIdx - 1;
                                                    testSettings.currentStudentIdx = null;
                                                    testSettings.currentQuestionGroupUuid = qGroup[0].uuid;
                                                "
                                            >
                                                <div :class="{ withCounter: accumulateCriteria(qGroup[0]) > 0 }">
                                                    <div
                                                        class="step-counter"
                                                        :class="{
                                                            'step-counter-current':
                                                                testSettings.currentQuestionGroupUuid == qGroup[0].uuid &&
                                                                testSettings.currentStudentIdx == null,
                                                            'step-counter-completed material-icons':
                                                                (!allQnsNotRequired(qGroup[0]) && isQuestionAnswered(testObj.questions[qGroupIdx][0])) ||
                                                                (allQnsNotRequired(qGroup[0]) && !accumulateCriteria(qGroup[0])),
                                                        }"
                                                        :aria-hidden="isQuestionAnswered(testObj.questions[qGroupIdx][0])"
                                                    >
                                                        <template
                                                            v-if="
                                                                (!allQnsNotRequired(qGroup[0]) && isQuestionAnswered(testObj.questions[qGroupIdx][0])) ||
                                                                (allQnsNotRequired(qGroup[0]) && !accumulateCriteria(qGroup[0]))
                                                            "
                                                        >
                                                            check
                                                        </template>
                                                        <span v-else aria-hidden="true">
                                                            {{ globalInitials(qGroup[0].question.name) }}
                                                            <span v-if="accumulateCriteria(qGroup[0]) > 0" class="alert-icon"></span>
                                                        </span>
                                                    </div>

                                                    <label>{{ qGroup[0].question.name }}</label>
                                                    <span v-if="qGroup[0].isRequired" class="important" aria-label="required">*</span>
                                                </div>

                                                <div v-if="accumulateCriteria(qGroup[0]) > 0 && sort != 'answered'">
                                                    <span class="fs-14px">
                                                        <b>
                                                            {{ accumulateCriteria(qGroup[0]) }}
                                                            Not Answered
                                                        </b>
                                                    </span>
                                                </div>
                                            </a>
                                        </template>
                                    </li>
                                    <ul
                                        v-if="selectedSubmenu.includes(parseInt(qGroupIdx) + '_standalone')"
                                        :id="'sub_' + parseInt(qGroupIdx) + '_standalone'"
                                        class="standalone-sub-stems"
                                    >
                                        <template v-for="(stem, stemIdx) in qGroup[0].questionStems">
                                            <li
                                                v-if="canDisplayStem(stem)"
                                                :key="'stem_' + stemIdx"
                                                :class="{
                                                    active:
                                                        testSettings.currentQuestionGroupIdx == qGroupIdx - 1 &&
                                                        testSettings.currentQuestionGroupUuid == qGroup[0].uuid &&
                                                        testSettings.currentStudentIdx == null,
                                                }"
                                            >
                                                <a
                                                    href="#"
                                                    @click="
                                                        testSettings.currentQuestionGroupIdx = qGroupIdx - 1;
                                                        testSettings.currentStudentIdx = null;
                                                        testSettings.currentQuestionStemIdx = stemIdx - 1;
                                                        testSettings.currentQuestionGroupUuid = qGroup[0].uuid;
                                                    "
                                                >
                                                    <div>
                                                        <div
                                                            class="step-counter"
                                                            :class="{
                                                                'step-counter-current':
                                                                    testSettings.currentQuestionStemIdx == stemIdx - 1 &&
                                                                    testSettings.currentQuestionGroupIdx == qGroupIdx - 1 &&
                                                                    testSettings.currentQuestionGroupUuid == qGroup[0].uuid &&
                                                                    testSettings.currentStudentIdx == null,
                                                                'step-counter-completed material-icons': isStemAnswered(stem),
                                                            }"
                                                            :aria-label="'Question ' + stemIdx"
                                                        >
                                                            <template v-if="isStemAnswered(stem)"> check </template>
                                                            <template v-else> Q{{ stemIdx }} </template>
                                                        </div>
                                                        <span class="material-icons" aria-hidden="true">
                                                            <template v-if="qGroup[0].type == 'ratingV2'"> star </template>
                                                            <template v-if="qGroup[0].type == 'openendedV2'"> format_align_left </template>
                                                            <template v-if="qGroup[0].type == 'pointDistribution'"> tune </template>
                                                        </span>
                                                        <label class="fw-normal">{{ stem.stem }}</label>
                                                        <span v-if="stem.isRequired" class="important" aria-label="required">*</span>
                                                    </div>
                                                    <div
                                                        v-if="!sideBarClosedValue"
                                                        class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status marginLeft8"
                                                        :class="{
                                                            answered: isStemAnswered(stem),
                                                            danger: !isStemAnswered(stem),
                                                        }"
                                                    >
                                                        <p v-if="!isStemAnswered(stem)">Not Answered</p>
                                                        <p v-else>Answered</p>
                                                    </div>
                                                </a>
                                            </li>
                                        </template>
                                    </ul>
                                </template>
                                <template v-else-if="parseInt(qGroupIdx) == parseInt(questionBreakIdx) + 1">
                                    <template v-for="(member, memberIdx) in testObj.team">
                                        <li
                                            v-if="countQuestionLeft('questions', testObj.questions, [member.id]).totalQuestions > 0"
                                            :id="activateActiveId(testSettings.currentStudentIdx == memberIdx)"
                                            :class="{ active: testSettings.currentStudentIdx == memberIdx }"
                                        >
                                            <a
                                                :id="'header_' + parseInt(qGroupIdx) + '_' + memberIdx"
                                                href="#"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click="toggleSubMenu(parseInt(qGroupIdx) + '_' + memberIdx, $event)"
                                            >
                                                <div
                                                    :class="{
                                                        withCounter:
                                                            accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered > 0,
                                                    }"
                                                >
                                                    <div
                                                        class="step-counter withDynamicMenuHeight"
                                                        :class="{
                                                            'step-counter-current': testSettings.currentStudentIdx == memberIdx,
                                                            'step-counter-incomplete':
                                                                accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered > 0 &&
                                                                accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered <
                                                                    accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).totalQuestions,
                                                            'step-counter-completed material-icons': isReceiverCompletedAnswers(member.id),
                                                        }"
                                                        :aria-hidden="isReceiverCompletedAnswers(member.id)"
                                                    >
                                                        <template v-if="isReceiverCompletedAnswers(member.id)"> check </template>
                                                        <span v-else aria-hidden="true">
                                                            {{ globalInitials(member.displayName) }}
                                                            <span
                                                                v-if="
                                                                    accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered > 0
                                                                "
                                                                class="alert-icon"
                                                            ></span>
                                                        </span>
                                                    </div>

                                                    <label>
                                                        {{ member.displayName }}
                                                    </label>
                                                </div>

                                                <div>
                                                    <span
                                                        v-if="
                                                            accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered > 0 &&
                                                            sort != 'answered'
                                                        "
                                                        class="fs-14px marginRight8"
                                                    >
                                                        <b>
                                                            {{ accumulateStudentsCriteria(testObj.questions, questionBreakIdx, [member.id]).notAnswered }} Not
                                                            Answered
                                                        </b>
                                                    </span>
                                                    <i
                                                        class="fa fa-caret-up"
                                                        :class="{ 'fa-caret-down': selectedSubmenu.includes(parseInt(qGroupIdx) + '_' + memberIdx) }"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </a>
                                        </li>
                                        <ul
                                            v-if="selectedSubmenu.includes(parseInt(qGroupIdx) + '_' + memberIdx)"
                                            :id="'sub_' + parseInt(qGroupIdx) + '_' + memberIdx"
                                            class="sub-menus"
                                        >
                                            <template v-for="(qGroup2, qGroupIdx2) in testObj.questions" :key="qGroupIdx2">
                                                <li
                                                    v-if="
                                                        qGroup2[0].type != 'pointDistribution' &&
                                                        qGroup2[0].settings.answerQuestionsForEachTeammate &&
                                                        countQuestionLeft('stems', qGroup2[0].questionStems, [member.id]).sort > 0
                                                    "
                                                    :class="{
                                                        active:
                                                            testSettings.currentQuestionGroupUuid == qGroup2[0].uuid &&
                                                            testSettings.currentStudentIdx == memberIdx,
                                                    }"
                                                >
                                                    <div :id="'criteria_' + qGroupIdx2" class="students_criteria">
                                                        <div
                                                            class="step-counter withSubDynamicHeight"
                                                            :class="{
                                                                'step-counter-current':
                                                                    testSettings.currentQuestionGroupUuid == qGroup2[0].uuid &&
                                                                    testSettings.currentStudentIdx == memberIdx,
                                                                'step-counter-incomplete':
                                                                    accumulateCriteria(qGroup2[0], [member.id]) > 0 &&
                                                                    accumulateCriteria(qGroup2[0], [member.id]) < Object.keys(qGroup2[0].questionStems).length,
                                                                'step-counter-completed material-icons':
                                                                    (!allQnsNotRequired(qGroup2[0], [member.id]) &&
                                                                        isQuestionAnswered(testObj.questions[qGroupIdx2][0], [member.id])) ||
                                                                    (allNotRequiredAnswered(qGroup2[0], [member.id]) &&
                                                                        allQnsNotRequired(qGroup2[0], [member.id])),
                                                            }"
                                                            :aria-hidden="isQuestionAnswered(testObj.questions[qGroupIdx2][0], [member.id])"
                                                        >
                                                            <template
                                                                v-if="
                                                                    (!allQnsNotRequired(qGroup2[0], [member.id]) &&
                                                                        isQuestionAnswered(testObj.questions[qGroupIdx2][0], [member.id])) ||
                                                                    (allNotRequiredAnswered(qGroup2[0], [member.id]) &&
                                                                        allQnsNotRequired(qGroup2[0], [member.id]))
                                                                "
                                                            >
                                                                check
                                                            </template>
                                                            <span v-else aria-hidden="true">
                                                                {{ globalInitials(qGroup2[0].question.name) }}
                                                            </span>
                                                        </div>
                                                        <label>{{ qGroup2[0].question.name }}</label>
                                                    </div>
                                                    <ul class="sub-stems" :aria-labelledby="'criteria_' + qGroupIdx2">
                                                        <template v-for="(stem, stemIdx) in qGroup2[0].questionStems">
                                                            <li v-if="canDisplayStem(stem, [member.id])" :key="'stem_' + stemIdx">
                                                                <a
                                                                    href="#"
                                                                    @click="
                                                                        testSettings.currentQuestionGroupIdx = qGroupIdx2 - 1;
                                                                        testSettings.currentStudentIdx = memberIdx;
                                                                        testSettings.currentQuestionStemIdx = stemIdx - 1;
                                                                        testSettings.currentQuestionGroupUuid = qGroup2[0].uuid;
                                                                    "
                                                                >
                                                                    <div>
                                                                        <div
                                                                            class="step-counter"
                                                                            :class="{
                                                                                'step-counter-current':
                                                                                    testSettings.currentQuestionGroupIdx == qGroupIdx2 - 1 &&
                                                                                    testSettings.currentStudentIdx == memberIdx &&
                                                                                    testSettings.currentQuestionStemIdx == stemIdx - 1 &&
                                                                                    testSettings.currentQuestionGroupUuid == qGroup2[0].uuid,
                                                                                'step-counter-completed material-icons': isStemAnswered(stem, [member.id]),
                                                                            }"
                                                                            :aria-label="'Question ' + stemIdx"
                                                                        >
                                                                            <template v-if="isStemAnswered(stem, [member.id])"> check </template>
                                                                            <template v-else> Q{{ stemIdx }} </template>
                                                                        </div>

                                                                        <span class="material-icons" aria-hidden="true">
                                                                            <template v-if="qGroup2[0].type == 'ratingV2'"> star </template>
                                                                            <template v-if="qGroup2[0].type == 'openendedV2'"> format_align_left </template>
                                                                            <template v-if="qGroup2[0].type == 'pointDistribution'"> tune </template>
                                                                        </span>
                                                                        <label class="fw-normal">{{ stem.stem }}</label>
                                                                        <span v-if="stem.isRequired" class="important" aria-label="required">*</span>
                                                                    </div>
                                                                    <div
                                                                        v-if="!sideBarClosedValue"
                                                                        class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status marginLeft8"
                                                                        :class="{
                                                                            answered: isStemAnswered(stem, [member.id]),
                                                                            danger: !isStemAnswered(stem, [member.id]),
                                                                        }"
                                                                    >
                                                                        <p v-if="!isStemAnswered(stem, [member.id])">Not Answered</p>
                                                                        <p v-else>Answered</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </li>
                                            </template>
                                        </ul>
                                    </template>
                                </template>
                            </template>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </aside>
</template>

<script setup>
import useQuestion from '../../../../../../../src/composables/studentTest/question.ts';
import useTest from '../../../../../../../src/composables/studentTest/test.ts';
import { ref, onMounted, watch, nextTick, computed, defineEmits } from 'vue';

const props = defineProps({
    sideBarClosedValue: {
        type: Boolean,
    },
    testObj: {
        type: Object,
        default: () => ({}),
    },
    testSettings: {
        type: Object,
        default: () => ({}),
    },
});

const { isReceiverCompletedAnswers } = useTest(props.testObj);
const { isQuestionAnswered, isStemAnswered, accumulateCriteria, accumulateStudentsCriteria } = useQuestion();

const sort = ref('not answered');
const getNumbers = ref();
const questionBreakIdx = ref(0);

const initQuestions = () => {
    for (const [key, value] of Object.entries(props.testObj.questions)) {
        let qGroup = value[0];
        if (!qGroup.settings.answerQuestionsForEachTeammate || qGroup.type == 'pointDistribution') {
            questionBreakIdx.value = key;
        } else {
            break;
        }
    }
};

const getNumberOfQns = () => {
    nextTick(() => {
        getNumbers.value = $('.sidebar-navigator-V2 > ul > li:visible').length;
    });
};

const selectedSubmenu = ref([]);

const toggleSubMenu = (index, event) => {
    var currentMenu = event.target.closest('li');
    var getDynamicHeight = currentMenu.querySelector('.withDynamicMenuHeight');

    if (selectedSubmenu.value.includes(index)) {
        for (var i = 0; i < selectedSubmenu.value.length; i++) {
            if (selectedSubmenu.value[i] === index) {
                selectedSubmenu.value.splice(i, 1);
                break;
            }
        }

        nextTick(() => {
            getDynamicHeight.style.setProperty('--dynamic-height', `32px`);
        });
    } else {
        selectedSubmenu.value.push(index);
        nextTick(() => {
            var subMenusHeight = currentMenu.nextElementSibling.clientHeight;
            getDynamicHeight.style.setProperty('--dynamic-height', `${subMenusHeight + 32}px`);

            var subItems = currentMenu.nextElementSibling.querySelectorAll('.sub-menus > li');
            if (subItems) {
                subItems.forEach(function (subItem) {
                    subItem.style.setProperty('--sub-dynamic-height', `${subItem.querySelector('.sub-stems').clientHeight}px`);
                });
            }
        });
    }
};

const getSidebarHeight = () => {
    nextTick(() => {
        const topBarHeight = $('.test_topBar_evaluation_placeholder').height();
        const footerHeight = $('footer').height() + 24;
        const calculationStatusBar = 'calc(100% - (' + topBarHeight + 'px + ' + footerHeight + 'px))';

        $('.sideBarStatusV2 .statusBar').css('height', calculationStatusBar);

        const panelHeading = $('.sideBarStatusV2 .statusBar .panel-heading').height() + 33;
        const calculationPanelBody = 'calc(100% - ' + panelHeading + 'px)';

        $('.sideBarStatusV2 .statusBar .panel-body').css({
            height: calculationPanelBody,
            overflow: 'hidden',
        });

        const sideBarFiltering = $('.sideBarStatusV2 .statusBar .panel-body .sidebar-filtering').height() + 33;
        const calculationPanelContent = 'calc(100% - ' + sideBarFiltering + 'px)';

        $('.sideBarStatusV2 .statusBar .test-sideBar-panel-body-content').css('height', calculationPanelContent);
    });
};

const canDisplayStem = (stem, receiverIds = []) => {
    var status = false;

    if (stem.type == 'pointDistribution') {
        status = accumulateCriteria(stem) ? 0 : 1;
    } else {
        status = isStemAnswered(stem, receiverIds);
    }

    if ((status == 0 && sort.value == 'answered') || (status != 0 && sort.value == 'not answered')) {
        return false;
    }
    return true;
};

const emits = defineEmits(['evaluationSidebarClicked']);

const getPanelHeight = () => {
    emits('evaluationSidebarClicked');
    getSidebarHeight();
};

const allQnsNotRequired = (question, receiverIds = []) => {
    if (['openendedV2', 'ratingV2'].includes(question.type)) {
        const keys = Object.keys(question.questionStems);
        for (var i = 0; i < keys.length; i++) {
            let stem = question.questionStems[keys[i]];
            if (stem.isRequired) {
                return false;
            }
        }
        return true;
    } else if (question.type == 'pointDistribution') {
        if (question.isRequired) {
            return false;
        }
        return true;
    }
    return false;
};

const allNotRequiredAnswered = (question, receiverIds = []) => {
    if (allQnsNotRequired(question)) {
        if (['openendedV2', 'ratingV2'].includes(question.type)) {
            const keys = Object.keys(question.questionStems);
            for (var i = 0; i < keys.length; i++) {
                let stem = question.questionStems[keys[i]];
                if (!isStemAnswered(stem, receiverIds)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
};

const countQuestionLeft = (type, stems, receiverIds = []) => {
    var counts = {
        totalQuestions: 0,
        sort: 0,
    };

    if (type == 'stems') {
        Object.keys(stems).map((key) => {
            let status = isStemAnswered(stems[key], receiverIds);

            if (sort.value == 'answered') {
                if (status) {
                    counts.sort++;
                }
            } else if (sort.value == 'not answered') {
                if (!status) {
                    counts.sort++;
                }
            } else {
                counts.sort++;
            }
        });
    } else {
        for (const [key, value] of Object.entries(stems)) {
            let qGroup = value[0];
            if (qGroup.type != 'pointDistribution') {
                if (parseInt(key) == parseInt(questionBreakIdx.value) + 1 || qGroup.settings.answerQuestionsForEachTeammate) {
                    const QSkeys = Object.keys(qGroup.questionStems);
                    for (var i = 0; i < QSkeys.length; i++) {
                        let stem = qGroup.questionStems[QSkeys[i]];
                        if (sort.value == 'answered') {
                            if (isStemAnswered(stem, receiverIds)) {
                                counts.totalQuestions++;
                            }
                        } else if (sort.value == 'not answered') {
                            if (!isStemAnswered(stem, receiverIds)) {
                                counts.totalQuestions++;
                            }
                        } else {
                            counts.totalQuestions++;
                        }
                    }
                }
            }
        }
    }

    return counts;
};

const activateActiveId = (value) => {
    return value ? 'activated' : '';
};

const rearrangeSidebar = () => {
    selectedSubmenu.value.map((key) => {
        var splitted = key.split('_');
        var subMenusHeight;

        nextTick(() => {
            subMenusHeight = $('#sub_' + key).height();
            if ($('#header_' + key + ' .withDynamicMenuHeight')[0]) {
                $('#header_' + key + ' .withDynamicMenuHeight')[0].style.setProperty('--dynamic-height', `${subMenusHeight + 32}px`);
            }
        });

        if (splitted[1] != 'standalone') {
            nextTick(() => {
                var subItems = $('#sub_' + key + ' > li');
                if (subItems.length > 0) {
                    subItems.each(function (index, subItem) {
                        subItem.style.setProperty('--sub-dynamic-height', `${subItem.querySelector('.sub-stems').clientHeight}px`);
                    });
                }
            });
        }
    });
};

watch(props.testObj, () => {
    initQuestions();
    rearrangeSidebar();
});

watch(sort, () => {
    rearrangeSidebar();
});

onMounted(() => {
    initQuestions();

    nextTick(() => {
        getSidebarHeight();
    });
});
</script>
