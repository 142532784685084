<template>
    <div>
        <div class="col-xs-12 col-md-9 paddingRight10 question">
            <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-8 vsaLeftPanel paddingRight10">
                <template v-if="question.answers == 0">
                    <p class="fontItalic greyText">Students Have Not Answer</p>
                </template>

                <template v-for="(answer, answer_idx) in question.answers" v-else>
                    <div class="discussionOption flexOnly alignBaseline">
                        <div class="dropdown">
                            <template v-if="testObj.others.applicationType == 'team'">
                                <button
                                    id="popOver"
                                    v-tooltip="{ content: answer.team.name }"
                                    class="btn gradient btn-default"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    :aria-label="answer.team.name"
                                >
                                    <template v-if="answer.team.name.length > 10">
                                        {{ truncate(answer.team.name, 10) }}
                                    </template>
                                    <template v-else>
                                        {{ answer.team.name }}
                                    </template>
                                </button>
                            </template>
                            <template v-else>
                                <button
                                    id="popOver"
                                    v-tooltip="{
                                        content: answer.student.fullname,
                                    }"
                                    class="btn gradient btn-default"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    :aria-label="answer.student.fullname"
                                >
                                    <template v-if="answer.student.fullname.length > 10">
                                        {{ truncate(answer.student.fullname, 10) }}
                                    </template>
                                    <template v-else>
                                        {{ answer.student.fullname }}
                                    </template>
                                </button>
                            </template>

                            <div class="dropdown-menu padding10" aria-labelledby="popOver">
                                <div class="previewModelTeam">
                                    <div class="flexOnly marginBottom5 align-items">
                                        <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                        <span class="lineHeight08 fontBold">Team Members</span>
                                    </div>
                                    <div class="maxHeight100px">
                                        <ul class="marginBottom0">
                                            <li v-for="(student, idx) in answer.team.members" :key="idx">
                                                {{ student.displayName }}
                                            </li>
                                        </ul>
                                    </div>
                                    <template v-if="answer.team.members.length > 4">
                                        <div class="blue pointer marginTop10" aria-label="View More" @click="openModelInfoModal(answer)">View More</div>
                                    </template>
                                </div>
                            </div>
                        </div>

                        <div v-if="question.displayAnswerStatistics" class="marginLeft20">
                            <template v-if="answer.answer != null">
                                <i
                                    class="fa"
                                    :class="{
                                        'fa-check-circle successColor marginRight5': answer.isCorrect && question.displayAnswer,
                                        'fa-times-circle dangerColor marginRight5': !answer.isCorrect && question.displayAnswer,
                                    }"
                                    aria-hidden="true"
                                ></i>
                                <p class="wordBreakWord whiteSpacePreWrap displayInlineBlock" v-html="answer.answer"></p>
                            </template>
                            <template v-else>
                                <div class="fontItalic greyText">
                                    <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                    <template v-else> Student </template>
                                    Has Not Answered Yet.
                                </div>
                            </template>

                            <div class="marginTop20">
                                <div class="flexOnly marginBottom5 align-items">
                                    <i class="fas fa-comments marginRight5 greyText" aria-hidden="true"></i>
                                    <span class="lineHeight08 fontBold"> Elaboration </span>
                                </div>
                                <div v-if="answer.comment">
                                    <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                    <template v-if="answer.comment.length > 400">
                                        <div class="marginTop10 blue pointer" aria-label="View More" @click="openModelInfoModal(answer)">View More</div>
                                    </template>
                                </div>
                                <div v-else>-</div>
                            </div>

                            <div class="marginTop20">
                                <div class="flexOnly marginBottom5 align-items">
                                    <i class="fas fa-paperclip marginRight5 greyText" aria-hidden="true"></i>
                                    <span class="lineHeight08 fontBold">Attachment(s)</span>
                                </div>
                                <div>
                                    <template v-if="answer.attachments && answer.attachments.length != 0">
                                        <div v-for="(file, idx) in answer.attachments" :key="idx" class="flex maxHeight100px">
                                            <span class="marginRight5">{{ idx + 1 }}.</span>
                                            <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                        <template v-if="answer.attachments.length > 4">
                                            <div class="marginTop10 blue pointer" aria-label="View More" @click="openModelInfoModal(answer)">View More</div>
                                        </template>
                                    </template>
                                    <template v-else> - </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="question.displayAnswer">
                <div class="col-xs-12 col-md-4 marginTop20 paddingLeft10px">
                    <div class="vsaRightPanel">
                        <h3 class="fontBlack fs-18px marginBottom5">
                            <i class="fa fa-check-circle successColor marginRight8" aria-hidden="true"></i>
                            <b>Correct Answers</b>
                        </h3>
                        <template v-if="question.vsaSettings.correctAnswers == null">
                            <p class="fontItalic greyText padding15">There is no correct answer</p>
                        </template>
                        <template v-else>
                            <div class="tableFixHead">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Answer(s)</th>
                                            <th scope="col">
                                                No. of
                                                <template v-if="testObj.others.applicationType == 'team'"> Team(s) </template>
                                                <template v-else> Student(s) </template>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-for="(correctAnswer, idx) in question.vsaSettings.correctAnswers">
                                            <tr>
                                                <td>{{ correctAnswer }}</td>
                                                <td>
                                                    {{ calculateOfAnswer(correctAnswer) }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>

                    <div class="vsaRightPanel marginTop20">
                        <h3 class="fontBlack fs-18px marginBottom5">
                            <i class="fa fa-times-circle dangerColor marginRight8" aria-hidden="true"></i>
                            <b>Wrong Answers</b>
                        </h3>
                        <template v-if="question.vsaSettings.wrongAnswers == null">
                            <p class="fontItalic greyText padding15">There is no wrong answer</p>
                        </template>
                        <template v-else>
                            <div class="tableFixHead">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Answer(s)</th>
                                            <th scope="col">
                                                No. of
                                                <template v-if="testObj.others.applicationType == 'team'"> Team(s) </template>
                                                <template v-else> Student(s) </template>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-for="(wrongAnswer, idx) in question.vsaSettings.wrongAnswers">
                                            <tr>
                                                <td>{{ wrongAnswer }}</td>
                                                <td>
                                                    {{ calculateOfAnswer(wrongAnswer) }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>

        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 :id="'eGalleryModelModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-else-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight5 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight5 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" :key="idx" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true"></i>
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members" :key="idx">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question', 'oneColumn', 'twoColumns', 'threeColumns', 'fourColumns'],
    data() {
        return {
            previewTeam: {},
            previewAttachments: [],
            questionModal: '',
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
            previewComments: [],
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
        openModelInfoModal(answer, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = answer.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = answer.student;
            }
            this.previewModel.comment = answer.comment;
            this.previewModel.attachments = answer.attachments ? answer.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        highlightAnswerKeywords(answer = '') {
            answer = answer ? answer : '';
            var res = answer;
            var tags = this.question.tags ? this.question.tags : [];
            for (var i = 0; i < tags.length; i++) {
                res = res.replace(new RegExp(this.escapeRegExp(tags[i]), 'gi'), `<span class="highlight marginBottom10">${tags[i]}</span>`);
            }
            return res;
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        calculateOfAnswer(ans) {
            var counts = 0;

            for (var i = 0; i < this.question.answers.length; i++) {
                if (typeof this.question.answers[i].answer === 'string') {
                    if (ans == Number(this.question.answers[i].answer.replace(/,/g, '')) || ans == this.question.answers[i].answer.trim().toLowerCase()) {
                        counts++;
                    }
                }
            }

            return counts;
        },
    },
};
</script>

<style scoped lang="scss">
.col-xs-12,
.col-md-8,
.col-md-4 {
    float: left;
}
.tableFixHead {
    overflow-y: auto;
    max-height: 200px;

    table {
        margin: 0;
        border-collapse: collapse;
        width: 100%;
        border: none;

        thead {
            position: sticky;
            top: 0;
        }

        tbody {
            td {
                border-top: solid 1px #d8d8d8;
                padding: 10px 20px;

                &:last-child {
                    width: 100px;
                }
            }
        }
    }
}

.vsaRightPanel {
    border: 1px solid #d8d8d8;
    border-radius: 5px;

    h3 {
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        padding: 15px;
        margin: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

.vsaLeftPanel {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    max-height: 525px;
    overflow: auto;
}

@media (min-width: 992px) {
    .paddingLeft10px {
        padding-left: 10px;
    }
    .paddingRight10px {
        padding-left: 10px;
    }
}
</style>
