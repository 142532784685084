<template>
    <div class="col-md-3 left_col no-print" :class="{ isStudent: auth.user().role == 'Student' }">
        <div v-if="showToggle" class="left_col scroll-view">
            <!-- <div class="menu-logo-wrapper">
        		<div class="menu-logo">
		            <a v-if="showToggle" @click.prevent="toggleMenu()"class="nav toggle hidden-sm hiddex-md hidden-lg" style="position: absolute;left: 13px;top: 4px;font-size: 25px;color: white;"><i class="fa fa-bars"></i></a>
		            <router-link tag="a" :to="{name:'/'}" exact>
	        			<img src="/public/img/icons/_newicons_intedashboardlogo.png">
        			</router-link>
        		</div>
        	</div> -->
            <!-- <div class="menu-logo-wrapper hidden-md hidden-lg">
        <button v-if="showToggle" @click.prevent="toggleMenu()" class="btn">
          <i class="fa fa-bars whiteText" aria-hidden="true"></i>
        </button>
      </div> -->
            <div class="menu-logo-wrapper hidden-xs hidden-sm">
                <a href="#" class="d-block menu-logo">
                    <!-- <router-link tag="a" :to="{name:'/'}" exact> -->
                    <img src="/img/icons/_newicons_intedashboardlogo.png" width="25" alt="Intedashboard Logo" />
                    <!-- </router-link> -->
                </a>
            </div>
            <!--<router-link :to="{name:'profile.view'}">
		        <div class="profile">
		            <div class="profile_pic">
		            	<template v-if="auth.user().profileURL">
			            	<div class="actual-image" :style="'background-image:url('+auth.user().profileURL+');'">
	                        </div>
		            	</template>
		            	<template v-else>
		            		<div class="badge_profile img-circle">{{initials}}</div>
		            	</template>
		            </div>
		            <div class="profile_info text-center">
		                <span style="color:white">{{getStudentName(auth.user())}}</span>
		            </div>
		        </div>
	        </router-link>-->
            <!-- /menu profile quick info
	        <div class="clearfix"></div> -->
            <!-- sidebar menu -->
            <div id="sidebar-menu" class="main_menu_side hidden-print main_menu menu_fixed">
                <div class="menu_section">
                    <ul class="nav side-menu">
                        <router-link v-show="auth.user().role == 'Superuser'" tag="a" :to="{ name: 'accounts.index' }" aria-labelledby="Accounts">
                            <div class="menu-container">
                                <i class="fa fa-id-card" aria-hidden="true"></i>
                                <span class="menu-text">{{ $t('modules.accounts.moduleLabel') }}</span>
                            </div>
                        </router-link>

                        <router-link v-show="auth.user().role == 'Superuser'" tag="a" :to="{ name: 'superusers.index' }" aria-labelledby="Superuser">
                            <div class="menu-container">
                                <i class="fas fa-crown" aria-hidden="true"></i>
                                <span class="menu-text">{{ $t('modules.superusers.moduleLabel') }}</span>
                            </div>
                        </router-link>

                        <router-link
                            v-show="auth.user().role == 'Teacher' || auth.user().role == 'Super Admin'"
                            tag="a"
                            :to="{ name: 'courses.index' }"
                            aria-labelledby="Courses"
                        >
                            <div class="menu-container">
                                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                                <span class="menu-text">Courses</span>
                            </div>
                        </router-link>

                        <router-link
                            v-show="auth.user().role == 'Super Admin' || auth.user().role == 'Teacher'"
                            tag="a"
                            :to="{ name: 'people.index' }"
                            aria-labelledby="Teachers"
                        >
                            <div class="menu-container">
                                <i class="fa fa-users" aria-hidden="true"></i>
                                <span class="menu-text">People</span>
                            </div>
                        </router-link>

                        <!-- <router-link
              tag="a"
              :to="{ name: 'teachers.index' }"
              v-show="
                auth.user().role == 'Super Admin' ||
                  auth.user().role == 'Teacher'
              "
              aria-labelledby="Teachers"
            >
              <div class="menu-container">
                <i class="fas fa-chalkboard-teacher"></i>
                <span class="menu-text">Teachers</span>
              </div>
            </router-link>

            <router-link
              tag="a"
              :to="{ name: 'students.index' }"
              v-show="
                auth.user().role == 'Super Admin' ||
                  auth.user().role == 'Teacher' ||
                  auth.user().role == 'Superuser'
              "
              aria-labelledby="Students"
            >
              <div class="menu-container">
                <i class="fa fa-users"></i>
                <span class="menu-text">Students</span>
              </div>
            </router-link> -->

                        <!-- <router-link
              tag="a"
              :to="{ name: 'questions.index' }"
              v-show="
                (auth.user().role == 'Teacher' ||
                  auth.user().role == 'Super Admin') &&
                  auth.user().account.version == 'full'
              "
              tabindex="4"
              aria-labelledby="Questions"
            >
              <div class="menu-container">
                <i class="fa fa-list-ul"></i>
                <span class="menu-text">Questions</span>
              </div>
            </router-link> -->

                        <router-link
                            v-show="
                                (auth.user().role == 'Teacher' || auth.user().role == 'Super Admin') &&
                                auth.user().account.version == 'full' &&
                                !(auth.user().canViewQuestionBank == false)
                            "
                            tag="a"
                            :to="{ name: 'questions.index' }"
                            aria-labelledby="Library"
                        >
                            <div class="menu-container">
                                <i class="fa fa-list-ul" aria-hidden="true"></i>
                                <span class="menu-text">Library</span>
                            </div>
                        </router-link>

                        <router-link v-show="auth.user().role == 'Student'" tag="a" :to="{ name: 'tests.index' }">
                            <div class="menu-container" aria-labelledby="Activities">
                                <i class="fas fa-pencil-ruler" aria-hidden="true"></i>
                                <span class="menu-text">Activities</span>
                            </div>
                        </router-link>
                        <router-link v-show="auth.user().role == 'Student'" tag="a" :to="{ name: 'grades.index' }">
                            <div class="menu-container" aria-labelledby="Grades">
                                <i class="fa fa-book" aria-hidden="true"></i>
                                <span class="menu-text">Gradebook</span>
                            </div>
                        </router-link>

                        <router-link v-show="auth.user().role == 'Superuser'" tag="a" :to="{ name: 'report' }" aria-labelledby="Report">
                            <div class="menu-container">
                                <i class="fas fa-file-alt" aria-hidden="true"></i>
                                <span class="menu-text">{{ $t('modules.reports.moduleLabel') }}</span>
                            </div>
                        </router-link>

                        <router-link v-show="auth.user().role == 'Super Admin'" tag="a" :to="{ name: 'settings.edit' }" aria-labelledby="Report">
                            <div class="menu-container">
                                <!-- <i class="fas fa-file-alt"></i> -->
                                <i class="fa fa-wrench" aria-hidden="true"></i>
                                <span class="menu-text">Settings</span>
                            </div>
                        </router-link>

                        <a v-show="auth.user().roleId == 2 || auth.user().roleId == 3" href="https://community.intedashboard.com/" target="_blank">
                            <div class="menu-container">
                                <span class="material-icons" aria-hidden="true"> forum </span>
                                <span class="menu-text"> Community </span>
                            </div>
                        </a>
                    </ul>
                    <ul class="nav side-menu help-center">
                        <a
                            v-show="auth.user().role == 'Teacher' || auth.user().role == 'Super Admin' || auth.user().role == 'Superuser'"
                            href="https://help.intedashboard.com/contact-intedashboard-support"
                            target="_blank"
                            aria-label="Help Guides"
                        >
                            <div class="menu-container">
                                <i class="fas fa-question-circle" aria-hidden="true"></i>
                                <span class="menu-text">Help</span>
                            </div>
                        </a>
                        <a v-show="auth.user().role == 'Student'" href="https://help.intedashboard.com/learner" target="_blank" aria-label="Help Guides">
                            <div class="menu-container">
                                <i class="fas fa-question-circle" aria-hidden="true"></i>
                                <span class="menu-text">Help</span>
                            </div>
                        </a>
                    </ul>
                </div>
            </div>
            <!-- /sidebar menu -->

            <!-- /menu footer buttons -->
            <div class="sidebar-footer hidden-small" style="display: none">
                <a data-toggle="tooltip" data-placement="top" title="Settings">
                    <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
                </a>
                <a data-toggle="tooltip" data-placement="top" title="FullScreen">
                    <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
                </a>
                <a data-toggle="tooltip" data-placement="top" title="Lock">
                    <span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span>
                </a>
                <a data-toggle="tooltip" data-placement="top" title="Logout" @click.prevent="logout">
                    <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
                </a>
            </div>
            <!-- /menu footer buttons -->
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
import swal from 'sweetalert2';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            showToggle: false,
            //VUE_APP_ENV: process.env.VUE_APP_ENV,
        };
    },
    watch: {
        $route: {
            handler: function () {
                if ($(window).width() < 991) {
                    this.toggleMenu();
                }
            },
        },
    },
    created() {
        var that = this;
        if (this.$route.name == 'tests.preview') {
            this.showToggle = false;
        } else {
            this.showToggle = true;
        }
    },
    mounted() {
        if ($(window).width() < 991) {
            this.toggleMenu();
        }
    },
    methods: {
        logout() {
            swal({
                title: 'Are you sure you want to logout?',
                //text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            })
                .then((result) => {
                    Events.fire('logout', { redirect: { name: 'auth.login' } });
                })
                .catch((result) => {});
        },
        toggleMenu() {
            var $BODY = $('body'),
                $RIGHT_COL = $('.right_col'),
                $SIDEBAR_MENU = $('#sidebar-menu'),
                $NAV_MENU = $('.nav_menu'),
                $LEFT_COL = $('.left_col'),
                $SIDEBAR_FOOTER = $('.sidebar-footer'),
                $FOOTER = $('footer');
            if ($BODY.hasClass('nav-sm')) {
                $SIDEBAR_MENU.find('li.active ul').hide();
                $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
            } else {
                $SIDEBAR_MENU.find('li.active-sm ul').show();
                $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
            }
            $BODY.toggleClass('nav-sm nav-none');
            // reset height
            $RIGHT_COL.css('min-height', $(window).height());
            var bodyHeight = $BODY.outerHeight(),
                footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
                leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
                contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
            // normalize content
            contentHeight -= $NAV_MENU.height() + footerHeight;
            $RIGHT_COL.css('min-height', contentHeight);
        },
    },
};
</script>
<style scoped lang="scss">
.menu-logo {
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
    width: 25px;
    height: 52px;
    text-align: center;
    display: flex;
    align-items: center;
}
.menu_section {
    background-color: #495060;
}

.menu_section > ul {
    text-align: center;
    margin: 0;
    list-style: none;
}

.menu-img-wrapper {
    display: block;
}

.menu-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    width: 36px;
    height: 36px;
}

span.menu-text {
    display: block;
    font-size: 1rem;
    line-height: 1;
    margin-top: 2px;
}

.nav.side-menu {
    background: #495060;
}

.nav.side-menu > li,
.nav.side-menu > a {
    // padding: 6px;
    padding: 12px 8px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 64px;
    border-left: 6px solid transparent;
}

.nav.side-menu > li > a {
    color: #ffffff;
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
}

.nav.side-menu > li:hover,
.nav.side-menu > li.current-page:hover,
.nav.side-menu > li.active:hover,
.nav.side-menu > li:hover > a,
.nav.side-menu > li.current-page:hover > a,
.nav.side-menu > li.active:hover > a .nav.side-menu > li > a:hover,
.nav.side-menu > li.current-page > a :hover,
.nav.side-menu > li.active > a:hover,
.nav.side-menu > a:hover,
.nav.side-menu > a.current-page:hover,
.nav.side-menu > a.active:hover {
    background: #ffffff;
    color: #0071be;
    cursor: pointer;
    border-left: 6px solid #0071be30;
}

.nav.side-menu > li.current-page,
.nav.side-menu > li.active,
.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm,
.nav.side-menu > a.current-page,
.nav.side-menu > a.active {
    margin-left: 0;
    border-left: 6px solid #0071be;
    border-right: none;
    background: #ffffff;
    color: #0071be;
    // padding-left: 0;
}

.menu-logo-wrapper {
    background: #273142;
    padding: 10px;
}

@media (max-width: 991px) {
    .menu-logo-wrapper {
        padding: 10px 25px 10px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        text-align: center;
    }
}

.help-center {
    // position: absolute;
    position: fixed;
    bottom: 0;
    // width: 100%;
    width: 110px;
}

.isStudent {
    .left_col {
        background: #0071be;
        height: 100%;
    }
    .menu_section {
        background: none;
    }
    .nav {
        &.side-menu {
            background: none;
        }
    }

    .menu-logo-wrapper {
        background: #0071be;
    }
}
</style>
