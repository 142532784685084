<template>
    <div>
        <kr-panel :with-footer="false">
            <template #title>
                <div class="col-xs-12 flexSpaceBetween align-items">
                    <div class="col-xs-6">
                        <h2>
                            <template v-if="mode == 'view'"> Course Info </template>
                            <template v-else> Edit Course </template>
                        </h2>
                    </div>
                    <div class="flexRight textAlignRight">
                        <div class="hidden-xs hidden-sm">
                            <div
                                v-if="mode == 'view'"
                                v-tooltip="{
                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                }"
                                class="btn"
                                :class="{ 'p-0-i': hasWritePrivilege(course) }"
                            >
                                <button
                                    v-if="hasWritePrivilege(course)"
                                    class="btn btn-default mainBtn primaryHover"
                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                    aria-label="Edit Course"
                                    @click="mode = 'edit'"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitForm">Update</button>
                                <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="mode = 'view'">Cancel</button>
                            </div>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <div
                                v-if="mode == 'view'"
                                v-tooltip="{
                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Edit Course',
                                }"
                                class="btn"
                                :class="{ 'p-0-i': hasWritePrivilege(course) }"
                            >
                                <button
                                    v-if="hasWritePrivilege(course)"
                                    class="btn btn-default mainBtn primaryHover"
                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                    aria-label="Edit Course"
                                    @click="mode = 'edit'"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-success mainBtn" aria-label="Update" @click.prevent="submitForm">Update</button>
                                <button class="btn btn-default mainBtn" exact aria-label="Cancel" @click.prevent="mode = 'view'">Cancel</button>
                            </div>
                        </div>
                        <div
                            v-if="mode == 'view'"
                            v-tooltip="{
                                content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                            }"
                            class="viewDropdown dropdown marginLeft20"
                        >
                            <button
                                id="viewDropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                aria-label="More Actions"
                            >
                                More Actions
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Duplicate',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(course) == -1,
                                            }"
                                            href="#"
                                            aria-label="Duplicate"
                                            @click.prevent="openCopyCourseConfirmationModal()"
                                        >
                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Duplicate </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>

                                    <hr />

                                    <li v-if="!course.isArchived">
                                        <a
                                            v-tooltip="'Archive Course'"
                                            class="danger-state"
                                            href="#"
                                            aria-label="Archive Course"
                                            @click.prevent="openSuspendModal()"
                                        >
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive Course </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>

                                    <li v-else>
                                        <a v-tooltip="'Reinstate'" class="primary-state" href="#" aria-label="Reinstate" @click.prevent="openSuspendModal()">
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div
                class="dropdown-menu"
                aria-labelledby="viewDropdownMenuButton"
              >
                <div :class="{ 'p-0-i': hasWritePrivilege(course) }">
                  <button
                    class="btn btn-default primaryHover"
                    @click="openCopyCourseConfirmationModal()"
                    :disabled="(hasWritePrivilege(course) == -1) ? true:undefined"
                    aria-label="Duplicate"
                    v-tooltip="{
                      content:
                        hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                    }"
                  >
                    <i class="fa fa-files-o"></i>&nbsp;Duplicate
                  </button>
                </div>
                <button
                  class="btn btn-danger"
                  v-if="!course.isArchived"
                  @click.prevent="openSuspendModal()"
                  aria-label="Archive Course"
                >
                  <i class="fa fa-archive"></i>&nbsp;Archive Course
                </button>
                <button
                  class="btn btn-success"
                  v-else
                  @click.prevent="openSuspendModal()"
                  aria-label="Reinstate"
                >
                  <i class="fa fa-refresh"></i>&nbsp;Reinstate
                </button>
              </div> -->
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <div v-if="mode == 'view'" class="col-xs-12 col-md-6 viewFullDetails">
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Name </label>
                            <p class="form-control-static">
                                {{ course.name }}
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Code </label>
                            <p class="form-control-static">
                                {{ course.code ? course.code : '-' }}
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Starts From </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{
                                    convertToReadableDate(course.startDate, 'DD MMM YYYY').date
                                }}
                                ({{ convertToReadableDate(course.startDate, 'DD MMM YYYY').current_timezone }})
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Course Ends On </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{ convertToReadableDate(course.endDate, 'DD MMM YYYY').date }} ({{
                                    convertToReadableDate(course.endDate, 'DD MMM YYYY').current_timezone
                                }})
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created On </label>
                            <p class="form-control-static">
                                <i class="fa fa-calendar marginRight8" aria-hidden="true" />{{
                                    convertToReadableDate(course.dateCreated, 'DD MMM YYYY, h:mm a').date
                                }}
                                ({{ convertToReadableDate(course.dateCreated, 'DD MMM YYYY, h:mm a').current_timezone }})
                            </p>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <label class="control-label"> Created By </label>
                            <p class="form-control-static"><i class="fa fa-user-o marginRight8" aria-hidden="true" />{{ course.createBy }}</p>
                        </div>
                    </div>
                    <div class="col-xs-12 flex marginBottom20">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Overview </label>
                            <div v-if="course.description" class="whiteSpacePreLine" v-html="course.description" />
                            <div v-else>-</div>
                        </div>
                    </div>
                    <div class="col-xs-12 flex">
                        <div class="col-xs-12">
                            <label class="control-label"> Course Thumbnail </label>
                            <!--class="course-thumbnail"-->
                            <div v-if="course.coverImage">
                                <!-- <img :src="course.coverImage" /> -->
                                <div class="actual-image marginTop20" :style="'background-image:url(' + course.coverImage + ');'" />
                            </div>
                            <div v-else>-</div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-xs-12 col-md-6">
                    <course-form :form="form" />
                    <!--<div class="buttonGroup">
                    <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined"  @click="submitForm()" aria-label="Update">
                        <i class="fa fa-check"></i>&nbsp; Update
                    </button>
                    <router-link :to="{name:'courses.view',params:{id:$route.params.id},query:{tab:'overview'}}" tag="button" class="btn btn-default mainBtn" exact aria-label="Cancel">
                        Cancel
                    </router-link>
                </div>-->
                </div>
            </template>
        </kr-panel>
        <div id="copyCourseModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyCourseModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="copyCourseModal-title" class="modal-title">Copy Course Confirmation</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="marginBottom10">Are you sure you want to make a copy of this course?</div>
                                <!--<div class="checkboxField">
                            <input type="checkbox" v-model="">&nbsp;Copy students to the new course
                        </div>-->
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyModules"
                                        type="checkbox"
                                        aria-label="Copy modules to the new course"
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy modules to the new course
                                </div>
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyActivities"
                                        type="checkbox"
                                        :disabled="!copyCourseForm.copyModules ? true : undefined"
                                        aria-label="Copy activities to the new course"
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy activities to the new course
                                </div>
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyActivityQuestions"
                                        type="checkbox"
                                        :disabled="!copyCourseForm.copyActivities ? true : undefined"
                                        aria-label="Copy questions to the new course"
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy questions to the new course
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-success mainBtn"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, make a copy"
                                        @click.prevent="copyCourse()"
                                    >
                                        Yes, make a copy
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="courseSuspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="courseSuspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="courseSuspendModal-title" class="modal-title">
                            <template v-if="!course.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                            {{ course.name }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!course.isArchived" class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to archive this course?</div>
                                <div>You can reinstate it later by filtering your courses and showing Archived Courses.</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to reinstate this course?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                        <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                    </div>
                    <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                        <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                    </div>-->
                            <div class="flex">
                                <div v-if="!course.isArchived" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Yes, Archive" @click.prevent="archive()">
                                        <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Archive
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Yes, Reinstate" @click.prevent="reinstate()">
                                        Yes, Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="No, Cancel">No, Cancel</button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                    <div class="col-xs-12" style="text-align:center">
                        <template v-if="!superuser.isSuspended">
                            Suspending..
                        </template>
                        <template v-else>
                            Reinstating
                        </template>
                    </div>
                </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    components: {
        'course-form': require(`./partial.course.vue`).default,
    },
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            auth: new KrAuth(),
            mode: 'view',
            copyCourseForm: {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            },
            form: new KrForm({
                fields: {
                    name: '',
                    code: '',
                    description: '',
                    descriptionIsHTML: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    coverImage: '',
                },
                props: {},
                urls: {
                    put: 'courses/' + this.$route.params.id,
                },
            }),
        };
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.form.setModel(this.course);
        if (!this.form.model.objectives) {
            this.form.model.objectives = [];
        }
        this.form.model.descriptionIsHTML = false;
    },
    methods: {
        submitForm() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.mode = 'view';
                    that.$emit('course-updated', response.data.data);
                }
            });
        },
        archive() {
            $('#courseSuspendModal').modal('hide');
            var success = function () {
                this.course.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed course from list',
                });
                this.$router.push({ name: 'courses.index' });
            }.bind(this);
            var del = function () {
                this.course.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#courseSuspendModal').modal('hide');
            var success = function () {
                var that = this;
                this.course.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Course has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.course.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        openSuspendModal() {
            $('#courseSuspendModal').modal('show');
        },
        toggleCourseDuplicate() {
            if (this.copyCourseForm.copyModules == false) {
                this.copyCourseForm.copyActivities = false;
                this.copyCourseForm.copyActivityQuestions = false;
            }
            if (this.copyCourseForm.copyActivities == false) {
                this.copyCourseForm.copyActivityQuestions = false;
            }
        },
        openCopyCourseConfirmationModal() {
            this.selectedCourse = false;
            this.copyCourseForm = {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            };
            $('#copyCourseModal').modal('show');
        },
        copyCourse() {
            var that = this;
            axios
                .post('courses/' + that.course.uuid + '/duplicate', this.copyCourseForm)
                .then(function (response) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Course has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy course at this moment, please try again later',
                    });
                });
        },
    },
};
</script>

<style scoped>
#copyCourseModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyCourseModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#copyModuleModal .modal-body .row:last-child {
    margin-top: 30px;
}

#copyModuleModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyModuleModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>
