<template>
    <div class="create row flex">
        <div class="col-xs-12 col-md-6">
            <kr-select class="no-error" :display="$t('modules.accounts.superadmins.create.teacherLabel')" name="superadminUuid" :form="form" :items="teachers">
                <template #option="props">
                    <option value="">Select a teacher</option>
                    <template v-for="teacher in props.model" :key="teacher.uuid">
                        <option :value="teacher.uuid">{{ teacher.displayName }} ({{ teacher.email }})</option>
                    </template>
                </template>
            </kr-select>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['form', 'teachers'],
    data() {
        return {
            auth: new KrAuth(),
        };
    },
    created() {},
    methods: {},
};
</script>
<style scoped></style>
