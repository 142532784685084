<template>
    <div v-if="component_done_loading" :id="id">
        <kr-panel
            v-if="!addQuestionBankView && (pageType == 'form' || (pageType == 'confirmation' && manage)) && !activityObj.defaultTemplate"
            :with-footer="false"
        >
            <template #title>
                Choose A Method For Adding
                <span v-if="target" style="color: #222"> &nbsp; {{ target }} &nbsp;</span>
                Questions
            </template>
            <template #content>
                <div v-if="!addQuestionBankView" class="table-responsive creationMethod">
                    <div class="hidden-xs hidden-sm">
                        <div class="flex">
                            <button
                                v-if="add_methods.includes('qbank') && auth.user().account.version == 'full' && !(auth.user().canViewQuestionBank == false)"
                                class="btn btn-default d-block"
                                aria-label="From Question Bank"
                                @click.prevent="
                                    addQuestionBankView = true;
                                    viewSelectedInQuestionBank = false;
                                "
                            >
                                <div>
                                    <i class="fa fa-list-alt" aria-hidden="true" />
                                </div>
                                <div class="flex align-items justifyCenter">From Question Bank</div>
                            </button>
                        </div>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <div>
                            <button
                                v-if="add_methods.includes('qbank') && auth.user().account.version == 'full' && !(auth.user().canViewQuestionBank == false)"
                                class="btn btn-default flexOnly align-items"
                                @click.prevent="
                                    addQuestionBankView = true;
                                    viewSelectedInQuestionBank = false;
                                "
                            >
                                <i class="fa fa-list-alt marginRight8" aria-hidden="true" />From Question Bank
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <div v-if="addQuestionBankView" class="buttonGroupInline marginBottom30">
            <button class="btn btn-default mainBtn" aria-label="Back to Other Methods" @click.prevent="addQuestionBankView = false">
                Back to Other Methods
            </button>

            <button
                class="btn btn-success mainBtn"
                :disabled="selected_questions.length == 0 ? true : undefined"
                :aria-label="'Add Selected Questions (' + selected_questions.length + ')'"
                @click.prevent="saveSelectedQuestions"
            >
                Add Selected Questions ({{ selected_questions.length }})
            </button>
        </div>
        <div v-if="addQuestionBankView" class="alert alert-warning marginBottom20">
            <i class="fas fa-info-circle marginRight8" aria-hidden="true" />Please select question(s) for this activity from the question bank.
        </div>
        <div v-if="addQuestionBankView" class="flex questionIndex">
            <div class="col-xs-12 col-md-3">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="flex flexSpaceBetween width100">
                            <div>
                                <label> Filter By </label>
                            </div>

                            <div class="textAlignRight">
                                <button
                                    class="btn btn-default"
                                    @click.prevent="
                                        query_fields.q = '';
                                        query_fields.type = allowedQuestionTypes;
                                        query_fields.topics = '';
                                        query_fields.difficultyLevel = '';
                                        query_fields.creatorUuid = '';
                                        search();
                                    "
                                >
                                    Clear All
                                </button>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="questionFilterColumn">
                            <div class="form-group form-search has-feedback has-feedback-left">
                                <input v-model="query_fields.q" type="text" class="form-control" placeholder="Search..." aria-label="Search" />
                                <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                            </div>
                            <div class="form-group">
                                <label class="control-label"> Type </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.type == allowedQuestionTypes && filters.types.length > 1"> Select question type </template>
                                        <template v-else-if="query_fields.type == 'mcqs'"> MCQ Single Answer </template>
                                        <template v-else-if="query_fields.type == 'mcqm'"> MCQ Multiple Answers </template>
                                        <template v-else-if="query_fields.type == 'openended'"> Free Response </template>
                                        <template v-else-if="query_fields.type == 'rating'"> Rating </template>
                                        <template v-else-if="query_fields.type == 'vsa'"> VSA (Very Short Answers) </template>
                                        <template v-else-if="query_fields.type == 'matching'"> Matching </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                v-if="filters.types.length > 1"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.type = allowedQuestionTypes;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.type = allowedQuestionTypes;
                                                        search();
                                                    "
                                                >
                                                    Select question type
                                                </button>
                                            </li>
                                            <li
                                                v-for="(type, idx) in filters.types"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.type = type;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.type = type;
                                                        search();
                                                    "
                                                >
                                                    <template v-if="type == 'mcqs'"> MCQ Single Answer </template>
                                                    <template v-else-if="type == 'mcqm'"> MCQ Multiple Answers </template>
                                                    <template v-else-if="type == 'openended'"> Free Response </template>
                                                    <template v-else-if="type == 'rating'"> Rating </template>
                                                    <template v-else-if="type == 'vsa'"> VSA (Very Short Answers) </template>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="flex flexSpaceBetween marginBottom10 align-items">
                                    <label class="control-label marginBottom0 paddingTop5"> Creator </label>
                                    <div class="checkboxField">
                                        <label for="showOnlyMine-Manage" class="marginRight8"> Show only mine </label>
                                        <input
                                            id="showOnlyMine-Manage"
                                            v-model="query_fields.creatorUuid"
                                            class="marginRight0"
                                            type="checkbox"
                                            :true-value="auth.user().uuid"
                                            false-value=""
                                            @change="search()"
                                        />
                                    </div>
                                </div>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn QuestionBankFilterByDropdownField"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <label>
                                            <template v-if="query_fields.creatorUuid">
                                                {{ creatorName }}
                                            </template>
                                            <template v-else> Select creator </template>
                                        </label>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.creatorUuid = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.creatorUuid = '';
                                                        search();
                                                    "
                                                >
                                                    Select creator
                                                </button>
                                            </li>
                                            <li
                                                v-for="(creator, idx) in filters.creators"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.creatorUuid = creator.uuid;
                                                    search();
                                                "
                                            >
                                                <button
                                                    class="QuestionBankFilterByDropdown"
                                                    @click="
                                                        query_fields.creatorUuid = creator.uuid;
                                                        search();
                                                    "
                                                >
                                                    <label>
                                                        {{ creator.name }}
                                                    </label>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label"> Topic </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn QuestionBankFilterByDropdownField"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <label>
                                            <template v-if="query_fields.topics">
                                                {{ query_fields.topics }}
                                            </template>
                                            <template v-else> Select topic </template>
                                        </label>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.topics = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.topics = '';
                                                        search();
                                                    "
                                                >
                                                    Select topic
                                                </button>
                                            </li>
                                            <li
                                                v-for="(topic, idx) in filters.topics"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.topics = topic;
                                                    search();
                                                "
                                            >
                                                <button
                                                    class="QuestionBankFilterByDropdown"
                                                    @click="
                                                        query_fields.topics = topic;
                                                        search();
                                                    "
                                                >
                                                    <label>
                                                        {{ topic }}
                                                    </label>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label"> Difficulty </label>
                                <div class="dropdown new-custom-search-select d-block">
                                    <button
                                        id="viewDropdownMenuButton"
                                        class="btn"
                                        type="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <template v-if="query_fields.difficultyLevel">
                                            {{ query_fields.difficultyLevel }}
                                        </template>
                                        <template v-else> Select difficulty level </template>
                                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                        <ul role="listbox" class="maxHeight255px overflowAuto">
                                            <li
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.difficultyLevel = '';
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.difficultyLevel = '';
                                                        search();
                                                    "
                                                >
                                                    Select difficulty level
                                                </button>
                                            </li>
                                            <li
                                                v-for="(difficultyLevel, idx) in filters.difficultyLevels"
                                                role="option"
                                                class="paddingTop10 paddingBottom10 withHover"
                                                @click="
                                                    query_fields.difficultyLevel = difficultyLevel;
                                                    search();
                                                "
                                            >
                                                <button
                                                    @click="
                                                        query_fields.difficultyLevel = difficultyLevel;
                                                        search();
                                                    "
                                                >
                                                    {{ difficultyLevel }}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
            <div class="questionIndexPanel col-xs-12 col-md-9 cardsCon">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div v-if="!viewSelectedInQuestionBank" class="col-xs-6">Question Bank ({{ questionSearchData.meta.paginator.total }})</div>
                        <div v-else class="col-xs-6">Selected Questions ({{ selected_questions.length }})</div>
                    </template>
                    <template #content>
                        <div class="flexSpaceBetween marginBottom30 align-items paddingLeft20">
                            <div v-if="!viewSelectedInQuestionBank" class="checkboxField flex align-items">
                                <input id="selectAll" type="checkbox" aria-label="Select All" @click="selectAllQuestions()" />
                                <label for="selectAll"> Select All </label>
                            </div>
                            <div>
                                <button
                                    v-if="!viewSelectedInQuestionBank"
                                    class="btn btn-primary subBtn"
                                    :disabled="selected_questions.length == 0 ? true : undefined"
                                    @click.prevent="viewSelectedInQuestionBank = true"
                                >
                                    Only Show Selected ({{ selected_questions.length }})
                                </button>
                                <button
                                    v-else
                                    class="btn btn-default subBtn"
                                    aria-label="Back to Question Bank"
                                    @click.prevent="viewSelectedInQuestionBank = false"
                                >
                                    Back to Question Bank
                                </button>
                            </div>
                        </div>
                        <kr-search
                            name="questions_listing"
                            :url="{ url: '/questions/bank' }"
                            :options="{
                                per_page: 20,
                                page: pagination.page,
                                query_fields: query_fields,
                                thead_title: false,
                                search_field: false,
                                newServerMode: {
                                    includes: ['q', 'isSuspended', 'sort', 'order', 'creatorUuid', 'type', 'topics', 'difficultyLevel'],
                                },
                            }"
                            @retrieve-data="getQuestionData"
                        >
                            <template #listing-top />
                            <template #content="props">
                                <div v-if="!viewSelectedInQuestionBank" class="flex">
                                    <template v-if="props.model.length == 0">
                                        <div class="paddingLeft20">
                                            <p>No questions match your search / filter</p>
                                        </div>
                                    </template>

                                    <template v-for="question in props.model" v-else>
                                        <div class="cardCons-question-bank">
                                            <div class="card">
                                                <div class="card-header flex">
                                                    <div>
                                                        <i class="fa fa-list-ul marginRight8" />
                                                        <template v-if="question.type == 'mcqs'"> MCQ Single Answer </template>
                                                        <template v-if="question.type == 'mcqm'"> MCQ Multiple Answers </template>
                                                        <template v-if="question.type == 'openended'"> Free Response </template>
                                                        <template v-if="question.type == 'rating'"> Rating </template>
                                                        <template v-if="question.type == 'vsa'"> VSA </template>
                                                    </div>
                                                </div>
                                                <div class="card-body" @click.prevent="openQuestionInfoModal(question)">
                                                    <div :id="'questionStem_' + question.uuid" class="question-area col-xs-11 col-md-10">
                                                        <label class="control-label fs-14px"> Question Stem </label>
                                                        <kr-math
                                                            :input="question.question"
                                                            :safe="!question.questionIsHTML"
                                                            :class="{
                                                                fontSizeSmall: fontResized == 'small',
                                                                fontSizeNormal: fontResized == 'normal',
                                                                fontSizeLarge: fontResized == 'large',
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="moreLink col-xs-1 col-md-2">
                                                        <button aria-label="show more of " :aria-describedby="'questionStem_' + question.uuid">
                                                            <div class="custom-arrow-icon">
                                                                <div class="custom-arrow-icon-top"></div>
                                                                <div class="custom-arrow-icon-bottom"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <button class="btn primary-text" @click.prevent="openShowMoreDetailModal(question)">Details</button>
                                                </div>

                                                <div class="cardBtns">
                                                    <button
                                                        v-if="isQuestionSelected(question)"
                                                        v-tooltip="{
                                                            content: 'Remove Selection',
                                                        }"
                                                        class="btn btn-danger mainBtn"
                                                        aria-label="Delete"
                                                        @click.prevent="deleteFromSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button v-if="isQuestionSaved(question)" class="btn btn-danger mainBtn" disabled aria-label="delete">
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="!isQuestionSelected(question) && !isQuestionSaved(question)"
                                                        v-tooltip="{
                                                            content: 'Add Selection',
                                                        }"
                                                        class="btn btn-default mainBtn"
                                                        aria-label="Add"
                                                        @click.prevent="addToSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-plus" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div v-else class="flex">
                                    <template v-for="question in selected_questions">
                                        <div class="cardCons-question-bank">
                                            <div class="card">
                                                <div class="card-header flex">
                                                    <div>
                                                        <i class="fa fa-list-ul marginRight8" />
                                                        <template v-if="question.type == 'mcqs'"> MCQ Single Answer </template>
                                                        <template v-if="question.type == 'mcqm'"> MCQ Multiple Answers </template>
                                                        <template v-if="question.type == 'openended'"> Free Response </template>
                                                        <template v-if="question.type == 'rating'"> Rating </template>
                                                        <template v-if="question.type == 'vsa'"> VSA </template>
                                                    </div>
                                                </div>
                                                <div class="card-body" @click.prevent="openQuestionInfoModal(question)">
                                                    <div :id="'questionStem_' + question.uuid" class="question-area col-xs-11 col-md-10">
                                                        <label class="control-label fs-14px"> Question Stem </label>

                                                        <kr-math
                                                            :input="question.question"
                                                            :safe="!question.questionIsHTML"
                                                            :class="{
                                                                fontSizeSmall: fontResized == 'small',
                                                                fontSizeNormal: fontResized == 'normal',
                                                                fontSizeLarge: fontResized == 'large',
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="moreLink col-xs-1 col-md-2">
                                                        <button aria-label="show more of " :aria-describedby="'questionStem_' + question.uuid">
                                                            <div class="custom-arrow-icon">
                                                                <div class="custom-arrow-icon-top"></div>
                                                                <div class="custom-arrow-icon-bottom"></div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-footer">
                                                    <button class="btn primary-text" @click.prevent="openShowMoreDetailModal(question)">Details</button>
                                                </div>

                                                <div class="cardBtns">
                                                    <button
                                                        v-if="isQuestionSelected(question)"
                                                        v-tooltip="{
                                                            content: 'Remove Selection',
                                                        }"
                                                        class="btn btn-danger mainBtn"
                                                        aria-label="Delete"
                                                        @click.prevent="deleteFromSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button v-if="isQuestionSaved(question)" class="btn btn-danger mainBtn" disabled aria-label="Delete">
                                                        <i class="fa fa-times" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="!isQuestionSelected(question) && !isQuestionSaved(question)"
                                                        class="btn btn-default mainBtn"
                                                        aria-label="Add"
                                                        @click.prevent="addToSelectedQuestions(question)"
                                                    >
                                                        <i class="fa fa-plus" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-if="!viewSelectedInQuestionBank" #pagination="props">
                                <div class="paginationCons flex align-items paddingLeft20 paddingBottom20">
                                    <div class="col-xs-12">
                                        <div class="displayNo">Displaying {{ props.model.from }} of {{ props.model.to }} Question/s</div>
                                    </div>
                                    <div class="col-xs-12 buttonGroupInline">
                                        <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginRight20">
                                            <button
                                                class="btn btn-default mainBtn padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page == 1 ? true : undefined"
                                                aria-label="Back"
                                                @click.prevent="pagination.page = 1"
                                            >
                                                <i class="fas fa-backward" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div v-if="props.model.meta.paginator.current_page > 1" class="width36px marginRight20">
                                            <button
                                                class="btn btn-default mainBtn padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page - 1 < 1 ? true : undefined"
                                                aria-label="Back"
                                                @click.prevent="pagination.page = props.model.meta.paginator.current_page - 1"
                                            >
                                                <i class="fa fa-caret-left" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <ul v-if="props.model.meta.paginator.current_page == 1" class="pagination text-listing-pagination marginLeft0">
                                            <template v-for="page in props.model.pagination">
                                                <li
                                                    v-if="page != '...'"
                                                    :class="{
                                                        active: page == props.model.meta.paginator.current_page,
                                                    }"
                                                >
                                                    <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                </li>
                                                <li v-else>
                                                    <a :aria-label="page" @click.prevent="">{{ page }}</a>
                                                </li>
                                            </template>
                                        </ul>
                                        <ul v-if="props.model.meta.paginator.current_page > 1" class="pagination text-listing-pagination">
                                            <template v-for="page in props.model.pagination">
                                                <li
                                                    v-if="page != '...'"
                                                    :class="{
                                                        active: page == props.model.meta.paginator.current_page,
                                                    }"
                                                >
                                                    <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                </li>
                                                <li v-else>
                                                    <a :aria-label="page" @click.prevent="">{{ page }}</a>
                                                </li>
                                            </template>
                                        </ul>
                                        <div v-if="props.model.meta.paginator.current_page > 1" class="width36px marginLeft20">
                                            <button
                                                class="btn btn-default mainBtn padding16px width100"
                                                :disabled="
                                                    props.model.meta.paginator.current_page + 1 > props.model.meta.paginator.total_pages ? true : undefined
                                                "
                                                aria-label="Forward"
                                                @click.prevent="pagination.page = props.model.meta.paginator.current_page + 1"
                                            >
                                                <i class="fa fa-caret-right" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginLeft20">
                                            <button
                                                class="btn btn-default mainBtn padding16px width100"
                                                :disabled="props.model.meta.paginator.current_page == props.model.meta.paginator.total_pages ? true : undefined"
                                                aria-label="Forward"
                                                @click.prevent="pagination.page = props.model.meta.paginator.total_pages"
                                            >
                                                <i class="fas fa-forward" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>
        <div v-if="addQuestionBankView" class="buttonGroupInline marginTop30">
            <button class="btn btn-default mainBtn" aria-label="Back to Other Methods" @click.prevent="addQuestionBankView = false">
                Back to Other Methods
            </button>

            <button
                class="btn btn-success mainBtn"
                :disabled="selected_questions.length == 0 ? true : undefined"
                :aria-label="'Add Selected Questions (' + selected_questions.length + ')'"
                @click.prevent="saveSelectedQuestions"
            >
                Add Selected Questions ({{ selected_questions.length }})
            </button>
        </div>
        <kr-panel v-if="!addQuestionBankView" :with-footer="false" :class="{ marginTop20: pageType != 'form' && manage }">
            <template #title>
                <div class="hidden-xs hidden-sm width100 flexSpaceBetween align-items" tabindex="0">
                    <div class="flexOnly align-items">
                        <label v-if="!['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)">
                            Questions For This Activity ({{ total_questions_count }})
                        </label>
                        <label v-else> {{ target }} Questions For This Activity ({{ total_questions_count }}) </label>

                        <button
                            v-tooltip="questionGroups.length == 0 ? 'No Question Added' : ''"
                            class="btn btn-default"
                            :class="{ disabled: questionGroups.length == 0 }"
                            @click.prevent="questionGroups.length == 0 ? '' : $refs['createActivityFromTemplateComponent'].create(activityObj)"
                        >
                            <span class="material-icons marginRight8" aria-hidden="true">quiz</span>
                            Use Template
                        </button>
                    </div>
                    <div v-if="!manage && !activityObj.defaultTemplate" class="col-xs-6 buttonGroup">
                        <div class="switchToggle flexOnly align-items">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                    </div>
                    <div v-else-if="pageType != 'form' && manage && !activityObj.defaultTemplate" class="buttonGroup">
                        <div class="switchToggle flexOnly align-items marginRight20">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                        <div class="hidden-xs hidden-sm">
                            <button
                                class="btn btn-success mainBtn"
                                :disabled="!canUpdate ? true : undefined"
                                aria-label="Update"
                                @click.prevent="saveManageQuestions()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                class="btn btn-success mainBtn"
                                :disabled="!canUpdate ? true : undefined"
                                aria-label="Update"
                                @click.prevent="saveManageQuestions()"
                            >
                                <i class="fa fa-check" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div v-else-if="!activityObj.defaultTemplate" class="buttonGroup">
                        <div class="switchToggle flexOnly align-items">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                    </div>
                </div>

                <div class="hidden-md hidden-lg">
                    <div class="inlineFlex align-items">
                        <label v-if="!['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)">
                            Questions For This Activity ({{ total_questions_count }})
                        </label>
                        <label v-else> {{ target }} Questions For This Activity ({{ total_questions_count }}) </label>

                        <button
                            v-tooltip="questionGroups.length == 0 ? 'No Question Added' : ''"
                            class="btn btn-default"
                            :class="{ disabled: questionGroups.length == 0 }"
                            @click.prevent="questionGroups.length == 0 ? '' : $refs['createActivityFromTemplateComponent'].create(activityObj)"
                        >
                            <span class="material-icons marginRight8" aria-hidden="true">quiz</span>
                            Use Template
                        </button>
                    </div>

                    <div v-if="!manage && !activityObj.defaultTemplate" class="buttonGroup marginTop10">
                        <div class="switchToggle flexOnly align-items">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                    </div>

                    <div v-else-if="pageType != 'form' && manage && !activityObj.defaultTemplate" class="buttonGroup marginTop10">
                        <div class="switchToggle flexOnly align-items marginRight20">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                        <div class="hidden-xs hidden-sm">
                            <button
                                class="btn btn-success mainBtn"
                                :disabled="!canUpdate ? true : undefined"
                                aria-label="Update"
                                @click.prevent="saveManageQuestions()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                class="btn btn-success mainBtn"
                                :disabled="!canUpdate ? true : undefined"
                                aria-label="Update"
                                @click.prevent="saveManageQuestions()"
                            >
                                <i class="fa fa-check" aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    <div v-else-if="!activityObj.defaultTemplate" class="buttonGroup marginTop10">
                        <div class="switchToggle flexOnly align-items">
                            <label class="control-label margin0 marginRight10 width105px"> Detailed View </label>
                            <label class="form-switch">
                                <input v-model="search_options.isDetailed" type="checkbox" aria-label="Detailed View" />
                                <span class="slider round" />
                            </label>
                        </div>
                    </div>
                </div>
            </template>
            <template #content>
                <div v-if="questionGroups.length == 0">Currently no questions were added.</div>
                <template v-else-if="!activityObj.defaultTemplate">
                    <div v-if="manage && !['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)" class="marginBottom30">
                        <div class="hidden-xs hidden-sm">
                            <div class="col-xs-6 buttonGroup">
                                <button
                                    class="btn btn-default mainBtn primaryHover"
                                    aria-label="Set all as One Page"
                                    @click.prevent="setAllQuestionsAsOnePage()"
                                >
                                    Set all as One Page
                                </button>
                                <button class="btn btn-default mainBtn primaryHover" aria-label="Create New Page" @click.prevent="addQuestionGroup()">
                                    Create New Page
                                </button>
                            </div>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                class="col-xs-12 btn btn-default mainBtn primaryHover"
                                aria-label="Set All as One Page"
                                @click.prevent="setAllQuestionsAsOnePage()"
                            >
                                Set all as One Page
                            </button>
                            <button class="col-xs-12 btn btn-default mainBtn primaryHover" aria-label="Create New Page" @click.prevent="addQuestionGroup()">
                                Create New Page
                            </button>
                        </div>
                    </div>
                    <div v-if="!manage" class="alert alert-warning marginBottom20" role="alert">
                        <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true" />
                        <div>
                            Current set up allow students get to answer individual question on individual page. You may proceed with this or customise them.
                        </div>
                    </div>
                </template>
                <template v-for="(qGroup, idx) in questionGroups">
                    <div
                        :data-idx="idx"
                        class="questionList"
                        :draggable="manage"
                        @drop="dropToQuestionGroup($event, idx)"
                        @dragover="allowDropToQuestionGroup"
                        @dragleave="leaveDragFromQuestionGroup"
                        @dragstart="dragQuestionGroup($event, idx)"
                        @dragend="stopScroll = true"
                    >
                        <div class="header">
                            <span v-if="!activityObj.defaultTemplate" v-tooltip="{ content: 'Drag and Drop' }" style="float: left"
                                ><i class="fa fa-arrows" aria-hidden="true"
                            /></span>
                            PAGE {{ idx + 1 }}
                            <span
                                v-if="!activityObj.defaultTemplate"
                                v-tooltip="{ content: 'Delete page' }"
                                style="float: right"
                                @click="openDeletePageModal(idx)"
                                ><i class="fa fa-trash" aria-hidden="true"
                            /></span>
                        </div>
                        <div class="content hidden-xs hidden-sm">
                            <template v-if="qGroup.length == 0"> Drag and drop questions here </template>
                            <template v-else>
                                <template v-for="(question, question_idx) in qGroup">
                                    <div
                                        :data-idx="question_idx"
                                        class="managementQns"
                                        :draggable="manage"
                                        @dragstart="
                                            dragQuestion($event, question, {
                                                from: 'questionGroupQuestion',
                                            })
                                        "
                                        @drop="dropToQuestion($event, idx, question_idx)"
                                        @dragover="allowDropToQuestion"
                                        @dragleave="leaveDragFromQuestion"
                                        @dragend="stopScroll = true"
                                    >
                                        <div v-if="question._isCopied" class="copiedIndicationBar">
                                            <label aria-label="This is a copy question">Copy</label>
                                        </div>
                                        <div class="flexOnly">
                                            <div v-if="!activityObj.defaultTemplate" class="lefts">
                                                <div class="marginBottom20">
                                                    <div class="control-label" aria-hidden="false">Page</div>
                                                    <div class="form-group">
                                                        <div v-if="manage" class="form-dropdown">
                                                            <select
                                                                v-model="question._tempGroup"
                                                                class="form-control"
                                                                :aria-label="'Page '"
                                                                @change="
                                                                    questionGroups[$event.target.value - 1].length == 0
                                                                        ? moveQuestionOrder(idx, $event.target.value - 1, question_idx, 0)
                                                                        : questionGroups.splice()
                                                                "
                                                            >
                                                                <option value="">-</option>
                                                                <template v-for="(a, a_idx) in questionGroups">
                                                                    <option :value="a_idx + 1" :selected="idx == a_idx">
                                                                        {{ a_idx + 1 }}
                                                                    </option>
                                                                </template>
                                                            </select>
                                                        </div>
                                                        <input v-else :value="idx + 1" class="form-control" disabled />
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class="control-label" aria-hidden="false">Question No.</div>
                                                    <div v-if="manage" class="form-dropdown">
                                                        <select
                                                            :value="question._tempGroup - 1 == idx ? question_idx + 1 : ''"
                                                            class="form-control"
                                                            :aria-label="'Question Number '"
                                                            @change="moveQuestionOrder(idx, question._tempGroup - 1, question_idx, $event.target.value - 1)"
                                                        >
                                                            <option value="" disabled>-</option>
                                                            <template v-for="(question, b_idx) in questionGroups[question._tempGroup - 1]">
                                                                <option :value="b_idx + 1">
                                                                    {{ b_idx + 1 }}
                                                                </option>
                                                            </template>
                                                            <option v-if="questionGroups[question._tempGroup - 1].length == 0" :value="1">1</option>
                                                        </select>
                                                    </div>
                                                    <input v-else :value="question_idx + 1" class="form-control" disabled />
                                                </div>
                                            </div>

                                            <div class="center">
                                                <div class="header flexSpaceBetween">
                                                    <div>
                                                        <template v-for="(topic, topic_idx) in question.topics"> {{ topic }}&nbsp; </template>
                                                    </div>
                                                    <div>
                                                        LEVEL
                                                        {{ question.difficultyLevel }}
                                                    </div>
                                                </div>

                                                <div>
                                                    <caption class="visible-hidden">
                                                        Question:
                                                    </caption>
                                                    <kr-math
                                                        :input="question.question"
                                                        :safe="!question.questionIsHTML"
                                                        class="content wordBreakWord blueLink"
                                                    />
                                                </div>

                                                <div
                                                    v-if="(question.type == 'mcqs' || question.type == 'mcqm') && search_options.isDetailed"
                                                    class="marginBottom20"
                                                >
                                                    <div class="questionBankOptionsField">
                                                        <caption class="visible-hidden">
                                                            There are
                                                            {{
                                                                question.options ? question.options.length : '-'
                                                            }}
                                                            answers choice
                                                        </caption>

                                                        <div
                                                            v-for="(option, idx) in question.options"
                                                            :key="option + '_' + idx"
                                                            :class="{
                                                                correctOption: option.isCorrect,
                                                                paddingLeft35px: !option.isCorrect,
                                                            }"
                                                            class="checkboxField flex marginTop10"
                                                        >
                                                            <caption v-if="option.isCorrect" class="visible-hidden">
                                                                option
                                                                {{
                                                                    option.key
                                                                }}
                                                                is correct
                                                            </caption>

                                                            <div v-if="option.isCorrect" class="marginRight10">
                                                                <i v-if="question.type == 'mcqs'" class="fa fa-check-circle" aria-hidden="true" />
                                                                <i v-if="question.type == 'mcqm'" class="fa fa-check-square" aria-hidden="true" />
                                                            </div>
                                                            <label :for="question.uuid + '-' + option.key">
                                                                <span class="withOptionKey" :aria-label="'option ' + option.key"> {{ option.key }}. </span>
                                                                <span class="withAnswerKey">
                                                                    <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="footer flex">
                                                    <div v-if="question.type == 'mcqs'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />MCQ Single Answer
                                                    </div>
                                                    <div v-if="question.type == 'mcqm'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />MCQ Multiple Answer
                                                    </div>
                                                    <div v-if="question.type == 'openended'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />Free Response Answer
                                                    </div>
                                                    <template v-if="question.type == 'rating'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />Rating
                                                    </template>
                                                    <template v-if="question.type == 'vsa'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                                        VSA
                                                    </template>
                                                    <div>
                                                        <i class="fas fa-pen-alt marginRight8" aria-hidden="true" />
                                                        {{ question.createBy }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="!activityObj.defaultTemplate" class="right buttonGroup">
                                                <button
                                                    v-tooltip="{
                                                        content: 'View Question',
                                                    }"
                                                    class="btn btn-primary"
                                                    :aria-label="'View Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                    @click.prevent="openQuestionInfoModal(question)"
                                                >
                                                    <i class="fas fa-eye" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-if="manage"
                                                    v-tooltip="{
                                                        content: 'Edit Question',
                                                    }"
                                                    class="btn btn-default"
                                                    :aria-label="'Edit Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                    @click.prevent="editQuestion(question)"
                                                >
                                                    <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-if="manage"
                                                    v-tooltip="'Make a Copy'"
                                                    class="btn btn-default primaryHover"
                                                    :aria-label="'Make a copy of Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                    @click.prevent="copyQuestion(question)"
                                                >
                                                    <i class="material-symbols-outlined" aria-hidden="true"> content_copy </i>
                                                </button>
                                                <button
                                                    v-if="manage"
                                                    v-tooltip="{
                                                        content: 'Delete Question',
                                                    }"
                                                    class="btn btn-default deleteBtn"
                                                    :aria-label="'Delete Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                    @click.prevent="openDeleteQnsModal(idx, question_idx, question)"
                                                >
                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                </button>
                                                <button
                                                    v-if="manage"
                                                    v-tooltip="{
                                                        content: 'Drag and Drop',
                                                    }"
                                                    class="btn"
                                                    aria-label="Drag and Drop"
                                                >
                                                    <i class="fa fa-arrows" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <div v-if="qGroup.length == 0" class="content">Drag and drop questions here</div>
                            <template v-else>
                                <template v-for="(question, question_idx) in qGroup">
                                    <div class="managementQns">
                                        <div v-if="!activityObj.defaultTemplate" class="lefts">
                                            <div class="control-label">Page</div>
                                            <div class="form-group">
                                                <div v-if="manage" class="form-dropdown">
                                                    <select
                                                        v-model="question._tempGroup"
                                                        class="form-control"
                                                        :aria-label="question._tempGroup"
                                                        @change="questionGroups.splice()"
                                                    >
                                                        <option value="">-</option>
                                                        <template v-for="(a, a_idx) in questionGroups">
                                                            <option :value="a_idx + 1" :selected="idx == a_idx">
                                                                {{ a_idx + 1 }}
                                                            </option>
                                                        </template>
                                                    </select>
                                                </div>
                                                <input v-else :value="idx + 1" class="form-control" disabled />
                                            </div>
                                        </div>
                                        <div v-if="!activityObj.defaultTemplate" class="lefts">
                                            <div class="control-label">Question No.</div>
                                            <div class="form-group">
                                                <div v-if="manage" class="form-dropdown">
                                                    <select
                                                        :value="question._tempGroup - 1 == idx ? question_idx + 1 : ''"
                                                        class="form-control"
                                                        :aria-label="question._tempGroup - 1 == idx ? question_idx + 1 : ''"
                                                        @change="moveQuestionOrder(idx, question._tempGroup - 1, question_idx, $event.target.value - 1)"
                                                    >
                                                        <option value="" disabled>-</option>
                                                        <template v-for="(question, b_idx) in questionGroups[question._tempGroup - 1]">
                                                            <option :value="b_idx + 1">
                                                                {{ b_idx + 1 }}
                                                            </option>
                                                        </template>
                                                        <option v-if="questionGroups[question._tempGroup - 1].length == 0" :value="1">1</option>
                                                    </select>
                                                </div>
                                                <input v-else :value="question_idx + 1" class="form-control" disabled />
                                            </div>
                                        </div>
                                        <div class="center">
                                            <div class="header flexSpaceBetween">
                                                <div>
                                                    <template v-for="(topic, topic_idx) in question.topics"> {{ topic }}&nbsp; </template>
                                                </div>
                                                <div>
                                                    Level
                                                    {{ question.difficultyLevel }}
                                                </div>
                                            </div>
                                            <kr-math :input="question.question" :safe="!question.questionIsHTML" class="content wordBreakWord blueLink" />
                                            <div
                                                v-if="(question.type == 'mcqs' || question.type == 'mcqm') && search_options.isDetailed"
                                                class="marginBottom20"
                                            >
                                                <template v-for="(option, idx) in question.options">
                                                    <div
                                                        :class="{
                                                            correctOption: option.isCorrect,
                                                        }"
                                                        class="flexOnly qnsOptions"
                                                    >
                                                        <template v-if="option.isCorrect">
                                                            <div>
                                                                <i v-if="question.type == 'mcqs'" class="fa fa-check-circle" />&nbsp;
                                                                <i v-if="question.type == 'mcqm'" class="fa fa-check-square" />&nbsp;
                                                            </div>
                                                        </template>
                                                        <div class="flexOnly">
                                                            <div class="optionKey">{{ option.key }}.</div>
                                                            &nbsp;
                                                            <kr-math :input="option.content" :safe="!option.contentIsHTML" />
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="footer">
                                                <div class="col-xs-12">
                                                    <template v-if="question.type == 'mcqs'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />MCQ Single Answer
                                                    </template>
                                                    <template v-if="question.type == 'mcqm'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />MCQ Multiple Answer
                                                    </template>
                                                    <template v-if="question.type == 'openended'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />Free Response Answer
                                                    </template>
                                                    <template v-if="question.type == 'rating'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />Rating
                                                    </template>
                                                    <template v-if="question.type == 'vsa'">
                                                        <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                                        VSA
                                                    </template>
                                                </div>
                                                <div class="col-xs-12"><i class="fas fa-pen-alt marginRight8" aria-hidden="true" />{{ question.createBy }}</div>
                                            </div>
                                        </div>
                                        <div v-if="!activityObj.defaultTemplate" class="right">
                                            <div
                                                v-tooltip="{
                                                    content: 'View Question',
                                                }"
                                                class="btn btn-primary"
                                                aria-label="View"
                                                @click.prevent="openQuestionInfoModal(question)"
                                            >
                                                <i class="fas fa-eye" aria-hidden="true" />
                                            </div>
                                            <div
                                                v-if="manage"
                                                v-tooltip="{
                                                    content: 'Edit Question',
                                                }"
                                                class="btn btn-default"
                                                :aria-label="'Edit Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                @click.prevent="editQuestion(question)"
                                            >
                                                <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                            </div>
                                            <button
                                                v-if="manage"
                                                v-tooltip="'Make a Copy'"
                                                class="btn btn-default primaryHover"
                                                :aria-label="'Make a copy of Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                @click.prevent="copyQuestion(question)"
                                            >
                                                <i class="material-symbols-outlined" aria-hidden="true"> content_copy </i>
                                            </button>
                                            <div
                                                v-if="manage"
                                                v-tooltip="{
                                                    content: 'Delete Question',
                                                }"
                                                class="btn btn-default deleteBtn"
                                                :aria-label="'Delete Page ' + question._tempGroup + ' Question ' + (question_idx + 1)"
                                                @click.prevent="openDeleteQnsModal(idx, question_idx, question)"
                                            >
                                                <i class="fa fa-trash" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </kr-panel>
        <template v-if="pageType == 'form' || (pageType == 'confirmation' && manage)">
            <question-cru
                v-if="target != null"
                :key="target"
                :options="{ target: target, id: target }"
                @updated="syncUpdatedQuestion"
                @created="syncCreatedQuestion"
            />
            <question-cru v-else @updated="syncUpdatedQuestion" @created="syncCreatedQuestion" />
        </template>

        <div class="modal danger-modal deletePageModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deletePageModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deletePageModal-title" class="modal-title">Delete Page</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">You are about to delete</h2>
                        <h2 class="marginBottom20 fw-normal">Page {{ questionGroupToDeleteIdx + 1 }}</h2>

                        <div>Do you want to proceed?</div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteQuestionGroup()">
                            <i class="fa fa-trash marginRight8" aria-hidden="true" />Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal danger-modal deleteQnsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteQnsModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteQnsModal-title" class="modal-title">Remove Question</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">You are about to remove</h2>
                        <br />

                        <div class="bg-grey padding15 textAlignLeft">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="previewQuestion.type == 'mcqs'"> MCQ Single Answer </template>
                                <template v-if="previewQuestion.type == 'mcqm'"> MCQ Multiple Answers </template>
                                <template v-if="previewQuestion.type == 'openended'"> Free Response </template>
                                <template v-if="previewQuestion.type == 'rating'"> Rating </template>
                                <template v-if="previewQuestion.type == 'vsa'"> VSA </template>
                            </div>
                            <br />
                            <label class="control-label marginTop20"> Question Stem </label>
                            <question-display :question="previewQuestion" />
                        </div>

                        <br />
                        <p>
                            <b> Do you want to proceed? </b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteQuestion(questionGroupIdx, questionIdx)">
                            <i class="fa fa-trash marginRight8" aria-hidden="true" />Yes, Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal default-modal questionInfoModal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="questionInfoModal-title"
        >
            <div
                class="modal-dialog"
                :class="{
                    'maxWidth100 widthAuto paddingLeft20 paddingRight20': activityObj.type == 'evaluation' && !isMobileView,
                }"
            >
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="questionInfoModal-title" class="modal-title">View Question</h2>
                    </div>

                    <div class="modal-body height550px">
                        <question-display :question="previewQuestion" />
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="copyQuestionModal"
            class="modal default-modal copyQuestionModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="copyQuestionModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyQuestionModal-title" class="modal-title">Copy Question</h2>
                    </div>
                    <div class="modal-body">
                        <h2 class="margin0 marginTop20 textAlignCenter">You are making a copy of this question</h2>
                        <br />

                        <div class="bg-grey padding15">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="previewQuestion.type == 'mcqs'"> MCQ Single Answer </template>
                                <template v-if="previewQuestion.type == 'mcqm'"> MCQ Multiple Answers </template>
                                <template v-if="previewQuestion.type == 'openended'"> Free Response </template>
                                <template v-if="previewQuestion.type == 'rating'"> Rating </template>
                                <template v-if="previewQuestion.type == 'vsa'"> VSA </template>
                            </div>
                            <br />
                            <label class="control-label marginTop20"> Question Stem </label>
                            <question-display :question="previewQuestion" />
                        </div>

                        <br />
                        <p class="textAlignCenter">
                            <b>Continue?</b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="makeACopy(previewQuestion.uuid)">Make a Copy</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="showMoreDetailModal"
            class="modal default-modal showMoreDetailModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showMoreDetailModal-title"
        >
            <div class="modal-dialog minWidth350px width350px maxWidth350px">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="showMoreDetailModal-title" class="modal-title">Details</h2>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20">
                            <label class="control-label"> Created By </label>
                            <p class="questionBankModalText">
                                {{ previewQuestion.createBy }}
                            </p>
                        </div>

                        <div class="marginBottom20">
                            <label class="control-label"> Difficulty Level </label>
                            <p class="questionBankModalText">Level {{ previewQuestion.difficultyLevel }}</p>
                        </div>

                        <div>
                            <label class="control-label"> Topics </label>

                            <template v-if="topicsLength == 0">
                                <p class="questionBankModalText">No Topic</p>
                            </template>
                            <ul v-else>
                                <li v-for="topic in previewQuestion.topics" class="questionBankModalText">
                                    {{ topic }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <CreateFromTemplateComponent ref="createActivityFromTemplateComponent"></CreateFromTemplateComponent>
</template>

<script>
import KrAuth from '@/components/auth/auth';
export default {
    components: {
        'question-display': require(`../partials/question-display.vue`).default,
        'question-cru': require(`../cru.vue`).default,
        CreateFromTemplateComponent: require(`./actions/createActivityFromTemplate.vue`).default,
    },
    props: ['activityObj', 'options'],
    emits: ['questionBankOpened', 'questionBankClosed'],
    data() {
        var auth = new KrAuth();
        var type = 'mcqs';
        let add_methods = ['qbank', 'form', 'copyandpaste', 'upload'];
        if (['irat', 'trat', 'iratntrat'].includes(this.activityObj.type)) {
            type = 'mcqs';
        } else if (this.activityObj.type == 'application') {
            type = 'mcqs,mcqm,openended,vsa,matching';
        } else if (['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
            type = 'rating,openended';
            add_methods = ['qbank', 'form'];
        }

        return {
            component_done_loading: false,
            auth: auth,
            manage: true,
            activity: null,
            addQuestionBankView: false,
            viewSelectedInQuestionBank: false,
            debounced_search: null,
            questionSearchData: {
                meta: {
                    paginator: { total: 0 },
                },
            },
            pagination: { page: 1 },
            query_fields: {
                q: '',
                creatorUuid: '',
                difficultyLevel: '',
                topics: '',
                type: type,
            },
            //selected questions from question bank
            selected_questions: [],
            //deleted questions
            deleted_questions: [],
            //all questions on activity
            questionGroups: [],
            currentDraggedItem: null,
            //for view question
            previewQuestion: {},
            pageType: _.get(this.options, 'pageType', 'form'),
            allowedQuestionTypes: type,
            oldQuestionGroups: [],
            questionGroupToDeleteIdx: null,
            search_options: { isDetailed: true },
            add_methods: add_methods,
            target: _.get(this.options, 'target', null),
            id: _.get(this.options, 'target', Math.random().toString(13).replace('0.', '')),
            stopScroll: true,
            selectedAll: false,
            filters: {
                types: [],
                creators: [],
                topics: [],
                difficultyLevels: [],
            },
            topicsLength: 0,
            fontResized: localStorage.getItem('textSize'),
        };
    },
    computed: {
        total_questions_count() {
            let count = 0;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                count += qGroup.length;
            }
            return count;
        },
        canUpdate() {
            if (!_.isEqual(this.questionGroups, this.oldQuestionGroups) && this.total_questions_count != 0) {
                return true;
            }
            return false;
        },
        goToNextPage() {
            if (['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
                if (this.target == 'peer') {
                    if (this.activity.evaluationSettings.evaluateTeam) {
                        return 'Go to Team Questions';
                    } else if (this.activity.evaluationSettings.evaluateInstructor) {
                        return 'Go to Instructor Questions';
                    } else {
                        return 'Go to Optional Settings';
                    }
                } else if (this.target == 'team') {
                    if (this.activity.evaluationSettings.evaluateInstructor) {
                        return 'Go to Instructor Questions';
                    } else {
                        return 'Go to Optional Settings';
                    }
                } else if (this.target == 'instructor') {
                    return 'Go to Optional Settings';
                }
            }

            if (!(this.activityObj.type == 'application' && !this.activity.others.isApplicationGraded)) {
                return 'Go to Scoring';
            }

            return 'Go to Optional Settings';
        },
        creatorName() {
            if (this.query_fields.creatorUuid) {
                const filter = this.filters.creators.filter((creator) => creator.uuid == this.query_fields.creatorUuid);
                return filter[0].name;
            }
            return false;
        },
    },

    watch: {
        options: {
            handler: function () {
                let target = _.get(this, 'options.target');
                if (this.target != target) {
                    this.target = target;
                    this.init(true);
                }
            },
            deep: true,
        },
        addQuestionBankView: {
            handler: function () {
                if (this.addQuestionBankView) {
                    this.$emit('questionBankOpened');
                } else {
                    this.$emit('questionBankClosed');
                }
            },
        },
    },
    created() {
        var that = this;
        //this.activityObj = false;

        if (!this.activityObj) {
            this.fetchActivity().then(function (response) {
                that.activity = response.data.data;
                that.$emit('activity-updated', that.activity);
                that.init(true);
                this.getFilters();
                that.component_done_loading = true;
            });
        } else {
            this.activity = this.activityObj;
            that.init(true);
            this.getFilters();
            this.component_done_loading = true;
        }

        this.$root.$on('fontResized', (newTextSize) => {
            this.fontResized = newTextSize;
        });
    },
    methods: {
        toggledRandomize(event) {
            if (event.target.checked) {
                this.query_fields.sort = 'random';
                this.query_fields.order = 'asc';
            } else {
                this.query_fields.sort = 'id';
                this.query_fields.order = 'desc';
            }
            this.search();
        },
        getFilters() {
            var that = this;
            axios
                .get('/questions/bank/filters?type=' + this.activity.type)
                .then(function (response) {
                    that.filters = response.data;
                    // if (that.filters.types.length > 0) {
                    //     that.query_fields.type = that.filters.types[0];
                    // }
                    if (
                        _.findIndex(that.filters.creators, function (o) {
                            return o.uuid == that.auth.user().uuid;
                        }) == -1
                    ) {
                        that.filters.creators.push({
                            uuid: that.auth.user().uuid,
                            name: that.auth.user().name,
                        });
                    }
                })
                .catch(function (errors) {});
        },
        isPreviewStudentEnabledForEvaluation() {
            if (this.target == 'peer') {
                if (this.total_questions_count == 0) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.target == 'team') {
                if (
                    this.total_questions_count == 0 &&
                    !(_.get(this.activityObj, 'evaluationSettings.evaluateTeammates') || _.get(this.activityObj, 'evaluationSettings.evaluateThemselves'))
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            if (this.target == 'instructor') {
                if (
                    this.total_questions_count == 0 &&
                    !(
                        _.get(this.activityObj, 'evaluationSettings.evaluateTeammates') ||
                        _.get(this.activityObj, 'evaluationSettings.evaluateThemselves') ||
                        _.get(this.activityObj, 'evaluationSettings.evaluateTeam')
                    )
                ) {
                    return false;
                } else {
                    return true;
                }
            }

            return true;
        },
        dragFunction(e) {
            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (e.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (e.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        downloadQuestions() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            var url = '/activities/' + this.activityObj.uuid + '/questions/activity-questions/export';
            if (this.target) {
                url += '?type=' + this.target;
            }

            axios({
                url: url,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.activityObj.type + ')' + that.activityObj.name + '_Questions_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openDeletePageModal(idx) {
            this.questionGroupToDeleteIdx = idx;
            $('#' + this.id + ' .deletePageModal').modal('show');
        },
        openDeleteQnsModal(idx, question_idx, question) {
            this.questionGroupIdx = idx;
            this.questionIdx = question_idx;
            this.previewQuestion = question;
            $('.deleteQnsModal').modal('show');
        },
        saveAndExit() {
            this.saveManageQuestions(true);
        },
        search() {
            Events.fire('questions_listing_refresh');
            this.pagination.page = 1;
        },
        selectAllQuestions() {
            let questions = _.get(this.questionSearchData, 'data.data.data', []);

            this.selectedAll = !this.selectedAll;

            if (this.selectedAll) {
                for (var i = 0; i < questions.length; i++) {
                    this.addToSelectedQuestions(questions[i]);
                }
            } else {
                for (var i = 0; i < questions.length; i++) {
                    this.deleteFromSelectedQuestions(questions[i]);
                }
            }
        },
        syncUpdatedQuestion(data) {
            let question = data;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    if (question.uuid == qGroup[j].uuid) {
                        this.questionGroups[i][j] = question;
                        this.questionGroups[i][j].group = i + 1;
                        this.questionGroups[i][j]._tempGroup = i + 1;
                        this.questionGroups[i][j].order = j + 1;
                        this.questionGroups.splice();
                        this.openQuestionInfoModal(this.questionGroups[i][j]);
                        return;
                    }
                }
            }
        },
        syncCreatedQuestion(data) {
            var that = this;
            this.fetchActivity().then(function (response) {
                that.activity = response.data.data;
                that.$emit('activity-updated', that.activity);
                that.init();
                that.saveManageQuestions(false, false);
            });
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#' + this.id + ' .questionInfoModal').modal('show');
        },
        moveQuestionOrder(oldQGroupIdx, newQGroupIdx, old_question_idx, new_question_idx) {
            let old_question = this.questionGroups[oldQGroupIdx][old_question_idx];
            if (oldQGroupIdx == newQGroupIdx) {
                //console.log('same route!from:'+old_question_idx+'to:'+new_question_idx);
                if (old_question_idx == new_question_idx) {
                    return;
                }
                //question on same page
                this.array_move(this.questionGroups[newQGroupIdx], old_question_idx, new_question_idx);
                for (var i = 0; i < this.questionGroups[newQGroupIdx].length; i++) {
                    this.questionGroups[newQGroupIdx][i].order = i + 1;
                }
            } else {
                this.questionGroups[oldQGroupIdx].splice(old_question_idx, 1);
                this.questionGroups[newQGroupIdx].splice(new_question_idx, 0, old_question);
                for (var i = 0; i < this.questionGroups[oldQGroupIdx].length; i++) {
                    this.questionGroups[oldQGroupIdx][i]._tempGroup = oldQGroupIdx + 1;
                    this.questionGroups[oldQGroupIdx][i].group = oldQGroupIdx + 1;
                    this.questionGroups[oldQGroupIdx][i].order = i + 1;
                }
                for (var i = 0; i < this.questionGroups[newQGroupIdx].length; i++) {
                    this.questionGroups[newQGroupIdx][i]._tempGroup = newQGroupIdx + 1;
                    this.questionGroups[newQGroupIdx][i].group = newQGroupIdx + 1;
                    this.questionGroups[newQGroupIdx][i].order = i + 1;
                }
            }
            this.questionGroups[oldQGroupIdx].splice();
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },

        leaveDragFromQuestion(event) {
            var target = $(event.target).closest('.managementQns')[0];
            if ($(this.currentDraggedItem).hasClass('managementQns')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        dropToQuestion(event, qGroupIdx, question_idx) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            //alert(JSON.stringify(event));
            var data = JSON.parse(event.dataTransfer.getData('text'));
            if (data.from == 'questionGroupQuestion') {
                var target = $(event.target).closest('.managementQns')[0];
                let bias = 0;
                if ($(target).hasClass('selected-bottom') && data.question.group - 1 != qGroupIdx) {
                    bias = 1;
                }
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
                this.moveQuestionOrder(data.question.group - 1, qGroupIdx, data.question.order - 1, question_idx + bias);
            }
            //clear draggedItem
            this.saveManageQuestions(false, false);
            this.currentDraggedItem = null;
        },
        allowDropToQuestion(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('managementQns')) {
                var target = $(event.target).closest('.managementQns')[0];
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragQuestion(event, model, options) {
            //console.log(event);
            event.dataTransfer.setData('text', JSON.stringify({ question: model, from: options.from }));
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = $(event.target).closest('.managementQns')[0];
        },
        //Dragging question group to question group
        dropToQuestionGroup(event, qGroupIdx) {
            var that = this;
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            //alert(JSON.stringify(event));
            var data = JSON.parse(event.dataTransfer.getData('text'));
            if (data.from == 'questionGroupQuestion') {
                if (this.questionGroups[qGroupIdx].length == 0) {
                    this.dropToQuestion(event, qGroupIdx, 0);
                }
            }
            if (data.from == 'questionGroup') {
                var target = $(event.target).closest('.questionList')[0];
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
                this.questionGroups = this.array_move(this.questionGroups, data.qGroupIdx, qGroupIdx);

                /*for(var i = 0 ; i< this.questionGroups[data.qGroupIdx].length;i++){
						this.questionGroups[data.qGroupIdx][i].group = data.qGroupIdx + 1;
					}
					for(var i = 0 ; i< this.questionGroups[qGroupIdx].length;i++){
						this.questionGroups[qGroupIdx][i].group = qGroupIdx + 1;
					}*/
                for (var i = 0; i < this.questionGroups.length; i++) {
                    for (var j = 0; j < this.questionGroups[i].length; j++) {
                        this.questionGroups[i][j].group = i + 1;
                        this.questionGroups[i][j]._tempGroup = i + 1;
                    }
                }
                this.questionGroups.splice();
                this.saveManageQuestions(false, false);
            }
            //clear draggedItem
            this.currentDraggedItem = null;
        },
        leaveDragFromQuestionGroup(event) {
            var target = $(event.target).closest('.questionList')[0];
            if ($(this.currentDraggedItem).hasClass('questionList')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToQuestionGroup(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('questionList')) {
                var target = $(event.target).closest('.questionList')[0];
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragQuestionGroup(event, qGroupIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData('text', JSON.stringify({ qGroupIdx: qGroupIdx, from: 'questionGroup' }));
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        fetchTemplate() {
            return axios.get('/templates/' + this.activity.uuid);
        },
        fetchActivity() {
            return axios.get('/activities/' + this.activity.uuid);
        },
        setAllQuestionsAsOnePage() {
            let questions = [];
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                questions = questions.concat(qGroup);
            }
            this.questionGroups = [];
            this.addQuestionGroup(questions, false);
            for (var i = 0; i < this.questionGroups[0].length; i++) {
                this.questionGroups[0][i].order = i + 1;
                this.questionGroups[0][i]._tempGroup = 1;
                this.questionGroups[0][i].group = 1;
            }
            this.saveManageQuestions(false, false);
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'All questions are now in one page',
            });
        },
        addQuestionGroup(questions = [], notification = true) {
            let length = 0;
            if (!(questions instanceof Array)) {
                questions = [questions];
            }
            length = this.questionGroups.push(questions);
            if (notification) {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'a new page has been added',
                });
            }
            return length;
        },
        spliceQuestionGroup(question_group_idx) {
            this.questionGroups.splice(question_group_idx, 1);
            for (var i = question_group_idx; i < this.questionGroups.length; i++) {
                for (var j = 0; j < this.questionGroups[i].length; j++) {
                    this.questionGroups[i][j].group = i + 1;
                    this.questionGroups[i][j]._tempGroup = i + 1;
                }
            }
        },
        deleteQuestionGroup() {
            $('#' + this.id + ' .deletePageModal').modal('hide');
            let question_group_idx = this.questionGroupToDeleteIdx;
            for (var i = this.questionGroups[question_group_idx].length - 1; i >= 0; i--) {
                this.deleteQuestion(question_group_idx, i, false);
            }
            this.spliceQuestionGroup(question_group_idx);
            this.processDeletions();
        },
        deleteQuestion(qGroupIdx, questionIdx, apiCall = true) {
            $('.deleteQnsModal').modal('hide');
            this.questionGroups[qGroupIdx][questionIdx].order = null;
            this.questionGroups[qGroupIdx][questionIdx].group = null;
            this.deleted_questions.push(this.questionGroups[qGroupIdx][questionIdx]);

            this.questionGroups[qGroupIdx].splice(questionIdx, 1);

            for (var i = 0; i < this.questionGroups[qGroupIdx].length; i++) {
                this.questionGroups[qGroupIdx][i].order = i + 1;
            }
            if (apiCall) {
                this.processDeletions();
            }
        },
        processDeletions() {
            var that = this;
            let toDelete = [];

            for (var i = 0; i < this.deleted_questions.length; i++) {
                toDelete.push(this.deleted_questions[i].uuid);
            }
            var ret = { uuids: toDelete };
            if (this.target) {
                ret.target = this.target;
            }
            /*Splice Question groups again to be sure*/
            for (var i = this.questionGroups.length - 1; i >= 0; i--) {
                if (this.questionGroups[i].length == 0) {
                    this.spliceQuestionGroup(i);
                }
            }
            let newOrder = [];
            for (var i = 0; i < this.questionGroups.length; i++) {
                for (var j = 0; j < this.questionGroups[i].length; j++) {
                    newOrder.push({
                        uuid: this.questionGroups[i][j].uuid,
                        group: i + 1,
                        order: j + 1,
                    });
                }
            }
            axios.post(`/templates/${this.activity.uuid}/questions/bulk/delete`, ret).then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Question/s has been removed from the page.',
                });
                if (newOrder.length > 0) {
                    axios
                        .put(`templates/${that.activity.uuid}/questions/bulk/update`, {
                            data: newOrder,
                        })
                        .then(function (response) {})
                        .catch(function (errors) {});
                }
            });
        },
        saveManageQuestions(exitOnSave = false, goToNextStep = true) {
            var that = this;
            let toUpdate = [];

            /*Delete question groups without questions*/
            for (var i = this.questionGroups.length - 1; i >= 0; i--) {
                if (this.questionGroups[i] == 0) {
                    for (var j = i; j < this.questionGroups.length; j++) {
                        for (var k = 0; k < this.questionGroups[j].length; k++) {
                            this.questionGroups[j][k]._tempGroup = j;
                        }
                    }
                    this.questionGroups.splice(i, 1);
                }
            }
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    let question = qGroup[j];
                    toUpdate.push({
                        uuid: question.uuid,
                        group: i + 1,
                        order: j + 1,
                    });
                }
            }
            var ret = { data: toUpdate };
            if (this.target) {
                ret.target = this.target;
            }
            if (toUpdate.length == 0) {
                if ((exitOnSave === true && this.activity.type == 'michaelsenEvaluation') || this.activity.type != 'michaelsenEvaluation') {
                    that.$emit('save-and-exit');
                } else {
                    if (that.pageType == 'form' && goToNextStep) {
                        that.$emit('next-step');
                    }
                }
                return;
            }
            axios.put('/activities/' + this.activity.uuid + '/questions/bulk/update', ret).then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Activity questions have sucessfully been updated',
                });
                that.fetchActivity().then(function (response) {
                    that.activity = response.data.data;
                    that.$emit('activity-updated', that.activity);
                    that.init(true);
                    if (exitOnSave === true) {
                        that.$emit('save-and-exit');
                    } else {
                        if (that.pageType == 'form' && goToNextStep) {
                            that.$emit('next-step');
                        }
                        //that.manage = false;
                    }
                });
            });
        },
        saveSelectedQuestions() {
            var that = this;
            var data = [];
            let currentqGroupCount = this.questionGroups.length;
            let currentZeroGroupCount = _.get(this.questionGroups, '0', []).length + 1;
            for (var i = 0; i < this.selected_questions.length; i++) {
                if (!['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
                    data.push({
                        uuid: this.selected_questions[i].uuid,
                        group: ++currentqGroupCount,
                        order: 1,
                    });
                } else {
                    data.push({
                        uuid: this.selected_questions[i].uuid,
                        group: 1,
                        order: currentZeroGroupCount + i,
                    });
                }
            }
            var ret = { data: data };
            if (this.target) {
                ret.target = this.target;
            }
            axios.post(`/templates/${this.activity.uuid}/questions/import`, ret).then(function (response) {
                that.fetchTemplate().then(function (response) {
                    that.activity = response.data.data;
                    that.$emit('activity-updated', that.activity);
                    that.init();
                });
                that.selected_questions = [];
                that.addQuestionBankView = false;
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Questions have been successfully added',
                });
            });
        },
        //for bank selection methods
        addToSelectedQuestions(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1 && !this.isQuestionSelected(question) && !this.isQuestionSaved(question)) {
                this.selected_questions.push(question);
            }
        },
        deleteFromSelectedQuestions(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                this.selected_questions.push(question);
            }
            this.selected_questions.splice(idx, 1);
        },
        //for question bank tagging
        isQuestionSelected(question) {
            let idx = _.findIndex(this.selected_questions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                return false;
            }
            return true;
        },
        isQuestionSaved(question) {
            let idx = -1;
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                idx = _.findIndex(qGroup, function (o) {
                    return o.referenceQuestionUuid == question.uuid;
                });
                if (idx != -1) {
                    return true;
                }
            }
            return false;
        },
        //for question bank callback
        getQuestionData(data) {
            this.questionSearchData = data;
        },
        editQuestion(question) {
            Events.fire((this.target ? this.target : '') + 'open-question-edit', {
                question: question,
                url: '/templates/' + this.activity.uuid + '/questions/' + question.uuid,
                method: 'patch',
                allowedTypes: this.allowedQuestionTypes,
                showRequiredField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type),
                showCanAllowStudentAttachmentsField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type) ? false : true,
                showIsElaborationRequiredField: ['application'].includes(this.activityObj.type),
            });
        },
        createQuestionForm() {
            Events.fire((this.target ? this.target : '') + 'open-question-create-form', {
                url: '/activities/' + this.activity.uuid + '/questions',
                allowedTypes: this.allowedQuestionTypes,
                showRequiredField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type),
                showCanAllowStudentAttachmentsField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type) ? false : true,
                showIsElaborationRequiredField: ['application'].includes(this.activityObj.type),
            });
        },
        createQuestionCopy() {
            Events.fire((this.target ? this.target : '') + 'open-question-create-copy', {
                url: '/activities/' + this.activity.uuid + '/questions/store-pasted',
                allowedTypes: this.allowedQuestionTypes,
                showRequiredField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type),
                showCanAllowStudentAttachmentsField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type) ? false : true,
            });
        },
        createQuestionUpload() {
            Events.fire((this.target ? this.target : '') + 'open-question-create-upload', {
                url: '/activities/' + this.activity.uuid + '/questions/store-uploaded',
                allowedTypes: this.allowedQuestionTypes,
                showRequiredField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type),
                showCanAllowStudentAttachmentsField: ['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type) ? false : true,
            });
        },
        init(syncOldQuestionGroups = false) {
            this.questionGroups = [];
            if (!['evaluation', 'michaelsenEvaluation'].includes(this.activity.type)) {
                this.questionGroups = _.get(this.activity, 'questions', []);
            } else {
                //evaluation
                if (this.target == 'peer') {
                    this.questionGroups = _.get(this.activity, 'peerQuestions', []);
                } else if (this.target == 'team') {
                    this.questionGroups = _.get(this.activity, 'teamQuestions', []);
                } else {
                    this.questionGroups = _.get(this.activity, 'instructorQuestions', []);
                }
            }
            for (var i = 0; i < this.questionGroups.length; i++) {
                let qGroup = this.questionGroups[i];
                for (var j = 0; j < qGroup.length; j++) {
                    qGroup[j]._tempGroup = i + 1;
                }
            }
            if (syncOldQuestionGroups) {
                this.oldQuestionGroups = _.cloneDeep(this.questionGroups);
            }
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        copyQuestion(question) {
            this.previewQuestion = question;
            $('.copyQuestionModal').modal('show');
        },
        makeACopy(id) {
            var that = this;
            axios
                .post(`/activities/${this.activity.uuid}/questions/${id}/duplicate`)
                .then(function (response) {
                    $('html, body').animate({ scrollTop: document.body.scrollHeight }, 'slow');

                    that.fetchActivity().then(function (response) {
                        var questions = response.data.data.questions;
                        questions[questions.length - 1][0]._isCopied = true;

                        that.activity = response.data.data;

                        console.log(that.activity);
                        that.$emit('activity-updated', that.activity);
                        that.init();
                    });

                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy question at this moment, please try again later',
                    });
                });

            $('.copyQuestionModal').modal('hide');
        },
        openShowMoreDetailModal(question) {
            this.previewQuestion = question;
            this.topicsLength = question.topics.length;
            $('.showMoreDetailModal').modal('show');
        },
    },
};
</script>
<style lang="scss" scoped>
.questionIndexPanel .paginationCons {
    margin-top: 30px;
}

.flexSpaceBetween > button {
    border: none;
    padding: none;
}

.flexSpaceBetween > button .fa {
    color: #717171;
}

@media (min-width: 992px) {
    .questionIndexPanel {
        padding-left: 20px;
    }

    .flexSpaceBetween > button {
        border: 1px solid #d8d8d8;
        text-align: center;
        padding: 10px 20px;
        line-height: 1;
        border-radius: 3px;
    }

    .flexSpaceBetween > button .fa {
        font-size: 12px;
    }

    .table-responsive.creation {
        margin-bottom: 0;
    }
}

.questionList {
    margin-bottom: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.questionList:last-child {
    margin-bottom: 0;
}

.questionList .header {
    padding: 15px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
}

.questionList .content {
    padding: 20px 25px;
}

.managementQns .lefts {
    background-color: #f6f8fa;
}

.managementQns .center,
.managementQns .right {
    background-color: #fff;
}

.managementQns .center .header {
    background: none;
    text-align: left;
    color: #717171;
    border-bottom: 0;
    font-size: 12px;
    padding: 0;
}

.managementQns .center .content {
    padding: 10px 0 15px;
    line-height: 1.29;
}

.managementQns .center .footer {
    color: #717171;
    font-size: 12px;
}

@media (min-width: 992px) {
    .managementQns .lefts:first-child {
        border: 1px solid #d8d8d8;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .lefts {
        width: 30%;
        max-width: 150px;
        padding: 15px;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
    }

    .managementQns .lefts .form-dropdown {
        min-width: auto;
    }

    .managementQns .center {
        min-width: 288px;
        width: 100%;
        border: 1px solid #d8d8d8;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .right {
        border: 1px solid #d8d8d8;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .managementQns .center .footer div {
        margin-left: 20px;
    }

    .managementQns .center .footer div:first-child {
        margin-left: 0;
    }

    .managementQns {
        margin-top: 20px;
    }

    .managementQns:first-child {
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .managementQns .lefts {
        padding: 10px 20px;
    }

    .managementQns .center {
        padding: 20px;
    }

    .managementQns .right {
        padding: 0 20px 20px;
    }

    .managementQns .center .footer div {
        margin-top: 10px;
    }

    .managementQns .center .footer div:first-child {
        margin-top: 0;
    }
}

.qnsOptions {
    margin-top: 20px;
}

.optionKey {
    font-weight: bold;
}

.height550px {
    max-height: 550px;
    overflow: auto;
}
</style>
