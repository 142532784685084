//header setting moved to app.js
/*window.axios.defaults.headers.common = {
    //'X-CSRF-TOKEN': window.Laravel.csrfToken,
    //'X-Requested-With': 'XMLHttpRequest',
    //'userId':window.Laravel.user_id,
};*/
import KrAuth from './components/auth/auth.js';
import { notify } from '@kyvg/vue3-notification';
import { router } from './router';
import { globalMixins } from './mixins/global.js';

import Bugsnag from '@bugsnag/js';
axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';
axios.defaults.timeout = 60000;
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        let auth = new KrAuth();
        if (auth.isLoggedIn()) {
            config.baseURL = auth.credential().API_URL;
        }
        if (auth.getAuthHeader()) {
            config.headers['Authorization'] = auth.getAuthHeader();
        }
        if (_.get(config, '_internal.hasLoadingBar', true)) {
            $('#loading').removeClass('hide').addClass('show');
        }
        return config;
    },
    function (error) {
        // Do something with response error
        if (Bugsnag.isStarted()) {
            Bugsnag.notify(error);
        }
        // Do something with request error
        setTimeout(function () {
            $('#loading').removeClass('show').addClass('hide');
        }, 50);

        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        setTimeout(function () {
            $('#loading').removeClass('show').addClass('hide');
        }, 50);
        let unixServerTime = moment.utc(response.headers['date'], 'ddd, DD MMM YYYY HH:mm:ss [GMT]', true).unix();
        //console.log(response.headers['date'])
        //console.log('---converted unix---')
        //console.log(unixServerTime)
        //console.log('---end---')
        globalMixins.methods.setMomentOffset(unixServerTime);
        return response;
    },
    function (error) {
        setTimeout(function () {
            $('#loading').removeClass('show').addClass('hide');
        }, 50);
        if (!error.response) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
            // network error
            axios.Cancel('Network Error');
        } else if (error.response.status == 401 && _.get(error, 'response.data.status_code') != 200) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Session Expired',
            });
            Events.fire('logout', { from: 'AXIOS_401' });
            axios.Cancel('Session Expired');
        } else if (error.response.status == 404 && _.get(error, 'response.data.status_code') != 200) {
            let auth = new KrAuth();
            //else if(error.response.status==401&&_.get(error,'response.data.message')=='EXP'){
            if (auth.isLoggedIn()) {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Not Found Error',
                });
                //Events.fire('logout',{'redirect':{name:'auth.login'}});
                //window.location.reload();
                axios.Cancel('Not Found Error');
            }
        } else if (error.response.status == 403) {
            router.push({ name: 'errors.403' });
        } else if (error.code == 'ECONNABORTED') {
            Events.fire('axios_timed_out');
            axios.Cancel('API Connection Timed out');
        }
        // Do something with response error
        if (Bugsnag.isStarted()) {
            Bugsnag.notify(error);
        }
        return Promise.reject(error);
    }
);
