/*Kelvin Roger Ang Yap 2017
	krayap@gmail.com
*/
import Errors from './errors';
import { notify } from '@kyvg/vue3-notification';

export default class KrForm {
    constructor(data) {
        this.model = {};

        /*URLS for crud operations*/
        this.urls = {};
        this.urls.put = _.get(data, 'urls.put', '');
        this.urls.post = _.get(data, 'urls.post', '');
        this.urls.delete = _.get(data, 'urls.delete', '');
        this.urls.get = _.get(data, 'urls.get', '');
        this.urls.patch = _.get(data, 'urls.patch', '');
        /*Form errors here*/
        this.errors = new Errors();

        /*Internal data for use with inside class only*/
        this.internal = {};
        //flag if form is currently busy
        this.internal.busy = false;
        //retrieveFlag almost always on update
        this.internal.remote = false;
        this.internal.lastSendType = '';
        //delay rendering
        this.internal.delayRendering = false;
        this.internal.fields = {};

        this.internal.headers = false;
        //get rules
        for (let field in data.fields) {
            this.internal.fields[field] = {};
            var a = data.fields[field].split('|');
            //console.log(a);
            for (let a_count in a) {
                //console.log(a[a_count]);
                let rules = a[a_count].split('!:');
                //console.log(rules);
                if (rules[0] === 'required') {
                    this.internal.fields[field]['required'] = true;
                }
                if (rules[0] === 'readonly') {
                    this.internal.fields[field]['readonly'] = true;
                } else if (rules[0] === 'hidden') {
                    this.internal.fields[field]['hidden'] = true;
                } else if (rules[0] === 'disabled') {
                    this.internal.fields[field]['disabled'] = true;
                } else if (rules[0] === 'default') {
                    if (rules.length < 2) {
                        throw 'KRAYAP FORM: Default rule requires a parameter, none given: ' + rules[0];
                    } else {
                        this.internal.fields[field]['default'] = rules[1];
                    }
                } else if (rules[0] === 'type') {
                    if (rules.length < 2) {
                        throw 'KRAYAP FORM: Default rule requires a parameter, none given: ' + rules[0];
                    } else {
                        this.internal.fields[field]['type'] = rules[1];
                    }
                } else if (rules[0] === 'sendType') {
                    if (rules.length < 2) {
                        throw 'KRAYAP FORM: Default rule requires a parameter, none given: ' + rules[0];
                    } else {
                        this.internal.fields[field]['sendType'] = rules[1];
                    }
                } else if (rules[0] === 'sendNull') {
                    if (rules.length < 2) {
                        throw 'KRAYAP FORM: Default rule requires a parameter, none given: ' + rules[0];
                    } else {
                        this.internal.fields[field]['sendNull'] = rules[1];
                    }
                } else {
                    if (rules[0]) {
                        throw 'KRAYAP FORM: No such rule: ' + rules[0];
                    }
                }
            }
        }
        /*Pass required fields to form class*/
        /*
			fireOnSuccess -string- the event to fire if form 
								succeded
			fireOnFail -string- event to fire if form failed.

			autoReset = false,
		*/
        this.props = data.props || {};
        if (!this.props.manualReset == undefined) {
            this.props.manualReset = false;
        }
        if (this.props.successMessage == undefined) {
            this.props.successMessage = true;
        }
        if (this.props.failureMessage == undefined) {
            this.props.failureMessage = true;
        }
        if (this.props.headers != undefined) {
            this.internal.headers = this.props.headers;
        }
        /*custom column properties*/
        /*For loop here to initialize model variables*/
        /*Initialize first so the child components will be able to bind even if 
		we are retrieving model frrom the url*/
        this.initializeModelFields();
    }
    initializeModelFields(model = null) {
        for (var field in this.internal.fields) {
            //console.log(field);
            if (model != null && model.hasOwnProperty(field) && model[field] != null) {
                this.model[field] = _.cloneDeep(model[field]);
            } else {
                if (this.internal.fields[field]['type']) {
                    if (this.internal.fields[field]['type'] === 'array' || this.internal.fields[field]['type'] === 'array-files') {
                        this.model[field] = [];
                        continue;
                    }
                    if (this.internal.fields[field]['type'] === 'object') {
                        this.model[field] = {};
                        continue;
                    }
                }
                if (this.internal.fields[field]['default']) {
                    this.model[field] = this.internal.fields[field]['default'];
                    continue;
                }
                this.model[field] = null;
            }
        }
    }
    reset(sendType = null) {
        this.errors.clear();
        /*On Create Delete the form variables*/
        if (sendType == 'post') {
            this.initializeModelFields();
        }
    }
    /*for setting models*/
    setModel(model) {
        this.reset();
        this.initializeModelFields(model);
    }
    //set model field. for key dependencies
    setModelField(key, value) {
        this.model[key] = value;
    }
    get(url = null) {
        return this.submit('get', url);
    }
    patch(url = null) {
        return this.submit('patch', url);
    }
    post(url = null, options = {}) {
        return this.submit('post', url, options);
    }
    put(url = null, options = {}) {
        return this.submit('put', url, options);
    }
    remove(url = null) {
        return this.submit('delete', url);
    }
    /*Private function that processes url*/
    processURL(url) {
        var matched = function (match) {
            //console.log(1);
            //console.log(match);
            return this.model[match];
        }.bind(this);
        //console.log('processing url');
        return url.replace(/\{([^}]+)\}/g, function (x) {
            //console.log(x);
            return matched(x.replace(/[{}]/g, ''));
        });
    }
    /*get String URL*/
    getUrl(sendType) {
        if (sendType === 'post') {
            return this.processURL(this.urls.post);
        }
        if (sendType === 'patch') {
            return this.processURL(this.urls.patch);
        }
        if (sendType === 'put') {
            return this.processURL(this.urls.put);
        }
        if (sendType === 'delete') {
            return this.processURL(this.urls.delete);
        }
        if (sendType === 'get') {
            return this.processURL(this.urls.get);
        }
    }
    data(sendType) {
        var data = {};
        for (let field in this.model) {
            //hackish way because isEmpty treats integers as null
            //console.log(this.internal.fields[field]);

            let send = null;
            try {
                send = this.internal.fields[field].sendType;
            } catch (e) {
                throw 'KRAYAP FORM: field ' + field + ' not part of definition';
            }

            if (send) {
                //if may default send type
                if (send == 'json') {
                    data[field] = JSON.stringify(this.model[field]);
                    continue;
                }
            }
            //default
            let type = this.internal.fields[field].type;
            //file types should be escaped
            if (type) {
                if (type === 'file') {
                    /*Fix this binary encode*/
                    if (!(this.model[field] instanceof File)) {
                        continue;
                    }
                }
                if (type === 'object') {
                    //data[field] = JSON.stringify(this.model[field]);
                    data[field] = this.model[field];
                    continue;
                }
                if (type === 'array') {
                    data[field] = this.model[field];
                    continue;
                }
            }

            if (this.internal.fields[field]['readonly']) {
                //do not set into form data!
                continue;
            }
            if (this.internal.fields[field]['sendNull'] == 'false') {
                if (_.isEmpty(this.model[field]) || this.model[field] == null || this.model[field] == '') {
                    continue;
                }
            }
            //data[field] = this.model[field];
            if (!_.isEmpty(this.model[field]) || this.model[field] != null) {
                data[field] = this.model[field];
            }
        }
        //laravel cheat for updating!
        /*if(sendType==='put'||sendType==='delete'||sendType==="patch"){
			data['_method'] = sendType;
		}*/
        //console.log(data.getAll());
        return data;
    }
    /*Wrapping in resolve*/
    submit(requestType, url, options = {}) {
        this.internal.busy = true;
        if (url === null) {
            url = this.getUrl(requestType);
        }
        var tempRequestType = requestType;
        //For OnSuccess
        this.internal.lastSendType = requestType;
        /*if(requestType==="put"){
			//laravel cheat our way!
			tempRequestType = "post";
		}
		if(requestType==="patch"){
			//laravel cheat our way!
			tempRequestType = "post";
		}*/
        let data = options.data ? options.data : this.data(requestType);
        if (this.internal.headers == false) {
            //header sa app ko
            return new Promise((resolve, reject) => {
                axios({ method: tempRequestType, url: url, data: data, responseType: options.responseType ? options.responseType : 'json' })
                    .then((response) => {
                        this.onSuccess(response);
                        response.krFormError = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.onFail(error);

                        if (!error.response) {
                            error.response = {};
                        }
                        //throw an error but handle gracefully
                        error.response.krFormError = true;
                        resolve(error.response);
                    });
            });
        } else {
            return new Promise((resolve, reject) => {
                axios({
                    method: tempRequestType,
                    url: url,
                    data: this.data,
                    headers: this.internal.headers,
                    responseType: options.responseType ? options.responseType : 'json',
                })
                    .then((response) => {
                        this.onSuccess(response);
                        response.krFormError = false;
                        resolve(response);
                    })
                    .catch((error) => {
                        this.onFail(error);

                        if (!error.response) {
                            error.response = {};
                        }
                        //throw an error but handle gracefully
                        error.response.krFormError = true;
                        resolve(error.response);
                    });
            });
        }
    }
    onSuccess(response) {
        //console.log(response);
        this.internal.busy = false;

        if (this.props.successMessage) {
            if (_.get(response, 'data.message', null)) {
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: response.data.message.replace(/\n\r?/g, '<br />'),
                });
            } else {
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Your action has succeeded',
                });
            }
        }
        if (_.get(this, 'props.fireOnSuccess')) {
            Events.fire(this.props.fireOnSuccess);
        }
        if (!this.props.manualReset) {
            this.reset(this.internal.lastSendType);
        }
    }
    onFail(errors) {
        this.internal.busy = false;

        if (this.props.failureMessage) {
            /*If Message*/
            if (_.get(errors, 'response.data.custom_message', false) && _.isString(errors.response.data.custom_message)) {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: errors.response.data.custom_message.replace(/\n\r?/g, '<br />'),
                });
            } else {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Form submission has some errors',
                });
            }
        }
        /*Fire Global*/
        //Events.fire('refresh_list');
        if (_.get(errors, 'response.data.errors', false)) {
            this.errors.record(errors.response.data.errors);
        } else if (_.get(errors, 'response.data.message', false)) {
            this.errors.record(errors.response.data.message);
        }
    }
    /*Check if form is currently busy*/
    isBusy() {
        return this.internal.busy;
    }
    /*Check if Prevent Rendering before Ajax Retrival*/
    isDelayRendering() {
        return this.internal.delayRendering;
    }
    isDisabled(name) {
        var bool = _.get(this, 'internal.fields.' + name + '.disabled');
        if (bool) {
            return true;
        } else {
            return false;
        }
    }
    setDisabled(name, bool) {
        this.internal.fields[name].disabled = bool;
    }
    isHidden(name) {
        var bool = _.get(this, 'internal.fields.' + name + '.hidden');
        if (bool) {
            return true;
        } else {
            return false;
        }
    }
    isReadonly(name) {
        var bool = _.get(this, 'internal.fields.' + name + '.readonly');
        if (bool) {
            return true;
        } else {
            return false;
        }
    }
}
