<template>
    <div v-if="component_done_loading" class="test" :class="{ disabledImagePointerEvents: !test.settings.enableImagePointerEvents }">
        <test-topbar :test-obj="test" />
        <div class="col-xs-12 right-container clarification">
            <div class="flex">
                <div class="col-xs-12 marginBottom20">
                    <team-leadership :test-obj="test" @is-team-leader="processIsTeamLeader($event)" />
                </div>
            </div>
            <div v-if="test.clarificationStatus == 'released'" class="alert alert-primary align-items marginBottom20">
                <div><i class="fa fa-info-circle marginRight8" aria-hidden="true" />Please note that your team is highlighted in</div>
                <div class="statusTag statusTagLightWarning subBtn marginLeft5">
                    <i class="fa fa-users greyText marginRight8" aria-hidden="true" />Your team name
                </div>
            </div>
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-md-6">
                        <label> All Clarifying Questions ({{ clarified_questions.length }}) </label>
                    </div>
                </template>
                <template #content>
                    <div class="hidden-md hidden-lg marginBottom20">
                        <template v-if="canEditClarifications">
                            <button class="btn btn-primary subBtn" aria-label="New Freestyle" @click.prevent="openRequestModal(add_question)">
                                <i class="fas fa-plus marginRight8" aria-hidden="true" />New Freestyle
                            </button>
                        </template>
                    </div>
                    <div v-for="(question, question_idx) in clarified_questions" class="hidden-lg hidden-md mini-panel panel">
                        <div class="panel-heading">
                            <template v-if="question._type == 'question'">
                                Question {{ question.question_no }}
                                <i
                                    v-tooltip="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                    :class="{
                                        'fa bookmarked': question.studentAnswer.isBookmarked,
                                        'fa-regular': !question.studentAnswer.isBookmarked,
                                    }"
                                    class="fa-bookmark"
                                    :aria-label="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                />
                            </template>
                            <template v-else> Freestyle </template>
                        </div>
                        <div class="panel-body">
                            <div>
                                <div v-if="question._type == 'question'">
                                    <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML" />
                                    <div class="marginTop10 fontBold blue pointer" aria-label="Read More" @click.prevent="openQuestionInfoModal(question)">
                                        Read More<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="alert alert-primary marginTop10">
                                        <div>
                                            <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                        </div>
                                        &nbsp;
                                        <span>
                                            For freestyle clarification, you may type in any clarifying questions OR vote for questions raised by other teams if
                                            your team has a similar question about the test.
                                        </span>
                                        <div>
                                            <button type="button" class="close minWidthAuto" data-dismiss="modal" aria-label="Close Modal">
                                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="question._type == 'question'" class="marginTop20">
                                <label class="control-label">Answers choices in sequence</label>
                                <br />
                                <div
                                    v-if="
                                        question._type == 'question' && (question.studentAnswer.attempts ? question.studentAnswer.attempts.length != 0 : false)
                                    "
                                    class="statusTag whiteSpaceNowrap"
                                    :class="{
                                        tagCorrect: question.percent == 100,
                                        tagPrimary: question.percent >= 50 && question.percent < 100,
                                        tagWarning: question.percent < 50 && question.percent > 0,
                                        tagIncorrect: question.percent == 0,
                                    }"
                                >
                                    <template v-for="(key, idx) in question.studentAnswer.attempts">
                                        <span class="paddingTop2">
                                            {{ key }}
                                            <template v-if="idx != question.studentAnswer.attempts.length - 1">&nbsp;</template>
                                        </span>
                                    </template>
                                </div>
                                <template v-else> - </template>
                            </div>
                            <div class="marginTop20">
                                <div class="flex align-items">
                                    <label class="control-label mariginRight10">We would like to clarify...</label>
                                    <div v-if="question._status != 'voted' && !(!question._status && !canEditClarifications)">
                                        <template v-if="canEditClarifications">
                                            <button
                                                v-if="question._status || question._type == 'freestyle'"
                                                v-tooltip="
                                                    question._type == 'question'
                                                        ? 'Edit clarification for question ' + question.question_no
                                                        : question._type == 'freestyle'
                                                        ? 'Edit freestyle clarification'
                                                        : ''
                                                "
                                                class="btn btn-primary minWidthAuto"
                                                :aria-label="
                                                    question._type == 'question'
                                                        ? 'Edit clarification for question ' + question.question_no
                                                        : question._type == 'freestyle'
                                                        ? 'Edit freestyle clarification'
                                                        : ''
                                                "
                                                @click.prevent="openRequestModal(question)"
                                            >
                                                <i class="fa fa-edit" aria-hidden="true" />
                                            </button>
                                            <button
                                                v-else
                                                v-tooltip="question.studentAnswer.attempts.length == 0 ? 'You did not submit any answer' : ''"
                                                class="btn btn-primary"
                                                :class="{
                                                    'disabled hasToolTip': question.studentAnswer.attempts.length == 0,
                                                }"
                                                :aria-label="
                                                    question.studentAnswer.attempts.length > 0 && question._type == 'question'
                                                        ? 'Request clarification for question ' + question.question_no
                                                        : question._type == 'freestyle'
                                                        ? 'Request freestyle clarification'
                                                        : ''
                                                "
                                                @click.prevent="question.studentAnswer.attempts.length == 0 ? '' : openRequestModal(question)"
                                            >
                                                Request
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button
                                                v-if="question._status"
                                                v-tooltip="
                                                    question._type == 'question'
                                                        ? 'View question ' + question.question_no + ' clarification'
                                                        : question._type == 'freestyle'
                                                        ? 'View freestyle calrification'
                                                        : ''
                                                "
                                                class="btn btn-primary minWidthAuto"
                                                :aria-label="
                                                    question._type == 'question'
                                                        ? 'View question ' + question.question_no + ' clarification'
                                                        : question._type == 'freestyle'
                                                        ? 'View freestyle calrification'
                                                        : ''
                                                "
                                                @click.prevent="openRequestModal(question)"
                                            >
                                                <i class="fas fa-eye" aria-hidden="true" />
                                            </button>
                                        </template>
                                    </div>
                                </div>
                                <template v-if="question._type == 'question'">
                                    <div
                                        v-if="question.studentAnswer.attachments && question.studentAnswer.attachments.length != 0"
                                        class="flex align-items marginBottom20 col-xs-12 col-md-12"
                                    >
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in question.studentAnswer.attachments" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.</span>
                                                    <span>
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="question._type == 'freestyle'">
                                    <div
                                        v-if="question.attachments && question.attachments.length != 0"
                                        class="flex align-items marginBottom20 col-xs-12 col-md-12"
                                    >
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in question.attachments" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.</span>
                                                    <span>
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="question._type == 'question'">
                                    <div class="heightMax100px">
                                        {{ question.studentAnswer.clarifications }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="heightMax100px">
                                        {{ question.freestyle }}
                                    </div>
                                </template>
                            </div>
                            <div v-if="question._status" class="marginTop20">
                                <div class="control-label">MY TEAM HAS…</div>
                                <div class="fontBold flexOnly">
                                    <i class="fa fa-check-circle textSuccess marginRight8" aria-hidden="true" />
                                    <template v-if="question._status == 'requested'">
                                        <span> Requested </span>
                                    </template>
                                    <template v-if="question._status == 'voted'">
                                        <span> Voted </span>
                                    </template>
                                </div>
                            </div>
                            <div v-if="canEditClarifications && question._status" class="marginTop20">
                                <button class="col-xs-12 btn btn-danger subBtn" aria-label="Remove" @click.prevent="removeClarifications(question)">
                                    Remove<i class="fa fa-trash marginLeft8" aria-hidden="true" />
                                </button>
                            </div>
                            <div v-if="question._type == 'question' && test.clarificationStatus == 'released'" class="marginTop20">
                                <div class="control-label">OTHER TEAM CLARIFICATIONS</div>
                                <template v-for="(request, request_idx) in question.otherClarifications">
                                    <div class="marginTop20">
                                        <div class="col-xs-6 buttonGroup">
                                            <button
                                                class="btn gradient btn-default subBtn flex1"
                                                :aria-label="request.team"
                                                @click.prevent="openTeamInfoModal(request)"
                                            >
                                                {{ request.team }}
                                            </button>
                                            <button
                                                v-if="request.attachments && request.attachments.length != 0"
                                                class="btn gradient btn-default subBtn flex1"
                                                aria-label="Team's Attachments"
                                                @click.prevent="
                                                    openTeamAttachmentsModal({
                                                        name: request.team,
                                                        attachments: request.attachments,
                                                    })
                                                "
                                            >
                                                <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                            </button>
                                        </div>
                                        <div class="marginTop10">
                                            {{ request.clarifications }}
                                        </div>
                                    </div>
                                </template>
                                <template v-if="!question.otherClarifications || (question.otherClarifications && question.otherClarifications.length == 0)">
                                    NO OTHER CLARIFICATIONS
                                </template>
                            </div>
                            <div
                                v-if="question._type == 'freestyle' && test.clarificationStatus == 'released' && !question.isRequestedByMe"
                                class="marginTop20"
                            >
                                <div class="control-label">TEAM WHO REQUESTED</div>
                                <div class="marginTop20">
                                    <div class="col-xs-6 buttonGroup">
                                        <button
                                            class="btn gradient btn-default subBtn flex1"
                                            :aria-label="question.requestedBy.name"
                                            @click.prevent="openTeamInfoModal(question.requestedBy)"
                                        >
                                            {{ question.requestedBy.name }}
                                        </button>
                                        <button
                                            v-if="question.attachments && question.attachments.length != 0"
                                            class="btn gradient btn-default subBtn flex1"
                                            aria-label="Team's Attachments"
                                            @click.prevent="
                                                openTeamAttachmentsModal({
                                                    name: question.requestedBy.name,
                                                    attachments: question.attachments,
                                                })
                                            "
                                        >
                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                        </button>
                                    </div>
                                    <div class="marginTop10">
                                        {{ question.freestyle }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="test.clarificationStatus == 'released'" class="marginTop20">
                                <div class="control-label">TEAMS ASSIGNED</div>
                                <div v-for="(team, team_idx) in question.assignedTo" class="marginTop10">
                                    <button
                                        v-if="team.uuid != test.myProgression.team.uuid"
                                        class="btn gradient btn-default subBtn"
                                        :aria-label="team.name"
                                        @click.prevent="openTeamInfoModal(team)"
                                    >
                                        <i class="fa fa-users marginRight8" aria-hidden="true" />{{ team.name }}
                                        <!--<i class="fas fa-times"></i>-->
                                    </button>
                                    <button
                                        v-else
                                        class="statusTag statusTagLightWarning subBtn"
                                        :aria-label="team.name"
                                        @click.prevent="openTeamInfoModal(team)"
                                    >
                                        <i class="fa fa-users greyText marginRight8" aria-hidden="true" />{{ team.name }}
                                        <!--<i class="fas fa-times"></i>-->
                                    </button>
                                </div>
                                <template v-if="!question.assignedTo || (question.assignedTo && question.assignedTo.length == 0)"> NO TEAMS ASSIGNED </template>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="hidden-xs hidden-sm table">
                            <thead>
                                <tr>
                                    <th style="width: 5%">Bookmark</th>
                                    <th style="width: 30%">Areas That Need Clarification</th>
                                    <th>Answers Choices In Sequence</th>
                                    <th style="width: 25%">We Would Like To Clarify...</th>
                                    <th style="width: 144px">My Team Has...</th>
                                    <th v-if="canEditClarifications" style="width: 125px">Actions</th>
                                    <th v-if="test.clarificationStatus == 'released'" style="width: 146px">Assigned Team</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(question, questionIdx) in clarified_questions" :key="'clarified-questions-' + questionIdx">
                                    <tr>
                                        <td>
                                            <div v-if="question._type == 'question'" style="text-align: center">
                                                <i
                                                    v-tooltip="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                                    :class="{
                                                        'fa bookmarked': question.studentAnswer.isBookmarked,
                                                        'fa-regular': !question.studentAnswer.isBookmarked,
                                                    }"
                                                    class="fa-bookmark"
                                                    :aria-label="question.studentAnswer.isBookmarked ? 'Is bookmarked' : 'Not bookmark'"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div v-if="question._type == 'question'">
                                                <button
                                                    class="btn gradient btn-default marginBottom20"
                                                    :aria-label="`Question ${question.question_no}`"
                                                    @click.prevent="openQuestionInfoModal(question)"
                                                >
                                                    Question
                                                    {{ question.question_no }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true" />
                                                </button>
                                                <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML" />
                                                <!--<div class="marginTop10 fontBold">
				    							Read More&nbsp;<i class="fa fa-caret-right"></i>
				    						</div>-->
                                            </div>
                                            <div v-else>
                                                <span class="btn gradient btn-default subBtn"> Freestyle </span>
                                                <div class="alert alert-primary marginTop10">
                                                    <div>
                                                        <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                                    </div>
                                                    <span>
                                                        For freestyle clarification, you may type in any clarifying questions
                                                        <!--OR vote for questions raised by other teams if your team has a similar question about the test.-->
                                                    </span>
                                                    <div>
                                                        <button type="button" class="close minWidthAuto" data-dismiss="modal" aria-label="Close Modal">
                                                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                v-if="
                                                    question._type == 'question' &&
                                                    (question.studentAnswer.attempts ? question.studentAnswer.attempts.length != 0 : false)
                                                "
                                                class="statusTag whiteSpaceNowrap"
                                                :class="{
                                                    tagCorrect: question.percent == 100,
                                                    tagPrimary: question.percent >= 50 && question.percent < 100,
                                                    tagWarning: question.percent < 50 && question.percent > 0,
                                                    tagIncorrect: question.percent == 0,
                                                }"
                                            >
                                                <template v-for="(key, idx) in question.studentAnswer.attempts">
                                                    <span class="paddingTop2">
                                                        {{ key }}
                                                        <template v-if="idx != question.studentAnswer.attempts.length - 1">&nbsp;</template>
                                                    </span>
                                                </template>
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="test.clarificationStatus != 'released'">
                                                <template v-if="question._type == 'question'">
                                                    <div
                                                        v-if="question.studentAnswer.attachments && question.studentAnswer.attachments.length != 0"
                                                        class="flex align-items marginBottom20 col-xs-12 col-md-12"
                                                    >
                                                        <div class="col-xs-12">
                                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                                            <div v-for="(file, idx) in question.studentAnswer.attachments" class="flexSpaceBetween">
                                                                <div>
                                                                    <span>{{ idx + 1 }}.</span>
                                                                    <span>
                                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                                    </span>
                                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="question._type == 'freestyle'">
                                                    <div
                                                        v-if="question.attachments && question.attachments.length != 0"
                                                        class="flex align-items marginBottom20 col-xs-12 col-md-12"
                                                    >
                                                        <div class="col-xs-12">
                                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                                            <div v-for="(file, idx) in question.attachments" class="flexSpaceBetween">
                                                                <div>
                                                                    <span>{{ idx + 1 }}.</span>
                                                                    <span>
                                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                                    </span>
                                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                                        file.filename
                                                                    }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-if="question._type == 'question'">
                                                    <span v-if="question.studentAnswer.clarifications" class="whiteSpacePreLine marginRight20">{{
                                                        question.studentAnswer.clarifications
                                                    }}</span>
                                                </template>
                                                <template v-else>
                                                    <span v-if="question.freestyle" class="whiteSpacePreLine marginRight20">{{ question.freestyle }}</span>
                                                </template>
                                                <!-- <a v-if="question._status!='voted'&&canEditClarifications" @click.prevent="question._edit=true;clarified_questions.splice()">EDIT</a> -->
                                                <span v-if="question._status != 'voted' && !(!question._status && !canEditClarifications)">
                                                    <template v-if="canEditClarifications">
                                                        <button
                                                            v-if="question._status || question._type == 'freestyle'"
                                                            class="btn btn-primary minWidthAuto"
                                                            :aria-label="
                                                                question._type == 'question'
                                                                    ? 'Edit clarification for question ' + question.question_no
                                                                    : question._type == 'freestyle'
                                                                    ? 'Edit freestyle clarification'
                                                                    : ''
                                                            "
                                                            @click.prevent="openRequestModal(question)"
                                                        >
                                                            <i class="fa fa-edit" aria-hidden="true" />
                                                        </button>
                                                        <button
                                                            v-else
                                                            v-tooltip="question.studentAnswer.attempts.length == 0 ? 'You did not submit any answer' : ''"
                                                            class="btn btn-primary"
                                                            :class="{
                                                                'disabled hasToolTip': question.studentAnswer.attempts.length == 0,
                                                            }"
                                                            :aria-label="
                                                                question.studentAnswer.attempts.length > 0 && question._type == 'question'
                                                                    ? 'Request clarification for question ' + question.question_no
                                                                    : question._type == 'freestyle'
                                                                    ? 'Request freestyle clarification'
                                                                    : ''
                                                            "
                                                            @click.prevent="question.studentAnswer.attempts.length == 0 ? '' : openRequestModal(question)"
                                                        >
                                                            Request
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button
                                                            v-if="question._status"
                                                            v-tooltip="
                                                                question._type == 'question'
                                                                    ? 'View question ' + question.question_no + ' calrification'
                                                                    : question._type == 'freestyle'
                                                                    ? 'View freestyle calrification'
                                                                    : ''
                                                            "
                                                            class="btn btn-primary minWidthAuto"
                                                            :aria-label="
                                                                question._type == 'question'
                                                                    ? 'View question ' + question.question_no + ' calrification'
                                                                    : question._type == 'freestyle'
                                                                    ? 'View freestyle calrification'
                                                                    : ''
                                                            "
                                                            @click.prevent="openRequestModal(question)"
                                                        >
                                                            <i class="fas fa-eye" aria-hidden="true" />
                                                        </button>
                                                    </template>
                                                </span>
                                            </template>
                                            <template v-else>
                                                <template v-if="question._type == 'question'">
                                                    <!-- <span
                                                        class="btn gradient btn-default-border-primary"
                                                    >
                                                        {{
                                                            (question._status ==
                                                            'requested'
                                                                ? 1
                                                                : 0) +
                                                            question
                                                                .otherClarifications
                                                                .length
                                                        }}
                                                        TEAM<template
                                                            v-if="(question._status == 'requested' ? 1 : 0) + question.otherClarifications.length > 1"
                                                        >
                                                            S
                                                        </template>
                                                    </span> -->
                                                    <template v-if="question._status == 'requested'">
                                                        <div>
                                                            <div
                                                                class="col-xs-6"
                                                                :class="{
                                                                    buttonGroup:
                                                                        question.studentAnswer.attachments && question.studentAnswer.attachments.length != 0,
                                                                }"
                                                            >
                                                                <button
                                                                    class="statusTag statusTagLightWarning subBtn whiteSpaceNowrap"
                                                                    @click.prevent="openTeamInfoModal(test.myProgression.team)"
                                                                >
                                                                    <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                                                    {{ test.myProgression.team.name }}
                                                                </button>
                                                                <button
                                                                    v-if="question.studentAnswer.attachments && question.studentAnswer.attachments.length != 0"
                                                                    class="btn gradient btn-default subBtn whiteSpaceNowrap"
                                                                    aria-label="Team's Attachments"
                                                                    @click.prevent="
                                                                        openTeamAttachmentsModal({
                                                                            name: test.myProgression.team.name,
                                                                            attachments: question.studentAnswer.attachments,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                </button>
                                                            </div>
                                                            <div class="marginTop10">
                                                                {{ question.studentAnswer.clarifications }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-for="(request, request_idx) in question.otherClarifications">
                                                        <div class="marginTop20">
                                                            <div
                                                                class="col-xs-6"
                                                                :class="{
                                                                    buttonGroup: request.attachments && request.attachments.length != 0,
                                                                }"
                                                            >
                                                                <button
                                                                    class="btn gradient btn-default subBtn whiteSpaceNowrap"
                                                                    :aria-label="request.team"
                                                                    @click.prevent="openTeamInfoModal(request)"
                                                                >
                                                                    {{ request.team }}
                                                                </button>
                                                                <button
                                                                    v-if="request.attachments && request.attachments.length != 0"
                                                                    class="btn gradient btn-default subBtn whiteSpaceNowrap"
                                                                    aria-label="Team's Attachments"
                                                                    @click.prevent="
                                                                        openTeamAttachmentsModal({
                                                                            name: request.team,
                                                                            attachments: request.attachments,
                                                                        })
                                                                    "
                                                                >
                                                                    <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                                </button>
                                                            </div>
                                                            <div class="marginTop10">
                                                                {{ request.clarifications }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-if="test.clarificationStatus == 'released' && question._type == 'freestyle'">
                                                <!-- <span
                                                    class="btn gradient btn-default-border-primary"
                                                >
                                                    1 TEAM
                                                </span> -->
                                                <div>
                                                    <div
                                                        class="col-xs-6"
                                                        :class="{
                                                            buttonGroup: question.attachments && question.attachments.length != 0,
                                                        }"
                                                    >
                                                        <button
                                                            v-if="question.requestedBy.uuid != test.myProgression.team.uuid"
                                                            class="btn gradient btn-default subBtn whiteSpaceNowrap"
                                                            :aria-label="question.requestedBy.name"
                                                            @click.prevent="openTeamInfoModal(question.requestedBy)"
                                                        >
                                                            {{ question.requestedBy.name }}
                                                        </button>
                                                        <span
                                                            v-else
                                                            class="statusTag statusTagLightWarning subBtn whiteSpaceNowrap"
                                                            @click.prevent="openTeamInfoModal(question.requestedBy)"
                                                        >
                                                            <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                                            {{ question.requestedBy.name }}
                                                        </span>
                                                        <button
                                                            v-if="question.attachments && question.attachments.length != 0"
                                                            class="btn gradient btn-default subBtn flex1"
                                                            aria-label="Team's Attachments"
                                                            @click.prevent="
                                                                openTeamAttachmentsModal({
                                                                    name: question.requestedBy.name,
                                                                    attachments: question.attachments,
                                                                })
                                                            "
                                                        >
                                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                                        </button>
                                                    </div>

                                                    <div class="marginTop10">
                                                        {{ question.freestyle }}
                                                    </div>
                                                </div>
                                            </template>
                                        </td>
                                        <td>
                                            <div v-if="question._status" class="fontBold flexOnly">
                                                <i class="fa fa-check-circle textSuccess marginRight8" aria-hidden="true" />
                                                <template v-if="question._status == 'requested'">
                                                    <span> Requested </span>
                                                </template>
                                                <template v-if="question._status == 'voted'">
                                                    <span> Voted </span>
                                                </template>
                                            </div>
                                        </td>
                                        <td v-if="canEditClarifications">
                                            <div v-if="question._status" class="hidden-xs hidden-sm buttonGroup">
                                                <button
                                                    class="btn btn-danger subBtn"
                                                    aria-label="Remove"
                                                    @click.prevent="openDeleteClarificationModal(question)"
                                                >
                                                    Remove<i class="fa fa-trash marginLeft8" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <div v-if="question._status" class="hidden-md hidden-lg buttonGroup">
                                                <button
                                                    class="btn btn-danger subBtn"
                                                    aria-label="Remove"
                                                    @click.prevent="openDeleteClarificationModal(question)"
                                                >
                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </td>
                                        <td v-if="test.clarificationStatus == 'released'">
                                            <div v-for="(team, team_idx) in question.assignedTo" class="multiselect marginTop10">
                                                <button
                                                    v-if="team.uuid != test.myProgression.team.uuid"
                                                    class="col-xs-12 btn gradient btn-default subBtn"
                                                    :aria-label="team.name"
                                                    @click.prevent="openTeamInfoModal(team)"
                                                >
                                                    <i class="fa fa-users marginRight8" aria-hidden="true" />
                                                    {{ team.name }}
                                                    <!--<i class="fas fa-times"></i>-->
                                                </button>
                                                <div
                                                    v-else
                                                    class="statusTag statusTagLightWarning subBtn"
                                                    :aria-label="team.name"
                                                    @click.prevent="openTeamInfoModal(team)"
                                                >
                                                    <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                                    {{ team.name }}
                                                    <!--<i class="fas fa-times"></i>-->
                                                </div>
                                                <template v-if="!question.assignedTo || (question.assignedTo && question.assignedTo.length == 0)">
                                                    NO TEAMS ASSIGNED
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="clarified_questions.length == 0">
                                    <td colspan="6" style="text-align: center">NO CLARIFIED QUESTIONS</td>
                                </tr>
                                <tr v-if="canEditClarifications">
                                    <td />
                                    <td />

                                    <td />
                                    <td>
                                        <button class="btn btn-primary subBtn" aria-label="New Freestyle" @click.prevent="openRequestModal(add_question)">
                                            <i class="fas fa-plus marginRight8" aria-hidden="true" />NEW FREESTYLE
                                        </button>
                                    </td>
                                    <td />
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </kr-panel>
        </div>

        <div id="voteModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="voteModal-title">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="voteModal-title" class="modal-title">Add Clarification</h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex">
                            <template v-for="(clarification, idx) in test.clarifications.freestyles">
                                <div v-if="!clarification.isRequestedByMe" class="clarificationPanel col-xs-12 col-md-6">
                                    <div class="clarification-panel">
                                        <div class="header">
                                            <span>
                                                {{ clarification.votedBy.length }}
                                            </span>
                                            <span>VOTES</span><i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                        </div>
                                        <div class="body">
                                            <div class="content">
                                                <p>
                                                    {{ clarification.freestyle }}
                                                </p>
                                            </div>
                                            <div class="footer flexRight">
                                                <div v-if="clarification.isVotedByMe" class="col-xs-3 buttonGroup">
                                                    <button
                                                        class="btn btn-warning mainBtn"
                                                        aria-label="Unvote"
                                                        @click.prevent="toggleVoteClarifications(clarification)"
                                                    >
                                                        Unvote
                                                    </button>
                                                </div>
                                                <div v-else class="col-xs-3 buttonGroup">
                                                    <button
                                                        class="btn btn-success mainBtn"
                                                        aria-label="Vote"
                                                        @click.prevent="toggleVoteClarifications(clarification)"
                                                    >
                                                        Vote
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- 
								<div v-if="!clarification.isRequestedByMe" class="col-xs-12 col-md-6">
									<div class="clarification-panel">
										<div class="header">
											<span style="font-size:30px;margin-left:10px">
											{{clarification.votedBy.length}}
											</span>&nbsp;<span style="font-size:14px;">Votes</span>&nbsp; <i class="fa fa-flag"></i>
										</div>
										<div class="body">
											<div class="content">
												<p>{{clarification.freestyle}}</p>
											</div>
											<div class="footer">
												<div class="col-xs-12" style="padding:0;" v-if="!clarification.isVotedByMe">
												<button class="btn btn-action" style="background-color:#e69618;color:white;margin:0;height:36px;" @click.prevent="toggleVoteClarifications(clarification)">Vote for this!
												</button>
											</div>
											<div class="col-xs-12" style="padding:0;" v-else>
												<button class="btn btn-action" style="background-color:white;color:#e69618;border: 1px solid #e69618;margin:0;height:36px;margin-right:10px;">Great! your team has voted for this!
												</button>
												<button class="btn btn-action" style="background-color:#9b9f9f;color:white;margin:0;height:36px;"  @click.prevent="toggleVoteClarifications(clarification)">Unvote
												</button>
											</div>	
											</div>
										</div>
									</div>
								</div>	
							</template> -->
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div id="clarificationRequestModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationRequestModal-title">
            <div class="modal-dialog modal-lg">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationRequestModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6 paddingRight10">
                                <template v-if="crudRequest._type == 'question'">
                                    <question-display :test-obj="test" :question="crudRequest" :options="{ showCorrectAnswer: false }" />
                                </template>
                                <template v-else>
                                    <button class="btn gradient btn-default subBtn">Freestyle</button>
                                    <div class="alert alert-primary marginTop10">
                                        <div>
                                            <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                                        </div>
                                        <span>
                                            For freestyle clarification, you may type in any clarifying questions OR vote for questions raised by other teams if
                                            your team has a similar question about the test.
                                        </span>
                                        <div>
                                            <button type="button" class="close minWidthAuto" data-dismiss="modal" aria-label="Close Modal">
                                                <i class="fa-solid fa-xmark" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="hidden-xs hidden-sm col-md-6">
                                <template v-if="crudRequest._type == 'question'">
                                    <div class="marginBottom15">
                                        <label class="control-label">Answers choices in sequence</label>
                                        <br />
                                        <div
                                            v-if="crudRequest.studentAnswer.attempts ? crudRequest.studentAnswer.attempts.length != 0 : false"
                                            class="statusTag whiteSpaceNowrap"
                                            :class="{
                                                tagCorrect: crudRequest.percent == 100,
                                                tagPrimary: crudRequest.percent >= 50 && crudRequest.percent < 100,
                                                tagWarning: crudRequest.percent < 50 && crudRequest.percent > 0,
                                                tagIncorrect: crudRequest.percent == 0,
                                            }"
                                        >
                                            <template v-for="(key, idx) in crudRequest.studentAnswer.attempts">
                                                <span class="paddingTop2">
                                                    {{ key }}
                                                    <template v-if="idx != crudRequest.studentAnswer.attempts.length - 1">&nbsp;</template>
                                                </span>
                                            </template>
                                        </div>
                                        <template v-else> - </template>
                                    </div>
                                </template>
                                <!-- <div class="row">
	    							<button v-if="add_question.type=='freestyle'" class="btn btn-default mainBtn" @click.prevent="openVoteModal(question)">Vote for Other Teams Clarification</button> -->
                                <!--<a v-if="add_question.type=='freestyle'" href="#" class="link fontBold" @click.prevent="openVoteModal(add_question.question)">
	    								Vote Other Teams Clarification
	    							</a>
	    						</div>-->
                                <div class="clarifyPanel">
                                    <div
                                        v-if="crudRequest._attachments && crudRequest._attachments.length != 0"
                                        class="flex align-items marginBottom20 col-xs-12"
                                    >
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in crudRequest._attachments" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.</span>
                                                    <span>
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                </div>
                                                <div>
                                                    <span class="important" aria-label="Delete" @click.prevent="deleteAttachment(crudRequest, idx)">
                                                        <i class="fa fa-trash" aria-hidden="true" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clarifyBody">
                                        <textarea
                                            v-model="crudRequest._clarifications"
                                            :disabled="!canEditClarifications ? true : undefined"
                                            rows="5"
                                            cols="3"
                                            class="form-control"
                                            placeholder="Type clarification here…"
                                        />
                                    </div>
                                    <div class="clarifyFooter flexSpaceBetween align-items">
                                        <a v-show="canEditClarifications" href="#" class="link" @click.prevent="addAttachments(crudRequest)">
                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Add Attachments
                                        </a>
                                        <div>
                                            <template v-if="crudRequest.uuid">
                                                <button
                                                    v-if="crudRequest._type == 'question'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="updateClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="crudRequest._type == 'freestyle'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="updateClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="canEditClarifications"
                                                    :disabled="!crudRequest._status ? true : undefined"
                                                    class="btn btn-danger subBtn"
                                                    data-dismiss="modal"
                                                    aria-label="Delete"
                                                    @click.prevent="openDeleteClarificationModal(crudRequest)"
                                                >
                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    v-if="crudRequest._type == 'question'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="addClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="crudRequest._type == 'freestyle'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="addClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg col-xs-12 marginTop20">
                                <template v-if="crudRequest._type == 'question'">
                                    <div class="marginBottom15">
                                        <label class="control-label">Answers choices in sequence</label>
                                        <br />
                                        <div
                                            v-if="crudRequest.studentAnswer.attempts ? crudRequest.studentAnswer.attempts.length != 0 : false"
                                            class="statusTag whiteSpaceNowrap"
                                            :class="{
                                                tagCorrect: crudRequest.percent == 100,
                                                tagPrimary: crudRequest.percent >= 50 && crudRequest.percent < 100,
                                                tagWarning: crudRequest.percent < 50 && crudRequest.percent > 0,
                                                tagIncorrect: crudRequest.percent == 0,
                                            }"
                                        >
                                            <template v-for="(key, idx) in crudRequest.studentAnswer.attempts">
                                                <span class="paddingTop2">
                                                    {{ key }}
                                                    <template v-if="idx != crudRequest.studentAnswer.attempts.length - 1">&nbsp;</template>
                                                </span>
                                            </template>
                                        </div>
                                        <template v-else> - </template>
                                    </div>
                                </template>
                                <!-- <div class="row">
	    							<button v-if="add_question.type=='freestyle'" class="btn btn-default mainBtn" @click.prevent="openVoteModal(question)">Vote for Other Teams Clarification</button> -->
                                <!--<a v-if="add_question.type=='freestyle'" href="#" class="link fontBold" @click.prevent="openVoteModal(add_question.question)">
	    								Vote Other Teams Clarification
	    							</a>
	    						</div>-->
                                <div class="clarifyPanel">
                                    <div
                                        v-if="crudRequest._attachments && crudRequest._attachments.length != 0"
                                        class="flex align-items marginBottom20 col-xs-12"
                                    >
                                        <div class="col-xs-12">
                                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                            <div v-for="(file, idx) in crudRequest._attachments" class="flexSpaceBetween">
                                                <div>
                                                    <span>{{ idx + 1 }}.</span>
                                                    <span>
                                                        <i class="fa fa-file-image-o" aria-hidden="true" />
                                                    </span>
                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                </div>
                                                <div>
                                                    <span class="important" aria-label="Delete" @click.prevent="deleteAttachment(crudRequest, idx)">
                                                        <i class="fa fa-trash" aria-hidden="true" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clarifyBody">
                                        <textarea
                                            v-model="crudRequest._clarifications"
                                            :disabled="!canEditClarifications ? true : undefined"
                                            rows="5"
                                            cols="3"
                                            class="form-control"
                                            placeholder="Type clarification here…"
                                        />
                                    </div>
                                    <div class="clarifyFooter flexSpaceBetween align-items">
                                        <a v-show="canEditClarifications" href="#" class="link" @click.prevent="addAttachments(crudRequest)">
                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Add Attachments
                                        </a>
                                        <div>
                                            <template v-if="crudRequest.uuid">
                                                <button
                                                    v-if="crudRequest._type == 'question'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="updateClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="crudRequest._type == 'freestyle'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="updateClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="canEditClarifications"
                                                    :disabled="!crudRequest._status ? true : undefined"
                                                    class="btn btn-danger subBtn"
                                                    data-dismiss="modal"
                                                    aria-label="Delete"
                                                    @click.prevent="openDeleteClarificationModal(crudRequest)"
                                                >
                                                    <i class="fa fa-trash" aria-hidden="true" />
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button
                                                    v-if="crudRequest._type == 'question'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="addClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    v-if="crudRequest._type == 'freestyle'"
                                                    class="btn btn-primary subBtn"
                                                    :disabled="!canSubmitClarification(crudRequest) ? true : undefined"
                                                    aria-label="Submit"
                                                    @click.prevent="addClarifications(crudRequest)"
                                                >
                                                    Submit
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div id="clarificationQuestionInfoModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationQuestionInfoModal-title">
            <div class="modal-dialog modal-lg">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <div class="flex">
                            <div class="col-xs-12 col-md-6">
                                <question-display :test-obj="test" :question="previewQuestion" :options="{ showCorrectAnswer: false }" />
                            </div>
                            <div v-if="previewQuestion._type == 'question'" class="hidden-xs hidden-sm col-md-6">
                                <label class="control-label">Answers choices in sequence</label>
                                <br />
                                <div
                                    v-if="previewQuestion.studentAnswer.attempts ? previewQuestion.studentAnswer.attempts.length != 0 : false"
                                    class="statusTag whiteSpaceNowrap"
                                    :class="{
                                        tagCorrect: previewQuestion.percent == 100,
                                        tagPrimary: previewQuestion.percent >= 50 && previewQuestion.percent < 100,
                                        tagWarning: previewQuestion.percent < 50 && previewQuestion.percent > 0,
                                        tagIncorrect: previewQuestion.percent == 0,
                                    }"
                                >
                                    <template v-for="(key, idx) in previewQuestion.studentAnswer.attempts">
                                        <span class="paddingTop2">
                                            {{ key }}
                                            <template v-if="idx != previewQuestion.studentAnswer.attempts.length - 1">&nbsp;</template>
                                        </span>
                                    </template>
                                </div>
                                <template v-else> - </template>
                            </div>
                            <div v-if="previewQuestion._type == 'question'" class="hidden-md hidden-lg col-xs-12 marginTop20">
                                <label class="control-label">Answers choices in sequence</label>
                                <br />
                                <div
                                    v-if="previewQuestion.studentAnswer.attempts ? previewQuestion.studentAnswer.attempts.length != 0 : false"
                                    class="statusTag whiteSpaceNowrap"
                                    :class="{
                                        tagCorrect: previewQuestion.percent == 100,
                                        tagPrimary: previewQuestion.percent >= 50 && previewQuestion.percent < 100,
                                        tagWarning: previewQuestion.percent < 50 && previewQuestion.percent > 0,
                                        tagIncorrect: previewQuestion.percent == 0,
                                    }"
                                >
                                    <template v-for="(key, idx) in previewQuestion.studentAnswer.attempts">
                                        <span class="paddingTop2">
                                            {{ key }}
                                            <template v-if="idx != previewQuestion.studentAnswer.attempts.length - 1">&nbsp;</template>
                                        </span>
                                    </template>
                                </div>
                                <template v-else> - </template>
                            </div>
                        </div>

                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div
            id="clarificationTeamInfoModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clarificationTeamInfoModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationTeamInfoModal-title" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <ul v-for="(student, idx) in previewTeam.members">
                                <li>{{ student }}</li>
                            </ul>
                            <ul v-for="(student, idx) in previewTeam.students">
                                <li>
                                    {{ student.firstname + (student.lastname ? student.lastname : '') }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div
            id="clarificationTeamAttachmentsModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clarificationTeamAttachmentsModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationTeamAttachmentsModal-title" class="modal-title">{{ previewTeam.name }} Attachments</h2>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20 col-xs-12 col-md-6">
                            <div v-if="previewTeam.attachments && previewTeam.attachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in previewTeam.attachments" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div
            id="clarificationRequestMoreInfoModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clarificationRequestMoreInfoModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationRequestMoreInfoModal-title" class="modal-title">Request Information</h2>
                    </div>
                    <div v-if="previewRequest._type" class="modal-body">
                        <template v-if="previewRequest._type == 'question'">
                            <button class="btn gradient btn-default-border-primary">
                                {{ previewRequest.requests.length }}
                                TEAM<template v-if="previewRequest.requests.length > 1"> S </template>
                            </button>
                            <template v-for="(request, request_idx) in previewRequest.requests">
                                <div class="marginTop20">
                                    <div class="col-xs-6 buttonGroup">
                                        <button
                                            v-if="request.requestedBy.uuid != test.myProgression.team.uuid"
                                            class="btn gradient btn-default subBtn flex1"
                                            data-dismiss="modal"
                                            :aria-label="request.requestedBy.name"
                                            @click.prevent="openTeamInfoModal(request.requestedBy)"
                                        >
                                            {{ request.requestedBy.name }}
                                        </button>
                                        <button
                                            v-else
                                            class="statusTag statusTagLightWarning subBtn flex1"
                                            data-dismiss="modal"
                                            :aria-label="request.requestedBy.name"
                                            @click.prevent="openTeamInfoModal(request.requestedBy)"
                                        >
                                            <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                            {{ request.requestedBy.name }}
                                        </button>

                                        <button
                                            v-if="request.attachments && request.attachments.length != 0"
                                            class="btn gradient btn-default subBtn flex1"
                                            data-dismiss="modal"
                                            aria-label="Team's Attachments"
                                            @click.prevent="
                                                openTeamAttachmentsModal({
                                                    name: request.requestedBy.name,
                                                    attachments: request.attachments,
                                                })
                                            "
                                        >
                                            <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                        </button>
                                    </div>
                                    <div class="marginTop10">
                                        {{ request.clarifications }}
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <button class="btn gradient btn-default-border-primary">
                                {{ previewRequest.votedBy.length + 1 }}
                                Team<template v-if="previewRequest.votedBy.length > 1"> s </template>
                            </button>
                            <div class="marginTop20">
                                <div class="col-xs-6 buttonGroup">
                                    <button
                                        v-if="previewRequest.requestedBy.uuid != test.myProgression.team.uuid"
                                        class="btn gradient btn-default subBtn flex1"
                                        data-dismiss="modal"
                                        :aria-label="previewRequest.requestedBy.name"
                                        @click.prevent="openTeamInfoModal(question.requestedBy)"
                                    >
                                        {{ previewRequest.requestedBy.name }}
                                    </button>
                                    <button
                                        v-else
                                        class="statusTag statusTagLightWarning subBtn flex1"
                                        data-dismiss="modal"
                                        :aria-label="previewRequest.requestedBy.name"
                                        @click.prevent="openTeamInfoModal(previewRequest.requestedBy)"
                                    >
                                        <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                        {{ previewRequest.requestedBy.name }}
                                    </button>
                                    <button
                                        v-if="previewRequest.attachments && previewRequest.attachments.length != 0"
                                        class="btn gradient btn-default subBtn flex1"
                                        data-dismiss="modal"
                                        aria-label="Team's Attachments"
                                        @click.prevent="
                                            openTeamAttachmentsModal({
                                                name: previewRequest.requestedBy.name,
                                                attachments: previewRequest.attachments,
                                            })
                                        "
                                    >
                                        <i class="fa fa-paperclip marginRight8" aria-hidden="true" />Team’s Attachment(s)
                                    </button>
                                </div>
                            </div>
                            <template v-for="(team, team_idx) in previewRequest.votedBy">
                                <div class="marginTop20">
                                    <button
                                        v-if="team.name != test.myProgression.team.name"
                                        class="btn gradient btn-default subBtn"
                                        data-dismiss="modal"
                                        :aria-label="`${team.name}(Voted)`"
                                        @click.prevent="openTeamInfoModal(team)"
                                    >
                                        {{ team.name }}(Voted)
                                    </button>
                                    <div
                                        v-else
                                        class="statusTag statusTagLightWarning subBtn"
                                        :aria-label="`${team.name}(Voted)`"
                                        @click.prevent="openTeamInfoModal(team)"
                                    >
                                        <i class="fa fa-users greyText marginRight8" aria-hidden="true" />
                                        {{ team.name }} (Voted)
                                    </div>
                                    <button class="btn gradient btn-default subBtn" :aria-label="`${team.name}(Voted)`">{{ team.name }}(Voted)</button>
                                </div>
                            </template>
                        </template>
                        <div class="col-xs-12 buttonGroup marginTop20">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div id="deleteClarificationModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteClarificationModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="deleteClarificationModal-title" class="modal-title">Delete Confirmation Confirmation</h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to delete this clarification?</div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="flex">
                                    <div class="col-xs-12 col-md-6">
                                        <button
                                            id="modal-delete-activity-btn"
                                            class="btn btn-danger mainBtn"
                                            style="width: 100%; padding-right: 10px"
                                            data-dismiss="modal"
                                            aria-label="Yes, Delete"
                                            @click.prevent="removeClarifications(crudRequest)"
                                        >
                                            Yes, Delete
                                        </button>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <button
                                            id="modal-delete-activity-dismiss-btn"
                                            class="btn btn-default mainBtn"
                                            style="width: 100%"
                                            data-dismiss="modal"
                                            aria-model="No, Cancel"
                                        >
                                            No, Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as filestack from 'filestack-js';
import KrAuth from '../../../../../components/auth/auth';
export default {
    data() {
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            client: filestack.init(process.env.VUE_APP_FILESTACK_API_KEY), //filestack client
            test: {},
            display_test_proper: false,
            clarified_questions: [],
            not_clarified_questions: [],
            question: {},
            results: [],
            add_question: {
                _type: 'freestyle',
                _clarifications: '',
                _attachments: [],
            },
            isTeamLeader: false,
            previewQuestion: {},
            previewTeam: {},
            previewRequest: {},
            crudRequest: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.test = response.data.data;
            if (['Lapsed', 'Expired'].includes(that.auth.user().status) && that.auth.user().account.paymentMethod == 'Student-Paid') {
                that.$router.push({ name: 'errors.403' });
            }
            that.init();
            that.component_done_loading = true;
            that.processEchoListeners();
            Vue.nextTick(function () {
                if (!_.get(that, 'test.settings.allowTeamClarifications')) {
                    that.$router.push({ name: 'tests.index' });
                }
            });
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                let idx = _.findIndex(that.clarified_questions, function (o) {
                    return o.uuid == e.activityQuestionUuid;
                });
                if (idx != -1) {
                    //update-delete
                    that.clarified_questions[idx].studentAnswer.clarifications = e.answer.clarifications;
                    that.clarified_questions[idx].studentAnswer.attachments = e.answer.attachments;
                    if (!that.clarified_questions[idx].studentAnswer.attachments) {
                        that.clarified_questions[idx].studentAnswer.attachments = [];
                    }
                    that.clarified_questions[idx]._clarifications = e.answer.clarifications;
                    that.clarified_questions[idx]._attachments = _.cloneDeep(that.clarified_questions[idx].studentAnswer.attachments);
                    that.clarified_questions[idx]._edit = false;

                    if (e.answer.clarifications) {
                        that.clarified_questions[idx]._status = 'requested';
                    } else {
                        that.clarified_questions[idx]._status = '';
                    }
                } else {
                    //create
                    let idx = _.findIndex(that.not_clarified_questions, function (o) {
                        return o.uuid == e.activityQuestionUuid;
                    });
                    if (idx != -1) {
                        //should always be the case
                        that.not_clarified_questions[idx].studentAnswer.clarifications = e.answer.clarifications;
                        that.not_clarified_questions[idx].studentAnswer.attachments = e.answer.attachments;
                        if (!that.not_clarified_questions[idx].studentAnswer.attachments) {
                            that.not_clarified_questions[idx].studentAnswer.attachments = [];
                        }
                        that.not_clarified_questions[idx]._clarifications = e.answer.clarifications;
                        that.not_clarified_questions[idx]._attachments = _.cloneDeep(that.not_clarified_questions[idx].studentAnswer.attachments);
                        that.not_clarified_questions[idx]._edit = false;
                        that.not_clarified_questions[idx]._status = 'requested';
                        /*Vue.set(
                            that.not_clarified_questions[idx],
                            '_clarifications',
                            e.answer.clarifications
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_attachments',
                            _.cloneDeep(
                                that.not_clarified_questions[idx].studentAnswer
                                    .attachments
                            )
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_edit',
                            false
                        );
                        Vue.set(
                            that.not_clarified_questions[idx],
                            '_status',
                            'requested'
                        );*/
                        that.not_clarified_questions[idx].isRequestedByMe = true;
                        let removed = that.not_clarified_questions.splice(idx, 1)[0];
                        that.clarified_questions.push(removed);
                    } else {
                        //console.log('created new clarification error');
                    }
                }
            };
            //Store Answer?
            let c1 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationSaved', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'ClarificationSaved',
                handler: h1,
            });

            let h2 = (e) => {
                this.test.clarificationStatus = e.clarificationStatus;
                if (this.test.clarificationStatus == 'released') {
                    //alert('init results!');
                    var that = this;
                    this.fetchTest().then(function (response) {
                        that.test = response.data.data;
                        that.init();
                    });
                }
            };
            let c2 = window.Echo.private(`test.${this.test.testUuid}.student`).listen('TestClarificationStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'TestClarificationStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                let isRequestedByMe = e.freestyle.requestedBy.uuid == this.test.myProgression.team.uuid;
                if (isRequestedByMe) {
                    let idx = _.findIndex(this.clarified_questions, function (o) {
                        return o.uuid == e.freestyle.uuid;
                    });
                    if (idx != -1) {
                        this.clarified_questions[idx].freestyle = e.freestyle.freestyle;
                        this.clarified_questions[idx].attachments = e.freestyle.attachments;
                        if (!this.clarified_questions[idx].attachments) {
                            this.clarified_questions[idx].attachments = [];
                        }
                        this.clarified_questions[idx].votedBy = e.freestyle.votedBy ? e.freestyle.votedBy : [];
                        this.clarified_questions[idx]._clarifications = e.freestyle.freestyle;
                        this.clarified_questions[idx]._attachments = _.cloneDeep(this.clarified_questions[idx].attachments);
                        this.clarified_questions[idx]._edit = false;

                        if (!this.clarified_questions[idx].votedBy.includes(this.test.myProgression.team.uuid)) {
                            this.clarified_questions[idx].isVotedByMe = false;
                            this.clarified_questions[idx]._status = 'requested';
                            //delete this.clarified_questions[idx]._status;
                            //this.clarified_questions.splice(idx, 1);
                        } else {
                            this.clarified_questions[idx]._status = 'voted';
                            this.clarified_questions[idx].isVotedByMe = true;
                        }
                    } else {
                        let temp_idx = _.findIndex(this.test.clarifications.freestyles, function (o) {
                            return o.uuid == e.freestyle.uuid;
                        });
                        if (temp_idx != -1) {
                            this.test.clarifications.freestyles[temp_idx].freestyle = e.freestyle.freestyle;
                            this.test.clarifications.freestyles[temp_idx].attachments = e.freestyle.attachments;
                            if (!this.test.clarifications.freestyles[temp_idx].attachments) {
                                this.test.clarifications.freestyles[temp_idx].attachments = [];
                            }
                            this.test.clarifications.freestyles[temp_idx].votedBy = e.freestyle.votedBy ? e.freestyle.votedBy : [];
                            this.test.clarifications.freestyles[temp_idx]._clarifications = e.freestyle.freestyle;
                            this.test.clarifications.freestyles[temp_idx]._attachments = _.cloneDeep(this.test.clarifications.freestyles[temp_idx].attachments);
                            this.test.clarifications.freestyles[temp_idx]._edit = false;
                            if (!this.test.clarifications.freestyles[temp_idx].votedBy.includes(this.test.myProgression.team.uuid)) {
                                this.test.clarifications.freestyles[temp_idx].isVotedByMe = false;
                                this.test.clarifications.freestyles[temp_idx]._status = '';
                            } else {
                                this.test.clarifications.freestyles[temp_idx].isVotedByMe = true;
                                this.test.clarifications.freestyles[temp_idx]._status = 'voted';
                                this.clarified_questions.push(this.test.clarifications.freestyles[temp_idx]);
                            }
                        } else {
                            let clarification = {
                                uuid: e.freestyle.uuid,
                                attachments: e.freestyle.attachments ? e.freestyle.attachments : [],
                                freestyle: e.freestyle.freestyle,
                                _clarifications: e.freestyle.freestyle,
                                _edit: false,
                                _type: 'freestyle',
                                _status: '',
                                isRequestedByMe: true,
                                isVotedByMe: false,
                                isAssignedToMe: false,
                                assignedTo: e.freestyle.assignedTo ? e.freestyle.assignedTo : [],
                                requestedBy: e.freestyle.requestedBy,
                                votedBy: e.freestyle.votedBy ? e.freestyle.votedBy : [],
                            };
                            //this.clarified_questions.push(clarification);
                            this.test.clarifications.freestyles.push(clarification);
                            this.init();
                            //console.log(this.test.clarifications.freestyles);
                            //console.log(this.test.clarifications.freestyles);
                        }
                    }
                }
            };
            let c3 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationFreestyleSaved', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ClarificationFreestyleSaved',
                handler: h3,
            });

            let h4 = (e) => {
                let idx = _.findIndex(this.test.clarifications.freestyles, function (o) {
                    return o.uuid == e.freestyle.uuid;
                });
                if (idx != -1) {
                    this.test.clarifications.freestyles.splice(idx, 1);
                }
                idx = _.findIndex(this.clarified_questions, function (o) {
                    return o.uuid == e.freestyle.uuid;
                });
                if (idx != -1) {
                    this.clarified_questions.splice(idx, 1);
                }
            };
            let c4 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.myProgression.team.uuid}`).listen('ClarificationFreestyleDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ClarificationFreestyleDeleted',
                handler: h4,
            });
            let h5 = (e) => {
                if (e.test.uuid != this.test.testUuid) {
                    return false;
                }
                that.test.enableAnonymityInPresentationMode = e.test.enableAnonymityInPresentationMode;
                this.fetchTest().then(function (response) {
                    that.test = response.data.data;
                });
            };
            let c5 = window.Echo.private(`activity.${this.test.activityUuid}.student`).listen('TestVisibilityUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestVisibilityUpdated',
                handler: h5,
            });
        },
        canSubmitClarification(question) {
            if (question.uuid) {
                if (question._type == 'question') {
                    if (
                        question.studentAnswer.clarifications != question._clarifications ||
                        !_.isEqual(question.studentAnswer.attachments, question._attachments)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (question._type == 'freestyle') {
                    if (question.freestyle != question._clarifications || !_.isEqual(question.attachments, question._attachments)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                if (question._type == 'question') {
                    if (question._clarifications != '') {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (question._type == 'freestyle') {
                    if (question._clarifications != '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        openDeleteClarificationModal(question = null) {
            this.crudRequest = question;
            $('#deleteClarificationModal').modal('show');
        },
        openRequestModal(question = null) {
            this.crudRequest = question;
            $('#clarificationRequestModal').modal('show');
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#clarificationQuestionInfoModal').modal('show');
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamInfoModal').modal('show');

            $('#clarificationTeamInfoModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openTeamAttachmentsModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#clarificationTeamAttachmentsModal').modal('show');
            $('#clarificationTeamAttachmentsModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openRequestMoreInfoModal(request) {
            var that = this;
            this.previewRequest = request;
            $('#clarificationRequestMoreInfoModal').modal('show');
            $('#clarificationRequestMoreInfoModal').on('hidden.bs.modal', function () {
                that.previewRequest = {};
            });
        },
        processIsTeamLeader(isTeamLeader) {
            this.isTeamLeader = isTeamLeader;
        },
        isClarificationVoted(freestyle) {
            if (freestyle.isVotedByMe) {
                return true;
            }
            return false;
        },
        addAttachments(question) {
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        //that.is_uploading = false;
                        if (!question._attachments) {
                            question._attachments = [];
                        }
                        let currentLength = question._attachments.length;
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            _.set(question._attachments, currentLength++, res.filesUploaded[i]);
                        }
                        question._attachments.splice();
                    },
                })
                .open();
        },
        deleteAttachment(question, idx) {
            question._attachments.splice(idx, 1);
        },
        init() {
            this.clarified_questions = [];
            this.not_clarified_questions = [];
            let count = 0;
            for (var i = 0; i < this.test.clarifications.questions.length; i++) {
                for (var j = 0; j < this.test.clarifications.questions[i].length; j++) {
                    this.test.clarifications.questions[i][j].question_no = ++count;
                    let clarification = _.get(this.test.clarifications.questions, i + '.' + j + '.studentAnswer.clarifications', null);
                    let attachments = _.get(this.test.clarifications.questions, i + '.' + j + '.studentAnswer.attachments', null);
                    if (!attachments) {
                        attachments = [];
                    }
                    this.test.clarifications.questions[i][j]._clarifications = clarification;
                    this.test.clarifications.questions[i][j]._attachments = _.cloneDeep(attachments);
                    this.test.clarifications.questions[i][j].studentAnswer.attachments = attachments;
                    this.test.clarifications.questions[i][j]._edit = false;
                    this.test.clarifications.questions[i][j]._type = 'question';
                    if (clarification) {
                        this.test.clarifications.questions[i][j]._status = 'requested';
                    } else {
                        this.test.clarifications.questions[i][j]._status = '';
                    }
                    this.clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                    /*if(clarification){
                			this.test.clarifications.questions[i][j]._clarifications = clarification;
                			this.test.clarifications.questions[i][j]._attachments = _.cloneDeep(attachments);
                			this.test.clarifications.questions[i][j].studentAnswer.attachments = attachments;
                			this.test.clarifications.questions[i][j]._edit = false;
          					this.test.clarifications.questions[i][j]._type = 'question';
          					this.test.clarifications.questions[i][j]._status = 'requested';
                			this.clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                		}else{
                			this.test.clarifications.questions[i][j]._type = 'question';
                			this.not_clarified_questions.push(_.clone(this.test.clarifications.questions[i][j]));
                		}*/
                }
            }
            for (var i = 0; i < this.test.clarifications.freestyles.length; i++) {
                let freestyle = this.test.clarifications.freestyles[i];
                let attachments = _.get(this.test.clarifications.freestyles, i + '.attachments', null);
                if (!attachments) {
                    attachments = [];
                }
                if (freestyle.isRequestedByMe || (this.test.clarificationStatus == 'released' && freestyle.assignedTo && freestyle.assignedTo.length > 0)) {
                    freestyle._type = 'freestyle';
                    if (freestyle.isRequestedByMe) {
                        freestyle._status = 'requested';
                    }
                    freestyle._edit = false;
                    freestyle._clarifications = freestyle.freestyle;
                    freestyle.attachments = attachments;
                    freestyle._attachments = _.cloneDeep(attachments);
                    this.clarified_questions.push(_.clone(freestyle));
                } else {
                    if (this.isClarificationVoted(freestyle)) {
                        freestyle._type = 'freestyle';
                        freestyle._status = 'voted';
                        freestyle._edit = false;
                        freestyle._clarifications = freestyle.freestyle;
                        freestyle.attachments = attachments;
                        freestyle._attachments = _.cloneDeep(attachments);
                        this.clarified_questions.push(_.clone(freestyle));
                    }
                }
            }
        },
        /*initResults(){
				this.results = [];
				for(var i = 0; i<this.test.results.questions.length;i++){
					let clarification = this.test.results.questions[i];
					clarification._type = 'question';
					this.results.push(_.clone(clarification));
				}
				for(var i = 0; i<this.test.results.freestyles.length;i++){
					let clarification = this.test.results.freestyles[i];
					clarification._type = 'freestyle';
					this.results.push(_.clone(clarification));
				}
			},*/
        //freestyle clarifications only
        toggleVoteClarifications(clarification) {
            var that = this;
            axios.patch('student/tests/' + this.test.uuid + '/freestyles/' + clarification.uuid + '/vote').then(function (response) {
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have successfully toggled voting for this freestyle clarification',
                });
                //Events.fire('changed-test',response.data.data);
                that.clearAddClarificationsForm();
            });
        },
        clearAddClarificationsForm() {
            this.add_question._clarifications = '';
            this.add_question._attachments = [];
            this.add_question._type = 'freestyle';
        },
        addClarifications(question) {
            var that = this;
            if (question.type == 'question') {
                axios
                    .post('/student/tests/' + this.test.uuid + '/clarifications', {
                        userPlacementTestUuid: this.test.uuid,
                        activityQuestionUuid: question.question.uuid,
                        clarifications: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully added clarifications for this question',
                        });
                        //Events.fire('changed-test',response.data.data);
                        $('#clarificationRequestModal').modal('hide');
                        that.clearAddClarificationsForm();
                    });
            } else {
                //freestyle
                axios
                    .post('/student/tests/' + this.test.uuid + '/freestyles', {
                        freestyle: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully added a freestyle clarification',
                        });
                        //Events.fire('changed-test',response.data.data);
                        $('#clarificationRequestModal').modal('hide');
                        that.clearAddClarificationsForm();
                    });
            }
        },
        updateClarifications(question) {
            var that = this;
            if (question._type == 'question') {
                axios
                    .post('/student/tests/' + this.test.uuid + '/clarifications', {
                        userPlacementTestUuid: this.test.uuid,
                        activityQuestionUuid: question.uuid,
                        clarifications: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        question._edit = false;
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully updated clarifications for this question',
                        });
                        //Events.fire('changed-test',response.data.data);
                    });
            } else {
                axios
                    .patch('/student/tests/' + this.test.uuid + '/freestyles/' + question.uuid, {
                        freestyle: question._clarifications,
                        attachments: question._attachments,
                    })
                    .then(function (response) {
                        question._edit = false;
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully updated a freestyle clarification',
                        });
                        // Events.fire('changed-test',response.data.data);
                        that.clearAddClarificationsForm();
                    });
            }
        },
        removeClarifications(question) {
            var that = this;
            if (question._type == 'question') {
                axios.delete('/student/tests/' + this.test.uuid + '/clarifications/' + question.studentAnswer.uuid).then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'You have successfully deleted a question clarification',
                    });
                    //Events.fire('changed-test',response.data.data);
                    that.clearAddClarificationsForm();
                });
            } else {
                if (question._status == 'requested') {
                    axios.delete('/student/tests/' + this.test.uuid + '/freestyles/' + question.uuid).then(function (response) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'You have successfully deleted a freestyle clarification',
                        });
                        //Events.fire('changed-test',response.data.data);
                        that.clearAddClarificationsForm();
                    });
                } else {
                    this.toggleVoteClarifications(question);
                }
            }
        },
        openVoteModal(question) {
            this.question = question;
            $('#voteModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/clarifications');
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'team-leadership': require(`./../partials/team-leadership.vue`).default,
        'question-groups': require(`./../partials/questions/question-groups.vue`).default,
        'question-pagination': require(`./../partials/question-pagination.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    computed: {
        canEditClarifications() {
            if (['ongoing'].includes(this.test.clarificationStatus) && this.isTeamLeader) {
                return true;
            }
            return false;
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        Events.off('current-question-changed');
    },
};
</script>

<style scoped>
.optionBarsDanger {
    padding: 10px;
    border-radius: 3px;
    background-color: #ad2224;
    color: #fff;
}

.clarification .dropdown-toggle,
.clarification .dropdown-menu {
    width: 100%;
}

.clarification .dropdown-menu {
    padding: 0;
    margin-top: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    padding-top: 15px;
}

.clarification .dropdown-menu .footer .btn {
    margin-top: 0;
}

.clarification .dropdown-menu .footer {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border-top: 1px solid #d8d8d8;
    padding: 10px;
}

.clarification .dropdown-menu select {
    width: 100%;
    border: none;
    padding: 15px 15px 5px;
}

.clarification .dropdown-menu li span:first-child {
    font-size: 14px;
    font-weight: bold;
}

.clarification .dropdown-menu li.selected,
.clarification .dropdown-menu li:hover {
    background-image: linear-gradient(to bottom, #f1f4f8, #f5f7f9);
}

.clarification .dropdown-menu li.selected a,
.clarification .dropdown-menu li:hover a {
    color: #0071be;
}

.clarification .dropdown-menu li span:last-child {
    font-size: 12px;
}

.clarification ul {
    margin: 0;
    padding: 0 0 10px;
    max-height: 100px;
    overflow: auto;
    list-style-type: none;
}

.clarification ul a {
    color: #222;
}

.clarification ul li {
    padding: 5px 15px;
    white-space: nowrap;
    overflow: hidden;
}

.clarification-panel {
    border: 1px solid #ededed;
    border-radius: 3px;
}

.clarificationPanel {
    margin-top: 20px;
}

.clarificationPanel:first-child {
    margin-top: 0;
}

.clarification-panel .header {
    padding: 10px 20px;
    background-color: #495060;
    line-height: 1;
    color: #fff;
}

.clarification-panel .header span:first-child {
    font-size: 30px;
    font-weight: bold;
}

.clarification-panel .body {
    height: 173px;
    background-color: white;
    padding: 15px !important;
}

.clarification-panel .body .content {
    height: 92px;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    white-space: pre-line;
    word-wrap: break-word;
}
.clarification-panel .body .content p {
    margin: 0;
    white-space: pre-line;
    word-wrap: break-word;
}
.clarification-panel .body .footer {
    height: 56px;
    padding-top: 15px;
}

.clarification .dropdown a:hover {
    background: none;
}

.clarifyBody textarea {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.clarifyFooter {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 1px solid #d8d8d8;
    padding: 10px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.clarifyFooter a.link {
    font-size: 12px;
    font-weight: bold;
}

.clarifyFooter a.link .fa {
    color: #9b9f9f;
}

.multiselect .col-xs-12.btn {
    margin-top: 15px;
}

.multiselect .col-xs-12.btn:first-child {
    margin-top: 0;
}

@media (min-width: 992px) {
    .clarificationPanel:nth-child(-n + 2) {
        margin-top: 0;
    }

    .clarificationPanel:nth-child(even) {
        padding-left: 10px;
    }

    .clarificationPanel:nth-child(odd) {
        padding-right: 10px;
    }
}

body.test .clarification .panel-body {
    height: auto;
    max-height: 364px;
}

#deleteClarificationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#deleteClarificationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#deleteClarificationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>

<style>
body.test .panel:last-child {
    margin-bottom: 0;
}
</style>
