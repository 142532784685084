<template>
    <div
        ref="modal"
        class="modal default-modal editQuestionStemModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="editQuestionStemModal-title"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="editQuestionStemModal-title" class="modal-title">Change Question Copywriting</h2>
                </div>
                <div class="modal-body newRadioField" :class="{ textAlignCenter: step == 2 }">
                    <div class="stepper-container">
                        <div class="stepper-wrapper justifyCenter">
                            <button
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 1,
                                    completed: step == 2,
                                }"
                                @click.prevent="step = 1"
                            >
                                <template v-if="step == 1">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 1</div>
                                </template>
                                <template v-else-if="step == 2">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 1</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">1</div>
                                </template>

                                <label> Change Question Copywriting</label>
                            </button>
                            <button
                                v-tooltip="!updatedQuestion ? 'No update for any of the copywriting' : ''"
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 2,
                                    completed: step == 3,
                                    'disabled hasToolTip': !updatedQuestion,
                                }"
                                :aria-label="!updatedQuestion ? 'Step 2 confirmation disabled No update for any of the copywriting' : 'Step 2 confirmation'"
                                @click.prevent="updatedQuestion ? (step = 2) : ''"
                            >
                                <template v-if="step == 2">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 2</div>
                                </template>
                                <template v-else-if="step == 3">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 2</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">2</div>
                                </template>

                                <label> Confirmation </label>
                            </button>
                        </div>
                    </div>

                    <template v-if="step == 1">
                        <kr-editor
                            :key="question.uuid"
                            v-model="questionModel.question"
                            :options="{
                                toolbar1: 'bold italic underline strikethrough | bullist numlist outdent indent | table | image | link',
                                toolbar2: '',
                            }"
                        ></kr-editor>
                        <div
                            v-for="(option, option_idx) in newOptions"
                            class="flexOnly discussionOption borderNone"
                            :class="{
                                correct: option.isCorrect,
                                newCorrect: optionCorrect(question.id, option.key),
                                'padding10 paddingLeft35px': !(option.isCorrect || optionCorrect(question.id, option.key)),
                            }"
                        >
                            <i v-if="option.isCorrect || optionCorrect(question.id, option.key)" class="fa fa-check-circle" />
                            <div
                                class="flexOnly width100 paddingTop5"
                                :class="{
                                    paddingTop5: !(option.isCorrect || optionCorrect(question.id, option.key)),
                                }"
                            >
                                <span :aria-label="'option key ' + option.key" class="marginRight8">
                                    <b>{{ option.key }}.</b>
                                </span>

                                <kr-editor
                                    :key="question.uuid + '-' + option.key"
                                    v-model="option.content"
                                    class="width100"
                                    :options="{
                                        toolbar1: 'bold italic underline strikethrough | bullist numlist outdent indent | table | image | link',
                                        toolbar2: '',
                                    }"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else-if="step == 2">
                        <h4>You are about to update question</h4>
                        <h4>
                            for <b> Question {{ getQuestionNo(question.group - 1, question.order - 1) }} </b> in <b> {{ test.name }} </b>
                        </h4>
                        <br />

                        <div class="collapsingQuestion textAlignLeft">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeQuestionCopyWrite'"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeQuestionCopyWrite'"
                            >
                                View Question
                                {{ getQuestionNo(question.group - 1, question.order - 1) }} Question Stem
                            </button>

                            <div
                                :id="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeQuestionCopyWrite'"
                                class="collapse padding10"
                            >
                                <div class="question">
                                    <kr-math :input="questionModel.question" :safe="!question.questionIsHTML" />
                                </div>
                            </div>
                        </div>

                        <template v-for="(option, option_idx) in optionUpdated">
                            <div class="collapsingQuestion textAlignLeft marginTop20">
                                <button
                                    class="btn"
                                    :data-target="'#viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-seeChanges-' + option_idx"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    :aria-controls="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-seeChanges-' + option_idx"
                                >
                                    View Question
                                    {{ getQuestionNo(question.group - 1, question.order - 1) }}: Option {{ option.key }}
                                </button>

                                <div
                                    :id="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-seeChanges-' + option_idx"
                                    class="collapse padding10"
                                >
                                    <div class="question">
                                        <kr-math :input="option.content" :safe="!option.questionIsHTML" />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <br />
                        <p>After confirmation, all tests using this question</p>
                        <p>will be updated.</p>
                        <br />
                        <p v-if="!['not started'].includes(test.status)" class="important">
                            <i>Please advise students to refresh browser</i>
                        </p>
                    </template>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click="closeModal">Cancel</button>
                    <button
                        v-if="step == 1"
                        v-tooltip="!updatedQuestion ? 'No update for any of the copywriting' : ''"
                        class="btn btn-primary"
                        :class="{
                            'disabled hasToolTip': !updatedQuestion,
                        }"
                        :aria-label="!updatedQuestion ? 'Next Step disabled' : 'Next Step'"
                        @click.prevent="updatedQuestion ? (step = 2) : ''"
                    >
                        Next Step
                    </button>
                    <button v-if="step == 2" class="btn btn-success" @click.prevent="submit">
                        <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Update Question
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['accepted']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick, watch } from 'vue';

const modal = ref(null);
const question = ref({});
const questionModel = ref({});
const newOptions = ref([]);
const optionUpdated = ref([]);

const test = ref({});
const updatedQuestion = ref(false);
const step = ref(1);

function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}

function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}

function accept(t = {}, q = {}) {
    test.value = t;
    question.value = q;
    step.value = 1;
    questionModel.value.question = question.value.question;
    questionModel.value.isHTML = true;
    newOptions.value = _.cloneDeep(question.value.options);
    openModal();
}

function getQuestionNo(question_group_idx, question_idx) {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += test.value.questions[i].length;
    }
    count += question_idx + 1;
    return count;
}

function optionCorrect(questionId, key) {
    if (['ended', 'completed', 'not started'].includes(test.value.status) && question.value.type == 'mcqs') {
        for (var i = 0; i < test.value.acceptedNewAnswers.length; i++) {
            if (test.value.acceptedNewAnswers[i].activityQuestionId == questionId) {
                for (var j = 0; j < test.value.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                    if (test.value.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                        return true;
                    }
                }
            }
        }
    }
}

function submit() {
    question.value.options = newOptions.value;

    axios
        .post('activities/' + test.value.activityUuid + '/change-question-copywriting', {
            activityQuestionUuid: question.value.uuid,
            options: question.value.options,
            question: questionModel.value,
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Question Stem are updated',
            });
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to change question copywriting at this moment, please try again later',
            });
        });
}

watch(
    () => questionModel.value.question,
    (newVal, oldVal) => {
        if (newVal != question.value.question) {
            updatedQuestion.value = true;
        } else {
            updatedQuestion.value = false;
        }
    }
);

watch(
    () => newOptions.value,
    (newVal, oldVal) => {
        for (var i = 0; i < newVal.length; i++) {
            if (newVal[i].content != question.value.options[i].content) {
                const keyExist = optionUpdated.value.findIndex((item) => item.key === newVal[i].key);

                if (keyExist !== -1) {
                    optionUpdated.value[keyExist].content = newVal[i].content;
                } else {
                    optionUpdated.value.push({ key: newVal[i].key, content: newVal[i].content });
                }

                updatedQuestion.value = true;
            }
        }
    }
);

defineExpose({ accept });
</script>
