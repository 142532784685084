<template>
    <div>
        <div>
            <caption class="visible-hidden">
                Question Stem
            </caption>
            <kr-math
                :input="question.question"
                :safe="!question.question.questionIsHTML"
                class="question"
                :can-highlight="true"
                :question-uuid="question.uuid"
            />
        </div>

        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <template v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename">
                    <div class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <template
                            v-if="
                                (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                                testObj.type != 'application'
                            "
                        >
                            <template v-if="isPdf(file)">
                                <template v-if="testObj.settings.allowPdfDownload">
                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                </template>
                            </template>
                            <template v-else>
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                        </template>
                    </div>
                </template>
            </div>
        </template>

        <fieldset class="marginTop20">
            <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices</legend>

            <div v-if="!isMobileView" class="matching-display-question">
                <div class="two-grid-more-left">
                    <div>
                        <div class="two-grid">
                            <label class="control-label capitalize">{{ question.options.labels.prompt }}</label>
                            <label class="control-label capitalize">{{ question.options.labels.answer }}</label>
                        </div>
                        <template v-for="(optionGroup, optionGroupIdx) in question.options.options">
                            <template v-for="(option, optionKey) in optionGroup">
                                <template v-if="option.type == 'prompt'">
                                    <div class="two-grid categorize-container">
                                        <div>
                                            <label class="control-label capitalize">{{ question.options.labels.prompt }} {{ optionGroupIdx + 1 }}</label>
                                            <p>{{ option.content }}</p>
                                        </div>
                                        <div :class="{ filled: answers[getPromptIdx(optionKey)].answer.length > 0, disabled: disabled }">
                                            <template v-if="!disabled">
                                                <label :id="question.options.labels.answer + '-' + optionGroupIdx + 1" class="control-label capitalize">
                                                    {{ question.options.labels.answer }} {{ optionGroupIdx + 1 }}
                                                </label>
                                                <draggable
                                                    v-model="answers[getPromptIdx(optionKey)].answer"
                                                    class="drop-area"
                                                    :class="{
                                                        'd-block': answers[getPromptIdx(optionKey)].answer.length > 0,
                                                        'flexOnly align-items justifyCenter': answers[getPromptIdx(optionKey)].answer.length === 0,
                                                    }"
                                                    item-key="key"
                                                    group="answers"
                                                    :disabled="disabled"
                                                    @change="disabled ? updateAnswer : ''"
                                                >
                                                    <template #header>
                                                        <p>Drop in here</p>
                                                    </template>

                                                    <template #item="{ element, index }">
                                                        <div class="dropdown width100" :class="{ marginTop10: index != 0 }" data-draggable="true">
                                                            <button
                                                                v-tooltip="
                                                                    disabled ? 'Only Team Leader can change the answer' : 'Drag or select the correct prompt'
                                                                "
                                                                class="btn btn-outline-default width100"
                                                                :class="{ 'disabled hasTooltip': disabled }"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                <i class="fas fa-grip-vertical marginRight8" aria-hidden="true" />
                                                                {{ element.content }}
                                                            </button>

                                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                                <ul>
                                                                    <li v-for="(optionGroups, optionGroupIdxs) in question.options.options">
                                                                        <a
                                                                            class="primary-state"
                                                                            href="#"
                                                                            @click.prevent="addToPrompt(optionGroupIdxs, element)"
                                                                        >
                                                                            <span> Prompt {{ optionGroupIdxs + 1 }} </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </draggable>
                                            </template>

                                            <template v-else>
                                                <dl class="margin0">
                                                    <dt class="control-label capitalize">
                                                        Selected {{ question.options.labels.answer }} For {{ question.options.labels.prompt }}
                                                        {{ optionGroupIdx + 1 }}
                                                    </dt>
                                                    <template v-if="answers[getPromptIdx(optionKey)].answer.length > 0">
                                                        <dd
                                                            v-for="(ans, idx) in answers[getPromptIdx(optionKey)].answer"
                                                            :key="'mobile-categorize-' + ans.key"
                                                            class="categorize-mobile-answer"
                                                        >
                                                            {{ ans.content }}
                                                        </dd>
                                                    </template>
                                                    <template v-else> 0 answer selected </template>
                                                </dl>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </div>

                    <div>
                        <div class="marginBottom20 textAlignCenter lh-1">
                            <label class="control-label capitalize">Answers Choice </label>
                        </div>
                        <div class="categorize-drag-student">
                            <template v-if="!disabled">
                                <p>{{ filteredAnswersList.length }} answer choice/s</p>

                                <draggable
                                    v-model="filteredAnswersList"
                                    item-key="key"
                                    group="answers"
                                    class="drop-area"
                                    :class="{
                                        'flex rowGap10px columnGap10px': filteredAnswersList.length > 0,
                                        'flexOnly align-items justifyCenter': filteredAnswersList.length === 0,
                                    }"
                                    :disabled="disabled"
                                >
                                    <template #item="{ element, index }">
                                        <div class="dropdown" data-draggable="true">
                                            <button
                                                v-tooltip="disabled ? 'Only Team Leader can change the answer' : 'Drag or select the correct prompt'"
                                                class="btn btn-outline-default"
                                                :class="{ 'disabled hasTooltip': disabled }"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-labelledby="
                                                    'element-content-categorize-answer-choice-qns-' +
                                                    getQuestionNo(question.group - 1, question.order - 1) +
                                                    '-' +
                                                    index
                                                "
                                            >
                                                <i class="fas fa-grip-vertical marginRight8" aria-hidden="true" />
                                                <p
                                                    :id="
                                                        'element-content-categorize-answer-choice-qns-' +
                                                        getQuestionNo(question.group - 1, question.order - 1) +
                                                        '-' +
                                                        index
                                                    "
                                                >
                                                    {{ element.content }}
                                                </p>
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li v-for="(optionGroup, optionGroupIdx) in question.options.options">
                                                        <a class="primary-state" href="#" @click.prevent="addToPrompt(optionGroupIdx, element)">
                                                            <span> Prompt {{ optionGroupIdx + 1 }} </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="filteredAnswersList.length === 0" #footer>
                                        <p>Drop here</p>
                                    </template>
                                </draggable>
                            </template>

                            <dl v-else class="margin0">
                                <dt class="control-label">List of answers</dt>
                                <div v-if="filteredAnswersList.length > 0" class="flex rowGap10px columnGap10px">
                                    <dd
                                        v-for="(ans, idx) in filteredAnswersList"
                                        :key="'mobile-categorize-' + ans.key"
                                        class="categorize-mobile-answer marginTop0"
                                    >
                                        {{ ans.content }}
                                    </dd>
                                </div>
                                <template v-else> 0 answer choice </template>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <template v-for="(optionGroup, optionGroupIdx) in question.options.options" v-else>
                <template v-for="(optionData, optionKey) in optionGroup">
                    <div
                        v-if="optionData.type == 'prompt'"
                        class="sequencing-div-student"
                        :class="{ displayGrid: !isMobileView, filled: answers[getPromptIdx(optionKey)].answer.length > 0, disabled: disabled }"
                    >
                        <div :class="{ 'grid-order-2': !isMobileView }">
                            <label class="control-label capitalize">{{ question.options.labels.prompt }} {{ optionGroupIdx + 1 }}</label>
                            <p :id="'matching-one-to-many-student-' + (optionGroupIdx + 1)">{{ optionData.content }}</p>
                        </div>

                        <div v-if="!disabled" :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                            <label :for="'matching-one-to-many-student-select-' + (optionGroupIdx + 1)" class="control-label capitalize">
                                <template v-if="disabled"> Selected {{ question.options.labels.answer }} </template>
                                <template v-else> Select the {{ question.options.labels.answer }} </template>
                            </label>

                            <VueMultiselect
                                :id="'matching-one-to-many-student-select-' + (optionGroupIdx + 1)"
                                v-model="answers[getPromptIdx(optionKey)].answer"
                                v-tooltip="disabled ? 'Only Team Leader can change the answer' : ''"
                                :class="{ hasTooltip: disabled }"
                                :options="answersList"
                                :multiple="true"
                                placeholder="Select Options"
                                track-by="key"
                                label="content"
                                :aria-disabled="disabled"
                                :disabled="disabled"
                                :close-on-select="false"
                                @select="(selectedOption) => select(selectedOption, getPromptIdx(optionKey))"
                                @remove="(removedOption) => remove(removedOption, getPromptIdx(optionKey))"
                            >
                                <template #selection="{ values }">
                                    <span v-if="values.length != 0">Selected {{ values.length }} option{{ values.length > 1 ? 's' : '' }} </span>
                                </template>
                            </VueMultiselect>
                        </div>

                        <div class="marginTop20">
                            <dl class="margin0">
                                <dt class="capitalize">
                                    Selected {{ question.options.labels.answer }} For {{ question.options.labels.prompt }} {{ optionGroupIdx + 1 }}
                                </dt>
                                <template v-if="answers[getPromptIdx(optionKey)].answer.length > 0">
                                    <dd
                                        v-for="(ans, idx) in answers[getPromptIdx(optionKey)].answer"
                                        :key="'mobile-categorize-' + ans.key"
                                        class="categorize-mobile-answer"
                                    >
                                        {{ ans.content }}
                                    </dd>
                                </template>
                                <template v-else> 0 answer selected </template>
                            </dl>
                        </div>
                    </div>
                </template>
            </template>
        </fieldset>

        <span id="sr-message-update-on-drop" aria-live="polite" class="sr-only"></span>
    </div>
</template>

<script setup>
import draggable from 'vuedraggable';
draggable.compatConfig = { MODE: 3 };
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import VueMultiselect from 'vue-multiselect';

const emit = defineEmits(['update:answer']);
const answersList = ref([]);
const filteredAnswersList = ref([]);
const props = defineProps({
    testObj: {
        type: Object,
        default: null,
    },
    question: {
        type: Object,
        default: null,
    },
    answer: {
        type: Array,
        default: function () {
            return [];
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isTestCompleted: {
        type: Boolean,
        default: false,
    },
});

const answers = computed({
    // Use computed to wrap the object
    get: () => {
        let ret = [];
        for (var i = 0; i < props.answer.length; i++) {
            let answer = _.cloneDeep(props.answer[i]);
            for (var j = 0; j < answer.answer.length; j++) {
                let idx = _.findIndex(answersList.value, function (o) {
                    return o.key == answer.answer[j];
                });
                if (idx != -1) {
                    answer.answer.splice(j, 1, answersList.value[idx]);
                }
            }
            ret.push(answer);
        }
        return ret;
    },
});

const updateAnswer = () => {
    let a = _.cloneDeep(answers.value);

    for (var i = 0; i < a.length; i++) {
        a[i].prompt = parseInt(a[i].prompt);
        a[i].answer = a[i].answer.map((a) => parseInt(a.key));
    }

    emit('update:answer', a);
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += props.testObj.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

const getPromptIdx = (promptKey) => {
    return _.findIndex(answers.value, (o) => {
        return o.prompt == promptKey;
    });
};

const isAnswerTaken = (answerKey) => {
    for (var i = 0; i < answers.value.length; i++) {
        if (
            _.findIndex(answers.value[i].answer, (o) => {
                return o.key == answerKey;
            }) != -1
        ) {
            return true;
        }
    }
    return false;
};

const initialize = () => {
    answersList.value = [];
    filteredAnswersList.value = [];
    for (var i = 0; i < props.question.options.options.length; i++) {
        let optionGroup = props.question.options.options[i];
        Object.keys(optionGroup).forEach((key) => {
            if (optionGroup[key].type == 'answer') {
                answersList.value.push({ ...optionGroup[key], key: key });
            }
        });
    }

    for (var i = 0; i < answersList.value.length; i++) {
        if (!isAnswerTaken(answersList.value[i].key)) {
            filteredAnswersList.value.push({ ...answersList.value[i] });
        }
    }
};

const select = (selectedOption, promptIdx) => {
    let a = _.cloneDeep(answers.value);

    a.forEach((ans, id) => {
        if (id != promptIdx) {
            let idx = ans.answer.findIndex((o) => {
                return o.key === selectedOption.key;
            });

            if (idx != -1) {
                ans.answer.splice(idx, 1);
            }
        }
    });

    for (var i = 0; i < a.length; i++) {
        a[i].prompt = parseInt(a[i].prompt);
        a[i].answer = a[i].answer.map((a) => parseInt(a.key));
    }

    emit('update:answer', a);

    $('#sr-message-update-on-drop').text('');
    const message =
        'You have added ' +
        props.question.options.labels.answer +
        ' ' +
        selectedOption.content +
        ' to ' +
        props.question.options.labels.prompt +
        ' ' +
        promptIdx +
        1;
    setTimeout(() => {
        $('#sr-message-update-on-drop').text(message);
    }, 100);

    filteredAnswersList.value = [];
    for (var i = 0; i < answersList.value.length; i++) {
        if (!isAnswerTaken(answersList.value[i].key)) {
            filteredAnswersList.value.push({ ...answersList.value[i] });
        }
    }
};

const remove = (removedOption, promptIdx) => {
    let a = _.cloneDeep(answers.value);

    let idx = _.findIndex(a[promptIdx].answer, function (o) {
        return o.key == removedOption.key;
    });

    if (idx != -1) {
        a[promptIdx].answer.splice(idx, 1);
    }

    for (var i = 0; i < a.length; i++) {
        a[i].prompt = parseInt(a[i].prompt);
        a[i].answer = a[i].answer.map((a) => parseInt(a.key));
    }

    emit('update:answer', a);

    $('#sr-message-update-on-drop').text('');
    const message =
        'You have removed ' +
        props.question.options.labels.answer +
        ' ' +
        removedOption.content +
        ' to ' +
        props.question.options.labels.prompt +
        ' ' +
        promptIdx +
        1;
    setTimeout(() => {
        $('#sr-message-update-on-drop').text(message);
    }, 100);

    filteredAnswersList.value = [];
    for (var i = 0; i < answersList.value.length; i++) {
        if (!isAnswerTaken(answersList.value[i].key)) {
            filteredAnswersList.value.push({ ...answersList.value[i] });
        }
    }
};

const addToPrompt = (prompt, newAns) => {
    let a = _.cloneDeep(answers.value);

    a.forEach((ans, id) => {
        if (id != prompt) {
            let idx = ans.answer.findIndex((o) => {
                return o.key === newAns.key;
            });

            if (idx != -1) {
                ans.answer.splice(idx, 1);
            }
        }

        if (id == prompt) {
            ans.answer.push(newAns);
        }
    });

    for (var i = 0; i < a.length; i++) {
        a[i].prompt = parseInt(a[i].prompt);
        a[i].answer = a[i].answer.map((a) => parseInt(a.key));
    }

    $('#sr-message-update-on-drop').text('');
    const message = 'Moved ' + props.question.options.labels.answer + ' ' + newAns.content + ' to ' + props.question.options.labels.prompt + ' ' + prompt + 1;
    setTimeout(() => {
        $('#sr-message-update-on-drop').text(message);
    }, 100);

    emit('update:answer', a);

    nextTick(() => {
        initialize();
    });
};

watch(
    () => props.question,
    (question) => {
        initialize();
    }
);

onMounted(() => {
    initialize();

    nextTick(() => {
        setTimeout(() => {
            $('.multiselect').attr('tabIndex', 0);
        }, 50);
    });
});
</script>
