<template>
    <div>
        <fieldset>
            <legend>Question {{ currentStemIdx + 1 }} Rating Choice</legend>
            <div class="hidden-xs hidden-sm rating-flex-layout">
                <template v-for="(rating, ratingIdx) in props.question.ratingSettings">
                    <div class="newRadioField rating-scale-wrapper">
                        <div class="radioField">
                            <input
                                :id="`question-${props.question.uuid}-rating-${ratingIdx}-md-label`"
                                v-model.number="value"
                                :name="`question-${props.question.uuid}-rating-md`"
                                type="radio"
                                :aria-describedby="`question-${props.question.uuid}-rating-${ratingIdx}-md-label`"
                                :value="rating.weight"
                                :checked="rating.weight === value"
                                :disabled="disabled ? true : undefined"
                            />
                        </div>
                        <label :for="`question-${props.question.uuid}-rating-${ratingIdx}-md-label`" class="rating-label"> {{ rating.weight }} </label>
                        <label
                            v-if="rating.label || (!rating.label && rating.rubric)"
                            :for="`question-${props.question.uuid}-rating-${ratingIdx}-md-label`"
                            class="rating-label"
                        >
                            {{ rating.label }}
                        </label>
                        <label v-if="rating.rubric" :for="`question-${props.question.uuid}-rating-${ratingIdx}-xs-label`" class="fw-normal rating-label">
                            {{ rating.rubric }}
                        </label>
                    </div>
                </template>
            </div>

            <div class="hidden-md hidden-lg">
                <template v-for="(rating, ratingIdx) in props.question.ratingSettings">
                    <div class="newRadioField rating-scale-wrapper" :class="{ marginTop20: ratingIdx > 0 }">
                        <div class="radioField">
                            <input
                                :id="`question-${props.question.uuid}-rating-${ratingIdx}-xs-label`"
                                v-model.number="value"
                                type="radio"
                                :name="`question-${props.question.uuid}-rating-xs`"
                                :aria-describedby="'radio-label_' + ratingIdx + '_desc'"
                                :value="rating.weight"
                                :disabled="disabled ? true : undefined"
                            />
                        </div>
                        <div class="paddingTop15">
                            <label :for="`question-${props.question.uuid}-rating-${ratingIdx}-xs-label`"> {{ rating.weight }} {{ rating.label }} </label>
                            <br v-if="rating.rubric" />
                            <label v-if="rating.rubric" :for="`question-${props.question.uuid}-rating-${ratingIdx}-xs-label`" class="fw-18px fw-normal">
                                {{ rating.rubric }}
                            </label>
                        </div>
                    </div>
                </template>
            </div>
        </fieldset>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { computed } from 'vue';
const props = defineProps({
    modelValue: {
        type: Number || null,
        default: null,
    },
    question: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelModifiers: { default: () => ({}) },
    currentStemIdx: {
        type: Number,
        default: 0,
    },
});
const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>
