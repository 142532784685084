<template>
    <div
        ref="modal"
        class="modal default-modal changeCorrectAnsModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="changeCorrectAnsModal-title"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="changeCorrectAnsModal-title" class="modal-title">Change Correct Answer</h2>
                </div>
                <div class="modal-body newRadioField" :class="{ textAlignCenter: step == 2 }">
                    <div class="alert alert-warning marginBottom30" role="alert">
                        <i class="fa fa-circle-exclamation marginRight8" aria-hidden="true"></i>
                        <p>
                            You can use this function only <b>once</b>.
                            <br />
                            Once updated, you can't use this function after the activity ends.
                            <br />
                            Alternatively, you may reset the activity and update the answer.
                        </p>
                    </div>

                    <template v-if="step == 2">
                        <img src="/img/warning.png" class="width50px" alt=" " />
                        <p class="important">
                            <b>This will affect all the sections in this activity.</b>
                        </p>
                    </template>
                    <div class="stepper-container">
                        <div class="stepper-wrapper justifyCenter">
                            <button
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 1,
                                    completed: step == 2,
                                }"
                                @click.prevent="step = 1"
                            >
                                <template v-if="step == 1">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 1</div>
                                </template>
                                <template v-else-if="step == 2">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 1</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">1</div>
                                </template>

                                <label> Select New Correct Answer </label>
                            </button>
                            <button
                                v-tooltip="newCorrectAnswer == null ? 'Please select new answer' : ''"
                                class="stepper-item justifyContentFlexStart"
                                :class="{
                                    active: step == 2,
                                    completed: step == 3,
                                    'disabled hasToolTip': newCorrectAnswer == null,
                                }"
                                :aria-label="newCorrectAnswer == null ? 'Step 2 confirmation disabled Please select new answer' : 'Step 2 confirmation'"
                                @click.prevent="newCorrectAnswer != null ? (step = 2) : ''"
                            >
                                <template v-if="step == 2">
                                    <div class="step-marker-container">
                                        <div class="step-marker"></div>
                                    </div>
                                    <div class="visible-hidden">Current at step 2</div>
                                </template>
                                <template v-else-if="step == 3">
                                    <div class="step-completed material-icons" aria-hidden="true">check</div>
                                    <div class="visible-hidden">Completed Step 2</div>
                                </template>
                                <template v-else>
                                    <div class="visible-hidden">Step</div>
                                    <div class="step-counter">2</div>
                                </template>

                                <label> Confirmation </label>
                            </button>
                        </div>
                    </div>

                    <template v-if="step == 1">
                        <div class="marginBottom20 collapsingQuestion">
                            <button
                                class="btn"
                                :data-target="'#viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeCorrectAnswer'"
                                data-toggle="collapse"
                                aria-expanded="false"
                                :aria-controls="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeCorrectAnswer'"
                            >
                                Question
                                {{ getQuestionNo(question.group - 1, question.order - 1) }}
                            </button>

                            <div
                                :id="'viewQuestion-' + getQuestionNo(question.group - 1, question.order - 1) + '-changeCorrectAnswer'"
                                class="collapse padding10"
                            >
                                <div class="question">
                                    <kr-math :input="question.question" :safe="!question.questionIsHTML" />
                                </div>
                            </div>
                        </div>

                        <fieldset>
                            <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} answer choices</legend>
                            <template v-for="(option, option_idx) in question.optionKeys">
                                <div
                                    class="radioField flex"
                                    :class="{
                                        'discussionOption newCorrect': optionCorrect(question.id, option.key),
                                        'padding10 paddingLeft32': !optionCorrect(question.id, option.key),
                                    }"
                                >
                                    <i v-if="optionCorrect(question.id, option.key)" class="fa fa-check-circle" />
                                    <input
                                        v-else
                                        :id="question.uuid + '-changeNewAns-' + option.key"
                                        v-model="newCorrectAnswer"
                                        :name="question.uuid"
                                        type="radio"
                                        :value="option.key"
                                        :checked="option.isCorrect"
                                    />

                                    <label
                                        :for="question.uuid + '-changeNewAns-' + option.key"
                                        :class="{
                                            paddingTop5: !optionCorrect(question.id, option.key),
                                        }"
                                    >
                                        <span class="withOptionKey"> {{ option.key }}. </span>
                                        <span class="question">
                                            <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                        </span>
                                    </label>
                                </div>
                            </template>
                        </fieldset>
                    </template>
                    <template v-else-if="step == 2">
                        <h4>You are about to change the correct answer</h4>
                        <h4>
                            for <b> Question {{ getQuestionNo(question.group - 1, question.order - 1) }} </b> in <b> {{ test.name }} </b>
                        </h4>
                        <br />

                        <template v-for="(option, option_idx) in question.optionKeys">
                            <template v-if="option.isCorrect">
                                <div class="collapsingQuestion textAlignLeft marginBottom20">
                                    <button
                                        class="btn"
                                        :data-target="'#viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                        data-toggle="collapse"
                                        aria-expanded="false"
                                        :aria-controls="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                    >
                                        View Previous Correct Answer For Question
                                        {{ getQuestionNo(question.group - 1, question.order - 1) }}: Option {{ option.key }}
                                    </button>

                                    <div
                                        :id="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-previousCorrectAnswer'"
                                        class="collapse padding10"
                                    >
                                        <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-for="(option, option_idx) in question.optionKeys">
                            <template v-if="option.key == newCorrectAnswer">
                                <div class="collapsingQuestion textAlignLeft correctOption">
                                    <button
                                        class="btn"
                                        :data-target="'#viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-newCorrectAnswer'"
                                        data-toggle="collapse"
                                        aria-expanded="false"
                                        :aria-controls="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-newCorrectAnswer'"
                                    >
                                        View New Correct Answer For Question
                                        {{ getQuestionNo(question.group - 1, question.order - 1) }}: Option {{ option.key }}
                                    </button>

                                    <div
                                        :id="'viewAnswer-' + getQuestionNo(question.group - 1, question.order - 1) + '-newCorrectAnswer'"
                                        class="collapse padding10"
                                    >
                                        <kr-math :input="question.options[option_idx].content" :safe="!question.options[option_idx].contentIsHTML" />
                                    </div>
                                </div>
                            </template>
                        </template>

                        <br />
                        <p v-if="!['not started'].includes(test.status)">All student results will be recalculated accordingly.</p>
                        <br />
                    </template>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click="closeModal">Cancel</button>
                    <button
                        v-if="step == 1"
                        v-tooltip="newCorrectAnswer == null ? 'Please select new answer' : ''"
                        class="btn btn-primary"
                        :class="{
                            'disabled hasToolTip': newCorrectAnswer == null,
                        }"
                        :aria-label="newCorrectAnswer == null ? 'Next Step disabled' : 'Next Step'"
                        @click.prevent="newCorrectAnswer != null ? (step = 2) : ''"
                    >
                        Next Step
                    </button>
                    <button v-if="step == 2" class="btn btn-success" @click.prevent="submit">
                        <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save New Answer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['accepted']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';

const modal = ref(null);
const question = ref({});

const test = ref({});
const newCorrectAnswer = ref(null);
const step = ref(1);
function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function accept(t = {}, q = {}) {
    test.value = t;
    question.value = q;
    step.value = 1;
    newCorrectAnswer.value = null;
    openModal();
}
function getQuestionNo(question_group_idx, question_idx) {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += test.value.questions[i].length;
    }
    count += question_idx + 1;
    return count;
}

function optionCorrect(questionId, key) {
    if (['ended', 'completed', 'not started'].includes(test.value.status) && question.value.type == 'mcqs') {
        for (var i = 0; i < test.value.acceptedNewAnswers.length; i++) {
            if (test.value.acceptedNewAnswers[i].activityQuestionId == questionId) {
                for (var j = 0; j < test.value.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                    if (test.value.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                        return true;
                    }
                }
            }
        }
    }
}

function submit() {
    axios
        .post('activities/' + test.value.activityUuid + '/change-correct-answer', {
            activityQuestionUuid: question.value.uuid,
            newCorrectAnswerOption: newCorrectAnswer.value,
        })
        .then(function (response) {
            closeModal();
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Correct answer/s changed',
            });
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Unable to change correct answer at this moment, please try again later',
            });
        });
}
defineExpose({ accept });
</script>
