<template>
    <div v-if="component_done_loading" id="attendance-block" class="height100">
        <kr-panel :with-footer="false" :options="{ canToggle: false }" class="height100">
            <template #title>
                <div class="hidden-xs col-xs-12 flexSpaceBetween align-items">
                    <label class="marginRight10"> Attendance </label>

                    <div class="flex justifyEnd">
                        <div class="dropdown inlineFlex align-items filterBox">
                            <button
                                id="viewDropdownMenuButton"
                                class="flexSpaceBetween paddingLeft5"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <div class="flex align-items">
                                    <i class="material-symbols-outlined marginRight5" aria-hidden="true"> filter_alt </i>
                                    <div class="paddingTop2">
                                        <template v-if="attendanceFilterArray == '' || attendanceFilterArray == 'Show All'"> Show All </template>
                                        <template v-else>
                                            {{ attendanceFilterArray }}
                                        </template>
                                    </div>
                                </div>

                                <i class="fa fa-caret-down" aria-hidden="true"></i>
                            </button>

                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new attendance-block-filter" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Show All' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Show All'"
                                        >
                                            <span> Show All </span>
                                            <i
                                                v-if="attendanceFilterArray == '' || attendanceFilterArray == 'Show All'"
                                                class="fa fa-check marginLeft8"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Present' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Present'"
                                        >
                                            <span> Present </span>
                                            <i v-if="attendanceFilterArray == 'Present'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Absent' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Absent'"
                                        >
                                            <span> Absent </span>
                                            <i v-if="attendanceFilterArray == 'Absent'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </li>
                                    <li v-if="testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')">
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Team Reporter' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Team Reporter'"
                                        >
                                            <span> Team Reporter </span>
                                            <i v-if="attendanceFilterArray == 'Team Reporter'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </li>

                                    <li
                                        v-if="
                                            (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                            testObj.settings.hasTimeLimit
                                        "
                                    >
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Has Extra Time' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Has Extra Time'"
                                        >
                                            <span> Has Extra Time </span>
                                            <i v-if="attendanceFilterArray == 'Has Extra Time'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </li>
                                    <li v-if="testObj.earlyStartDurationInMinutes">
                                        <button
                                            class="buttonRole primary-state flexSpaceBetween"
                                            role="option"
                                            :aria-selected="attendanceFilterArray == 'Has Early Start' ? true : false"
                                            @click.prevent="attendanceFilterArray = 'Has Early Start'"
                                        >
                                            <span> Has Early Start </span>
                                            <i v-if="attendanceFilterArray == 'Has Early Start'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group form-search has-feedback has-feedback-left marginLeft8" style="width: 200px">
                            <input
                                v-model="searchData.q"
                                type="text"
                                class="form-control paddingTop5 paddingBottom5 paddingLeft5 height100"
                                placeholder="Search..."
                                aria-label="search student name"
                                @keyup="search"
                            />
                            <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                        </div>
                    </div>
                </div>
                <div class="hidden-sm hidden-md hidden-lg col-xs-12">
                    <div class="marginBottom10">
                        <label class="marginRight10"> Attendance </label>
                    </div>
                    <div class="dropdown inlineFlex align-items filterBox marginBottom10">
                        <button
                            id="viewDropdownMenuButton"
                            class="flexSpaceBetween paddingLeft5"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <div class="flex align-items">
                                <i class="material-symbols-outlined marginRight5" aria-hidden="true"> filter_alt </i>
                                <div class="paddingTop2">
                                    <template v-if="attendanceFilterArray == '' || attendanceFilterArray == 'Show All'"> Show All </template>
                                    <template v-else>
                                        {{ attendanceFilterArray }}
                                    </template>
                                </div>
                            </div>

                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new attendance-block-filter" aria-labelledby="dropdownMenuButton">
                            <ul>
                                <li>
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Show All' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Show All'"
                                    >
                                        <span> Show All </span>
                                        <i
                                            v-if="attendanceFilterArray == '' || attendanceFilterArray == 'Show All'"
                                            class="fa fa-check marginLeft8"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Present' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Present'"
                                    >
                                        <span> Present </span>
                                        <i v-if="attendanceFilterArray == 'Present'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Absent' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Absent'"
                                    >
                                        <span> Absent </span>
                                        <i v-if="attendanceFilterArray == 'Absent'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </li>
                                <li v-if="testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')">
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Team Reporter' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Team Reporter'"
                                    >
                                        <span> Team Reporter </span>
                                        <i v-if="attendanceFilterArray == 'Team Reporter'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </li>
                                <li
                                    v-if="
                                        (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                            (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                        testObj.settings.hasTimeLimit
                                    "
                                >
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Has Extra Time' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Has Extra Time'"
                                    >
                                        <span> Has Extra Time </span>
                                        <i v-if="attendanceFilterArray == 'Has Extra Time'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </li>
                                <li v-if="canStartEarly">
                                    <button
                                        class="buttonRole primary-state flexSpaceBetween"
                                        role="option"
                                        :aria-selected="attendanceFilterArray == 'Has Early Start' ? true : false"
                                        @click.prevent="attendanceFilterArray = 'Has Early Start'"
                                    >
                                        <span> Has Early Start </span>
                                        <i v-if="attendanceFilterArray == 'Has Early Start'" class="fa fa-check marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group form-search has-feedback has-feedback-left" style="width: 200px">
                        <input
                            v-model="searchData.q"
                            type="text"
                            class="form-control paddingTop5 paddingBottom5 paddingLeft5"
                            placeholder="Search..."
                            aria-label="search student name"
                            @keyup="search"
                        />
                        <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                    </div>
                </div>
            </template>
            <template #content>
                <div class="container">
                    <div class="attendanceView">
                        <div
                            v-if="
                                testObj.unassignedStudentsInTestLevel.length > 0 && !['completed', 'ended'].includes(testObj.status) && !auth.isImpersonation()
                            "
                            class="paddingLeft15 paddingRight15 marginBottom20 addStudentsDiv"
                            @click.prevent="openAddStudentsToTestModal"
                            @keyup.enter="openAddStudentsToTestModal"
                        >
                            <div class="alert alert-warning-bg" role="alert">
                                <i class="fa fa-exclamation-triangle marginRight8" aria-hidden="true" />
                                <p>
                                    {{ testObj.unassignedStudentsInTestLevel.length }}
                                    student/s from your course are
                                    <u>NOT</u> yet included in this activity
                                    <br v-if="hasWritePrivilege(testObj.course) != -1" />
                                    <b v-if="hasWritePrivilege(testObj.course) != -1"> Click this box to add them </b>
                                </p>
                            </div>
                        </div>
                        <div class="hidden-xs flexSpaceBetween align-items paddingBottom16 paddingLeft20 paddingRight20">
                            <div>
                                <b>Section {{ testObj.sectionName }}</b>
                            </div>

                            <div
                                v-if="
                                    (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                        (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                    testObj.settings.hasTimeLimit
                                "
                                class="dropdown"
                            >
                                <button
                                    v-tooltip="selectedStudents.length < 1 ? 'Please select a student' : ''"
                                    class="btn btn-primary"
                                    :class="{
                                        'disabled hasToolTip': selectedStudents.length < 1,
                                    }"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    :aria-disabled="selectedStudents.length < 1"
                                >
                                    <i class="fa fa-clock marginRight8" aria-hidden="true" />Extra Time ({{ selectedStudents.length }})
                                </button>

                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        <li>
                                            <button class="buttonRole primary-state flex" @click.prevent="openExtendTimeModal('multiple')">
                                                <span>
                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />
                                                    Add
                                                </span>
                                            </button>
                                        </li>

                                        <li
                                            v-if="
                                                testObj.status == 'not started' ||
                                                (testObj.settings.type == 'asynchronous' && (testObj.status == 'scheduled' || testObj.status != 'scheduled'))
                                            "
                                        >
                                            <template v-if="isRemoveExtraTimeButtonDisabled">
                                                <button
                                                    v-tooltip="
                                                        testObj.settings.type == 'synchronous'
                                                            ? 'Student has no extra time'
                                                            : 'Extra time can only be removed if there is existing extra time or student has not started'
                                                    "
                                                    class="buttonRole danger-state flex disabled hasToolTip"
                                                >
                                                    <span>
                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                        Remove
                                                    </span>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="buttonRole danger-state flex" @click.prevent="clearExtraTimeModal('multiple')">
                                                    <span>
                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                        Remove
                                                    </span>
                                                </button>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div
                                v-if="
                                    (testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')) &&
                                    !['scheduled', 'not started'].includes(testObj.status)
                                "
                                class="flex align-items"
                            >
                                <label for="allowingTeamReporterMarkAttendance"> Allow teams to mark attendance </label>

                                <label class="form-switch marginLeft10">
                                    <input
                                        id="allowingTeamReporterMarkAttendance"
                                        v-model="testObj.allowTeamReporterToMarkAttendance"
                                        type="checkbox"
                                        aria-label="Allow teams to mark attendance"
                                        @change="updateAllowTeamMarkAttendance()"
                                    />
                                    <span class="slider round" />
                                </label>
                            </div>
                        </div>

                        <div class="hidden-sm hidden-md hidden-lg paddingBottom16 paddingLeft20 paddingRight20">
                            <div class="marginBottom10">
                                <b>Section {{ testObj.sectionName }}</b>
                            </div>

                            <div
                                v-if="
                                    (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                        (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                    testObj.settings.hasTimeLimit
                                "
                                class="dropdown"
                            >
                                <button
                                    class="btn btn-primary"
                                    :class="{
                                        disabled: selectedStudents.length < 1,
                                    }"
                                    :disabled="selectedStudents.length < 1 ? true : undefined"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i class="fa fa-clock marginRight8" aria-hidden="true" />Extra Time ({{ selectedStudents.length }})
                                </button>

                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        <li>
                                            <button class="buttonRole primary-state flex" @click.prevent="openExtendTimeModal('multiple')">
                                                <span>
                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />
                                                    Add
                                                </span>
                                            </button>
                                        </li>

                                        <li
                                            v-if="
                                                testObj.status == 'not started' ||
                                                (testObj.settings.type == 'asynchronous' && (testObj.status == 'scheduled' || testObj.status != 'scheduled'))
                                            "
                                        >
                                            <template v-if="isRemoveExtraTimeButtonDisabled">
                                                <button
                                                    v-tooltip="
                                                        testObj.settings.type == 'synchronous'
                                                            ? 'Student has no extra time'
                                                            : 'Extra time can only be removed if there is existing extra time or student has not started'
                                                    "
                                                    class="buttonRole danger-state flex disabled hasToolTip"
                                                >
                                                    <span>
                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                        Remove
                                                    </span>
                                                </button>
                                            </template>
                                            <template v-else>
                                                <button class="buttonRole danger-state flex" @click.prevent="clearExtraTimeModal('multiple')">
                                                    <span>
                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                        Remove
                                                    </span>
                                                </button>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div
                                v-if="
                                    (testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')) &&
                                    !['scheduled', 'not started'].includes(testObj.status)
                                "
                                class="flex align-items"
                            >
                                <label for="allowingTeamReporterMarkAttendance"> Allow teams to mark attendance </label>

                                <label class="form-switch marginLeft10">
                                    <input
                                        id="allowingTeamReporterMarkAttendance"
                                        v-model="testObj.allowTeamReporterToMarkAttendance"
                                        type="checkbox"
                                        aria-label="Allow teams to mark attendance"
                                        @change="updateAllowTeamMarkAttendance()"
                                    />
                                    <span class="slider round" />
                                </label>
                            </div>
                        </div>

                        <div class="namesScroll tableBodyFixedHeight-attendance">
                            <table class="table attendance-new-table">
                                <thead>
                                    <tr>
                                        <th class="leftRightWidth">Students</th>
                                        <th class="leftRightWidth"></th>
                                        <th class="width132px">Attendance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(team, idx) in attendance">
                                        <template v-if="!searchData.isSearching || team._matched != 0">
                                            <tr>
                                                <th class="subHeader" colspan="3" scope="colgroup">
                                                    <div class="flexOnly align-items">
                                                        <div
                                                            v-if="
                                                                testObj.type == 'trat' ||
                                                                (testObj.type == 'application' && testObj.others.applicationType == 'team')
                                                            "
                                                            class="dropdown marginRight10 z-index-1"
                                                        >
                                                            <button
                                                                class="vertical6DotsButton"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                aria-label="More Options"
                                                            >
                                                                <i class="fas fa-grip-vertical" aria-hidden="true" />
                                                            </button>

                                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                                <ul>
                                                                    <li>
                                                                        <a
                                                                            class="primary-state"
                                                                            href="#"
                                                                            :aria-label="
                                                                                testObj.teamsNotAllowedToUpdateTeamLeader &&
                                                                                testObj.teamsNotAllowedToUpdateTeamLeader.includes(team.uuid)
                                                                                    ? 'click here to enable unfreeze team'
                                                                                    : 'click here to enable freeze team'
                                                                            "
                                                                            @click.prevent="freezeUnfreezeTeamLeader(idx)"
                                                                        >
                                                                            <span>
                                                                                <template
                                                                                    v-if="
                                                                                        testObj.teamsNotAllowedToUpdateTeamLeader &&
                                                                                        testObj.teamsNotAllowedToUpdateTeamLeader.includes(team.uuid)
                                                                                    "
                                                                                >
                                                                                    <i class="fa fa-unlock marginRight8" aria-hidden="true" />
                                                                                    Allow Change Team Reporter
                                                                                </template>
                                                                                <template v-else>
                                                                                    <i class="fa fa-lock marginRight8" aria-hidden="true" />
                                                                                    Disallow Change Team Reporter
                                                                                </template>
                                                                            </span>
                                                                        </a>
                                                                    </li>

                                                                    <hr v-if="canClearAnswers" />

                                                                    <li v-if="canClearAnswers">
                                                                        <a
                                                                            class="danger-state"
                                                                            href="#"
                                                                            :aria-label="'Clear ' + team.name + ' Answers'"
                                                                            @click.prevent="clearAnswers(team.uuid, team.name)"
                                                                        >
                                                                            <span>
                                                                                <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                                                Clear Answers
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="flexOnly align-items">
                                                            <label>
                                                                <b>
                                                                    {{ team.name }}
                                                                </b>
                                                            </label>
                                                            <div
                                                                v-if="
                                                                    testObj.teamsNotAllowedToUpdateTeamLeader &&
                                                                    testObj.teamsNotAllowedToUpdateTeamLeader.includes(team.uuid)
                                                                "
                                                                class="marginLeft10 flex align-items"
                                                            >
                                                                <i class="fa fa-lock marginRight8" aria-hidden="true" />
                                                                <p>Team is locked. Use six dots icon to change.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>

                                            <template v-for="(student, student_idx) in team.members">
                                                <tr v-if="!searchData.isSearching || student._searchDisplay">
                                                    <td class="leftRightWidth">
                                                        <div
                                                            class="flexSpaceBetween align-items"
                                                            :class="{
                                                                paddingLeft20:
                                                                    testObj.type == 'trat' ||
                                                                    (testObj.type == 'application' && testObj.others.applicationType == 'team'),
                                                            }"
                                                        >
                                                            <div class="inlineFlex align-items">
                                                                <template
                                                                    v-if="
                                                                        (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                            (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                                                        testObj.settings.hasTimeLimit
                                                                    "
                                                                >
                                                                    <div class="checkboxField justifyCenter marginRight8">
                                                                        <input
                                                                            v-if="
                                                                                student.status == 'completed' ||
                                                                                (testObj.settings.type == 'asynchronous' &&
                                                                                    ['ended', 'completed'].includes(testObj.status))
                                                                            "
                                                                            v-tooltip="
                                                                                student.status == 'completed'
                                                                                    ? 'Activity has been submitted'
                                                                                    : 'Activity is closed'
                                                                            "
                                                                            class="disabled hasToolTip"
                                                                            type="checkbox"
                                                                            onClick="return false"
                                                                        />
                                                                        <input
                                                                            v-else-if="
                                                                                testObj.settings.type == 'synchronous' &&
                                                                                testObj.startedEarly &&
                                                                                testObj.expectedEndingTime != null
                                                                            "
                                                                            v-tooltip="'Changes to extra time are not allowed during Early Start'"
                                                                            class="disabled hasToolTip"
                                                                            type="checkbox"
                                                                            onClick="return false"
                                                                        />
                                                                        <input v-else v-model="selectedStudents" type="checkbox" :value="student" />
                                                                    </div>
                                                                </template>
                                                                <div class="dropdown marginRight10">
                                                                    <button
                                                                        class="vertical6DotsButton paddingTop5"
                                                                        :class="{
                                                                            disabled: selectedStudents.length > 1,
                                                                        }"
                                                                        data-toggle="dropdown"
                                                                        aria-haspopup="true"
                                                                        aria-expanded="false"
                                                                        aria-label="More Options"
                                                                        :disabled="selectedStudents.length > 1 ? true : undefined"
                                                                        @click.prevent="dropdownToggle($event.target)"
                                                                    >
                                                                        <i class="fas fa-grip-vertical" aria-hidden="true" />
                                                                    </button>

                                                                    <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                                        <ul>
                                                                            <li
                                                                                v-if="
                                                                                    (testObj.type == 'trat' ||
                                                                                        (testObj.type == 'application' &&
                                                                                            testObj.others.applicationType == 'team')) &&
                                                                                    !['scheduled', 'not started'].includes(testObj.status) &&
                                                                                    (student.isReporter ||
                                                                                        (['ended'].includes(testObj.status) && getMembersPresent(team) == 0) ||
                                                                                        (['ongoing', 'paused'].includes(testObj.status) &&
                                                                                            !isTeamReportPresent(team)))
                                                                                "
                                                                            >
                                                                                <a class="primary-state disabled">
                                                                                    <span>
                                                                                        <template v-if="!student.isPresent">
                                                                                            <i class="fas fa-user-check marginRight8" aria-hidden="true" />
                                                                                            Mark as Present
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <i class="fas fa-user-times marginRight8" aria-hidden="true" />
                                                                                            Mark as Absent
                                                                                        </template>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                v-else-if="
                                                                                    (testObj.type == 'trat' ||
                                                                                        (testObj.type == 'application' &&
                                                                                            testObj.others.applicationType == 'team')) &&
                                                                                    !['scheduled', 'not started'].includes(testObj.status)
                                                                                "
                                                                                @click.prevent="openPresentModal(idx, student_idx)"
                                                                            >
                                                                                <a class="primary-state" href="#">
                                                                                    <span>
                                                                                        <template v-if="!student.isPresent">
                                                                                            <i class="fas fa-user-check marginRight8" aria-hidden="true" />
                                                                                            Mark as Present
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <i class="fas fa-user-times marginRight8" aria-hidden="true" />
                                                                                            Mark as Absent
                                                                                        </template>
                                                                                    </span>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                v-if="
                                                                                    (testObj.type == 'trat' ||
                                                                                        (testObj.type == 'application' &&
                                                                                            testObj.others.applicationType == 'team')) &&
                                                                                    student.isReporter
                                                                                "
                                                                                @click.prevent="changeTeamLeader(idx, student_idx)"
                                                                                @keyup.enter="changeTeamLeader(idx, student_idx)"
                                                                            >
                                                                                <a class="primary-state disabled">
                                                                                    <span>
                                                                                        <i class="fas fa-gamepad marginRight8" aria-hidden="true" />
                                                                                        Make Team Reporter
                                                                                    </span>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                v-else-if="
                                                                                    testObj.type == 'trat' ||
                                                                                    (testObj.type == 'application' && testObj.others.applicationType == 'team')
                                                                                "
                                                                                @click.prevent="changeTeamLeader(idx, student_idx)"
                                                                                @keyup.enter="changeTeamLeader(idx, student_idx)"
                                                                            >
                                                                                <a class="primary-state" href="#">
                                                                                    <span>
                                                                                        <i class="fas fa-gamepad marginRight8" aria-hidden="true" />
                                                                                        Make Team Reporter
                                                                                    </span>
                                                                                </a>
                                                                            </li>

                                                                            <li
                                                                                v-if="
                                                                                    (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                                        (testObj.type == 'application' &&
                                                                                            testObj.others.applicationType != 'team')) &&
                                                                                    testObj.settings.hasTimeLimit &&
                                                                                    student.userTestStatus != 'finished'
                                                                                "
                                                                            >
                                                                                <template
                                                                                    v-if="
                                                                                        student.status == 'completed' ||
                                                                                        (testObj.settings.type == 'asynchronous' &&
                                                                                            ['ended', 'completed'].includes(testObj.status)) ||
                                                                                        !canAddExtraTime
                                                                                    "
                                                                                >
                                                                                    <button
                                                                                        v-tooltip="
                                                                                            !canAddExtraTime
                                                                                                ? 'New answers has been added'
                                                                                                : student.status == 'completed'
                                                                                                ? 'Activity has been submitted'
                                                                                                : 'Activity is closed'
                                                                                        "
                                                                                        class="buttonRole primary-state flex disabled hasToolTip"
                                                                                    >
                                                                                        <span>
                                                                                            <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                                                                            Add Extra Time
                                                                                        </span>
                                                                                    </button>
                                                                                </template>

                                                                                <template
                                                                                    v-else-if="
                                                                                        testObj.settings.type == 'synchronous' &&
                                                                                        testObj.startedEarly &&
                                                                                        testObj.expectedEndingTime != null
                                                                                    "
                                                                                >
                                                                                    <button
                                                                                        v-tooltip="'Changes to extra time are not allowed during Early Start'"
                                                                                        class="buttonRole primary-state flex disabled hasToolTip"
                                                                                    >
                                                                                        <span>
                                                                                            <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                                                                            Add Extra Time
                                                                                        </span>
                                                                                    </button>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <button
                                                                                        class="buttonRole primary-state flex"
                                                                                        @click.prevent="openExtendTimeModal('individual', student)"
                                                                                    >
                                                                                        <span>
                                                                                            <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                                                                            Add Extra Time
                                                                                        </span>
                                                                                    </button>
                                                                                </template>
                                                                            </li>

                                                                            <li
                                                                                v-if="
                                                                                    student.extraTimeValues != null &&
                                                                                    (testObj.status == 'not started' ||
                                                                                        (testObj.settings.type == 'asynchronous' &&
                                                                                            (testObj.status == 'scheduled' ||
                                                                                                (testObj.status != 'scheduled' && !student.isPresent))))
                                                                                "
                                                                            >
                                                                                <button
                                                                                    v-tooltip="
                                                                                        testObj.startedEarly && testObj.expectedEndingTime != null
                                                                                            ? 'Changes to extra time are not allowed during Early Start'
                                                                                            : ''
                                                                                    "
                                                                                    class="buttonRole danger-state flex"
                                                                                    :class="{
                                                                                        'disabled hasToolTip':
                                                                                            testObj.startedEarly && testObj.expectedEndingTime != null,
                                                                                    }"
                                                                                    :aria-disabled="testObj.startedEarly && testObj.expectedEndingTime != null"
                                                                                    @click.prevent="
                                                                                        testObj.startedEarly && testObj.expectedEndingTime != null
                                                                                            ? ''
                                                                                            : clearExtraTimeModal('individual', student)
                                                                                    "
                                                                                >
                                                                                    <span>
                                                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />
                                                                                        Remove Extra Time
                                                                                    </span>
                                                                                </button>
                                                                            </li>

                                                                            <li
                                                                                v-if="
                                                                                    (['evaluation', 'michaelsenEvaluation', 'irat'].includes(testObj.type) ||
                                                                                        (testObj.type == 'application' &&
                                                                                            testObj.others.applicationType != 'team')) &&
                                                                                    canClearAnswers
                                                                                "
                                                                            >
                                                                                <button
                                                                                    class="buttonRole danger-state"
                                                                                    @click.prevent="clearAnswers(student.studentUuid, student.displayName)"
                                                                                    @keyup.space="clearAnswers(student.studentUuid, student.displayName)"
                                                                                >
                                                                                    <span>
                                                                                        <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Clear Answers
                                                                                    </span>
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <template v-if="student.avatar">
                                                                    <div
                                                                        class="profile_image marginRight8"
                                                                        :class="{
                                                                            withAccommodations:
                                                                                student.hasAccommodations == 1 &&
                                                                                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                                    (testObj.type == 'application' &&
                                                                                        testObj.others.applicationType != 'team')) &&
                                                                                testObj.settings.hasTimeLimit,
                                                                        }"
                                                                    >
                                                                        <div
                                                                            class="actual-image"
                                                                            :style="'background-image:url(' + student.avatar + ');'"
                                                                        ></div>

                                                                        <span
                                                                            v-if="
                                                                                student.hasAccommodations == 1 &&
                                                                                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                                    (testObj.type == 'application' &&
                                                                                        testObj.others.applicationType != 'team')) &&
                                                                                testObj.settings.hasTimeLimit
                                                                            "
                                                                            class="fa-stack"
                                                                            style="vertical-align: top"
                                                                        >
                                                                            <i class="fas fa-circle fa-stack-2x"></i>
                                                                            <i class="fas fa-universal-access color-purple fa-stack-1x fa-inverse"></i>
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div
                                                                        class="profile_pic marginRight8"
                                                                        :class="{
                                                                            withAccommodations:
                                                                                student.hasAccommodations == 1 &&
                                                                                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                                    (testObj.type == 'application' &&
                                                                                        testObj.others.applicationType != 'team')) &&
                                                                                testObj.settings.hasTimeLimit,
                                                                        }"
                                                                    >
                                                                        <div class="badge_profile img-circle" style="background: #2a3f54; color: white">
                                                                            {{ getInitials(student) }}
                                                                        </div>

                                                                        <span
                                                                            v-if="
                                                                                student.hasAccommodations == 1 &&
                                                                                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                                    (testObj.type == 'application' &&
                                                                                        testObj.others.applicationType != 'team')) &&
                                                                                testObj.settings.hasTimeLimit
                                                                            "
                                                                            class="fa-stack"
                                                                            style="vertical-align: top"
                                                                        >
                                                                            <i class="fas fa-circle fa-stack-2x"></i>
                                                                            <i class="fas fa-universal-access color-purple fa-stack-1x fa-inverse"></i>
                                                                        </span>
                                                                    </div>
                                                                </template>
                                                                <p
                                                                    class="student_name"
                                                                    :class="{
                                                                        fontBold: student.isReporter,
                                                                    }"
                                                                >
                                                                    {{ student.displayName }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="leftRightWidth">
                                                        <div
                                                            v-if="
                                                                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                                                                    (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                                                                testObj.settings.hasTimeLimit
                                                            "
                                                            class="inlineFlex align-items"
                                                        >
                                                            <div
                                                                v-if="student.canEarlyStart == 1"
                                                                class="attendanceTag-blue inlineFlex align-items marginRight8"
                                                            >
                                                                <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                                                Early
                                                            </div>
                                                            <attendance-timer-label
                                                                v-if="
                                                                    (student.extraTimeValues || student.expectedEndingTime) &&
                                                                    testObj.expectedEndingTime == null
                                                                "
                                                                :test-obj="testObj"
                                                                :student="student"
                                                            ></attendance-timer-label>
                                                        </div>

                                                        <div
                                                            v-if="
                                                                (testObj.type == 'trat' ||
                                                                    (testObj.type == 'application' && testObj.others.applicationType == 'team')) &&
                                                                student.isReporter
                                                            "
                                                            class="inlineFlex attendanceTag-primary"
                                                        >
                                                            <p>Team Reporter</p>
                                                        </div>
                                                    </td>
                                                    <td class="width132px">
                                                        <div
                                                            class="inlineFlex align-items"
                                                            :class="{
                                                                'attendanceTag-green': student.isPresent,
                                                                'attendanceTag-gray': !student.isPresent,
                                                            }"
                                                        >
                                                            <template v-if="student.isPresent">
                                                                <i class="fas fa-user-check marginRight8" aria-hidden="true" />
                                                                <p>Present</p>
                                                            </template>
                                                            <template v-else>
                                                                <i class="fas fa-user-times marginRight8" aria-hidden="true" />
                                                                <p>Absent</p>
                                                            </template>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>

        <div
            id="addStudentsToTestModal"
            class="modal default-modal addStudentsToTestModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="addStudentsToTestModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="addStudentsToTestModal-title" class="modal-title">Review Students</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="marginBottom20">
                                <div class="alert alert-warning-bg">
                                    <i class="fa fa-exclamation-triangle marginRight8" aria-hidden="true" />
                                    <p>
                                        Choose the students you want to include in this activity by
                                        <br />
                                        • Choosing the team; and
                                        <br />
                                        • Checking the “Approve” box.
                                        <br />
                                        <br />
                                        Click the “Update” button. The students will now be able to see and start this activity in their dashboard.
                                    </p>
                                </div>
                            </div>
                            <div class="col-xs-12 textAlignCenter">
                                You are now adding students to the current activity for
                                <h3>{{ testObj.name }}</h3>
                            </div>

                            <div class="table-responsive marginBottom30 student-modal-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th class="width190px">Team</th>
                                            <th class="width130px">
                                                <template v-if="total_count == 0"> Approval </template>
                                                <div v-if="total_count != 0" class="checkboxField">
                                                    <input
                                                        id="attendance_approvalId"
                                                        v-model="approvalToggle"
                                                        type="checkbox"
                                                        aria-label="Approval"
                                                        @change="toggleStudents()"
                                                    />
                                                    <label for="attendance_approvalId"> Approval </label>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="student in studentsData" :key="student.uuid">
                                            <tr>
                                                <td>
                                                    {{ student.user.user }}
                                                </td>
                                                <td>
                                                    <div class="form-dropdown">
                                                        <select
                                                            v-model="student.teamUuid"
                                                            class="form-control"
                                                            :aria-label="student.teamUuid"
                                                            @change="total_count++"
                                                        >
                                                            <template v-for="team in testObj.teams" :key="team.uuid">
                                                                <option :value="team.uuid">
                                                                    {{ team.name }}
                                                                </option>
                                                            </template>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="student.teamUuid">
                                                        <div class="checkboxField justifyCenter">
                                                            <input v-model="student.isApproved" type="checkbox" @change="processApprovalToggle" />
                                                        </div>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <tr v-if="studentsData.length == 0">
                                            <td colspan="3" style="text-align: center">NO DATA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" :disabled="!canAddStudents ? true : undefined" data-dismiss="modal" @click.prevent="addStudents()">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal danger-modal attendanceClearAnswersModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="clearAnswersModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clearAnswersModal-title" class="modal-title">Clear Answers</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">Clear Answers For</h2>
                        <h2 class="fw-normal">
                            {{ name }}
                        </h2>
                        <br />
                        <p>You are about to clear all</p>
                        <p>answers that were submitted</p>
                        <p>
                            by <b>{{ name }}</b> for this activity.
                        </p>

                        <br />

                        <p>Do you want to proceed?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="clear()">Yes, Clear</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="markAsPresentModal"
            class="modal warning-modal markAsPresentModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="markAsPresentModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="markAsPresentModal-title" class="modal-title">Important</h2>
                    </div>
                    <div class="modal-body">
                        <div class="textAlignCenter">
                            <template v-if="!studentDetails.isPresent">
                                <h2>
                                    Marking
                                    <span class="color-success"> Present </span>
                                    in Team Activity
                                </h2>
                                <br />
                                <img src="/img/warning.png" width="50px" alt="" />
                                <p>Marking a student as present will assign them</p>
                                <p>the same grade as their team members.</p>
                                <br />
                                <p>
                                    Continue to mark
                                    <b>{{ studentDetails.fullname }}</b> as <span class="color-success"><b>Present</b></span
                                    >?
                                </p>
                            </template>

                            <template v-else>
                                <h2>
                                    Marking
                                    <span class="color-danger"> Absent </span>
                                    in Team Activity
                                </h2>
                                <br />
                                <img src="/img/warning.png" width="50px" alt="" />
                                <p>Marking a student as absent will</p>
                                <p>reset their grade to zero.</p>
                                <br />
                                <p>
                                    Continue to mark
                                    <b>{{ studentDetails.fullname }}</b> as <span class="color-danger"><b>Absent</b></span
                                    >?
                                </p>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button
                            v-if="!studentDetails.isPresent"
                            class="btn btn-success"
                            data-dismiss="modal"
                            @click.prevent="markStudentPresent(teamIdx, studentIdx)"
                        >
                            Mark as Present
                        </button>
                        <button v-else class="btn btn-danger" data-dismiss="modal" @click.prevent="markStudentAbsent(teamIdx, studentIdx)">
                            Mark as Absent
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="extendTimeModal-Student"
            class="modal warning-modal extendTimeModal-Student"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="extendTimeModal-Student-title"
        >
            <div class="modal-dialog widthAuto minWidthAuto">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="extendTimeModal-Student-title" class="modal-title">Add Extra Time</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">You are setting extra time for</h2>
                        <br />

                        <dl>
                            <dt>Student Name:</dt>
                            <dd v-for="student in selectedStudents" class="marginTop10">
                                <span class="inlineFlex">
                                    {{ student.fullname }}

                                    <div
                                        v-tooltip="{
                                            content: 'Has extra time',
                                        }"
                                        class="attendanceTag-purple flex align-items marginLeft10"
                                    >
                                        <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                        <p v-if="student.extraTimeValues">
                                            {{ extendedTime(student.extraTimeValues) }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </span>
                            </dd>
                        </dl>

                        <template v-if="testObj.settings.type == 'synchronous'">
                            <p>This will not affect the main Activity Timer.</p>
                            <p>Students with extra time can continue the test even after it expires.</p>
                            <br />
                        </template>
                        <p class="important">
                            <b>But be mindful that ending the test manually</b>
                        </p>
                        <p class="important">
                            <b> will apply to all students, including those with extra time. </b>
                        </p>
                        <br />
                        <label class="control-label" for="durationHours"> Extra Time Accommodation </label>
                        <div class="flex justifyCenter">
                            <div class="paddingRight10">
                                <kr-field
                                    display="HOURS"
                                    class="no-error"
                                    :form="timer_form"
                                    name="durationHours"
                                    :options="{
                                        type: 'number',
                                        min: 0,
                                        dateWidth: true,
                                    }"
                                />
                            </div>
                            <div>
                                <kr-field
                                    display="MINUTES"
                                    class="no-error"
                                    :form="timer_form"
                                    name="durationMinutes"
                                    :options="{
                                        type: 'number',
                                        min: 0,
                                        max: 59,
                                        dateWidth: true,
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" type="button" data-dismiss="modal" @click.prevent="extendTime()">Add</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="attendanceClearExtraTimeModalAttendanceBlock"
            class="modal danger-modal attendanceClearExtraTimeModalAttendanceBlock"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="attendanceClearExtraTimeModalAttendanceBlock-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="attendanceClearExtraTimeModalAttendanceBlock-title" class="modal-title">Remove Extra Time</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop40">You are removing all extra time for</h2>
                        <br />

                        <dl>
                            <dt>Student Name:</dt>
                            <dd v-for="student in selectedStudents" class="marginTop10">
                                <span class="inlineFlex">
                                    {{ student.fullname }}

                                    <div
                                        v-tooltip="{
                                            content: 'Has extra time',
                                        }"
                                        class="attendanceTag-purple flex align-items marginLeft10"
                                    >
                                        <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                        <p v-if="student.extraTimeValues">
                                            {{ extendedTime(student.extraTimeValues) }}
                                        </p>
                                        <p v-else>-</p>
                                    </div>
                                </span>
                            </dd>
                        </dl>

                        <p>This will not affect the main Activity Timer</p>
                        <p>Do you want to continue?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Go Back</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="clearExtraTime()">Yes, remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../../../components/forms/form';
import KrAuth from '../../../../../../../components/auth/auth';
import AttendanceTimerLabel from './attendance-timer-label.vue';
export default {
    components: {
        AttendanceTimerLabel,
    },
    props: ['testObj'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: true,
            studentsData: [],
            approvalToggle: false,
            total_count: 0,
            auth: auth,
            searchData: { q: '', isSearching: false, matched: 0 },
            attendance: [],
            name: '',
            uuid: '',
            teamIdx: '',
            studentIdx: '',
            isPresent: false,
            studentDetails: '',
            timer_form: new KrForm({
                fields: {
                    //durationType:'',//extend,deduct
                    durationDays: 'default!:0',
                    durationHours: 'default!:0',
                    durationMinutes: 'default!:5',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
            }),
            selectedStudents: [],
            attendanceFilterArray: '',
        };
    },
    computed: {
        canAddStudents() {
            for (var i = 0; i < this.studentsData.length; i++) {
                if (this.studentsData[i].isApproved && this.studentsData[i].teamUuid) {
                    return true;
                }
            }
            return false;
        },
        canClearAnswers() {
            if (['scheduled', 'not started'].includes(this.testObj.status) || this.auth.isImpersonation()) {
                return false;
            } else {
                return true;
            }
        },
        isRemoveExtraTimeButtonDisabled() {
            for (var i = 0; i < this.selectedStudents.length; i++) {
                if (!this.selectedStudents[i].isPresent && this.selectedStudents[i].extraTimeValues != null) {
                    return false;
                }
            }
            return true;
        },
        canStartEarly() {
            return (
                ['not started'].includes(this.testObj.status) &&
                this.testObj.earlyStartDurationInMinutes &&
                !this.testObj.expectedEndingTime &&
                !this.testObj.startedEarly &&
                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(this.testObj.type) ||
                    (this.testObj.type == 'application' && this.testObj.others.applicationType == 'individual'))
            );
        },
        canAddExtraTime() {
            if (this.testObj.acceptedNewAnswers.length == 0) {
                return true;
            }
            return false;
        },
    },
    watch: {
        'testObj.attendance': {
            handler: function () {
                this.init();
                this.updateUI();
            },
            deep: true,
        },
        attendanceFilterArray() {
            if (this.attendanceFilterArray.length > 0) {
                var that = this;

                this.attendance = [];
                _.forOwn(this.testObj.attendance.groupedStudents, function (value, key) {
                    that.attendance.push({
                        name: key,
                        members: value.members,
                        uuid: value.uuid,
                    });
                });

                return (this.attendance = this.attendance.map((group) => {
                    group.members = group.members.filter((student) => {
                        switch (this.attendanceFilterArray) {
                            case 'Present':
                                return student.isPresent == true;
                            case 'Absent':
                                return student.isPresent == false;
                            case 'Team Reporter':
                                return student.isReporter == true;
                            case 'Has Extra Time':
                                return student.extraTimeValues != null;
                            case 'Has Early Start':
                                return student.canEarlyStart == 1;
                            case 'Show All':
                                return true;
                            default:
                                return false;
                        }
                    });
                    return group;
                }));
            } else {
                this.init();
            }
        },
    },
    created() {
        if (!this.testObj.teamsNotAllowedToUpdateTeamLeader) {
            this.testObj.teamsNotAllowedToUpdateTeamLeader = [];
        }
        this.init();
    },
    mounted() {
        var that = this;

        this.updateUI();
        this.processEchoListeners();

        $(window).on('resize.updateUI', function () {
            that.updateUI();
        });

        this.$root.$on('fontResized', () => {
            that.updateUI();
        });
    },
    updated() {
        this.updateUI();
    },
    beforeUnmount() {
        $(window).off('resize.updateUI');
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.testUuid != this.testObj.uuid) {
                    return false;
                }
                let idx = _.findIndex(that.attendance, function (o) {
                    return o.uuid == e.teamUuid;
                });
                if (idx != -1) {
                    for (var i = 0; i < that.attendance[idx].members.length; i++) {
                        if (that.attendance[idx].members[i].studentUuid == e.teamLeaderUuid) {
                            that.attendance[idx].members[i].isReporter = true;
                        } else {
                            that.attendance[idx].members[i].isReporter = false;
                        }
                    }
                }
            };
            let c1 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('TeamReporterUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TeamReporterUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                that.testObj.teamsNotAllowedToUpdateTeamLeader = e.teamsNotAllowedToUpdateTeamLeader || [];
                that.testObj.teamsNotAllowedToUpdateTeamLeader.splice();
            };
            let c2 = window.Echo.private(`course.${this.testObj.course.uuid}.teacher`).listen('TeacherTeamReporterToggled', h2);

            this.echoChannels.push({
                channel: c2,
                event: 'TeacherTeamReporterToggled',
                handler: h2,
            });

            let h3 = (e) => {
                that.testObj.allowTeamReporterToMarkAttendance = e.allowTeamReporterToMarkAttendance;
            };

            let c3 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('TeamReporterMarkAttendanceToggled', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'TeamReporterMarkAttendanceToggled',
                handler: h3,
            });

            let h4 = (e) => {
                let teamIndex = -1;
                let studentIndex = -1;

                for (let i = 0; i < that.attendance.length; i++) {
                    const members = that.attendance[i].members;
                    for (let j = 0; j < members.length; j++) {
                        if (members[j].studentUuid === e.userPlacementTestUuid) {
                            teamIndex = i;
                            studentIndex = j;

                            that.attendance[i].members[j].isPresent = e.isPresent;
                            break;
                        }
                    }
                    if (studentIndex !== -1) {
                        break;
                    }
                }
            };

            let c4 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('StudentAttendanceUpdated', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'StudentAttendanceUpdated',
                handler: h4,
            });

            let h5 = (e) => {
                for (let i = 0; i < that.attendance.length; i++) {
                    const members = that.attendance[i].members;
                    for (let j = 0; j < members.length; j++) {
                        let idx = _.findIndex(e, function (o) {
                            return o.uuid == members[j].studentUuid;
                        });
                        if (idx != -1) {
                            members[j].expectedEndingTime = e[idx].expectedEndingTime;
                            members[j].status = e[idx].userPlacementTestStatus;
                            members[j].isExtendedAfterEnded = e[idx].isExtendedAfterEnded;
                            members[j].extraTimeValues = e[idx].extraTimeValues;
                            /*Vue.set(
                                members[j],
                                'expectedEndingTime',
                                e[idx].expectedEndingTime
                            );
                            Vue.set(
                                members[j],
                                'status',
                                e[idx].userPlacementTestStatus
                            );
                            Vue.set(
                                members[j],
                                'isExtendedAfterEnded',
                                e[idx].isExtendedAfterEnded
                            );
                            Vue.set(
                                members[j],
                                'extraTimeValues',
                                e[idx].extraTimeValues
                            );*/
                        }
                    }
                }
            };
            let c5 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('TestStudentTimeExtended', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestStudentTimeExtended',
                handler: h5,
            });
            /*let h6 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (
                                e.userPlacementTestUuid == test.testStudent.uuid
                            ) {
                                Vue.set(
                                    test.testStudent.myProgression,
                                    'userTestStatus',
                                    e.userPlacementTestStatus
                                );
                                return;
                            }
                        }
                    }
                }
            };
            let c6 = window.Echo.private(
                `test.${this.testObj.uuid}.teacher`
            ).listen('StudentTestEndedWithExtraTime', h6);
            this.echoChannels.push({
                channel: h6,
                event: 'StudentTestEndedWithExtraTime',
                handler: h6,
            });*/
        },
        init() {
            var that = this;
            this.attendance = [];
            _.forOwn(this.testObj.attendance.groupedStudents, function (value, key) {
                that.attendance.push({
                    name: key,
                    members: value.members,
                    uuid: value.uuid,
                });
            });
            this.natSort(this.attendance, 'name');
            this.search();
        },
        search() {
            if (this.searchData.q != '') {
                this.searchData.isSearching = true;
                this.searchData.matched = 0;
                for (var i = 0; i < this.attendance.length; i++) {
                    this.attendance[i]._matched = 0;
                    //Vue.set(this.attendance[i], '_matched', 0);
                    for (var j = 0; j < this.attendance[i].members.length; j++) {
                        let student = this.attendance[i].members[j];
                        let haystack = (student.fullname + student.team + '').toLowerCase();
                        let doesContain = haystack.indexOf(this.searchData.q.toLowerCase());
                        if (doesContain != -1) {
                            student._searchDisplay = true;
                            //Vue.set(student, '_searchDisplay', true);
                            this.searchData.matched++;
                            this.attendance[i]._matched++;
                        } else {
                            student._searchDisplay = false;
                            //Vue.set(student, '_searchDisplay', false);
                        }
                    }
                }
            } else {
                this.searchData.isSearching = false;
            }
        },
        freezeUnfreezeTeamLeader(team_idx) {
            var that = this;
            let isFreeze = false;
            let teamsNotAllowedToUpdateTeamLeader = _.cloneDeep(this.testObj.teamsNotAllowedToUpdateTeamLeader);
            if (!this.testObj.teamsNotAllowedToUpdateTeamLeader.includes(this.attendance[team_idx].uuid)) {
                isFreeze = true;
                teamsNotAllowedToUpdateTeamLeader.push(this.attendance[team_idx].uuid);
            } else {
                teamsNotAllowedToUpdateTeamLeader.pop(this.attendance[team_idx].uuid);
            }
            axios
                .post(`tests/${this.testObj.uuid}/allow-update-team-leader`, {
                    teamsNotAllowedToUpdateTeamLeader: teamsNotAllowedToUpdateTeamLeader,
                })
                .then(function (response) {
                    that.testObj.teamsNotAllowedToUpdateTeamLeader = teamsNotAllowedToUpdateTeamLeader;
                    that.testObj.teamsNotAllowedToUpdateTeamLeader.splice();
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Successfully ' + (isFreeze ? 'frozen' : 'unfrozen') + ' team',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        changeTeamLeader(team_idx, student_idx) {
            var currentLeaderUuid;
            for (var i = 0; i < this.attendance[team_idx].members.length; i++) {
                if (this.attendance[team_idx].members[i].isReporter == true) {
                    currentLeaderUuid = this.attendance[team_idx].members[i].uuid;
                    break;
                }
            }

            if (currentLeaderUuid != this.attendance[team_idx].members[student_idx].uuid) {
                var that = this;
                axios
                    .post(`/tests/${this.testObj.uuid}/team-leader`, {
                        userPlacementTestUuid: this.attendance[team_idx].members[student_idx].studentUuid,
                    })
                    .then(function (response) {
                        for (var i = 0; i < that.attendance[team_idx].members.length; i++) {
                            if (i !== student_idx) {
                                that.attendance[team_idx].members[i].isReporter = false;
                            } else {
                                that.attendance[team_idx].members[i].isReporter = true;
                            }
                        }
                        that.attendance.splice();

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Team leader successfully changed',
                        });
                    })
                    .catch(function (errors) {
                        that.$notify({
                            group: 'form',
                            type: 'danger',
                            title: 'Error',
                            text: 'Something went wrong. Please try again later',
                        });
                    });
            }
        },
        getInitials(student) {
            var res = student.displayName.split(' ');
            let initials = '';
            for (var i = 0; i < res.length; i++) {
                if (res[i] !== '') {
                    initials += res[i].charAt(0);
                }
            }
            return initials;
            //return (student.firstname ? student.firstname.charAt(0):'')+(student.lastname?student.lastname.charAt(0):'');
        },
        updateUI() {
            var overViewBlockHeight = $('.dashboardCols .width396px #overViewBlock .x_content').height();

            $('.dashboardCols #attendance-block .x_content').css('height', 'calc(' + overViewBlockHeight + 'px + 40px');

            var overViewBlockHeightMobile = $('.dashboardCols .col-md-6 #overViewBlock .x_content').height();

            $('.dashboardCols .col-md-6 #attendance-block .x_content').css('height', 'calc(' + overViewBlockHeightMobile + 'px + 40px');
            // if (this.testObj.unassignedStudentsInTestLevel.length>0) {
            // 	$(".namesScroll").css("height","calc("+overViewBlockHeight+"px - 24px");
            // }
            // else {
            // 	$(".namesScroll").css("height","calc("+overViewBlockHeight+"px - 24px");
            // }

            var newNamesScrollHeight;

            if (
                this.testObj.unassignedStudentsInTestLevel.length > 0 &&
                !['completed', 'ended'].includes(this.testObj.status) &&
                !this.auth.isImpersonation()
            ) {
                newNamesScrollHeight =
                    overViewBlockHeight -
                    $('.dashboardCols .attendanceView .addStudentsDiv').height() -
                    $('.dashboardCols .attendanceView > div:nth-child(2)').height() -
                    15;
            } else {
                newNamesScrollHeight = overViewBlockHeight - $('.dashboardCols .attendanceView > div:first-child').height() + 10;
            }

            $('.dashboardCols .namesScroll').css('max-height', 'calc(' + newNamesScrollHeight + 'px');

            $('.dashboardCols .namesScroll').css('min-height', '455px');

            var newNamesScrollHeightMobile;

            if (
                this.testObj.unassignedStudentsInTestLevel.length > 0 &&
                !['completed', 'ended'].includes(this.testObj.status) &&
                !this.auth.isImpersonation()
            ) {
                newNamesScrollHeightMobile =
                    overViewBlockHeightMobile -
                    $('.dashboardCols .col-md-6 .attendanceView .addStudentsDiv').height() -
                    $('.dashboardCols .col-md-6 .attendanceView > div:nth-child(2)').height() -
                    15;
            } else {
                newNamesScrollHeightMobile = overViewBlockHeightMobile - $('.dashboardCols .col-md-6 .attendanceView > div:first-child').height() + 10;
            }

            $('.dashboardCols .col-md-6 .namesScroll').css('max-height', 'calc(' + newNamesScrollHeightMobile + 'px');

            $('.dashboardCols .col-md-6 .namesScroll').css('min-height', '455px');
        },
        processApprovalToggle() {
            var shown_approved_count = 0;
            for (var i = 0; i < this.studentsData.length; i++) {
                if (this.studentsData[i].teamUuid && this.studentsData[i].isApproved) {
                    shown_approved_count++;
                }
            }
            if (shown_approved_count == 0) {
                this.approvalToggle = false;
            } else if (this.total_count == shown_approved_count && this.total_count != 0) {
                this.approvalToggle = true;
            }
        },
        toggleStudents() {
            let bool = this.approvalToggle;
            for (var i = 0; i < this.studentsData.length; i++) {
                if (bool) {
                    this.studentsData[i].isApproved = bool;
                } else {
                    this.studentsData[i].isApproved = bool;
                }
            }
        },
        openAddStudentsToTestModal() {
            this.studentsData = [];
            this.total_count = 0;
            for (var i = 0; i < this.testObj.unassignedStudentsInTestLevel.length; i++) {
                this.studentsData.push({
                    user: this.testObj.unassignedStudentsInTestLevel[i],
                    teamUuid: this.testObj.unassignedStudentsInTestLevel[i].teamUuid,
                    isApproved: false,
                });
            }
            $('.addStudentsToTestModal').modal('show');
        },
        addStudents() {
            var that = this;
            let filteredData = [];
            let uuids = [];
            for (var i = 0; i < this.studentsData.length; i++) {
                if (this.studentsData[i].teamUuid && this.studentsData[i].isApproved) {
                    filteredData.push({
                        userUuid: this.studentsData[i].user.uuid,
                        teamUuid: this.studentsData[i].teamUuid,
                    });
                    uuids.push(this.studentsData[i].user.uuid);
                }
            }
            axios
                .post('/tests/' + this.testObj.uuid + '/add-students', {
                    data: filteredData,
                })
                .then(function (response) {
                    for (var i = that.testObj.unassignedStudentsInTestLevel.length - 1; i >= 0; i--) {
                        if (uuids.includes(that.testObj.unassignedStudentsInTestLevel[i].uuid)) {
                            that.testObj.unassignedStudentsInTestLevel.splice(i, 1);
                        }
                    }
                    $('.addStudentsToTestModal').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        getMembersPresent(team) {
            let count = 0;
            for (var i = 0; i < team.members.length; i++) {
                if (team.members[i].isPresent) {
                    count++;
                }
            }
            // return count + '/' + team.members.length + ' PRESENT';
            return count;
        },
        clearAnswers(uuid, name) {
            this.name = name;
            this.uuid = uuid;
            $('.attendanceClearAnswersModal').modal('show');
        },
        clear() {
            var that = this;
            let data = {};
            if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                data.teamUuid = this.uuid;
            } else {
                data.userPlacementTestUuid = this.uuid;
            }
            axios
                .post(`tests/${this.testObj.uuid}/reset-answers`, data)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Answers have been cleared',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        openPresentModal(idx, studentIdx) {
            this.teamIdx = idx;
            this.studentIdx = studentIdx;
            this.studentDetails = this.attendance[idx].members[studentIdx];
            $('.markAsPresentModal').modal('show');
        },
        markStudentPresent(idx, studentIdx) {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/attendance/present`, {
                    userPlacementTestUuid: this.attendance[idx].members[studentIdx].studentUuid,
                })
                .then(function (response) {
                    that.attendance[idx].members[studentIdx].isPresent = true;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: that.attendance[idx].members[studentIdx].fullname + ' is mark as present',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        markStudentAbsent(idx, studentIdx) {
            var that = this;
            axios
                .post(`/tests/${this.testObj.uuid}/attendance/absent`, {
                    userPlacementTestUuid: this.attendance[idx].members[studentIdx].studentUuid,
                })
                .then(function (response) {
                    that.attendance[idx].members[studentIdx].isPresent = false;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: that.attendance[idx].members[studentIdx].fullname + ' is mark as absent',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        updateAllowTeamMarkAttendance() {
            var that = this;
            axios
                .post(`tests/${this.testObj.uuid}/allow-team-reporter-to-mark-attendance`)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: that.testObj.allowTeamReporterToMarkAttendance
                            ? 'Team reporters are allowed to mark attendance'
                            : 'Team reporters are not allowed to mark attendance',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong. Please try again later',
                    });
                });
        },
        isTeamReportPresent(team) {
            for (var i = 0; i < team.members.length; i++) {
                if (team.members[i].isReporter) {
                    if (team.members[i].isPresent) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        openExtendTimeModal(type, student) {
            this.timer_form.model.durationMinutes = 5;
            this.timer_form.model.durationHours = 0;
            if (type == 'individual') {
                this.selectedStudents = [student];
            }
            $('.extendTimeModal-Student').modal('show');
        },
        extendTime() {
            var that = this;
            var data = {
                userPlacementTestUuids: _.map(this.selectedStudents, 'studentUuid'),
                durationDays: 0,
                durationHours: this.timer_form.model.durationHours,
                durationMinutes: this.timer_form.model.durationMinutes,
            };
            axios.post(`/tests/${this.testObj.uuid}/extra-time`, data).then(function (response) {
                const listItems = that.selectedStudents.map((student) => `<li>${student.fullname}</li>`).join('');
                const notificationContent = `Extra time has been applied for <br> <ul>${listItems}</ul>`;

                if (!response.krFormError) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: notificationContent,
                    });
                    that.timer_form.model.durationDays = 0;
                    that.timer_form.model.durationHours = 0;
                    that.timer_form.model.durationMinutes = 5;

                    that.selectedStudents = [];
                    $('.extendTimeModal-Student').modal('hide');
                } else {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to extend test at this moment, please try again later',
                    });
                }
            });
        },
        extendedTime(value) {
            var parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
            const days = parsedValue.durationDays || 0;
            const hours = parsedValue.durationHours || 0;
            const minutes = parsedValue.durationMinutes || 0;
            const timeParts = [];
            if (days > 1) {
                timeParts.push(days + ' days ');
            } else if (days == 1) {
                timeParts.push(days + ' day ');
            }

            if (hours > 1) {
                timeParts.push(hours + ' hrs ');
            } else if (hours == 1) {
                timeParts.push(hours + ' hr ');
            }

            if (minutes > 1) {
                timeParts.push(minutes + ' mins ');
            } else if (minutes == 1) {
                timeParts.push(minutes + ' min ');
            }
            return timeParts.length >= 1 ? timeParts.join(' ') : '';
        },
        clearExtraTimeModal(type, student = null) {
            if (type == 'individual') {
                this.selectedStudents = [student];
            }

            if (this.testObj.status != 'scheduled') {
                for (var i = this.selectedStudents.length - 1; i >= 0; i--) {
                    if (this.selectedStudents[i].isPresent || this.selectedStudents[i].extraTimeValues == null) {
                        this.selectedStudents.splice(i, 1);
                    }
                }
            }

            $('.attendanceClearExtraTimeModalAttendanceBlock').modal('show');
        },
        clearExtraTime() {
            var userPlacementTestUuids = _.map(this.selectedStudents, 'studentUuid');
            var that = this;
            axios
                .delete(`tests/${this.testObj.uuid}/extra-time`, {
                    data: {
                        userPlacementTestUuids: userPlacementTestUuids,
                    },
                })
                .then(function (response) {
                    const listItems = that.selectedStudents.map((student) => `<li>${student.fullname}</li>`).join('');
                    const notificationContent = `Extra time has been reset for <br><ul>${listItems}</ul>`;

                    that.attendance.forEach((team, teamIdx) => {
                        team.members.forEach((student, studentIdx) => {
                            let idx = _.findIndex(that.selectedStudents, function (o) {
                                return o.studentUuid == student.studentUuid;
                            });
                            if (idx != -1) {
                                student.expectedEndingTime = null;
                                student.extraTimeValues = null;
                            }
                        });
                    });
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: notificationContent,
                    });
                    that.selectedStudents = [];
                    $('.attendanceClearExtraTimeModalAttendanceBlock').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'danger',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        clearFilter() {
            this.attendanceFilterArray = [];
        },
        dropdownToggle(button) {
            const buttonPosition = button.getBoundingClientRect().top;
            const tableHeight = $('.attendance-new-table')[0].clientHeight;
            const tablePosition = $('.attendance-new-table')[0].getBoundingClientRect().top;

            if (Math.ceil(tableHeight + tablePosition) - 200 > Math.ceil(buttonPosition)) {
                $(button).closest('.dropdown').removeClass('dropup');
            } else {
                $(button).closest('.dropdown').addClass('dropup');
            }
        },
    },
};
</script>

<style lang="scss">
.attendanceView {
    width: 100%;
}

#attendance-block .x_content {
    padding: 0;
    padding-top: 20px;
    // padding-right: 5px;
    height: calc(100% - 67px);
}

.namesScroll {
    // height: 100px;
    min-height: 200px;
    height: 100%;
    // overflow: hidden;
    // overflow: auto;
    // overflow-y: scroll;
    // padding-right: 15px;
    // padding-left: 6px;
    overflow: auto;
}

@media (max-width: 992px) {
    .namesScroll {
        overflow: visible;
    }
}

.namesScroll div.active .fa-user-check {
    background-image: linear-gradient(129deg, #188e9b, #6dd7c7 98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.namesScroll {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background: #d8d8d8;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #989b9c;
        border-radius: 2px;
    }
}

.profile_image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.badge_profile {
    background: #2a3f54;
    color: white;
    width: 30px;
    height: 30px;
    line-height: 1.2;
    font-size: 18px;
}

.attendanceView .actual-image {
    width: 100%;
    height: 100%;
    background: #222;

    background-position: center;
    background-size: cover;
}

.attendanceView .fa-circle {
    position: absolute;
    right: 0;
    bottom: 0;
}

.attendanceView .fa-circle.online {
    color: #3e7c5b;
}

.attendanceView .fa-circle.offline {
    color: #ca3333;
}

.dashboardCols #attendance-block .x_panel .container {
    height: 100%;
}

.student-modal-table .table > tbody > tr > td {
    vertical-align: middle;
}

.student-modal-table .form-dropdown {
    width: 100%;
}

.student-modal-table .form-dropdown:after {
    top: 10px;
}

.teamContainer {
    &:first-of-type {
        margin-top: 10px;
    }

    &:not(:first-of-type) {
        margin-top: 20px;
    }

    .studentName {
        &:first-of-type {
            margin-top: 10px;
        }

        &:not(:first-of-type) {
            margin-top: 20px;
        }
    }
}
</style>
<style scoped lang="scss">
.addStudentsDiv:hover {
    cursor: pointer;
}
.profile_pic {
    width: auto;
    float: none;
    text-align: center;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: normal;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: normal;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: normal;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-weight: normal;
}

.student_name {
    max-width: 130px;
    word-wrap: break-word;
}

.z-index-10000 {
    z-index: 10000;
}

.z-index-1 {
    z-index: 1;
}
</style>
