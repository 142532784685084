<template>
    <div class="create row flex">
        <div class="profileImg">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }"></kr-file-picture>
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.superusers.create.firstnameLabel')"
                        :form="form"
                        name="firstname"
                        :aria-label="$t('modules.superusers.create.firstnameAriaLabel')"
                        :options="{ placeholder: 'e.g., John', required: true }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.superusers.create.lastnameLabel')"
                        :form="form"
                        name="lastname"
                        :aria-label="$t('modules.superusers.create.lastnameAriaLabel')"
                        :options="{ placeholder: 'e.g., Doe', required: true }"
                    ></kr-field>
                </div>
            </div>
            <template v-if="mode == 'create'">
                <kr-field
                    :display="$t('modules.superusers.create.emailLabel')"
                    :form="form"
                    name="email"
                    :aria-label="$t('modules.superusers.create.emailAriaLabel')"
                    :options="{
                        placeholder: 'e.g. johndoe@example.com',
                        required: true,
                    }"
                ></kr-field>
            </template>
            <template v-else>
                <div class="form-group">
                    <label class="control-label">{{ $t('modules.superusers.create.emailLabel') }}</label>
                    <p class="form-control-static">{{ form.model.email }}</p>
                </div>
            </template>
            <div class="col-xs-12">
                <kr-select
                    :display="$t('modules.superusers.create.accountTypeLabel')"
                    name="accountType"
                    :aria-label="$t('modules.superusers.create.accountTypeAriaLabel')"
                    :form="form"
                    :items="['super user']"
                >
                    <template #option="props">
                        <template v-for="item in props.model">
                            <option :value="item">
                                <template v-if="item == 'super user'"> Superuser </template>
                                <template v-else>
                                    {{ item }}
                                </template>
                            </option>
                        </template>
                    </template>
                </kr-select>
            </div>
            <!-- <template v-if="mode=='create'||mode=='update'">
                <kr-change-password :form="form"></kr-change-password>
            </template>   -->
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'mode'],
};
</script>

<style scoped></style>
