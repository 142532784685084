<template>
    <!--PUT HTML HERE-->
    <div>
        <div class="col-xs-12 right-container">
            <kr-search
                name="accounts_report"
                :url="{ url: './reports/access-codes' }"
                :columns="{
                    bookstoreName: 'display:' + $t('modules.reports.listing.table.bookstoreLabel') + '|sortable:false|width:9%',
                    accessCode: 'display:' + $t('modules.reports.listing.table.accessCodeLabel') + '|sortable:false|width:9%|mobile:hide',
                    dateCreated: 'display:' + $t('modules.reports.listing.table.dateCreatedLabel') + '|sortable:false|width:9%|mobile:hide',
                    validityPeriod: 'display:' + $t('modules.reports.listing.table.validityPeriodLabel') + '|sortable:false|width:8%|mobile:hide',
                    validForRefundDate: 'display:' + $t('modules.reports.listing.table.validForRefundBeforeLabel') + '|sortable:false|width:9%|mobile:hide',
                    toBeActivatedBeforeDate: 'display:' + $t('modules.reports.listing.table.toBeActivatedBeforeLabel') + '|sortable:false|width:9%|mobile:hide',
                    status: 'display:' + $t('modules.reports.listing.table.currentStatusLabel') + '|sortable:false|width:10%',
                    dateUsed: 'display:' + $t('modules.reports.listing.table.activationDateLabel') + '|sortable:false|width:9%|mobile:hide',
                    usedBy: 'display:' + $t('modules.reports.listing.table.accountExpiryDateLabel') + '|sortable:false|width:9%',
                    deactivatedOn: 'display:' + $t('modules.reports.listing.table.deactivationDateLabel') + '|sortable:false|width:9%|mobile:hide',
                    refundedOn: 'display:' + $t('modules.reports.listing.table.refundedOnLabel') + '|sortable:false|width:9%|mobile:hide',
                }"
                :options="{
                    activated_field: true,
                    suspended_field: false,
                    action_column: false,
                }"
                @retrieve-data="getSearchData"
            >
                <template #upper-right>
                    <!-- <div class="hidden-xs hidden-sm">
                        <router-link id="top-add-codes-btn" :to="{name:'accounts.codes.create',params:{account_id:account.uuid}}" tag="button" class="btn btn-primary mainBtn">
                            <i class="fa fa-plus"></i>&nbsp;New Access Codes
                        </router-link>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <router-link id="top-mobile-add-teacher-btn" :to="{name:'accounts.codes.create',params:{account_id:account.uuid}}" tag="button" class="btn btn-primary mainBtn">
                            <i class="fa fa-plus"></i>&nbsp;Access Codes</button>
                        </router-link>
                    </div> -->
                    <button class="btn btn-primary" :aria-label="$t('modules.reports.listing.table.accessCodesReportButtonAriaLabel')" @click="download">
                        <i class="fa fa-download marginRight8" aria-hidden="true" />
                        {{ $t('modules.reports.listing.table.accessCodesReportButtonLabel') }}
                    </button>
                </template>
                <!--  <template slot="top-display-from-to" slot-scope="props">
                    <div class="displayNo">
                        {{props.model.from}} - {{props.model.to}} of {{props.model.meta.paginator.total}}
                    </div>
                </template> -->
                <template #table-title="props">
                    <!-- <th colspan="10"> 
                        <template v-if="searchData.query.isSuspended=='0'">Active</template> 
                        <template v-if="searchData.query.isSuspended=='1'">Suspended</template>
                        <template v-if="searchData.query.isSuspended=='*'">All</template>
                        Accounts ({{props.model.from}} - {{props.model.to}} of {{props.model.meta.paginator.total}})
                    </th> -->
                </template>
                <template #bottom-display-from-to="props">
                    <div class="displayNo">
                        {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>
                <template #cdata="props">
                    <td>
                        {{ props.model.account }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{ props.model.code }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.dateCreated">
                            {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        {{ props.model.validityPeriod }}
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.validForRefundDate">
                            {{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.validForRefundDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.toBeActivatedBeforeDate">
                            {{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.toBeActivatedBeforeDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td>
                        <template v-if="props.model.status == 'unactivated'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Unactivated
                        </template>
                        <template v-else-if="props.model.status == 'activated'">
                            <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Activated
                        </template>
                        <template v-else-if="props.model.status == 'suspended'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended
                        </template>
                        <template v-else-if="props.model.status == 'deactivated'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Deactivated
                        </template>
                        <template v-else-if="props.model.status == 'refunded'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Refunded
                        </template>
                        <template v-else-if="props.model.status == 'expired'">
                            <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Expired
                        </template>
                        <template v-else> - </template>
                        <br />
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.dateUsed">
                            {{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.dateUsed, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.expiryDate">
                            {{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.expiryDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.deactivatedOn">
                            {{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.deactivatedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <template v-if="props.model.refundedOn">
                            {{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(props.model.refundedOn, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </template>
                        <template v-else> - </template>
                    </td>
                </template>
            </kr-search>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isSuspended: '0' },
            },
        };
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: that.$t('modules.reports.listing.breadcrumbLabel'), link: 'report' }],
        });
    },
    methods: {
        download() {
            var that = this;
            axios({
                url: '/reports/access-codes/export?isUsed=*',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ACCESS_CODES_REPORT_' + moment().format('YYYY_MM_DD') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        getSearchData(data) {
            this.searchData = data;
        },
    },
};
</script>

<style scoped>
.link {
    color: #0071be;
    cursor: pointer;
}
</style>
