<template><div></div></template>

<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useDownload } from '@/composables/helpers/useDownload.ts';
const route = useRoute();
const { download } = useDownload();
onMounted(() => {
    download(route.params.filename);
});
</script>
