<template>
    <div class="create row flex">
        <div class="profileImg">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }"></kr-file-picture>
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.teachers.create.firstnameLabel')"
                        :form="form"
                        name="firstname"
                        :aria-label="$t('modules.teachers.create.firstnameAriaLabel')"
                        :options="{ placeholder: 'e.g. John', required: true }"
                    ></kr-field>
                </div>

                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.teachers.create.lastnameLabel')"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{ placeholder: 'e.g. Doe', required: true }"
                    ></kr-field>
                </div>
            </div>
            <div>
                <template v-if="mode == 'create'">
                    <kr-field
                        :display="$t('modules.teachers.create.emailLabel')"
                        :form="form"
                        name="email"
                        :aria-label="$t('modules.teachers.create.lastnameAriaLabel')"
                        :options="{
                            placeholder: 'e.g. johndoe@example.com',
                            required: true,
                        }"
                    ></kr-field>
                </template>
                <template v-else>
                    <div class="form-group">
                        <label class="control-label">{{ $t('modules.teachers.edit.emailLabel') }}</label>
                        <p class="form-control-static">
                            {{ form.model.email }}
                        </p>
                    </div>
                </template>
            </div>
            <kr-toggle
                v-if="auth.user().roleId < 3 && auth.user().uuid != $route.params.id"
                name="canAddTeacher"
                :display="$t('modules.teachers.create.canAddTeacherLabel')"
                :form="form"
                class="marginBottom15 flexSpaceBetween"
                :options="{
                    setWidth: true,
                }"
            >
            </kr-toggle>
            <kr-toggle
                v-if="(auth.user().roleId < 3 || auth.user().canUseExpressSignIn || auth.user().canAddTeacher) && auth.user().uuid != $route.params.id"
                name="canUseExpressSignIn"
                :display="$t('modules.teachers.create.canUseExpressSigninLabel')"
                :form="form"
                class="marginBottom15 flexSpaceBetween"
            >
            </kr-toggle>
            <kr-toggle
                v-if="auth.user().roleId < 3 && auth.user().uuid != $route.params.id"
                name="canViewQuestionBank"
                :display="$t('modules.teachers.create.canViewQuestionBankLabel')"
                :form="form"
                class="marginBottom15 flexSpaceBetween"
            >
            </kr-toggle>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        :display="$t('modules.teachers.create.jobDesignationLabel')"
                        :form="form"
                        name="jobTitle"
                        :aria-label="$t('modules.teachers.create.jobDesignationAriaLabel')"
                        :options="{ placeholder: 'Enter job title' }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-select
                        class="no-error"
                        :display="$t('modules.teachers.create.academicDisciplineLabel')"
                        name="academicDiscipline"
                        :form="form"
                        :items="kr_var.academicDisciplines"
                        item_value_idx="value"
                        item_desc_idx="description"
                    >
                        <template #option="props">
                            <template v-for="option in props.model" :key="option.value">
                                <option :value="option.value">
                                    {{ option.description }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm-6 col-sm-offset-3">
            <kr-select display="Account" :form="form" name="accountUuid" :items="accounts" item_value_idx="value" item_desc_idx="description" v-if="!isSetAccount" @changed="changedAccount">
                <template #option="props">
                    <template v-for="account in props.model">
                        <option :value="account.uuid" >
                            {{account.organisationName+'-'+account.accountName}}
                        </option>
                    </template>
                </template>
            </kr-select>
            <div class="form-group" v-else>
                <label class="control-label text-uppercase">ACCOUNT</label>
                <p class="form-control-static">{{account.organisationName+'-'+account.accountName}}</p>
            </div>
            <kr-toggle :form="form" name="roleId" display="Is Admin" :options="{trueval:2,falseval:3}"></kr-toggle>
            <template v-if="mode=='create'">
                <kr-field display="Email" :form="form" name="email" :options="{placeholder:'e.g. johndoe@example.com'}"></kr-field>
            </template>
            <template v-else>
                <div class="form-group">
                    <label class="control-label">Email</label>
                    <p class="form-control-static">{{form.model.email}}</p>
                </div>
            </template>

        	<kr-field display="First name" :form="form" name="firstname" :options="{placeholder:'e.g. John'}"></kr-field>
        	<kr-field display="Last name" :form="form" name="lastname" :options="{placeholder:'e.g. Doe'}"></kr-field>
            
            <template v-if="mode=='update'">
                <kr-change-password :form="form"></kr-change-password>
            </template>
        </div> -->
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth';
export default {
    props: ['form', 'mode', 'accounts', 'isSetAccount'],
    data() {
        return {
            auth: new KrAuth(),
            account: {},
        };
    },
    created() {
        this.changedAccount();
    },
    methods: {
        changedAccount() {
            let account_uuid = this.form.model.accountUuid; // The value of the selected option
            this.account = _.find(this.accounts, function (o) {
                return o.uuid == account_uuid;
            });
        },
    },
};
</script>
<style scoped></style>
