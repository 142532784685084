<template>
    <div>
        <div :class="{ 'radioField-multipleRadio': !radioButtonVertical, radioField: radioButtonVertical }">
            <fieldset>
                <legend>
                    <template v-if="display">
                        <span v-html="display"></span>
                        <template v-if="required">
                            <span class="visible-hidden">Required</span>
                            <span class="important" aria-hidden="true">*</span>
                        </template>
                    </template>
                    <template v-else>
                        {{ label }}
                    </template>
                </legend>

                <div v-if="radioButtonVertical" class="newRadioField" role="group">
                    <div v-for="(item, idx) in items" :key="idx + '-radio-' + id" class="radioField flex alignFlexStart">
                        <div class="flexOnly alignFlexStart">
                            <input
                                :id="id + '-' + item[item_value_idx]"
                                v-model="value"
                                type="radio"
                                :value="item[item_value_idx]"
                                :disabled="item._disabled"
                                @change="emit"
                                @keydown="form.errors.clear($event.target.name)"
                            />
                            <div class="flexOnly alignFlexStart">
                                <label :for="id + '-' + item[item_value_idx]" class="width100" v-html="item[item_desc_idx]" />
                                <span
                                    class="badge badge-pill badge-info marginLeft5 marginTop5"
                                    :class="{
                                        newBadge: item.isNew,
                                        betaBadge: item.isBeta,
                                        legacyBadge: item.isLegacy || item.isComingSoon,
                                    }"
                                >
                                    <template v-if="item.isNew"> New </template>
                                    <template v-else-if="item.isBeta"> BETA </template>
                                    <template v-else-if="item.isLegacy"> Legacy </template>
                                    <template v-else-if="item.isComingSoon"> Coming Soon </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="flex" role="group">
                    <div v-for="(item, idx) in items" :key="idx + '-radio-' + id" class="radioField flex alignFlexStart marginBottom10 marginRight20">
                        <div class="flex">
                            <input
                                :id="id + '-' + item[item_value_idx]"
                                v-model="value"
                                type="radio"
                                :value="item[item_value_idx]"
                                :disabled="item._disabled"
                                @change="emit"
                                @keydown="form.errors.clear($event.target.name)"
                            />
                            <label :for="id + '-' + item[item_value_idx]" v-html="item[item_desc_idx]"> </label>
                        </div>

                        <span v-if="item.isNew" class="badge badge-pill badge-info marginLeft5 newBadge"> New </span>

                        <span v-if="item.isBeta" class="badge badge-pill badge-info marginLeft5 betaBadge"> BETA </span>

                        <span v-if="item.isLegacy" class="badge badge-pill badge-info marginLeft5 legacyBadge"> Legacy </span>

                        <span v-if="item.isComingSoon" class="badge badge-pill badge-info marginLeft5 legacyBadge"> Coming Soon </span>
                    </div>
                </div>
            </fieldset>
        </div>

        <span v-show="form.errors.has(name)" class="help-block">
            <strong>{{ form.errors.get(name) }}</strong>
        </span>
    </div>
</template>
<script>
export default {
    props: ['display', 'form', 'items', 'name', 'item_value_idx', 'item_desc_idx', 'options', 'label'],
    data() {
        return {
            value: null,
            id: '',
            required: false,
            radioButtonVertical: false,
        };
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                this.value = _.get(this.form.model, this.name, '');
            },
        },
    },
    created() {
        this.value = _.get(this.form.model, this.name);
        this.id = _.get(this, 'options.id', this.name);

        this.init();
    },
    methods: {
        emit(emit) {
            _.set(this.form.model, this.name, this.value);
            this.form.errors.clear(this.name);
            this.$emit('changed');
        },
        init() {
            //required
            var required = _.get(this, 'options.required', false);
            if (required) {
                this.required = required;
            }

            var radioButtonVertical = _.get(this, 'options.radioButtonVertical', false);
            if (radioButtonVertical) {
                this.radioButtonVertical = radioButtonVertical;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.create .flexRadio,
.qnsBuilderQnsOptions .flexRadio {
    margin-left: 0;
    margin-right: 20px;
}

.activityType .flexRadio {
    margin-bottom: 10px;
}

.activityType {
    .radioField {
        & > div {
            align-items: baseline;
        }

        label {
            line-height: 0.9;
        }
    }
}

.radioField-multipleRadio {
    label {
        width: calc(100% - 20px);
        line-height: 1.2;
    }

    input[type='radio'] {
        display: block;
        margin: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px #fff;
        border: solid 2px #222;
        background: #fff;
        margin-right: 5px;
        margin-top: 2px;

        &:hover {
            border: 2px solid #0071be;

            & + label {
                color: #0071be;
            }
        }

        &:checked {
            border-color: #0071be;
            box-shadow: inset 0 0 0 3px #fff;
            background: #0071be;

            &:before {
                content: inherit;
            }

            & + label {
                color: #0071be;
            }
        }
    }
}
</style>
