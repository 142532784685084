<template>
    <div :id="id">
        <div v-if="displayType == 'modal'">
            <!-- Modal for create question -->
            <div id="modality" class="modal createQuestionsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="modality-title">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                                <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            </button>

                            <h2 id="modality-title" class="modal-title">
                                <template v-if="formType == 'create'"> New Question </template>

                                <template v-if="formType == 'edit'"> Edit Question </template>
                            </h2>
                        </div>
                        <div class="modal-body">
                            <!--<template v-if="created_questions.length!=0">
                                <template v-for="(question,idx) in created_questions">
                                    <question-display :question="question" :question_no_label="idx" :options="{canHover:true}" @edit="initializeEditForm(question)" @duplicate="initializeEditForm(question,'create')"></question-display>
                                </template> 
                                <div v-if="viewForm">
                                    <div class="buttonGroup">
                                        <button class="btn btn-success mainBtn" @click.prevent="submit()">
                                            Create Question
                                        </button>
                                        <button class="btn btn-default mainBtn" data-dismiss="modal">
                                            <i class="fa fa-caret-left"></i>&nbsp;Back
                                        </button>
                                    </div>
                                    <question-form :form="form"></question-form>
                                </div>  
                            </template>-->
                            <!--<div style="background: #f6f8fa;padding:30px 20px 0px 20px;">
                                <div class="row">
                                    <div class="buttonGroup">
                                        <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="submit()">
                                            <template v-if="formType=='create'">Create Question</template>
                                            <template v-if="formType=='edit'">Save Changes</template>
                                        </button>
                                        <button class="btn btn-default mainBtn" data-dismiss="modal">
                                            Back
                                        </button>
                                    </div>
                                </div>    
                            </div>-->
                            <question-form
                                :modal_id="id"
                                :form="form"
                                :allowed-types="allowedTypes"
                                :display-is-required-field="showRequiredField ? true : false"
                                :display-add-to-question-bank-field="optionUrl && auth.user().account.version == 'full' ? true : false"
                                :display-can-allow-student-attachments-field="showCanAllowStudentAttachmentsField"
                                :display-can-allow-student-rich-text-responses-field="showCanAllowStudentRichTextResponsesField"
                                :display-is-elaboration-required-field="showIsElaborationRequiredField"
                            ></question-form>
                            <!--<div class="alert alert-warning marginBottom20"  v-if="createQuestionFormErrors">
                                 <i class="fas fa-exclamation-triangle"></i>&nbsp;
                                <div v-html="createQuestionFormErrors"></div>
                            </div>
                             -->

                            <div v-if="!canSend">
                                <div class="alert alert-warning marginBottom20" tabindex="0">
                                    <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true"></i>
                                    <div>
                                        In order to save this question, you must:
                                        <ul v-if="['mcqs', 'mcqm'].includes(form.model.type)" class="marginBottom0 paddingLeft15">
                                            <li>Enter a question stem</li>
                                            <li>provide at least 2 answer choices</li>
                                            <li v-if="form.model.type == 'mcqs' || form.model.type == 'mcqm'">Fill in all answer choices</li>
                                            <li v-if="form.model.type == 'mcqs'">select the correct answer choice</li>
                                            <li v-else>select at least one correct answer choice</li>
                                        </ul>
                                        <ul v-else class="marginBottom0 paddingLeft15">
                                            <li>Enter a question stem</li>
                                        </ul>
                                        <ul v-if="['vsa'].includes(form.model.type)" class="marginBottom0 paddingLeft15">
                                            <li>provide at least one correct answer</li>
                                            <li>ensure there is no duplicate answers on above</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="buttonGroup">
                                <button
                                    class="btn btn-success mainBtn"
                                    :disabled="!canSend ? true : undefined"
                                    :aria-label="formType == 'create' ? 'Save' : 'Update'"
                                    @click.prevent="submit()"
                                >
                                    <template v-if="formType == 'create'">Save</template>
                                    <template v-if="formType == 'edit'">Update</template>
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel" @click="closeAllModal()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal for create question Method -->
            <div class="modal questionsMethodModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="questionsMethodModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 id="questionsMethodModal-title" class="modal-title">NEW QUESTION</h2>
                        </div>
                        <div class="modal-body creation">
                            <div class="hidden-xs hidden-sm" role="radiogroup">
                                <div class="flex">
                                    <button
                                        class="btn btn-default d-block"
                                        :class="{
                                            activated: createType == 'form',
                                        }"
                                        aria-label="Create Manually"
                                        role="radio"
                                        :aria-checked="createType == 'form' ? 'true' : 'false'"
                                        @click="createType = 'form'"
                                    >
                                        <div aria-hidden="true">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </div>
                                        <div class="flex align-items justifyCenter">
                                            <i v-if="createType == 'form'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>
                                            Create Manually
                                        </div>
                                    </button>

                                    <button
                                        class="btn btn-default d-block"
                                        :class="{
                                            activated: createType == 'copy',
                                        }"
                                        aria-label="Copy & Paste"
                                        role="radio"
                                        :aria-checked="createType == 'copy' ? 'true' : 'false'"
                                        @click="createType = 'copy'"
                                    >
                                        <div aria-hidden="true">
                                            <i class="fas fa-clipboard"></i>
                                        </div>
                                        <div class="flex align-items justifyCenter" aria-hidden="true">
                                            <i v-if="createType == 'copy'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>Copy & Paste
                                        </div>
                                    </button>

                                    <button
                                        class="btn btn-default d-block"
                                        :class="{
                                            activated: createType == 'upload',
                                        }"
                                        aria-label="File Upload"
                                        role="radio"
                                        :aria-checked="createType == 'upload' ? 'true' : 'false'"
                                        @click="createType = 'upload'"
                                    >
                                        <div aria-hidden="true">
                                            <i class="fa fa-file"></i>
                                        </div>
                                        <div class="flex align-items justifyCenter" aria-hidden="true">
                                            <i v-if="createType == 'upload'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>File Upload
                                        </div>
                                    </button>
                                    <!--<button
                                        class="btn btn-default d-block"
                                        :class="{
                                            activated: createType == 'key',
                                        }"
                                        aria-label="Create via Key"
                                        role="radio"
                                        :aria-checked="createType == 'key' ? 'true' : 'false'"
                                        @click="createType = 'key'"
                                    >
                                        <div aria-hidden="true">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </div>
                                        <div class="flex align-items justifyCenter">
                                            <i v-if="createType == 'key'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>
                                            Via Key
                                            <span class="marginLeft8 badge badge-pill badge-info betaBadge"> BETA </span>
                                        </div>
                                    </button>-->
                                </div>
                                <div class="buttonGroup">
                                    <button
                                        class="btn btn-primary mainBtn"
                                        :disabled="createType == '' ? true : undefined"
                                        aria-label="Select Method"
                                        @click.prevent="confirmQuestionsCreateSelection()"
                                    >
                                        Select Method
                                    </button>
                                    <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>

                            <div class="hidden-md hidden-lg">
                                <div>
                                    <button class="btn btn-default mainBtn" aria-label="Create New Question" @click="createType = 'form'">
                                        <i v-if="createType == 'form'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>
                                        <i v-else class="fa fa-circle-o marginRight8" aria-hidden="true"></i>
                                        <!-- <i class="fa fa-circle-o"></i> -->
                                        <i class="fa fa-plus marginRight8" aria-hidden="true"></i>Create New Question
                                    </button>
                                    <button class="btn btn-default mainBtn" aria-label="Copy & Paste" @click="createType = 'copy'">
                                        <i v-if="createType == 'copy'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>
                                        <i v-else class="fa fa-circle-o marginRight8" aria-hidden="true"></i>
                                        <i class="fas fa-clipboard marginRight8" aria-hidden="true"></i>Copy & Paste
                                    </button>
                                    <button class="btn btn-default mainBtn" aria-label="File Upload" @click="createType = 'upload'">
                                        <i v-if="createType == 'upload'" class="fa fa-check-circle marginRight8" aria-hidden="true"></i>
                                        <i v-else class="fa fa-circle-o marginRight8" aria-hidden="true"></i>
                                        <i class="fa fa-file marginRight8" aria-hidden="true"></i>File Upload
                                    </button>
                                </div>
                                <div class="buttonGroup">
                                    <button
                                        class="btn btn-primary mainBtn"
                                        :disabled="createType == '' ? true : undefined"
                                        aria-label="Select Method"
                                        @click.prevent="confirmQuestionsCreateSelection()"
                                    >
                                        Select Method<i class="fa fa-caret-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                    <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>

            <!-- Modal for copy and paste -->
            <div class="modal copyPasteQnsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyPasteQnsModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 id="copyPasteQnsModal-title" class="modal-title">Copy & Paste</h2>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-primary align-items marginBottom20" role="alert">
                                <div>
                                    <a
                                        class="btn btn-primary marginRight8"
                                        href="#"
                                        aria-label="Download CSV Template"
                                        @click.prevent="download('intedashboard-question-upload-template.csv')"
                                    >
                                        <i class="fa fa-download marginRight8" aria-hidden="true"></i>
                                        CSV Template
                                    </a>
                                    Question stems are
                                    <span class="important">compulsory</span>, the remaining columns are optional and can be changed later.
                                </div>
                            </div>
                            <div class="textAlignRight">
                                <button @click.prevent="copy_paste_form.model.data = null">
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <kr-field
                                :form="copy_paste_form"
                                name="data"
                                :options="{
                                    placeholder: 'Paste your questions here…',
                                    type: 'text-area',
                                    hasLabel: false,
                                    hideHelpBlockIfNoErrors: true,
                                }"
                            ></kr-field>
                            <div class="buttonGroup marginTop30">
                                <button class="btn btn-success mainBtn" :disabled="!canSendCopyPaste ? true : undefined" @click.prevent="submitCopyPaste">
                                    Upload Questions
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>

            <!-- Modal for upload -->
            <div class="modal uploadQnsModal" tabindex="-1" role="dialog" aria-labelledby="uploadQnsModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                                <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            </button>

                            <h2 id="uploadQnsModal-title" class="modal-title">File Upload</h2>
                        </div>
                        <div class="modal-body">
                            <div class="alert alert-primary" role="alert">
                                <a
                                    class="btn btn-primary marginRight8"
                                    href="#"
                                    aria-label="Download CSV Template"
                                    @click.prevent="download('intedashboard-question-upload-template.csv')"
                                >
                                    <i class="fa fa-download marginRight8" aria-hidden="true"></i>
                                    CSV Template
                                </a>
                                Question stems are
                                <span class="important">compulsory</span>, the remaining columns are optional and can be changed later.
                            </div>
                            <button class="btn btn-primary" aria-label="Select Files to Upload" @click.prevent="openBulkUpload">Select Files to Upload</button>
                            <div class="buttonGroup">
                                <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined">Upload Questions</button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>
            <div class="modal uploadErrorQnsModal" tabindex="-1" role="dialog" aria-labelledby="uploadErrorQnsModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                                <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            </button>

                            <h2 id="uploadErrorQnsModal-title" class="modal-title">File Upload Errors</h2>
                        </div>
                        <div class="modal-body">
                            <div class="buttonGroup">
                                <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Reupload" @click="openBulkUpload">Reupload</button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                </button>
                            </div>
                            <div class="alert alert-danger marginTop10 marginBottom10" role="alert">
                                <i class="fa fa-info-cross marginRight8" aria-hidden="true"></i>
                                <!--You must upload a .CSV file (comma-separated-values file).-->
                                <span v-html="upload_form.errors.get('data')"></span>
                            </div>
                            <div class="buttonGroup">
                                <button class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Reupload">Reupload</button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>
            <!-- Modal for copy and paste -->
            <!-- <div
                class="modal copyPasteQnsModal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                role="dialog"
                aria-labelledby="copyPasteQnsModal-title"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 id="copyPasteQnsModal-title" class="modal-title">Copy & Paste</h2>
                        </div>
                        <div class="modal-body">
                            <div class="buttonGroup marginBottom20">
                                <button
                                    class="btn btn-success mainBtn"
                                    :disabled="!canSendCopyPaste ? true : undefined"
                                    aria-label="Upload Questions"
                                    @click.prevent="submitCopyPaste"
                                >
                                    Upload Questions
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                            <div class="alert alert-primary align-items marginBottom20" role="alert">
                                <div>
                                    <i class="fa fa-info-circle marginRight10" aria-hidden="true"></i>
                                    <a class="btn btn-primary" href="/files/intedashboard-question-upload-template.csv" download aria-label="Click Here">
                                        Click Here
                                    </a>
                                    &nbsp;for csv data format. Question stems are
                                    <span class="important">compulsory</span>, the remaining columns are optional and can be changed later.
                                </div>
                            </div>
                            <kr-field
                                :form="copy_paste_form"
                                name="data"
                                :options="{
                                    placeholder: 'Paste your questions here…',
                                    type: 'text-area',
                                    hasLabel: false,
                                    hideHelpBlockIfNoErrors: true,
                                }"
                            ></kr-field>
                            <div class="buttonGroup marginTop30">
                                <button
                                    class="btn btn-success mainBtn"
                                    :disabled="!canSendCopyPaste ? true : undefined"
                                    aria-label="Upload Questions"
                                    @click.prevent="submitCopyPaste"
                                >
                                    Upload Questions
                                </button>
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div> -->

            <!-- Modal for key -->
            <div id="uploadViaKeyModal" class="modal uploadViaKeyModal" tabindex="-1" role="dialog" aria-labelledby="uploadViaKeyModal-title">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                                <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            </button>

                            <h2 id="uploadQnsModal-title" class="modal-title marginBottom30">Upload Questions Via Key</h2>
                        </div>
                        <div class="modal-body">
                            <kr-field
                                v-if="!keyInfo"
                                :form="keyForm"
                                name="key"
                                :options="{
                                    placeholder: 'Paste your key here…',
                                    type: 'text',
                                    hasLabel: false,
                                    hideHelpBlockIfNoErrors: true,
                                }"
                                @keyup="debouncedGetKeyInfo(keyForm.model.key)"
                            ></kr-field>
                            <div v-else>
                                You are about to import
                                {{ keyInfo.count }} question<template v-if="keyInfo.count > 1">s</template> from {{ keyInfo.created_by }} ({{
                                    keyInfo.account.organisationName
                                }}). It will be added immediately in your question bank
                            </div>
                            <div class="buttonGroup marginTop30">
                                <button
                                    class="btn btn-success mainBtn"
                                    :disabled="!canSendKeyForm ? true : undefined"
                                    aria-label="Upload Questions"
                                    @click="submitKeyForm"
                                >
                                    Ok
                                </button>
                                <button
                                    v-if="keyInfo"
                                    class="btn btn-default mainBtn"
                                    data-dismiss="modal"
                                    aria-label="Back"
                                    @click="
                                        keyInfo = false;
                                        keyForm.model.key = '';
                                    "
                                >
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                </button>
                                <button v-if="!keyInfo" class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                </button>
                            </div>
                        </div>
                        <div class="modal-footer"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import * as filestack from 'filestack-js';

import { useDownload } from '@/composables/helpers/useDownload.ts';

export default {
    components: {
        'question-form': require(`./form-partials/partial.question.vue`).default,
        'question-display': require(`./partials/question-display.vue`).default,
    },
    props: ['options'],
    data() {
        let { download } = useDownload();
        var auth = new KrAuth();
        return {
            download: download,
            auth: auth,
            component_done_loading: false,
            displayType: _.get(this, 'options.displayType', 'modal'),
            formType: 'create',
            createType: '',
            question: {},
            viewForm: true,
            form: new KrForm({
                fields: {
                    question: '',
                    type: '',
                    attachments: 'type!:array',
                    options: 'type!:array',
                    minimum: '',
                    maximum: '',
                    range: '',
                    ratingSettings: 'type!:array',
                    questionIsHTML: '',
                    remarks: '',
                    tags: 'type!:array',
                    topics: 'type!:array',
                    canAllowStudentAttachments: '',
                    canAllowStudentRichTextResponses: '',
                    isRequired: '',
                    //pointsPerQuestion:'',
                    difficultyLevel: '',
                    addToQuestionBank: '',
                    target: 'sendNull!:false|default!:' + _.get(this.options, 'target', ''),
                    retainLastOptionSequence: '',
                    //topic:'',
                    hint: '',
                    explanation: '',
                    vsaSettings: 'type!:object',
                    //correctAnswers: "type!:array",
                    //wrongAnswers: "type!:array",
                    isElaborationRequired: '',
                    referenceQuestionUuid: 'readonly',
                },
                props: {},
                urls: {
                    post: 'questions',
                },
            }),
            copy_paste_form: new KrForm({
                fields: {
                    data: '',
                    //topic:'',
                },
                props: {},
                urls: {
                    post: 'questions/store-pasted',
                },
            }),
            upload_form: new KrForm({
                fields: {
                    data: '',
                    //topic:'',
                },
                props: {},
                urls: {
                    post: 'questions/store-uploaded',
                },
            }),
            keyForm: new KrForm({
                fields: {
                    key: '',
                },
            }),
            keyInfo: false,
            created_questions: [],
            optionUrl: false,
            showRequiredField: false,
            showCanAllowStudentAttachmentsField: true,
            showCanAllowStudentRichTextResponsesField: true,
            showIsElaborationRequiredField: true,
            allowedTypes: '',
            id: _.get(this.options, 'id', 'question-cru'),
            debouncedGetKeyInfo: null,
            //'mcqm','mcqs','openended','rating'
            method: 'put', //for edit
        };
    },
    computed: {
        canSend() {
            if (this.form.model.question && this.form.model.type) {
                if (this.form.model.type == 'mcqs' || this.form.model.type == 'mcqm' || this.form.model.type == 'binary') {
                    let checked = 0;
                    checked = _.filter(this.form.model.options, function (o) {
                        return o.isCorrect == true;
                    }).length;
                    if (checked == 0) {
                        return false;
                        //this.form.errors.append('options.correct',['There should be at least one correct answer']);
                    } else {
                        //this.form.errors.clear('options.correct');
                    }
                    for (var i = 0; i < this.form.model.options.length; i++) {
                        if (!this.form.model.options[i].content) {
                            return false;
                            // this.form.errors.append('options.'+i+'.content',['This is a required field']);
                        }
                    }
                }
                if (this.form.model.type == 'rating') {
                    var ratingSettings = this.form.model.ratingSettings != null ? this.form.model.ratingSettings : [];
                    // for(var i = 0;i<ratingSettings.length;i++){
                    //     if(ratingSettings[i].label!=''){
                    //         return true;
                    //         //this.form.errors.append('labels.'+i+'.label',['This is a required field']);
                    //     }
                    //     /*if(this.form.model.ratingSettings[i].weight!=''){
                    //         return false;
                    //         //this.form.errors.append('labels.'+i+'.weight',['This is a required field']);
                    //     }
                    //     */
                    // }
                    // return false;
                    return true;
                }
                if (this.form.model.type == 'vsa') {
                    let hasCorrectAnswer = false;
                    let noDuplicateAns = true;
                    var that = this;

                    for (var i = 0; i < this.form.model.vsaSettings.correctAnswers.length; i++) {
                        if (this.form.model.vsaSettings.correctAnswers[i] != '') {
                            hasCorrectAnswer = true;
                        }
                    }

                    this.form.model.vsaSettings.correctAnswers.forEach(function (value) {
                        if (value != '' && that.form.model.vsaSettings.wrongAnswers.indexOf(value) != -1) {
                            noDuplicateAns = false;
                        }
                    });

                    if (!hasCorrectAnswer || !noDuplicateAns) {
                        return false;
                    }
                }
                if (this.form.model.type == 'matching') {
                    if (!(this.form.model.options.labels.prompt && this.form.model.options.labels.answer)) {
                        return false;
                    }
                    for (var i = 0; i < this.form.model.options.options.length; i++) {
                        for (var j = 0; j < this.form.model.options.options[i].length; j++) {
                            if (!this.form.model.options.options[i][j].content) {
                                return false;
                            }
                        }
                    }
                }
                if (this.form.model.type == 'sequence') {
                    for (var i = 0; i < this.form.model.options.length; i++) {
                        if (!this.form.model.options[i].content) {
                            return false;
                        }
                    }
                }
                return true;
            }
            return false;
        },
        canSendCopyPaste() {
            if (this.copy_paste_form.model.data) {
                return true;
            }
            return false;
        },
        canSendKeyForm() {
            if (this.keyInfo && this.keyForm.model.key) {
                return true;
            }
            return false;
        },
        createQuestionFormErrors() {
            var ret = '';
            let optionError = false;
            //check missing options
            if (['mcqs', 'mcqm'].includes(this.form.model.type)) {
                let t = _.find(this.form.model.options, function (o) {
                    return o.content == '';
                });
                if (t) {
                    optionError = true;
                }
            }

            if (this.form.model.question == '' && optionError) {
                ret = 'To save changes, please ensure that all required fields (above) are filled up.';
            } else if (this.form.model.question == '') {
                ret = 'In order to save your question, you cannot leave your question stem empty.';
            } else if (optionError) {
                ret = 'In order to save your question, you cannot leave any answer choice(s) empty.';
            }
            return ret;
        },
    },
    watch: {
        options: {
            handler: function () {
                //alert('options changed');
            },
            deep: true,
        },
    },
    created() {
        this.allowedTypes = _.get('options', 'allowedTypes', '');
        this.form.model.addToQuestionBank = this.auth.user().account.version == 'full' ? true : false;
        this.initForm();
        // Prevent bootstrap dialog from blocking focusin
        $(document).on('focusin', function (e) {
            if ($(e.target).closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root').length) {
                e.stopImmediatePropagation();
            }
        });
        this.debouncedGetKeyInfo = _.debounce(function (key) {
            var that = this;
            axios
                .get(`questions/intedashboard-import/${key}`)
                .then(function (response) {
                    that.keyInfo = response.data.data;
                    $('#uploadViaKeyModal').modal('show');
                })
                .catch(function (errors) {
                    that.keyForm.errors.append('key', ['Key not found']);
                });
        }, 500);
    },
    beforeUnmount() {
        let id = this.id != 'question-cru' ? this.id : '';
        Events.off(id + 'open-question-create-select');
        Events.off(id + 'open-question-create-form');
        Events.off(id + 'open-question-create-copy');
        Events.off(id + 'open-question-create-upload');
        Events.off(id + 'open-question-edit');
    },
    mounted() {
        var that = this;
        let id = this.id != 'question-cru' ? this.id : '';
        Events.listen(id + 'open-question-create-select', function () {
            that.openQuestionCreateSelectionModal();
        });
        Events.listen(id + 'open-question-create-form', function (data = {}) {
            that.createType = 'form';
            that.formType = 'create';
            that.optionUrl = data.url ? data.url : false;
            that.showRequiredField = data.showRequiredField ? data.showRequiredField : false;
            that.showCanAllowStudentAttachmentsField = data.showCanAllowStudentAttachmentsField === false ? false : true;
            that.showCanAllowStudentRichTextResponsesField = data.showCanAllowStudentRichTextResponsesField === false ? false : true;
            that.showIsElaborationRequiredField = data.showIsElaborationRequiredField === false ? false : true;
            that.allowedTypes = data.allowedTypes ? data.allowedTypes : [];
            (that.form.model.target = _.get(that.options, 'target', '')), that.initForm();
            that.confirmQuestionsCreateSelection();
        });
        Events.listen(id + 'open-question-create-copy', function (data = {}) {
            that.createType = 'copy';
            that.optionUrl = data.url ? data.url : false;
            that.showRequiredField = data.showRequiredField ? data.showRequiredField : false;
            that.showCanAllowStudentAttachmentsField = data.showCanAllowStudentAttachmentsField === false ? false : true;
            that.showCanAllowStudentRichTextResponsesField = data.showCanAllowStudentRichTextResponsesField === false ? false : true;
            that.allowedTypes = data.allowedTypes ? data.allowedTypes : [];
            (that.form.model.target = _.get(that.options, 'target', '')), that.confirmQuestionsCreateSelection();
        });
        Events.listen(id + 'open-question-create-upload', function (data = {}) {
            that.createType = 'upload';
            that.optionUrl = data.url ? data.url : false;
            that.showRequiredField = data.showRequiredField ? data.showRequiredField : false;
            that.showCanAllowStudentAttachmentsField = data.showCanAllowStudentAttachmentsField === false ? false : true;
            that.showCanAllowStudentRichTextResponsesField = data.showCanAllowStudentRichTextResponsesField === false ? false : true;
            that.allowedTypes = data.allowedTypes ? data.allowedTypes : [];
            (that.form.model.target = _.get(that.options, 'target', '')), that.confirmQuestionsCreateSelection();
        });
        Events.listen(id + 'open-question-edit', function (data) {
            that.optionUrl = data.url ? data.url : false;
            that.showRequiredField = data.showRequiredField ? data.showRequiredField : false;
            that.showCanAllowStudentAttachmentsField = data.showCanAllowStudentAttachmentsField === false ? false : true;
            that.showCanAllowStudentRichTextResponsesField = data.showCanAllowStudentRichTextResponsesField === false ? false : true;
            that.showIsElaborationRequiredField = data.showIsElaborationRequiredField === false ? false : true;
            that.allowedTypes = data.allowedTypes ? data.allowedTypes : [];
            that.method = data.method ? data.method : 'put';
            (that.form.model.target = _.get(that.options, 'target', '')), that.initForm();
            that.openQuestionEditModal(data.question);
        });
    },
    methods: {
        addOption(options = {}) {
            let length = this.form.model.options.length == 0 ? 1 : this.form.model.options.length + 1;
            let key = String.fromCharCode(65 + length - 1);
            this.form.model.options.push({
                key: key,
                //tags: [],
                content: '',
                remarks: '',
                isCorrect: options.isCorrect ? true : false,
                contentIsHTML: true,
            });
        },
        openBulkUpload() {
            var client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            client
                .picker({
                    accept: [
                        'text/csv',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/tab-separated-values',
                    ],
                    maxFiles: 1,
                    minFiles: 1,
                    uploadInBackground: false,
                    onFileUploadFinished: function (file) {
                        $('#loading').removeClass('hide').addClass('show');
                        that.upload_form.model.data = file;
                        let url = that.optionUrl ? that.optionUrl : 'questions/store-uploaded';
                        that.$papa.parse(file.url, {
                            download: true,
                            complete: function (results, file) {
                                if (results.errors.length == 0) {
                                    that.upload_form.post(url).then(function (response) {
                                        //console.log(response);
                                        if (!response.krFormError) {
                                            that.$emit('created', response.data.data);
                                        } else {
                                            $('#' + that.id + ' .uploadErrorQnsModal').modal('show');
                                        }
                                        $('#loading').removeClass('show').addClass('hide');
                                    });
                                } else {
                                    //console.log(results);
                                    that.upload_form.errors.append('data', ['Malformed CSV. Please recheck.']);
                                    $('#loading').removeClass('show').addClass('hide');
                                    $('#' + that.id + ' .uploadErrorQnsModal').modal('show');
                                }
                            },
                        });
                    },
                })
                .open();
        },
        openQuestionEditModal(question) {
            this.initializeEditForm(question);
            $('#' + this.id + ' .createQuestionsModal').modal('show');
        },
        confirmQuestionsCreateSelection() {
            this.keyInfo = false;
            this.keyForm.model.key = '';
            if (($('#' + this.id + ' .questionsMethodModal').data('bs.modal') || {}).isShown) {
                $('#' + this.id + ' .questionsMethodModal').modal('hide');
            } else {
                if (this.createType == 'form') {
                    $('#' + this.id + ' .createQuestionsModal').modal('show');
                } else if (this.createType == 'copy') {
                    $('#' + this.id + ' .copyPasteQnsModal').modal('show');
                } else if (this.createType == 'upload') {
                    this.openBulkUpload();
                    // $('#uploadQnsModal').modal('show');
                } else if (this.createType == 'key') {
                    $('#' + this.id + ' .uploadViaKeyModal').modal('show');
                    // $('#uploadQnsModal').modal('show');
                }
            }
            if (this.createType == 'form') {
                $('#' + this.id + ' .createQuestionsModal').modal('show');
            } else if (this.createType == 'copy') {
                $('#' + this.id + ' .copyPasteQnsModal').modal('show');
            } else if (this.createType == 'upload') {
                this.openBulkUpload();
            } else if (this.createType == 'key') {
                $('#' + this.id + ' .uploadViaKeyModal').modal('show');
                // $('#uploadQnsModal').modal('show');
            }
        },
        openQuestionCreateSelectionModal() {
            this.initForm();
            this.formType = 'create';
            this.createType = 'form';
            $('#' + this.id + ' .questionsMethodModal').modal('show');
        },
        initForm() {
            this.form.model.question = '';
            this.form.model.type = '';
            this.form.model.attachments = [];
            this.form.model.options = [];
            this.form.model.ratingSettings = [];
            this.form.model.tags = [];
            this.form.model.topics = [];
            this.form.model.questionIsHTML = true;
            this.form.model.canAllowStudentAttachments = true;
            this.form.model.canAllowStudentRichTextResponses = true;
            this.form.model.isRequired = true;
            this.form.model.minimum = 1;
            this.form.model.maximum = 100;
            //this.form.model.pointsPerQuestion = 1;
            this.form.model.difficultyLevel = 1;
            //this.form.model.topic = "";
            this.form.model.addToQuestionBank = this.auth.user().account.version == 'full' ? true : false;
            this.form.model.retainLastOptionSequence = false;
            this.form.model.remarks = '';
            this.form.model.hint = '';
            this.form.model.explanation = '';
            //this.form.model.correctAnswers = [];
            //this.form.model.wrongAnswers = [];
            this.form.model.isElaborationRequired = false;
            this.form.model.vsaSettings = {
                label: '',
                placeholder: '',
                correctAnswers: [],
                wrongAnswers: [],
            };
            /*Vue.set(this.form.model, `vsaSettings`, {
                label: '',
                placeholder: '',
                correctAnswers: [],
                wrongAnswers: [],
            });*/
            this.form.model.referenceQuestionUuid = '';
        },
        initializeEditForm(question, formType = 'edit') {
            this.question = _.cloneDeep(question);

            this.formType = formType;
            this.initForm();
            this.form.setModel(question);
            this.form.model.addToQuestionBank = this.form.model.referenceQuestionUuid ? true : false;
            if (this.form.model.hint == null) {
                this.form.model.hint = '';
            }
            if (this.form.model.explanation == null) {
                this.form.model.explanation = '';
            }
            this.form.model.isRequired = this.form.model.isRequired == null ? false : this.form.model.isRequired;
            if (this.form.model.tags == null) {
                this.form.model.tags = [];
            }
            if (this.form.model.topics == null) {
                this.form.model.topics = [];
            }
            if (this.form.model.attachments == null) {
                this.form.model.attachments = [];
            }
            if (this.form.model.type == 'rating') {
                this.form.model.range = (this.form.model.ratingSettings != null ? this.form.model.ratingSettings : []).length;
            }
            if (!this.form.model.options || (this.form.model.options && this.form.model.options.length == 0)) {
                this.addOption();
                this.addOption();
            }
            if (['mcqs', 'mcqm'].includes(this.form.model.type)) {
                let length = this.form.model.options.length;
                this.form.model.retainLastOptionSequence = _.get(this.form.model, 'options.' + (length - 1) + '.retainLastOptionSequence', false);
            }
            if (this.form.model.type == 'openended') {
                this.form.model.minimum = this.question.minimum != null ? this.question.minimum : 1;
                this.form.model.maximum = this.question.maximum != null ? this.question.maximum : 100;
            }
            if (this.form.model.type == 'vsa') {
                this.form.model.vsaSettings.label = _.get(this.question, 'vsaSettings.label', '');
                this.form.model.vsaSettings.correctAnswers = _.get(this.question, 'vsaSettings.correctAnswers', []);
                this.form.model.vsaSettings.wrongAnswers = _.get(this.question, 'vsaSettings.wrongAnswers', []);
                if (this.form.model.vsaSettings.wrongAnswers == null) {
                    this.form.model.vsaSettings.wrongAnswers = [''];
                }
            }
            this.viewForm = true;
        },
        submit(viewFormAfter = true) {
            var that = this;
            let url = '';
            if (['mcqs', 'mcqm'].includes(this.form.model.type)) {
                let length = this.form.model.options.length;
                this.form.model.options[length - 1].retainLastOptionSequence = this.form.model.retainLastOptionSequence;
            }
            if (this.form.model.type == 'vsa') {
                for (var i = this.form.model.vsaSettings.correctAnswers.length - 1; i >= 0; i--) {
                    if (this.form.model.vsaSettings.correctAnswers[i] == '') {
                        this.form.model.vsaSettings.correctAnswers.splice(i, 1);
                    }
                }
                for (var i = this.form.model.vsaSettings.wrongAnswers.length - 1; i >= 0; i--) {
                    if (this.form.model.vsaSettings.wrongAnswers[i] == '') {
                        this.form.model.vsaSettings.wrongAnswers.splice(i, 1);
                    }
                }
                if (this.form.model.vsaSettings.wrongAnswers.length == 0) {
                    this.form.model.vsaSettings.wrongAnswers = null;
                }
            }
            if (this.formType == 'create') {
                url = this.optionUrl ? this.optionUrl : '/questions';
                this.form.post(url).then(function (response) {
                    if (!response.krFormError) {
                        let idx = _.findIndex(that.created_questions, function (o) {
                            return response.data.data.uuid == o.uuid;
                        });
                        if (idx == -1) {
                            that.created_questions.push(response.data.data);
                        } else {
                            that.question = null;
                            that.created_questions[idx] = response.data.data;
                            /*Vue.set(
                                that.created_questions,
                                idx,
                                response.data.data
                            );*/
                        }
                        that.formType = 'create';
                        that.createType = 'create';
                        that.viewForm = viewFormAfter;
                        that.initForm();
                        if (that.displayType == 'modal') {
                            $('#' + that.id + ' .createQuestionsModal').modal('hide');
                        }
                        that.$emit('created', response.data.data);
                    }
                });
            } else {
                url = this.optionUrl ? this.optionUrl : '/questions/' + this.question.uuid;
                this.form.submit(this.method, url).then(function (response) {
                    if (!response.krFormError) {
                        that.formType = 'edit';
                        that.viewForm = viewFormAfter;
                        //that.initializeEditForm(response.data.data);
                        if (that.displayType == 'modal') {
                            $('#' + that.id + ' .createQuestionsModal').modal('hide');
                        }
                        let q = _.cloneDeep(that.form.model);
                        q.uuid = that.question.uuid;
                        that.$emit('updated', response.data.data ? response.data.data : q);
                    }
                });
            }
        },
        submitCopyPaste() {
            var that = this;
            let url = that.optionUrl ? that.optionUrl : 'questions/store-pasted';
            let str = this.copy_paste_form.model.data;
            str = str.replace(/[\""]/g, '\\"');
            this.$papa.parse(str, {
                complete: function (results, file) {
                    if (results.errors.length == 0) {
                        that.copy_paste_form.post(url).then(function (response) {
                            if (!response.krFormError) {
                                that.$emit('created', response.data.data);
                                if (that.displayType == 'modal') {
                                    $('#' + that.id + ' .copyPasteQnsModal').modal('hide');
                                }
                            }
                        });
                    } else {
                        //console.log(results);
                        that.copy_paste_form.errors.append('data', ['Malformed CSV. Please recheck.']);
                    }
                },
            });
        },
        submitKeyForm() {
            var that = this;
            axios
                .post(`questions/intedashboard-import`, {
                    key: this.keyForm.model.key,
                })
                .then(function (response) {
                    that.$emit('created', response.data.data);
                    $('#uploadViaKeyModal').modal('hide');
                })
                .catch(function (errors) {
                    that.keyForm.errors.append('key', ['Something went wrong please try again later']);
                });
        },
    },
};
</script>

<style scoped>
.questionsMethodModal .modal-dialog {
    min-width: 80%;
}

.createQuestionsModal .modal-body {
    background: #f6f8fa;
}

.questionsMethodModal .flex .btn {
    padding: 25px;
    width: 275px;
    font-size: 16px;
}

.questionsMethodModal .flex .btn div:nth-child(2) {
    margin-top: 10px;
}

.questionsMethodModal .buttonGroup {
    margin-top: 30px;
}

.questionsMethodModal div:first-child > .btn.btn-default:hover,
.questionsMethodModal div:first-child > .btn.btn-default:active,
.questionsMethodModal div:first-child > .btn.btn-default:focus {
    color: #0071be;
    border: 1px solid #0071be;
}

.questionsMethodModal div:first-child > .btn.btn-default:hover i,
.questionsMethodModal div:first-child > .btn.btn-default:active i,
.questionsMethodModal div:first-child > .btn.btn-default:focus i {
    color: #0071be;
}

.questionsMethodModal div:first-child > .btn .fa-check-circle {
    color: #0071be;
}

@media (min-width: 992px) {
    #questionsMethodModal div:first-child > .btn i:nth-child(2) {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .questionsMethodModal .flex i {
        color: #717171;
        font-size: 24px;
    }
    .questionsMethodModal .btn {
        width: 100%;
        margin-left: 0;
    }

    .questionsMethodModal .buttonGroup {
        display: block;
    }

    .questionsMethodModal .btn {
        margin-top: 20px;
    }

    .questionsMethodModal .btn:first-child {
        margin-top: 0;
    }

    .questionsMethodModal div:first-child > .btn {
        text-align: left;
    }

    .questionsMethodModal div:first-child > .btn i:first-child {
        font-size: 18px;
    }

    .questionsMethodModal div:first-child > .btn .fa-circle-o {
        color: #d8d8d8;
    }
}
</style>
