import { createI18n } from 'vue-i18n';
import svSe from './lang/sv-SE.json';
import enUs from './lang/en-US.json';
import fil from './lang/fil.json';
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || 'enUs',
    fallbackLocale: '',
    messages: {
        enUs,
        fil,
        svSe,
    },
});

export default i18n;
