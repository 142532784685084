<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container module-activities-view">
        <div v-if="!['signin', 'lms'].includes(tab)" class="stepsNaviFixed nav-tabsContainer">
            <div class="links">
                <ul>
                    <li :class="{ active: tab == 'module' }" @click="tab = 'module'">
                        <a class="nav-link" data-toggle="tab" href="#modulesActivities" aria-label="Modules & Activities"> MODULES & ACTIVITIES </a>
                    </li>
                    <li :class="{ active: tab == 'student' }" @click="tab = 'student'">
                        <a class="nav-link" data-toggle="tab" href="#students" aria-label="Students"> STUDENTS </a>
                    </li>

                    <!-- <li :class="{'active':tab=='lms'}" @click="tab='lms'">
						<a class="nav-link" data-toggle="tab" href="#lmsIntegration" aria-label="LMS Integration">LMS INTEGRATION</a>
					</li> -->

                    <li :class="{ active: tab == 'downloadables' }" @click="tab = 'downloadables'">
                        <a class="nav-link" data-toggle="tab" href="#downloadables" aria-label="Grades"> GRADES </a>
                    </li>

                    <!-- <li
						v-if="
							(hasWritePrivilege(course) === 1 && auth.user().canUseExpressSignIn) || course.isSelfSignIn
						"
						:class="{ active: tab == 'signin' }"
						@click="tab = 'signin'"
					>
						<a class="nav-link" data-toggle="tab" href="#signin" aria-label="Express Sign-in Url"
							>EXPRESS SIGN-IN-URL</a
						>
					</li> -->

                    <li :class="{ active: tab == 'overview' }" @click="tab = 'overview'">
                        <a class="nav-link" data-toggle="tab" href="#courseOverview" aria-label="Settings"> SETTINGS </a>
                    </li>
                    <!--<li :class="{ active: tab == 'permission' }" @click="tab = 'permission'">
						<a class="nav-link" data-toggle="tab" href="#permissions" aria-label="Permissions"
							>COLLABORATORS</a
						>
					</li>
					<li :class="{ active: tab == 'enrollment' }" @click="tab = 'enrollment'">
						<a class="nav-link" data-toggle="tab" href="#enrollment" aria-label="Enrollment Settings"
							>Enrollment Settings</a
						>
					</li>
					-->
                    <!--<li :class="{'active':tab=='analytics'}" v-if="APP_ENV!='production'">
						<a class="nav-link" data-toggle="tab" href="#analytics">ANALYTICS</a>
					</li>-->
                    <!--v-if="!(course.accountPaymentMethod=='Student'&&course.accountType=='Paid')"-->
                </ul>
            </div>
        </div>

        <div class="tab-content">
            <div v-if="tab == 'module'" class="pageTitle">
                <h1>Modules & Activities</h1>
                <p class="col-md-6">
                    <a href="https://help.intedashboard.com/how-do-i-organize-my-modules" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Organize your course lesson plans into modules
                    </a>
                    and
                    <a href="https://help.intedashboard.com/how-do-i-create-an-activity" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        create activities
                    </a>
                    within the modules. You can
                    <a href="https://help.intedashboard.com/how-do-i-create-a-module" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        create as many modules
                    </a>
                    and activities you need.
                </p>
            </div>
            <div v-else-if="tab == 'student'" class="pageTitle">
                <template v-if="!updateManageValue">
                    <h1>Students</h1>
                    <p class="col-md-6">
                        <a href="https://help.intedashboard.com/how-do-i-enroll-learners-to-a-course" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            Enroll
                        </a>
                        and manage students here. You can create "<a
                            href="https://help.intedashboard.com/how-do-i-run-activities-across-multiple-sections"
                            target="newTab"
                        >
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            sections</a
                        >" to group students who are going to take this course together.
                    </p>
                </template>
                <template v-else>
                    <div class="flex">
                        <h1>Student Accommodations</h1>
                        <i class="fas fa-universal-access color-purple fs-24px marginLeft8" aria-hidden="true" />
                    </div>
                    <p class="col-md-6">
                        Manage default accommodations settings here.
                        <br />
                        When you create a timed individual activity, students with these accommodations will appear on the Publish settings.
                    </p>
                </template>
            </div>
            <div v-else-if="tab == 'downloadables'" class="pageTitle">
                <h1>Grades</h1>
                <p class="col-md-6">
                    Download any triggered activity-level or course-level grades here.
                    <a href="https://help.intedashboard.com/how-do-i-download-my-activitys-grades" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Trigger an activity-level grades export
                    </a>
                    or
                    <a href="https://help.intedashboard.com/where-can-i-download-the-full-course-grades" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Trigger the course-level grades export </a
                    >.
                </p>
            </div>
            <div v-else-if="tab == 'overview'" class="pageTitle">
                <h1>Settings</h1>
                <p class="col-md-6">
                    Manage the Course Details,
                    <a href="https://help.intedashboard.com/how-do-i-share-access-with-other-instructors-in-my-course" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" /> Add Course Collaborators
                    </a>
                    and Course Enrollment Settings here.
                </p>
            </div>
            <div v-if="tab == 'module'" id="modulesActivities" class="tab-pane fade in active">
                <kr-panel :with-footer="false" :with-title="false" class="new-panel">
                    <template #content>
                        <div class="flex">
                            <div v-if="sectionList.length > 1" class="x_side_bar">
                                <div class="list-group">
                                    <label>Sections</label>
                                    <template v-for="(model, idx) in sectionList" :key="model.uuid + '_section_list'">
                                        <a
                                            href="#"
                                            class="list-group-item list-group-item-action"
                                            :class="{
                                                active: model.uuid == query_fields.sectionUuid,
                                            }"
                                            @click="
                                                store.setCurrentSection({
                                                    courseUuid: course.uuid,
                                                    sectionUuid: model.uuid,
                                                });
                                                query_fields.sectionUuid = model.uuid;
                                                searchModule();
                                            "
                                        >
                                            Section {{ model.name }}
                                        </a>
                                    </template>
                                </div>
                            </div>
                            <div class="x_with_side_bar" :class="{ 'col-12': sectionList.length <= 1 }">
                                <kr-search
                                    name="modules_listing"
                                    :url="{
                                        url: '/courses/' + course.uuid + '/modules',
                                    }"
                                    :columns="{
                                        name: 'display:MODULE|sortable:false|width:30%',
                                        startsFrom: 'display:STARTS FROM|sortable:false|width:15%|mobile:hide',
                                        endsOn: 'display:ENDS ON |sortable:false|width:15%|mobile:hide',
                                    }"
                                    :options="{
                                        archived_field: false,
                                        search_field: false,
                                        thead_title: false,
                                        thead: false,
                                        sort: '',
                                        order: '',
                                        page_number: false,
                                        query_fields: query_fields,
                                    }"
                                    @retrieve-data="processRetrieveModulesData"
                                >
                                    <template #lower-left>
                                        <div class="flexOnly align-items">
                                            <div class="viewFilter">
                                                <div class="form-group displayContent">
                                                    <div class="form-dropdown viewFilter marginLeft0">
                                                        <select
                                                            v-model="query_fields.isArchived"
                                                            class="form-control"
                                                            :aria-label="
                                                                query_fields.isArchived == 0
                                                                    ? 'Show active modules and activities'
                                                                    : 'Show archived modules and activities'
                                                            "
                                                            @change="searchModule"
                                                        >
                                                            <option value="0">Active</option>
                                                            <option value="1">Archived</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="modules.length > 0" class="marginLeft20">
                                                <div class="checkboxField flexOnly alignFlexStart justifyCenter">
                                                    <input
                                                        id="selectAllModules"
                                                        v-model="selectAllModules"
                                                        type="checkbox"
                                                        aria-label="Select All Modules"
                                                        @change="toggleModules"
                                                    />
                                                    <label for="selectAllModules"> Select All Modules </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #upper-right>
                                        <div
                                            v-tooltip="{
                                                content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="marginRight10"
                                            :class="{
                                                'p-0-i': hasWritePrivilege(course),
                                            }"
                                        >
                                            <router-link
                                                v-slot="{ navigate }"
                                                :to="{
                                                    name: 'courses.modules.create',
                                                    params: {
                                                        course_id: course.uuid,
                                                    },
                                                }"
                                                custom
                                            >
                                                <button
                                                    class="btn btn-success"
                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                    @click="navigate"
                                                >
                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />New Module
                                                </button>
                                            </router-link>
                                        </div>

                                        <div v-if="hasWritePrivilege(course)" class="dropdown">
                                            <button
                                                id="viewModuleDropdownMenuButton"
                                                v-tooltip="{
                                                    content: selectedModules.length == 0 ? 'You need to select at least 1 module to enable the button' : '',
                                                }"
                                                class="btn btn-danger mainBtn"
                                                :class="{
                                                    disabled: hasWritePrivilege(course) == -1 || selectedModules.length == 0,
                                                }"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                :aria-label="
                                                    hasWritePrivilege(course) == -1 || selectedModules.length == 0 ? 'More Actions disabled' : 'More Actions'
                                                "
                                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                                            >
                                                More Actions<i
                                                    :class="{
                                                        'fa fa-caret-up': isMoreOptionsOpened,
                                                        'fa fa-caret-down': !isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            v-tooltip="'Copy'"
                                                            class="primary-state"
                                                            href="#"
                                                            aria-label="Copy"
                                                            @click.prevent="openCopyModuleConfirmationModal()"
                                                        >
                                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy </span>

                                                            <!-- <span>⇧⌘D</span> -->
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                    <template #ndata="props">
                                        <tr>
                                            <td :colspan="props.model.total_column_count" class="padding0 borderNone">
                                                <template v-if="moduleSearchData.query.isArchived != '0'"> You have not archived any modules. </template>
                                                <template v-else>
                                                    No modules yet,
                                                    <template v-if="hasWritePrivilege(course) > 0">
                                                        <router-link
                                                            v-if="hasWritePrivilege(course)"
                                                            :to="{
                                                                name: 'courses.modules.create',
                                                                params: {
                                                                    course_id: course.uuid,
                                                                },
                                                            }"
                                                            tag="a"
                                                            class="link"
                                                        >
                                                            create module here.
                                                        </router-link>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                    <template #adata="props">
                                        <template v-if="modules.length == 0">
                                            <tr>
                                                <td :colspan="props.model.total_column_count" class="padding0 borderNone">
                                                    No modules yet,
                                                    <template v-if="hasWritePrivilege(course) > 0">
                                                        <router-link
                                                            v-if="hasWritePrivilege(course)"
                                                            :to="{
                                                                name: 'courses.modules.create',
                                                                params: {
                                                                    course_id: course.uuid,
                                                                },
                                                            }"
                                                            tag="a"
                                                            class="link"
                                                            aria-label="Click here"
                                                        >
                                                            create module here.
                                                        </router-link>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>

                                        <template v-for="(module, idx) in modules" :key="idx + 'modules'">
                                            <tr
                                                colspan="12"
                                                class="subContentTable moduleRow"
                                                :draggable="true"
                                                @dragover="allowDropToModule"
                                                @dragstart="dragModule($event, idx)"
                                                @drop="dropToModule($event, idx)"
                                                @dragleave="leaveDragFromModule"
                                                @dragend="
                                                    stopScroll = true;
                                                    currentDraggedItem = null;
                                                "
                                            >
                                                <td colspan="12" class="subContent">
                                                    <table class="table moduleTable">
                                                        <thead :id="module.uuid" tabindex="-1" class="subContent-header">
                                                            <tr>
                                                                <th colspan="12">
                                                                    <div class="flexSpaceBetween hidden-xs hidden-sm">
                                                                        <div class="flex">
                                                                            <div class="flex">
                                                                                <i
                                                                                    v-tooltip="{
                                                                                        content: 'Drag Me!',
                                                                                        show: isOpenModule[idx],
                                                                                        trigger: 'manual',
                                                                                    }"
                                                                                    class="fas fa-grip-vertical marginRight8 paddingTop2"
                                                                                    aria-hidden="true"
                                                                                    @mouseover="isOpenModule[idx] = true"
                                                                                    @mouseleave="isOpenModule[idx] = false"
                                                                                    @mousedown="isOpenModule[idx] = false"
                                                                                />
                                                                                <span class="checkboxField marginRight8">
                                                                                    <input
                                                                                        :id="'module uuid_' + module.uuid"
                                                                                        v-model="selectedModules"
                                                                                        :name="module.uuid"
                                                                                        type="checkbox"
                                                                                        class="checkbox-field"
                                                                                        :value="module.uuid"
                                                                                        :aria-label="
                                                                                            activitiesCount(module) <= 1
                                                                                                ? 'course name ' +
                                                                                                  course.name +
                                                                                                  'module name ' +
                                                                                                  module.name +
                                                                                                  ' (' +
                                                                                                  activitiesCount(module) +
                                                                                                  'activity)'
                                                                                                : 'course name ' +
                                                                                                  course.name +
                                                                                                  'module name ' +
                                                                                                  module.name +
                                                                                                  ' (' +
                                                                                                  activitiesCount(module) +
                                                                                                  'activities)'
                                                                                        "
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <label :for="'module uuid_' + module.uuid">
                                                                                    {{ module.name }}
                                                                                    (<span
                                                                                        v-text="
                                                                                            activitiesCount(module) <= 1
                                                                                                ? activitiesCount(module) + ' activity'
                                                                                                : activitiesCount(module) + ' activities'
                                                                                        "
                                                                                    />)
                                                                                </label>
                                                                                <div>
                                                                                    <small class="text-muted flexOnly alignBaseline">
                                                                                        <i class="fas fa-calendar-alt marginRight8" aria-hidden="true" />
                                                                                        {{ convertToReadableDate(module.startDate, 'DD MMM YYYY').date }}
                                                                                        ({{
                                                                                            convertToReadableDate(module.startDate, 'DD MMM YYYY')
                                                                                                .current_timezone
                                                                                        }}) -
                                                                                        <i
                                                                                            class="fas fa-calendar-alt marginLeft5 marginRight8"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        {{ convertToReadableDate(module.endDate, 'DD MMM YYYY').date }}
                                                                                        ({{
                                                                                            convertToReadableDate(module.endDate, 'DD MMM YYYY')
                                                                                                .current_timezone
                                                                                        }})
                                                                                    </small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="buttonGroupInline">
                                                                            <router-link
                                                                                v-if="!module.isArchived && hasWritePrivilege(course)"
                                                                                v-slot="{ navigate }"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                                                                }"
                                                                                :to="{
                                                                                    name: 'courses.modules.activities.create',
                                                                                    params: {
                                                                                        course_id: course.uuid,
                                                                                        module_id: module.uuid,
                                                                                    },
                                                                                }"
                                                                                custom
                                                                            >
                                                                                <button
                                                                                    class="btn btn-success"
                                                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                    @click="navigate"
                                                                                >
                                                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />New Activity
                                                                                </button>
                                                                            </router-link>
                                                                            <router-link
                                                                                v-if="hasWritePrivilege(course)"
                                                                                v-slot="{ navigate }"
                                                                                v-tooltip="{
                                                                                    content:
                                                                                        hasWritePrivilege(course) == -1
                                                                                            ? readOnlyLabel
                                                                                            : 'Edit ' + module.name + ' module',
                                                                                }"
                                                                                :to="{
                                                                                    name: 'courses.modules.edit',
                                                                                    params: {
                                                                                        id: module.uuid,
                                                                                    },
                                                                                }"
                                                                                custom
                                                                            >
                                                                                <button
                                                                                    class="btn btn-default primaryHover"
                                                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                    @click="navigate"
                                                                                >
                                                                                    <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                                                                </button>
                                                                            </router-link>

                                                                            <button
                                                                                v-if="!module.isArchived"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Make a Copy',
                                                                                }"
                                                                                class="btn btn-default"
                                                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                :aria-label="'Make a Copy of' + module.name + ' module'"
                                                                                @click.prevent="
                                                                                    selectedModules = [module.uuid];
                                                                                    openCopyModuleConfirmationModal(module);
                                                                                "
                                                                            >
                                                                                <i class="fa fa-files-o" aria-hidden="true" />
                                                                            </button>

                                                                            <button
                                                                                v-if="!module.isArchived"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Archive Module',
                                                                                }"
                                                                                class="btn btn-default"
                                                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                :aria-label="'Archive ' + module.name + ' module'"
                                                                                @click.prevent="openArchiveModuleModal(module)"
                                                                            >
                                                                                <i class="fa fa-archive" aria-hidden="true" />
                                                                            </button>

                                                                            <button
                                                                                v-if="module.isArchived"
                                                                                v-tooltip="{
                                                                                    content:
                                                                                        hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Reinstate Module',
                                                                                }"
                                                                                class="btn btn-default"
                                                                                :aria-label="'Reinstate ' + module.name + ' module'"
                                                                                @click.prevent="openArchiveModuleModal(module)"
                                                                            >
                                                                                <i class="fa fa-refresh" aria-hidden="true" />
                                                                            </button>
                                                                            <button
                                                                                v-if="module._display !== false"
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show Less of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.addHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = false;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                Less
                                                                                <i class="fa fa-caret-up marginLeft8" aria-hidden="true" />
                                                                            </button>
                                                                            <button
                                                                                v-else
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show more of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.removeHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = true;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                More<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="hidden-md hidden-lg">
                                                                        <div>
                                                                            <div>
                                                                                {{ module.name }}
                                                                                (<span
                                                                                    v-text="
                                                                                        activitiesCount(module) <= 1
                                                                                            ? ' activity'
                                                                                            : activitiesCount(module) + ' activities'
                                                                                    "
                                                                                />)
                                                                            </div>
                                                                            <div>
                                                                                <small class="text-muted flexOnly alignBaseline">
                                                                                    <i class="fas fa-calendar-alt marginRight8" aria-hidden="true" />
                                                                                    {{ convertToReadableDate(module.startDate, 'DD MMM YYYY').date }}
                                                                                    ({{
                                                                                        convertToReadableDate(module.startDate, 'DD MMM YYYY').current_timezone
                                                                                    }}) -
                                                                                    <i
                                                                                        class="fas fa-calendar-alt marginLeft5 marginRight8"
                                                                                        aria-hidden="true"
                                                                                    />
                                                                                    {{ convertToReadableDate(module.endDate, 'DD MMM YYYY').date }}
                                                                                    ({{
                                                                                        convertToReadableDate(module.endDate, 'DD MMM YYYY').current_timezone
                                                                                    }})</small
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                        <div class="buttonGroupInline marginTop10">
                                                                            <div
                                                                                v-if="!module.isArchived && hasWritePrivilege(course)"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : '',
                                                                                }"
                                                                                class="btn"
                                                                                :class="{
                                                                                    'p-0-i': hasWritePrivilege(course),
                                                                                }"
                                                                            >
                                                                                <router-link
                                                                                    v-slot="{ navigate }"
                                                                                    :to="{
                                                                                        name: 'courses.modules.activities.create',
                                                                                        params: {
                                                                                            course_id: course.uuid,
                                                                                            module_id: module.uuid,
                                                                                        },
                                                                                    }"
                                                                                    custom
                                                                                >
                                                                                    <button
                                                                                        class="btn btn-default primaryHover"
                                                                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                        @click="navigate"
                                                                                    >
                                                                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />New Activity
                                                                                    </button>
                                                                                </router-link>
                                                                            </div>

                                                                            <div
                                                                                v-if="hasWritePrivilege(course)"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Edit Module',
                                                                                }"
                                                                                class="btn"
                                                                                :class="{
                                                                                    'p-0-i': hasWritePrivilege(course),
                                                                                }"
                                                                            >
                                                                                <router-link
                                                                                    v-slot="{ navigate }"
                                                                                    v-tooltip="{
                                                                                        content:
                                                                                            hasWritePrivilege(course) == -1
                                                                                                ? readOnlyLabel
                                                                                                : 'Edit ' + module.name + ' module',
                                                                                    }"
                                                                                    :to="{
                                                                                        name: 'courses.modules.edit',
                                                                                        params: {
                                                                                            id: module.uuid,
                                                                                        },
                                                                                    }"
                                                                                    custom
                                                                                >
                                                                                    <button
                                                                                        class="btn btn-default primaryHover"
                                                                                        :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                        @click="navigate"
                                                                                    >
                                                                                        <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                                                                    </button>
                                                                                </router-link>
                                                                            </div>

                                                                            <div
                                                                                v-if="!module.isArchived"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Make a Copy',
                                                                                }"
                                                                                class="btn"
                                                                                :class="{
                                                                                    'p-0-i': hasWritePrivilege(course),
                                                                                }"
                                                                            >
                                                                                <button
                                                                                    class="btn btn-default"
                                                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                    :aria-label="'Make a Copy of ' + module.name + ' module'"
                                                                                    @click.prevent="
                                                                                        selectedModules = [module.uuid];
                                                                                        openCopyModuleConfirmationModal(module);
                                                                                    "
                                                                                >
                                                                                    <i class="fa fa-files-o" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            <div
                                                                                v-if="!module.isArchived"
                                                                                v-tooltip="{
                                                                                    content: hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Archive Module',
                                                                                }"
                                                                                class="btn"
                                                                                :class="{
                                                                                    'p-0-i': hasWritePrivilege(course),
                                                                                }"
                                                                            >
                                                                                <button
                                                                                    class="btn btn-default"
                                                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                    :aria-label="'Archive Module ' + module.name + ' module'"
                                                                                    @click.prevent="openArchiveModuleModal(module)"
                                                                                >
                                                                                    <i class="fa fa-archive" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            <div
                                                                                v-if="module.isArchived"
                                                                                v-tooltip="{
                                                                                    content:
                                                                                        hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Reinstate Module',
                                                                                }"
                                                                                class="btn"
                                                                                :class="{
                                                                                    'p-0-i': hasWritePrivilege(course),
                                                                                }"
                                                                            >
                                                                                <button
                                                                                    class="btn btn-default"
                                                                                    :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                    :aria-label="'Reinstate Module ' + module.name + ' module'"
                                                                                    @click.prevent="openArchiveModuleModal(module)"
                                                                                >
                                                                                    <i class="fa fa-refresh" aria-hidden="true" />
                                                                                </button>
                                                                            </div>

                                                                            <button
                                                                                v-if="module._display !== false"
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show less of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.addHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = false;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                Less
                                                                                <i class="fa fa-caret-up" aria-hidden="true" />
                                                                            </button>

                                                                            <button
                                                                                v-else
                                                                                class="btn minWidth5em alignRight marginLeft0"
                                                                                :aria-label="'Show more of ' + module.name + ' table'"
                                                                                @click="
                                                                                    store.removeHiddenModule({
                                                                                        courseUuid: course.uuid,
                                                                                        sectionUuid: query_fields.sectionUuid,
                                                                                        moduleUuid: module.uuid,
                                                                                    });
                                                                                    module._display = true;
                                                                                    props.model.splice();
                                                                                "
                                                                            >
                                                                                More<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-if="module.activities.length > 0 && module._display !== false">
                                                                <tr class="hidden-xs hidden-sm">
                                                                    <td>
                                                                        <div>
                                                                            <div class="table-responsive">
                                                                                <table class="table subTable border-collapse">
                                                                                    <caption class="visible-hidden">
                                                                                        {{
                                                                                            module.name
                                                                                        }}
                                                                                        activity table
                                                                                    </caption>

                                                                                    <thead>
                                                                                        <tr class="activityHeaders">
                                                                                            <th class="moduleActionHeader" style="width: 40px" />
                                                                                            <th
                                                                                                scope="col"
                                                                                                aria-label="activity name"
                                                                                                style="
                                                                                                    width: calc(
                                                                                                        100% - 128px - 134px - 147px - 85px - 320px - 41px
                                                                                                    );
                                                                                                "
                                                                                            >
                                                                                                NAME
                                                                                                <template v-if="sort === 'name'">
                                                                                                    <button
                                                                                                        v-if="order === 'asc'"
                                                                                                        aria-label="ascending order"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort-amount-asc" aria-hidden="true" />
                                                                                                    </button>
                                                                                                    <button
                                                                                                        v-else
                                                                                                        aria-label="descending order"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort-amount-desc" aria-hidden="true" />
                                                                                                    </button>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                    <button
                                                                                                        aria-label="sort"
                                                                                                        @click.prevent="toggle('name', idx)"
                                                                                                    >
                                                                                                        <i class="fa fa-sort" aria-hidden="true" />
                                                                                                    </button>
                                                                                                </template>
                                                                                            </th>
                                                                                            <!-- <th style="width:154px;"> -->
                                                                                            <th scope="col" class="moduleActivityTable-type">TYPE</th>
                                                                                            <!-- <th style="min-width:121px;"> -->
                                                                                            <th scope="col" style="width: 134px">MODALITY</th>
                                                                                            <th scope="col" style="width: 147px">STATUS</th>
                                                                                            <th scope="col" style="width: 85px">VISIBILITY</th>
                                                                                            <th scope="col" style="width: 320px">ACTIONS</th>
                                                                                        </tr>
                                                                                    </thead>

                                                                                    <template
                                                                                        v-for="(activity, activity_idx) in module.activities"
                                                                                        :key="activity.uuid + '-d-activities'"
                                                                                    >
                                                                                        <tbody
                                                                                            :id="activity.uuid + '-d'"
                                                                                            class="activityTable"
                                                                                            tabindex="-1"
                                                                                            :draggable="true"
                                                                                            @dragover="allowDropToActivity"
                                                                                            @dragstart="dragActivity($event, idx, activity_idx)"
                                                                                            @drop="dropToActivity($event, idx, activity_idx)"
                                                                                            @dragleave="leaveDragFromActivity"
                                                                                            @dragend="
                                                                                                stopScroll = true;
                                                                                                currentDraggedItem = null;
                                                                                            "
                                                                                        >
                                                                                            <template v-if="!activity.isPublished">
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <i
                                                                                                            v-tooltip="{
                                                                                                                content: 'Drag Me!',
                                                                                                                show: isOpenActivity[idx + '_' + activity_idx],
                                                                                                                trigger: 'manual',
                                                                                                            }"
                                                                                                            class="fas fa-grip-vertical fontBlack fs-14px"
                                                                                                            style="padding-top: 1px"
                                                                                                            aria-hidden="true"
                                                                                                            @mouseover="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = true
                                                                                                            "
                                                                                                            @mouseleave="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = false
                                                                                                            "
                                                                                                            @mousedown="
                                                                                                                isOpenActivity[idx + '_' + activity_idx] = false
                                                                                                            "
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td scope="row">
                                                                                                        {{ activity.name }}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <template v-if="activity.type == 'prework'">
                                                                                                            Prework
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'irat'">
                                                                                                            IRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'trat'">
                                                                                                            TRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'iratntrat'">
                                                                                                            IRAT & TRAT
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'application'">
                                                                                                            Application Case
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'evaluation'">
                                                                                                            360 Degree Evaluation
                                                                                                        </template>
                                                                                                        <template
                                                                                                            v-if="activity.type == 'michaelsenEvaluation'"
                                                                                                        >
                                                                                                            Peer Evaluation: Michaelsen’s Method
                                                                                                        </template>
                                                                                                        <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                            Peer Evaluation
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td class="capitalize">-</td>
                                                                                                    <td>
                                                                                                        <test-status :test="false" />
                                                                                                    </td>
                                                                                                    <td class="textCenteralize">
                                                                                                        <button
                                                                                                            v-tooltip="{
                                                                                                                content: 'Hidden from students',
                                                                                                            }"
                                                                                                            class="btn btn-empty-border-none disabled has-tooltip"
                                                                                                        >
                                                                                                            <i
                                                                                                                class="fas fa-eye-slash disabledIcon"
                                                                                                                aria-hidden="true"
                                                                                                            />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                    <td class="flexOnly align-items">
                                                                                                        <router-link
                                                                                                            v-slot="{ navigate }"
                                                                                                            v-tooltip="{
                                                                                                                content:
                                                                                                                    hasWritePrivilege(course) == -1
                                                                                                                        ? readOnlyLabel
                                                                                                                        : '',
                                                                                                            }"
                                                                                                            :to="{
                                                                                                                name: 'courses.modules.activities.continue',
                                                                                                                params: {
                                                                                                                    id: activity.uuid,
                                                                                                                },
                                                                                                            }"
                                                                                                            custom
                                                                                                        >
                                                                                                            <button
                                                                                                                class="btn btn-outline-primary"
                                                                                                                :disabled="hasWritePrivilege(course) == -1"
                                                                                                                @click="navigate"
                                                                                                            >
                                                                                                                Continue Setup
                                                                                                            </button>
                                                                                                        </router-link>
                                                                                                        <div
                                                                                                            v-if="hasWritePrivilege(course) != -1"
                                                                                                            class="dropup marginLeft20"
                                                                                                        >
                                                                                                            <button
                                                                                                                id="viewDropdownMenuButton"
                                                                                                                class="marginBottom0"
                                                                                                                data-toggle="dropdown"
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded="false"
                                                                                                            >
                                                                                                                Actions<i
                                                                                                                    class="fa fa-caret-down marginLeft8"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>

                                                                                                            <div
                                                                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                                aria-labelledby="dropdownMenuButton"
                                                                                                            >
                                                                                                                <ul>
                                                                                                                    <li
                                                                                                                        v-if="
                                                                                                                            activity.type != 'peerEvaluationV2'
                                                                                                                        "
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            v-tooltip="{
                                                                                                                                content: 'Make a Copy',
                                                                                                                            }"
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            :aria-label="
                                                                                                                                'Make a Copy of ' +
                                                                                                                                activity.name +
                                                                                                                                ' activity'
                                                                                                                            "
                                                                                                                            @click.prevent="
                                                                                                                                openCopyActivityConfirmationModal(
                                                                                                                                    activity
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-files-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Make a Copy
                                                                                                                            </span>

                                                                                                                            <!-- <span>⇧⌘D</span> -->
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <hr
                                                                                                                        v-if="
                                                                                                                            activity.type != 'peerEvaluationV2'
                                                                                                                        "
                                                                                                                    />

                                                                                                                    <li v-if="canDeleteActivity(activity)">
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                openDeleteActivityModal(
                                                                                                                                    activity
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-trash-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />
                                                                                                                                Delete Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <tr
                                                                                                    v-for="(test, test_idx) in activity.tests"
                                                                                                    :key="test_idx + 'test_activities'"
                                                                                                >
                                                                                                    <td
                                                                                                        v-if="test_idx == 0"
                                                                                                        :rowspan="activity.type == 'iratntrat' ? 2 : ''"
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <div class="flex">
                                                                                                            <i
                                                                                                                v-tooltip="{
                                                                                                                    content: 'Drag Me!',
                                                                                                                    show: isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ],
                                                                                                                    trigger: 'manual',
                                                                                                                }"
                                                                                                                class="fas fa-grip-vertical fontBlack fs-14px"
                                                                                                                style="padding-top: 1px"
                                                                                                                aria-hidden="true"
                                                                                                                @mouseover="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = true
                                                                                                                "
                                                                                                                @mouseleave="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = false
                                                                                                                "
                                                                                                                @mousedown="
                                                                                                                    isOpenActivity[
                                                                                                                        idx + '_' + activity_idx
                                                                                                                    ] = false
                                                                                                                "
                                                                                                            />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                        scope="row"
                                                                                                    >
                                                                                                        {{ test.name }}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <template v-if="test.type == 'prework'">
                                                                                                            Prework
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'irat'"> IRAT </template>
                                                                                                        <template v-if="test.type == 'trat'"> TRAT </template>
                                                                                                        <template v-if="test.type == 'iratntrat'">
                                                                                                            IRAT & TRAT
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'application'">
                                                                                                            Application Case
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'evaluation'">
                                                                                                            360 Degree Evaluation
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'michaelsenEvaluation'">
                                                                                                            Peer Evaluation: Michaelsen’s Method
                                                                                                        </template>
                                                                                                        <template v-if="test.type == 'peerEvaluationV2'">
                                                                                                            Peer Evaluation
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        class="capitalize"
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        {{ getTestModality(test) }}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        :class="{
                                                                                                            'opacity-7':
                                                                                                                test.status == 'completed' ||
                                                                                                                test.status == 'ended',
                                                                                                        }"
                                                                                                    >
                                                                                                        <test-status :test="test" />
                                                                                                    </td>
                                                                                                    <td class="textCenteralize">
                                                                                                        <template v-if="test.isPublished">
                                                                                                            <template
                                                                                                                v-if="
                                                                                                                    [
                                                                                                                        'not started',
                                                                                                                        'scheduled',
                                                                                                                        'ended',
                                                                                                                        'completed',
                                                                                                                    ].includes(test.status)
                                                                                                                "
                                                                                                            >
                                                                                                                <button
                                                                                                                    v-tooltip="{
                                                                                                                        content: 'Shown to students',
                                                                                                                    }"
                                                                                                                    aria-label="Shown to students"
                                                                                                                    class="btn btn-empty-border-none"
                                                                                                                    :disabled="hasWritePrivilege(course) == -1"
                                                                                                                    @click="openUnpublishTestModal(test)"
                                                                                                                >
                                                                                                                    <i class="fas fa-eye" aria-hidden="true" />
                                                                                                                </button>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                        <template v-else>
                                                                                                            <button
                                                                                                                v-tooltip="{
                                                                                                                    content: 'Hidden from students',
                                                                                                                }"
                                                                                                                aria-label="Hidden from students"
                                                                                                                class="btn btn-empty-border-none"
                                                                                                                :disabled="hasWritePrivilege(course) == -1"
                                                                                                                @click="toggleTestPublish(test)"
                                                                                                            >
                                                                                                                <i
                                                                                                                    class="fas fa-eye-slash"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td class="flexOnly align-items">
                                                                                                        <template v-if="test.isPublished">
                                                                                                            <router-link
                                                                                                                v-slot="{ navigate }"
                                                                                                                :to="{
                                                                                                                    name:
                                                                                                                        test.type != 'peerEvaluationV2'
                                                                                                                            ? 'activities.tests.dashboard'
                                                                                                                            : 'activities.tests.dashboard.peer-evaluation',
                                                                                                                    params: {
                                                                                                                        id: test.uuid,
                                                                                                                    },
                                                                                                                }"
                                                                                                                target="_self"
                                                                                                                custom
                                                                                                            >
                                                                                                                <button
                                                                                                                    class="btn btn-primary"
                                                                                                                    @click="navigate"
                                                                                                                >
                                                                                                                    Go to Dashboard
                                                                                                                </button>
                                                                                                            </router-link>
                                                                                                        </template>
                                                                                                        <template v-else>
                                                                                                            <router-link
                                                                                                                v-slot="{ navigate }"
                                                                                                                :to="{
                                                                                                                    name:
                                                                                                                        test.type != 'peerEvaluationV2'
                                                                                                                            ? 'activities.tests.dashboard'
                                                                                                                            : 'activities.tests.dashboard.peer-evaluation',
                                                                                                                    params: {
                                                                                                                        id: test.uuid,
                                                                                                                    },
                                                                                                                }"
                                                                                                                target="_self"
                                                                                                                custom
                                                                                                            >
                                                                                                                <button
                                                                                                                    class="btn btn-primary"
                                                                                                                    @click="navigate"
                                                                                                                >
                                                                                                                    Go to Dashboard
                                                                                                                </button>
                                                                                                            </router-link>
                                                                                                        </template>

                                                                                                        <div class="dropup marginLeft20">
                                                                                                            <button
                                                                                                                id="viewDropdownMenuButton"
                                                                                                                data-toggle="dropdown"
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded="false"
                                                                                                            >
                                                                                                                Actions<i
                                                                                                                    class="fa fa-caret-down marginLeft8"
                                                                                                                    aria-hidden="true"
                                                                                                                />
                                                                                                            </button>

                                                                                                            <div
                                                                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                                aria-labelledby="dropdownMenuButton"
                                                                                                            >
                                                                                                                <ul>
                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            :aria-label="
                                                                                                                                'Create ' +
                                                                                                                                activity.name +
                                                                                                                                ' as a Template'
                                                                                                                            "
                                                                                                                            @click.prevent="
                                                                                                                                createTemplate(activity)
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-plus marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Create as a Template
                                                                                                                            </span>

                                                                                                                            <!-- <span>⇧⌘D</span> -->
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                    <li
                                                                                                                        v-if="
                                                                                                                            activity.type != 'peerEvaluationV2'
                                                                                                                        "
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            @click.prevent="
                                                                                                                                openCopyActivityConfirmationModal(
                                                                                                                                    activity
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-files-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Make a Copy
                                                                                                                            </span>

                                                                                                                            <!-- <span>⇧⌘D</span> -->
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <li
                                                                                                                        v-if="
                                                                                                                            canDisplayExportGradesButton(
                                                                                                                                activity,
                                                                                                                                test
                                                                                                                            )
                                                                                                                        "
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            class="primary-state"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                openGradesExportConfirmationModalActivity(
                                                                                                                                    test
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fas fa-download marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Export Grades
                                                                                                                            </span>

                                                                                                                            <!-- <span>⇧⌘D</span> -->
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <hr />

                                                                                                                    <li>
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                openResetConfirmationModal(
                                                                                                                                    activity
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-refresh marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />Reset Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>

                                                                                                                    <li v-if="canDeleteActivity(activity)">
                                                                                                                        <a
                                                                                                                            class="danger-state"
                                                                                                                            :class="{
                                                                                                                                disabled:
                                                                                                                                    hasWritePrivilege(course) ==
                                                                                                                                    -1,
                                                                                                                            }"
                                                                                                                            href="#"
                                                                                                                            @click.prevent="
                                                                                                                                openDeleteActivityModal(
                                                                                                                                    activity
                                                                                                                                )
                                                                                                                            "
                                                                                                                        >
                                                                                                                            <span>
                                                                                                                                <i
                                                                                                                                    class="fa fa-trash-o marginRight8"
                                                                                                                                    aria-hidden="true"
                                                                                                                                />
                                                                                                                                Delete Activity
                                                                                                                            </span>
                                                                                                                        </a>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </tbody>
                                                                                    </template>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <div class="hidden-md hidden-lg padding20">
                                                                    <template v-for="activity in module.activities" :key="activity.uuid + '-m'">
                                                                        <div :id="activity.uuid + '-m'" class="subContentDetail" tabindex="-1">
                                                                            <div class="header">
                                                                                <div>
                                                                                    <div class="inline-flex">
                                                                                        <div class="btn-grey subBtn">
                                                                                            <template v-if="activity.type == 'prework'"> Prework </template>
                                                                                            <template v-if="activity.type == 'irat'"> IRAT </template>
                                                                                            <template v-if="activity.type == 'trat'"> TRAT </template>
                                                                                            <template v-if="activity.type == 'iratntrat'">
                                                                                                IRAT & TRAT
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'application'">
                                                                                                Application Case
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'evaluation'">
                                                                                                360 Degree Evaluation
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'michaelsenEvaluation'">
                                                                                                Peer Evaluation: Michaelsen’s Method
                                                                                            </template>
                                                                                            <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                Peer Evaluation
                                                                                            </template>
                                                                                        </div>
                                                                                        <div class="blueTitle">
                                                                                            {{ activity.name }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="marginTop10">
                                                                                        <div
                                                                                            v-tooltip="{
                                                                                                content:
                                                                                                    hasWritePrivilege(course) == -1
                                                                                                        ? readOnlyLabel
                                                                                                        : 'Edit Activity',
                                                                                            }"
                                                                                            class="btn"
                                                                                            :class="{
                                                                                                'p-0-i': hasWritePrivilege(course),
                                                                                            }"
                                                                                        >
                                                                                            <router-link
                                                                                                v-if="canEditActivity(activity)"
                                                                                                v-slot="{ navigate }"
                                                                                                v-tooltip="{
                                                                                                    content:
                                                                                                        hasWritePrivilege(course) == -1
                                                                                                            ? readOnlyLabel
                                                                                                            : 'Edit Activity',
                                                                                                }"
                                                                                                :to="{
                                                                                                    name: 'courses.modules.activities.republish',
                                                                                                    params: {
                                                                                                        id: activity.uuid,
                                                                                                    },
                                                                                                }"
                                                                                                custom
                                                                                            >
                                                                                                <button
                                                                                                    class="btn btn-default primaryHover"
                                                                                                    :disabled="
                                                                                                        hasWritePrivilege(course) == -1 ? true : undefined
                                                                                                    "
                                                                                                    @click="navigate"
                                                                                                >
                                                                                                    <i class="fa fa-pencil-square-o" aria-hidden="true" />
                                                                                                </button>
                                                                                            </router-link>
                                                                                        </div>
                                                                                        <div
                                                                                            v-if="activity.type != 'peerEvaluationV2'"
                                                                                            v-tooltip="{
                                                                                                content:
                                                                                                    hasWritePrivilege(course) == -1
                                                                                                        ? readOnlyLabel
                                                                                                        : 'Make a Copy',
                                                                                            }"
                                                                                            class="btn"
                                                                                            :class="{
                                                                                                'p-0-i': hasWritePrivilege(course),
                                                                                            }"
                                                                                        >
                                                                                            <button
                                                                                                class="btn btn-default subBtn"
                                                                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                                aria-label="Make a Copy"
                                                                                                @click.prevent="openCopyActivityConfirmationModal(activity)"
                                                                                            >
                                                                                                <i class="fa fa-files-o" aria-hidden="true" />
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            v-tooltip="{
                                                                                                content:
                                                                                                    hasWritePrivilege(course) == -1
                                                                                                        ? readOnlyLabel
                                                                                                        : 'Reset Activity',
                                                                                            }"
                                                                                            class="btn"
                                                                                            :class="{
                                                                                                'p-0-i': hasWritePrivilege(course),
                                                                                            }"
                                                                                        >
                                                                                            <button
                                                                                                class="btn btn-default subBtn"
                                                                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                                aria-label="Reset Activity"
                                                                                                @click="openResetConfirmationModal(activity)"
                                                                                            >
                                                                                                <i class="fa fa-refresh" aria-hidden="true" />
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            v-tooltip="{
                                                                                                content:
                                                                                                    hasWritePrivilege(course) == -1
                                                                                                        ? readOnlyLabel
                                                                                                        : 'Delete Activity',
                                                                                            }"
                                                                                            class="btn"
                                                                                            :class="{
                                                                                                'p-0-i': hasWritePrivilege(course),
                                                                                            }"
                                                                                        >
                                                                                            <button
                                                                                                v-if="canDeleteActivity(activity)"
                                                                                                class="btn btn-default subBtn"
                                                                                                :disabled="hasWritePrivilege(course) == -1 ? true : undefined"
                                                                                                aria-label="Delete Activity"
                                                                                                @click.prevent="openDeleteActivityModal(activity)"
                                                                                            >
                                                                                                <i class="fa fa-trash-o" aria-hidden="true" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="!activity.isPublished" class="content">
                                                                                <div class="subContents">
                                                                                    <!--<div class="subContentHeader">Section1</div>-->
                                                                                    <div class="subContentDetails">
                                                                                        <div class="form-control-static flexSpaceBetween">
                                                                                            <label class="control-label">TYPE</label>
                                                                                            <div class="greyText">
                                                                                                <template v-if="activity.type == 'prework'"> Prework </template>
                                                                                                <template v-if="activity.type == 'irat'"> IRAT </template>
                                                                                                <template v-if="activity.type == 'trat'"> TRAT </template>
                                                                                                <template v-if="activity.type == 'iratntrat'">
                                                                                                    IRAT & TRAT
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'application'">
                                                                                                    Application Case
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'evaluation'">
                                                                                                    360 Degree Evaluation
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'michaelsenEvaluation'">
                                                                                                    Peer Evaluation: Michaelsen’s Method
                                                                                                </template>
                                                                                                <template v-if="activity.type == 'peerEvaluationV2'">
                                                                                                    Peer Evaluation
                                                                                                </template>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="form-control-static flexSpaceBetween">
                                                                                            <label class="control-label">Modality</label>
                                                                                            <p class="greyText capitalize">-</p>
                                                                                        </div>
                                                                                        <div>
                                                                                            <test-status :test="test" />
                                                                                        </div>
                                                                                        <div class="marginTop20">
                                                                                            <router-link
                                                                                                v-slot="{ navigate }"
                                                                                                v-tooltip="{
                                                                                                    content:
                                                                                                        hasWritePrivilege(course) == -1
                                                                                                            ? readOnlyLabel
                                                                                                            : 'Continue Setup',
                                                                                                }"
                                                                                                :to="{
                                                                                                    name: 'courses.modules.activities.continue',
                                                                                                    params: {
                                                                                                        id: activity.uuid,
                                                                                                    },
                                                                                                }"
                                                                                                custom
                                                                                            >
                                                                                                <button
                                                                                                    class="btn btn-default primaryHover"
                                                                                                    :disabled="
                                                                                                        hasWritePrivilege(course) == -1 ? true : undefined
                                                                                                    "
                                                                                                    @click="navigate"
                                                                                                >
                                                                                                    Continue Setup
                                                                                                </button>
                                                                                            </router-link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else class="content">
                                                                                <div class="subContents">
                                                                                    <div
                                                                                        v-for="(test, idx) in activity.tests"
                                                                                        :key="idx + 'activity-test-module-activities'"
                                                                                        class="subContentDetails"
                                                                                    >
                                                                                        <div>
                                                                                            <div class="form-control-static flexSpaceBetween">
                                                                                                <label class="control-label">Type</label>
                                                                                                <p class="greyText">
                                                                                                    <template v-if="test.type == 'prework'"> Prework </template>
                                                                                                    <template v-if="test.type == 'irat'"> IRAT </template>
                                                                                                    <template v-if="test.type == 'trat'"> TRAT </template>
                                                                                                    <template v-if="test.type == 'iratntrat'">
                                                                                                        IRAT & TRAT
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'application'">
                                                                                                        Application Case
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'evaluation'">
                                                                                                        360 Degree Evaluation
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'michaelsenEvaluation'">
                                                                                                        Peer Evaluation: Michaelsen’s Method
                                                                                                    </template>
                                                                                                    <template v-if="test.type == 'peerEvaluationV2'">
                                                                                                        Peer Evaluation
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div class="form-control-static flexSpaceBetween">
                                                                                                <label class="control-label">Modality</label>
                                                                                                <p class="greyText capitalize">
                                                                                                    {{ getTestModality(test) }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            <template v-if="!test.isPublished">
                                                                                                <div class="col-xs-12 btn statusTagDanger subBtn">
                                                                                                    Hidden from students
                                                                                                </div>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <test-status :test="test" />
                                                                                            </template>
                                                                                        </div>
                                                                                        <div class="marginTop20 inlineFlex">
                                                                                            <template v-if="test.isPublished">
                                                                                                <router-link
                                                                                                    v-slot="{ navigate }"
                                                                                                    :to="{
                                                                                                        name:
                                                                                                            test.type != 'peerEvaluationV2'
                                                                                                                ? 'activities.tests.dashboard'
                                                                                                                : 'activities.tests.dashboard.peer-evaluation',
                                                                                                        params: {
                                                                                                            id: test.uuid,
                                                                                                        },
                                                                                                    }"
                                                                                                    target="_self"
                                                                                                    custom
                                                                                                >
                                                                                                    <button class="btn btn-primary" @click="navigate">
                                                                                                        Go to Dashboard
                                                                                                    </button>
                                                                                                </router-link>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <router-link
                                                                                                    v-slot="{ navigate }"
                                                                                                    :to="{
                                                                                                        name:
                                                                                                            test.type != 'peerEvaluationV2'
                                                                                                                ? 'activities.tests.dashboard'
                                                                                                                : 'activities.tests.dashboard.peer-evaluation',
                                                                                                        params: {
                                                                                                            id: test.uuid,
                                                                                                        },
                                                                                                    }"
                                                                                                    target="_self"
                                                                                                    custom
                                                                                                >
                                                                                                    <button class="btn btn-primary" @click="navigate">
                                                                                                        Go to Dashboard
                                                                                                    </button>
                                                                                                </router-link>
                                                                                            </template>

                                                                                            <div class="dropup marginLeft20">
                                                                                                <button
                                                                                                    id="viewDropdownMenuButton"
                                                                                                    data-toggle="dropdown"
                                                                                                    aria-haspopup="true"
                                                                                                    aria-expanded="false"
                                                                                                >
                                                                                                    Actions<i
                                                                                                        class="fa fa-caret-down marginLeft8"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                </button>

                                                                                                <div
                                                                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                                                    aria-labelledby="dropdownMenuButton"
                                                                                                >
                                                                                                    <ul>
                                                                                                        <li v-if="activity.type != 'peerEvaluationV2'">
                                                                                                            <a
                                                                                                                class="primary-state"
                                                                                                                href="#"
                                                                                                                :class="{
                                                                                                                    disabled: hasWritePrivilege(course) == -1,
                                                                                                                }"
                                                                                                                @click.prevent="
                                                                                                                    openCopyActivityConfirmationModal(activity)
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-files-o marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Make a Copy
                                                                                                                </span>

                                                                                                                <!-- <span>⇧⌘D</span> -->
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <li v-if="canDisplayExportGradesButton(activity, test)">
                                                                                                            <a
                                                                                                                class="primary-state"
                                                                                                                href="#"
                                                                                                                @click.prevent="
                                                                                                                    openGradesExportConfirmationModalActivity(
                                                                                                                        test
                                                                                                                    )
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fas fa-download marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Export Grades
                                                                                                                </span>

                                                                                                                <!-- <span>⇧⌘D</span> -->
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <hr />

                                                                                                        <li>
                                                                                                            <a
                                                                                                                class="danger-state"
                                                                                                                :class="{
                                                                                                                    disabled: hasWritePrivilege(course) == -1,
                                                                                                                }"
                                                                                                                href="#"
                                                                                                                @click.prevent="
                                                                                                                    openResetConfirmationModal(activity)
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-refresh marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />Reset Activity
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>

                                                                                                        <li v-if="canDeleteActivity(activity)">
                                                                                                            <a
                                                                                                                class="danger-state"
                                                                                                                :class="{
                                                                                                                    disabled: hasWritePrivilege(course) == -1,
                                                                                                                }"
                                                                                                                href="#"
                                                                                                                @click.prevent="
                                                                                                                    openDeleteActivityModal(activity)
                                                                                                                "
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <i
                                                                                                                        class="fa fa-trash-o marginRight8"
                                                                                                                        aria-hidden="true"
                                                                                                                    />
                                                                                                                    Delete Activity
                                                                                                                </span>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="module.activities.length == 0 && module._display !== false">
                                                                <tr>
                                                                    <td :colspan="props.model.total_column_count" class="borderNone">
                                                                        <span v-if="!module.isArchived && hasWritePrivilege(course)">
                                                                            No activities yet,
                                                                            <router-link
                                                                                :to="{
                                                                                    name: 'courses.modules.activities.create',
                                                                                    params: {
                                                                                        course_id: course.uuid,
                                                                                        module_id: module.uuid,
                                                                                    },
                                                                                }"
                                                                                tag="a"
                                                                                class="link"
                                                                                aria-label="Create activity page"
                                                                            >
                                                                                Create an activity here
                                                                            </router-link>
                                                                        </span>
                                                                        <span v-else> No activity. </span>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </kr-search>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <!-- end of new ui -->
            </div>
            <div v-if="tab == 'student'" id="students" class="tab-pane fade in active">
                <manage-students :course-obj="course" @update-manage-accommodations="lookForValue" @get-back-to-module-tab="getBackToModule" />
            </div>
            <!--<div v-if="tab == 'permission'" class="tab-pane fade in active" id="permissions">
				
			</div>-->
            <div v-if="tab == 'lms'" id="lmsIntegration" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">LMS INTEGRATION CREDENTIALS</div>
                    </template>
                    <template #content>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <span>CONFIGURATION URL</span>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent widthAuto">
                                        <p>{{ API_URL }}/lti/config/{{ course.consumerKeyLTI }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/config/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="API_URL + '/lti/config/' + course.consumerKeyLTI"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <span>LAUNCH URL</span>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent widthAuto">
                                        <p>{{ API_URL }}/lti/launch/{{ course.consumerKeyLTI }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/launch/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="API_URL + '/lti/launch/' + course.consumerKeyLTI"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <span>CONSUMER KEY</span>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent">
                                        <span>{{ course.consumerKeyLTI }}</span>
                                    </div>
                                </div>
                                <!--<div class="LMSBtn">
					        		<button class="btn btn-primary subBtn hidden-xs hidden-sm">
		                                <i class="fa fa-files-o"></i>&nbsp;Open Link
		                            </button>
		                            <div class="hidden-md hidden-lg">
		                            	<button class="btn btn-primary mainBtn">
			                                <i class="fa fa-files-o"></i>
			                            </button>
		                            </div>
					        	</div>-->
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <span>SHARED SECRET</span>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent">
                                        <span>{{ course.sharedSecretLTI }}</span>
                                    </div>
                                </div>
                                <!--<div class="LMSBtn">
					        		<button class="btn btn-primary subBtn hidden-xs hidden-sm">
		                                <i class="fa fa-files-o"></i>&nbsp;Open Link
		                            </button>
		                            <div class="hidden-md hidden-lg">
		                            	<button class="btn btn-primary mainBtn">
			                                <i class="fa fa-files-o"></i>
			                            </button>
		                            </div>
					        	</div>-->
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>

            <div
                v-if="tab == 'signin' && ((hasWritePrivilege(course) === 1 && auth.user().canUseExpressSignIn) || course.isSelfSignIn)"
                id="signin"
                class="tab-pane fade in active"
            >
                <div class="alert alert-warning marginBottom20">
                    <i class="fa fa-info-circle marginRight8" aria-hidden="true" />
                    <span class="displayInlineBlock">
                        Express Sign in is a feature that allows your students/participants to enrol themselves into this course via a custom URL. See example
                        of
                        <a class="link marginLeft2" @click="openSignInFormModal()">learner view here</a>
                    </span>
                </div>
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">Express Sign In</div>
                    </template>

                    <template #content>
                        <template v-if="auth.user().canUseExpressSignIn == 0">
                            <p>Express sign in is not available. Please contact super admin to enable.</p>
                        </template>
                        <template v-if="hasWritePrivilege(course) === 1">
                            <div class="col-xs-12 enrollmentSetting">
                                <kr-toggle :options="{ reverse: true }" name="isSelfSignIn" :form="form" class="marginTop5" @changed="updateIsSelfSignIn" />
                                <div v-if="!form.model.isSelfSignIn">
                                    <label>Enable Express Sign In</label>
                                </div>
                                <div v-else>
                                    <label>Enable Express Sign In</label>
                                    <p>Students will be able to self-register for this course at the following URL:</p>

                                    <div>
                                        <div class="LMSRow">
                                            <div class="LMSSubCon">
                                                <div class="LMSContainer">
                                                    <div class="LMSTitle">
                                                        <label>EXPRESS SIGN IN URL</label>
                                                        <span class="hidden-xs hidden-sm">:</span>
                                                    </div>
                                                    <div class="LMSContent">
                                                        <p>{{ EXPRESS_SIGN_IN_URL }}</p>
                                                    </div>
                                                </div>
                                                <div class="LMSBtn">
                                                    <button
                                                        v-clipboard:copy="EXPRESS_SIGN_IN_URL"
                                                        v-clipboard:success="onCopyClipboard"
                                                        v-clipboard:error="onErrorClipboard"
                                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                                        aria-label="copy EXPRESS SIGN IN URL"
                                                    >
                                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                                    </button>
                                                    <div class="hidden-md hidden-lg">
                                                        <button
                                                            v-clipboard:copy="EXPRESS_SIGN_IN_URL"
                                                            v-clipboard:success="onCopyClipboard"
                                                            v-clipboard:error="onErrorClipboard"
                                                            v-tooltip="{
                                                                content: 'Copy Link',
                                                            }"
                                                            class="btn btn-primary mainBtn"
                                                            aria-label="copy EXPRESS SIGN IN URL"
                                                        >
                                                            <i class="fa fa-files-o" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <!-- <button class="btn btn-primary subBtn" aria-label="Preview">Preview</button> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="LMSRow">
                                            <div class="LMSSubCon">
                                                <div class="LMSContainer">
                                                    <div class="LMSTitle">
                                                        <span>SIGN IN QR CODE</span>
                                                        <span class="hidden-xs hidden-sm">:</span>
                                                    </div>
                                                    <div class="flex LMSContent" style="margin-left: 5px">
                                                        <vue-qrcode :value="EXPRESS_SIGN_IN_URL" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <p class="marginTop8">
                                        Students can alternatively sign in to the course from our Sign in Page by choosing "Express Sign in" and then entering
                                        the following Course ID
                                    </p>

                                    <div>
                                        <div class="LMSRow">
                                            <div class="LMSSubCon">
                                                <div class="LMSContainer">
                                                    <div class="LMSTitle">
                                                        <label>COURSE ID</label>
                                                        <span class="hidden-xs hidden-sm">:</span>
                                                    </div>
                                                    <div class="LMSContent">
                                                        <p>
                                                            {{ course.urlCode }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="auth.user().canUseExpressSignIn">
                            <div v-if="form.model.isSelfSignIn" class="col-xs-12 enrollmentSetting">
                                <kr-toggle
                                    v-if="auth.user().account.paymentMethod != 'Student-Paid'"
                                    :options="{
                                        trueval: false,
                                        falseval: true,
                                        reverse: true,
                                    }"
                                    name="anonymous"
                                    :form="form"
                                    class="marginTop5"
                                    @changed="updateOthers"
                                />
                                <template v-else>
                                    <div class="marginRight10" style="width: 60px; padding-top: 8px; padding-left: 15px">
                                        <b>Yes</b>
                                    </div>
                                </template>
                                <div class="col-xs-12">
                                    <label>Require students to set an email and password</label>
                                    <p>
                                        If this is turned off, students only need to enter their name/nickname to enter the course. Turn this off only if you
                                        are running ad-hoc workshops and convenience of access is more important than security.
                                        <a
                                            href="https://help.intedashboard.com/how-do-i-enable-learners-to-self-register-with-an-email-and-password"
                                            class="link"
                                            target="newTab"
                                            aria-label="click here on how do I enable students to self register with an email and password?"
                                        >
                                            Click here
                                        </a>
                                        for more information. <br /><br />
                                        Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.
                                    </p>
                                    <textarea
                                        v-model="form.model.allowedDomains"
                                        placeholder="E.g. intedashboard.com, intelearner.com"
                                        :disabled="form.model.anonymous ? true : undefined"
                                        @blur="updateOthers"
                                        @keyup="debouncedSubmit"
                                        @paste="updateOthers"
                                    />
                                </div>
                            </div>

                            <div v-if="form.model.isSelfSignIn && form.model.anonymous" class="col-xs-12 enrollmentSetting">
                                <kr-toggle
                                    :options="{ reverse: true }"
                                    name="isSecretPin"
                                    :form="form"
                                    class="marginTop5"
                                    @changed="form.model.isSecretPin == false ? updateOthers() : ''"
                                />
                                <div class="col-xs-12">
                                    <label> Students are required to enter a secret pin to register </label>
                                    <p>Enter a minimum of 6 numeric characters below.</p>
                                    <p
                                        :class="{
                                            red: form.model.secretPin != null && form.model.secretPin.length < 6,
                                            green: form.model.secretPin != null && form.model.secretPin.length >= 6,
                                        }"
                                    >
                                        {{ form.model.secretPin != null ? form.model.secretPin.length : 0 }}
                                        character/s &nbsp; 6 characters required
                                    </p>
                                    <pin-code v-model="form.model.secretPin" :disabled="!form.model.isSecretPin ? true : undefined" @completed="updateOthers" />
                                </div>
                            </div>

                            <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="col-xs-12 enrollmentSetting">
                                <kr-toggle :options="{ reverse: true }" name="ssoMicrosoft" :form="form" class="marginTop5" @changed="updateOthers" />
                                <div class="col-xs-12">
                                    <label>Enable sign in with a Microsoft Teams Account</label>
                                    <p>Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.</p>
                                    <textarea
                                        v-model="form.model.ssoMicrosoftAllowedDomains"
                                        :disabled="!form.model.ssoMicrosoft ? true : undefined"
                                        placeholder="E.g. intedashboard.com, intelearner.com"
                                        @blur="updateOthers"
                                        @keyup="debouncedSubmit"
                                        @paste="updateOthers"
                                    />
                                </div>
                            </div>

                            <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="col-xs-12 enrollmentSetting">
                                <kr-toggle :options="{ reverse: true }" name="ssoGoogle" :form="form" class="marginTop5" @changed="updateOthers()" />
                                <div class="col-xs-12">
                                    <label>Enable sign in with a Google Classroom Account</label>
                                    <p>Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.</p>
                                    <textarea
                                        v-model="form.model.ssoGoogleAllowedDomains"
                                        :disabled="!form.model.ssoGoogle ? true : undefined"
                                        placeholder="E.g. intedashboard.com, intelearner.com"
                                        @blur="updateOthers"
                                        @keyup="debouncedSubmit"
                                        @paste="updateOthers"
                                    />
                                </div>
                            </div>
                        </template>
                    </template>
                </kr-panel>
            </div>

            <div v-if="tab == 'overview'" id="courseOverview" class="tab-pane fade in active">
                <course-settings :course="course" @course-updated="syncCourseObject" />
                <manage-teachers :course-obj="course" />
                <enrollment-settings :course="course" />
                <lti1p3-settings v-if="canDisplayLti1p3" :course="course" />
            </div>
            <!--<div v-if="APP_ENV!='production'" id="analytics" class="tab-pane fade in" :class="{'active':tab=='analytics'}">
				<kr-panel :withFooter="false">
					<template #title>
						<div class="col-xs-6">
							ANALYTICS
						</div>	
					</template>
				    <template #content>
				    	<div class="nav-tabsContainer">
							<ul class="nav nav-pills">
								<li :class="{'active':tab=='module'}">
									<a class="nav-link" data-toggle="tab" href="#prediction">Prediction</a>
								</li>
								<li :class="{'active':tab=='question_optimization'}">
									<a class="nav-link" data-toggle="tab" href="#question_optimization">Question Optimization</a>
								</li>
								<li :class="{'active':tab=='student_optimization'}">
									<a class="nav-link" data-toggle="tab" href="#student_optimization">Student Optimization</a>
								</li>
							</ul>
						</div>
						<div class="row tab-content">
							<div :class="{'active':tab=='module'}" id="prediction" class="tab-pane fade in">
								<prediction :courseObj="course"></prediction>
							</div>
							<div :class="{'active':tab=='question_optimization'}" id="question_optimization" class="tab-pane fade in">
								<question-optimization></question-optimization>
							</div>
							<div :class="{'active':tab=='student_optimization'}" id="student_optimization" class="tab-pane fade in">
								<student-optimization></student-optimization>
							</div>
						</div>
				  	</template>
				</kr-panel>
			</div>-->
            <div v-show="tab == 'downloadables'" id="downloadables" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 flexSpaceBetween align-items">
                            <div class="col-xs-6">Grades</div>
                            <div class="hidden-xs hidden-sm buttonGroup">
                                <button
                                    v-tooltip="{
                                        content:
                                            hasWritePrivilege(course) == -1 && !auth.isImpersonation()
                                                ? readOnlyLabel
                                                : course.students.length == 0 || course.activities.length == 0
                                                ? 'No grades to generate'
                                                : 'Generate Course Grades Report',
                                    }"
                                    class="btn btn-default mainBtn primaryHover hasToolTip"
                                    :disabled="
                                        hasWritePrivilege(course) == -1 || course.students.length == 0 || course.activities.length == 0 ? true : undefined
                                    "
                                    @click="openEmailGradesConfirmationModal"
                                >
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />Generate Course Grades Report
                                </button>
                            </div>
                            <div class="hidden-md hidden-lg buttonGroupInline">
                                <button
                                    v-tooltip="{
                                        content:
                                            hasWritePrivilege(course) == -1 && !auth.isImpersonation()
                                                ? readOnlyLabel
                                                : course.students.length == 0 || course.activities.length == 0
                                                ? 'No grades to generate'
                                                : 'Generate Course Grades Report',
                                    }"
                                    class="btn btn-default mainBtn primaryHover hasToolTip"
                                    :disabled="
                                        hasWritePrivilege(course) == -1 || course.students.length == 0 || course.activities.length == 0 ? true : undefined
                                    "
                                    @click="openEmailGradesConfirmationModal"
                                >
                                    <i class="fa fa-download marginRight8" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <kr-search
                            v-if="tab == 'downloadables'"
                            name="downloadables_listing"
                            :url="{
                                url: `/courses/${course.uuid}/downloadables`,
                            }"
                            :columns="{
                                name: 'display:NAME|sortable:false',
                                section: 'display:SECTION|sortable:false',
                                test_type: 'display:TYPE|sortable:false|width:140px',
                                generated_at: 'display:GENERATED AT|sortable:false|width:280px',
                                generated_by_user: 'display:GENERATED BY|sortable:false|width:145px',
                                status: 'display:STATUS|sortable:false|width:125px',
                                action: 'display:|sortable:false|width:145px',
                            }"
                            :options="{
                                archived_field: false,
                                drop_page_number: false,
                                search_field: false,
                                thead_title: false,
                                hide_table_if_empty: true,
                                top_bar: false,
                                action_column: false,
                            }"
                        >
                            <template #cdata="props">
                                <td>{{ props.model.name }}</td>
                                <!--<td>{{props.model.module_name}}</td>-->
                                <td>{{ props.model.section_name }}</td>

                                <td>
                                    <template v-if="props.model.test_type == 'prework'"> PREWORK </template>
                                    <template v-else-if="props.model.test_type == 'irat'"> IRAT </template>
                                    <template v-else-if="props.model.test_type == 'trat'"> TRAT </template>
                                    <template v-else-if="props.model.test_type == 'iratntrat'"> IRAT & TRAT </template>
                                    <template v-else-if="props.model.test_type == 'application'"> Application Case </template>
                                    <template v-else-if="props.model.test_type == 'evaluation'"> 360 Degree Evaluation </template>
                                    <template v-else-if="props.model.test_type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                    <template v-else-if="props.model.test_type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                    <template v-else>
                                        {{ props.model.test_type }}
                                    </template>
                                </td>
                                <!--<td><template v-if="props.model.type=='test_grade_report'">
										TEST GRADE REPORT
									</template>
									<template v-if="props.model.type=='course_grade_report'">
										COURSE GRADE REPORT
									</template>
								</td>-->
                                <td>
                                    <template v-if="props.model.generated_at">
                                        {{ convertToReadableDate(props.model.generated_at, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(props.model.generated_at, 'DD MMM YYYY, h:mm a').current_timezone }}) </template
                                    ><template v-else> - </template>
                                </td>

                                <td>{{ props.model.generated_by_user }}</td>

                                <td>
                                    <template v-if="props.model.status == '0'">
                                        <div class="statusTag statusTagWarning">Pending</div>
                                    </template>
                                    <template v-else-if="props.model.status == '1'">
                                        <div class="statusTag statusTagSuccess">Ready</div>
                                    </template>
                                    <template v-else-if="props.model.status == '2'">
                                        <div class="statusTag statusTagDanger">Failed</div>
                                    </template>
                                </td>

                                <td>
                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="props.model.status != '1' ? true : undefined"
                                            aria-label="Actions"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <a
                                                        v-tooltip="
                                                            hasWritePrivilege(course) == -1
                                                                ? readOnlyLabel
                                                                : 'Download Grades Report for ' +
                                                                  (props.model.type == 'test_grade_report' ? 'Activity' : 'Course')
                                                        "
                                                        class="primary-state"
                                                        :class="{
                                                            disabled: hasWritePrivilege(course) == -1,
                                                        }"
                                                        href="#"
                                                        @click.prevent="hasWritePrivilege(course) == -1 ? '' : downloadReport(props.model)"
                                                    >
                                                        <span>
                                                            <i class="fa fa-download marginRight8" aria-hidden="true" />Download Grades Report for
                                                            <template v-if="props.model.type == 'test_grade_report'"> Activity </template>
                                                            <template v-else> Course </template>
                                                        </span>
                                                    </a>
                                                </li>
                                                <hr />
                                                <li>
                                                    <a
                                                        v-tooltip="hasWritePrivilege(course) == -1 ? readOnlyLabel : 'Delete Report'"
                                                        class="danger-state"
                                                        :class="{
                                                            disabled: hasWritePrivilege(course) == -1,
                                                        }"
                                                        @click.prevent="hasWritePrivilege(course) == -1 ? '' : openDeleteReportConfirmationModal(props.model)"
                                                    >
                                                        <span> <i class="fa fa-trash-o marginRight8" aria-hidden="true" />Delete Report </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- <div class="buttonGroupInline">
                    <router-link tag="button" v-tooltip="{content: 'Edit'}" v-if="!props.model.course.isTrashed&&hasWritePrivilege(props.model)" :to="{name:'courses.edit',params:{id:props.model.course.uuid}}" class="btn btn-default subBtn">
			                                <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
			                            </router-link>

                    <div
                      v-if="props.model.status == '1'"
                      class="btn p-0-i"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(course) == -1 &&
                          !auth.isImpersonation()
                            ? readOnlyLabel
                            : 'Download ' +
                              (props.model.type == 'test_grade_report'
                                ? 'Activity'
                                : 'Course') +
                              ' Grades Report',
                      }"
                    >
                      <button
                        class="btn btn-default subBtn primaryHover"
                        @click="downloadReport(props.model)"
                        :disabled="
                          hasWritePrivilege(course) == -1 &&
                            !auth.isImpersonation()
                        "
                        aria-label="Download Course Grades Report"
                      >
                        <i class="fa fa-download"></i>
                      </button>
                    </div>
                    <div
                      v-if="props.model.status == '1'"
                      class="btn p-0-i"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(course) == -1
                            ? readOnlyLabel
                            : 'Delete Report',
                      }"
                    >
                      <button
                        v-if="props.model.status == '1'"
                        class="btn btn-danger subBtn primaryHover"
                        @click="openDeleteReportConfirmationModal(props.model)"
                        :disabled="(hasWritePrivilege(course) == -1) ? true:undefined"
                        aria-label="Delete"
                      >
                        <i class="fa fa-trash-o"></i>
                      </button>
                    </div>
                  </div> -->
                                </td>
                            </template>
                            <template #ndata="props">
                                <div>No grades to export</div>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>

        <div
            id="suspendModuleModal"
            class="modal"
            :class="{
                'danger-modal': !moduleToCopy.isArchived,
                'warning-modal': moduleToCopy.isArchived,
            }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModuleModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModuleModal-title" class="modal-title">
                            <template v-if="!moduleToCopy.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                            This Module
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">
                            <template v-if="!moduleToCopy.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ moduleToCopy.name }}
                        </h2>

                        <div class="col-xs-12 col-md-8 col-md-offset-2">
                            <template v-if="!moduleToCopy.isArchived">
                                <p>
                                    Are you sure you want to
                                    <br />
                                    archive this module?
                                </p>
                                <br />
                                <p class="fontSize14 greyText">
                                    <i>
                                        All associated activities will also be
                                        <br />
                                        hidden until you reinstate this module.
                                    </i>
                                </p>
                            </template>
                            <template v-else>
                                <p>
                                    Are you sure you want to
                                    <br />
                                    reinstate this module?
                                </p>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button
                            class="btn"
                            :class="{
                                'btn-danger': !moduleToCopy.isArchived,
                                'btn-success': moduleToCopy.isArchived,
                            }"
                            data-dismiss="modal"
                            @click="toggleModule()"
                        >
                            <i v-if="!moduleToCopy.isArchived" class="fa fa-archive marginRight8" aria-hidden="true" />
                            <template v-if="!moduleToCopy.isArchived"> Yes, Archive </template>
                            <template v-else> Yes, Reinstate </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="transferModal" class="modal default-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="transferModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>
                        <h2 id="transferModal-title" class="modal-title">Transfer Ownership</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <p>Before you delete this course from your course list, please select the new owner of this course:</p>
                                <p>
                                    <b>{{ course.name }}</b>
                                </p>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom: 15px">
                            <transfer-form :form="transfer_form" :course="course" />
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-primary" data-dismiss="modal" @click="transfer">Confirm New Owner</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal primary-modal copyModuleModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyModuleModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyModuleModal-title" class="modal-title">Copy Module</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h2>Make a Copy</h2>

                        <div class="bg-grey padding15 marginBottom20 modalListingArray">
                            <h3 class="marginTop0">Modules</h3>
                            <ul>
                                <template v-if="moduleToCopy">
                                    <li>1. {{ moduleToCopy.name }}</li>
                                </template>
                                <template v-else>
                                    <li v-for="(module, idx) in arrayOfModulesToCopy" :key="'list_of_module_copy_' + idx">{{ idx + 1 }}. {{ module.name }}</li>
                                </template>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-md-8 col-md-offset-2">
                            <p>Please select a course</p>
                            <p>you wish to copy the selected module(s) to.</p>

                            <br />
                            <p>
                                <b>
                                    Note: only the corresponding activities and questions will be copied; student and team configurations will remain in this
                                    course.
                                </b>
                            </p>
                        </div>

                        <div class="form-group marginTop10 textAlignLeft">
                            <label>Course</label>
                            <select v-model="selectedCourse" class="form-control" :aria-label="selectedCourse">
                                <option :value="false">-</option>
                                <template v-for="(course, idx) in courseList" :key="`c-list-${idx}`">
                                    <option :value="course">
                                        {{ course.name }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" :disabled="!selectedCourse" @click="copyModule()">Yes, Copy Module(s)</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="copyActivityModal" class="modal default-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyActivityModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyActivityModal-title" class="modal-title">Copy Activity</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h2>You are making a copy of</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ activityToDelete.name }}
                        </h2>
                        <br />
                        <p>
                            <b> Continue? </b>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="copyActivity()">Yes, Copy Activity</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="deleteActivityModal" class="modal danger-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteActivityModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="deleteActivityModal-title" class="modal-title">Delete Activity</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">Delete</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ activityToDelete.name }}
                        </h2>

                        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
                            <p class="marginBottom10">
                                All existing data (student answers, grades, comments, attachments etc) for this activity will be erased.
                            </p>

                            <p class="marginBottom10">Please download the grades for this activity first if you wish to retain a copy.</p>

                            <p>To confirm, type out your InteDashboard email in the input below and then press the "Delete" button.</p>
                        </div>
                        <div class="row">
                            <div class="grey checkboxField justifyCenter alignFlexStart marginBottom20">
                                <input v-model="agree" type="checkbox" />
                                <label class="fw-normal">I understand and accept the risk!</label>
                            </div>
                            <div class="col-xs-12 col-md-12">
                                <div class="form-group">
                                    <input v-model="resetConfirmationField" class="form-control" :placeholder="`Type ${auth.user().email}`" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            class="btn btn-default"
                            data-dismiss="modal"
                            @click="
                                closeAllModal();
                                resetConfirmationField = '';
                                agree = false;
                            "
                        >
                            Cancel
                        </button>

                        <button class="btn btn-danger" data-dismiss="modal" :disabled="!canReset" @click.prevent="deleteActivity()">Delete</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="unpublishTestModal" class="modal danger-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="unpublishTestModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="unpublishTestModal-title" class="modal-title">Hide Activity</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">Hide</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ testToUnpublish.name }}
                        </h2>

                        <div class="col-xs-12 col-sm-8 col-sm-offset-2">
                            <p class="marginBottom10">
                                Students for this section will
                                <br />
                                no longer be able to see it.
                            </p>

                            <p>
                                Are you sure you want to
                                <br />
                                hide this activity?
                            </p>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="closeAllModal()">No, cancel</button>

                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="toggleTestPublish()">Yes, Hide</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="signInFormModal" class="modal default-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="signInFormModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="signInFormModal-title" class="modal-title">Express Sign-in form</h2>
                    </div>
                    <div class="modal-body">
                        <img src="/img/self-sign-in-form.png" width="100%" alt=" " />
                    </div>
                </div>
            </div>
        </div>

        <div
            id="emailGradesConfirmationModal"
            class="modal default-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="emailGradesConfirmationModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="emailGradesConfirmationModal-title" class="modal-title">Generate Course Grades Report</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h2>Generate Course Report:</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ course.name }}
                        </h2>

                        <p>
                            You will be notified when your Grades Report
                            <br />
                            is ready (approx. 2-3 minutes).
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>

                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="emailGrades()">Yes, Proceed</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="deleteReportModal" class="modal danger-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="deleteReportModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="deleteReportModal-title" class="modal-title">Delete Report</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">Delete Report</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ report.name }}
                        </h2>

                        <p>
                            Are you sure you want to
                            <br />
                            delete this report?
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="closeAllModal()">No, cancel</button>

                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteReport()">Yes, Delete</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="dashboardResetModal" class="modal danger-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="dashboardResetModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="dashboardResetModal-title" class="modal-title">Reset Activity</h2>
                    </div>

                    <div class="modal-body">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h1 class="textAlignCenter marginTop40">Reset</h1>

                        <h2 class="marginBottom20 textAlignCenter fw-normal">
                            {{ activityToReset.name }}
                        </h2>

                        <h3 class="textAlignCenter fw-normal">
                            Current data in
                            <b class="important">all</b> activity sections
                        </h3>

                        <h3 class="textAlignCenter fw-normal">will be <b class="important">lost</b> upon reset.</h3>

                        <h3 class="textAlignCenter fw-normal marginBottom10">This <b class="important">cannot be reversed</b>.</h3>

                        <h4 class="textAlignCenter fw-normal marginBottom5 marginTop30">
                            We recommend that you download the <br />
                            activity data first.
                        </h4>

                        <div class="grey checkboxField alignFlexStart marginTop30 marginBottom5">
                            <input v-model="agree" type="checkbox" />
                            <label class="fw-normal">I have downloaded the data for this activity.</label>
                        </div>

                        <label for="email">
                            <b> Type your email below to continue. </b>
                        </label>

                        <div class="col-xs-12 col-md-12">
                            <div class="form-group">
                                <input id="email" v-model="resetConfirmationField" class="form-control" :placeholder="`${auth.user().email}`" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button
                            class="btn btn-default"
                            data-dismiss="modal"
                            @click="
                                closeAllModal();
                                resetConfirmationField = '';
                                agree = false;
                                downloadedGrade = null;
                            "
                        >
                            Go Back
                        </button>

                        <button
                            class="btn btn-danger"
                            data-dismiss="modal"
                            :disabled="!canReset"
                            @click.prevent="
                                downloadedGrade = null;
                                reset();
                            "
                        >
                            Reset Activity
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div
      class="modal alert-modal"
      id="dashboardDownloadGradeModal"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dashboardDownloadGradeModal-title"
    >
      	<div class="modal-dialog">
			<div class="preview modal-content">
				<div class="modal-header">
					<h1 id="dashboardDownloadGradeModal-title" class="modal-title">
						Reset Activity
					</h1>
				</div>	

				<div class="modal-body">

					<img src="/img/warning.png" width="10%" class="alert-modal-warning-icon" />

					<p class="textAlignCenter marginTop20">
						All data will be <b class="important">erased</b>.
					</p>
					<p class="textAlignCenter marginTop20">
						Would you like to download the data first?
					</p>
				</div>

				<div class="modal-footer bg-white padding20 paddingTop10">
					<div class="hidden-xs hidden-sm flexSpaceBetween">
						<div>
							<button
								class="btn btn-link mainBtn"
								data-dismiss="modal"
								aria-label="Go Back"
							>
								Go Back
							</button>
						</div>

						<div>
							<button
								class="btn btn-outline-danger mainBtn"
								data-dismiss="modal"
								aria-label="Reset without downloading"
								@click.prevent="openResetSecondStepConfirmationModal(false)"
							>
								Continue without downloading
							</button>

							<button
								class="btn btn-success mainBtn"
								data-dismiss="modal"
								aria-label="Download"
								@click.prevent="openResetSecondStepConfirmationModal(true)"
							>
								Download
							</button>
						</div>
					</div>
					<div class="hidden-md hidden-lg">
						<button
							class="col-xs-12 btn btn-link mainBtn"
							data-dismiss="modal"
							aria-label="Go Back"
						>
							Go Back
						</button>

						<button
							class="col-xs-12 btn btn-outline-danger mainBtn"
							data-dismiss="modal"
							aria-label="Reset without downloading"
							@click.prevent="openResetSecondStepConfirmationModal(false)"
						>
							Continue without downloading
						</button>

						<button
							class="col-xs-12 btn btn-success mainBtn"
							data-dismiss="modal"
							aria-label="Download"
							@click.prevent="openResetSecondStepConfirmationModal(true)"
						>
							Download
						</button>
					</div>
				</div>
			</div>
		</div>
    </div>

	<div
      class="modal alert-modal"
      id="dashboardResetModal"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dashboardResetModal-title"
    >
      	<div class="modal-dialog">
			<div class="preview modal-content">
				<div class="modal-header">
					<h1 id="dashboardResetModal-title" class="modal-title">
						Reset Activity
					</h1>
				</div>	

				<div class="modal-body">

					<img src="/img/warning.png" width="10%" class="alert-modal-warning-icon" />

					<template v-if="downloadedGrade">
						<p class="textAlignCenter marginBottom20">The data has been downloaded.</p>
					</template>
					<template v-else>
						<p class="textAlignCenter marginBottom20">The data has <b>not</b> been downloaded.</p>
					</template>

					<p class="textAlignCenter">
						Reset
					</p>

					<p class="marginBottom20 textAlignCenter">
						<b>{{ activityToReset.name }}</b>
					</p>

					<p class="textAlignCenter">
						All activity data will be <b class="important">erased</b>.
					</p>

					<p class="textAlignCenter">
						This <b class="important">cannot be reversed</b>.
					</p>

					<div
						class="
						grey
						checkboxField 
						align-items
						marginTop30
						marginBottom15
						"
					>
						<input type="checkbox" v-model="agree" />
						<template v-if="downloadedGrade">
							&nbsp;I understand and accept the risk!
						</template>
						<template v-else>
							&nbsp;I understand the risk that data not downloaded will be erased when activity is reset.
						</template>
					</div>

					<label for="email" class="fontSize16">
						<b>
							Type your email below to continue.
						</b>
					</label>

					<div class="col-xs-12 col-md-12">
						<div class="form-group">
						<input
							id="email"
							class="form-control"
							:placeholder="`Type ${auth.user().email}`"
							v-model="resetConfirmationField"
						/>
						</div>
					</div>
				</div>

				<div class="modal-footer bg-white padding20 paddingTop10">
					<div class="hidden-xs hidden-sm">
						<button
							id="modal-dismiss-btn"
							class="btn btn-default mainBtn"
							data-dismiss="modal"
							@click="
								resetConfirmationField = '';
								agree = false;
								downloadedGrade = null;
							"
							aria-label="Go Back"
						>
							Go Back
						</button>

						<button
							id="modal-logout-btn"
							class="btn btn-danger mainBtn"
							@click.prevent="
								downloadedGrade = null; reset();"
							data-dismiss="modal"
							:disabled="(!canReset) ? true:undefined"
							aria-label="Reset"
						>
							Reset Activity
						</button>
					</div>
					<div class="hidden-md hidden-lg">
						<button
							id="modal-dismiss-btn"
							class="col-xs-12 btn btn-default mainBtn"
							data-dismiss="modal"
							@click="
								resetConfirmationField = '';
								agree = false;
								downloadedGrade = null;
							"
							aria-label="Go Back"
						>
							Go Back
						</button>
						<button
							id="modal-logout-btn"
							class="col-xs-12 btn btn-danger mainBtn"
							@click.prevent="
								downloadedGrade = null; reset();"
							data-dismiss="modal"
							:disabled="(!canReset) ? true:undefined"
							aria-label="Reset"
						>
							Reset Activity
						</button>
					</div>
				</div>
			</div>
		</div>
    </div> -->

        <div
            id="gradesExportConfirmationModalActivity"
            class="modal default-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="gradesExportConfirmationModalActivityModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="gradesExportConfirmationModalActivityModal-title" class="modal-title">Generate Activity Grades Report</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2>Generate Activity Report:</h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ testToExportGrades.name }}
                        </h2>

                        <p>
                            You will be notified when your Grades Report
                            <br />
                            is ready (approx. 2-3 minutes).
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>

                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="emailGradesActivity()">Yes, Proceed</button>
                    </div>
                </div>
            </div>
        </div>
        <CreateTemplateComponent ref="createTemplateComponent"></CreateTemplateComponent>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
import CreateTemplateComponent from '../questions/library-templates/actions/createFromActivity.vue';
import { useAuthUserStore } from '../../../stores/auth';
export default {
    components: {
        'manage-students': require(`./students/manage.vue`).default,
        'manage-teachers': require(`./teachers/manage.vue`).default,
        'transfer-form': require(`./form-partials/partial.transfer.vue`).default,
        prediction: require(`./analytics/prediction.vue`).default,
        'question-optimization': require(`./analytics/question-optimization.vue`).default,
        'student-optimization': require(`./analytics/student-optimization.vue`).default,
        'course-settings': require(`./form-partials/partial.course-settings.vue`).default,
        'enrollment-settings': require(`./form-partials/partial.enrollment-settings.vue`).default,
        'lti1p3-settings': require(`./form-partials/partial.lti1p3-settings.vue`).default,
        CreateTemplateComponent,
    },
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.updateOthers, 3000);
        return {
            store: useAuthUserStore(),
            component_done_loading: false,
            course: null,
            isMoreOptionsOpened: false,
            auth: auth,
            tab: 'module',
            transfer_form: new KrForm({
                fields: {
                    userUuid: '',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
            }),
            moduleToCopy: {},
            arrayOfModulesToCopy: [],
            moduleSearchData: {
                query: { isTrashed: 0 },
                data: { data: { data: [] } },
            },
            modules: [],
            activityToDelete: {},
            testToUnpublish: {},
            report: {},
            readOnlyLabel: 'You have read-only access',
            courseList: [],
            selectedCourse: false,
            selectedModules: [],
            query_fields: { isArchived: '0', sectionUuid: '' },
            selectAllModules: false,
            order: 'desc',
            sort: 'Name',
            sectionList: [],
            currentDraggedItem: null,
            stopScroll: false,
            isOpenModule: [],
            isOpenActivity: [],
            activityToReset: {},
            testToExportGrades: {},
            agree: false,
            resetConfirmationField: '',
            API_URL: auth.credential().API_URL,
            EXPRESS_SIGN_IN_URL: process.env.VUE_APP_EXPRESS_SIGN_IN_URL,
            form: new KrForm({
                fields: {
                    anonymous: '',
                    isSelfSignIn: '',
                    allowedDomains: '',
                    isSecretPin: '',
                    secretPin: '',
                    ssoGoogle: '',
                    ssoGoogleAllowedDomains: '',
                    ssoMicrosoft: '',
                    ssoMicrosoftAllowedDomains: '',
                },
            }),
            debouncedSubmit: debouncedSubmit,
            latestModelCopy: {},
            downloadedGrade: null,
            updateManageValue: false,
        };
    },

    computed: {
        canReset() {
            if (this.agree && this.resetConfirmationField.toLowerCase() == this.auth.user().email.toLowerCase()) {
                return true;
            }
            return false;
        },
        canDisplayLti1p3() {
            if (
                [
                    '08299f88-6223-49aa-84e7-1958d703de6e',
                    'd1505db9-f490-4d30-81c8-0501410aecee',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                    '937caf1e-aa76-4f46-a391-86aef3941638',
                    '58be2094-6f74-4087-bba5-ace0b7e3b651',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                    'd89d09f5-ec57-47af-8c4b-dd4291e3100d',
                    '04dee48c-19f0-43b2-87ed-5efb86eed881',
                    '8730eab5-a205-4cb3-85d9-9c10dcf7a1f5',
                ].includes(this.auth.user().account.uuid) ||
                process.env.VUE_APP_ENV == 'staging'
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            var that = this;
            this.fetch();
        },
        selectedModules: {
            handler: function () {
                if (this.modules.length == this.selectedModules.length && this.modules.length != 0) {
                    this.selectAllModules = true;
                } else {
                    this.selectAllModules = false;
                }
            },
        },
        tab: {
            handler: function () {
                if (!this.course) {
                    return;
                }
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Courses', link: 'courses.index' },
                        {
                            name: this.course.name + (this.course.code ? ' (' + this.course.code + ')' : ''),
                        },
                        {
                            name:
                                this.tab == 'module'
                                    ? 'Module & Activities'
                                    : this.tab == 'student'
                                    ? 'Students'
                                    : this.tab == 'downloadables'
                                    ? 'Grades'
                                    : this.tab == 'overview'
                                    ? 'Settings'
                                    : '',
                        },
                    ],
                });
                var tabName =
                    this.tab == 'module'
                        ? 'Module & Activities'
                        : this.tab == 'student'
                        ? 'Students'
                        : this.tab == 'downloadables'
                        ? 'Grades'
                        : this.tab == 'overview'
                        ? 'Settings'
                        : '';
                document.title = `${tabName} | ${this.course.name} | InteDashboard | TBL Makes Teams Work`;
            },
        },
    },
    created() {
        this.tab = _.get(this, '$route.query.tab', 'module');
        var that = this;
        if (this.tab == 'permission') {
            this.tab = 'overview';
        }
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
        let courses = this.store.courses;
        let idx = _.findIndex(courses, function (o) {
            return o.uuid == that.$route.params.id;
        });
        if (idx != -1) {
            let selectedSectionUuid = courses[idx].selectedSectionUuid;
            if (selectedSectionUuid) {
                this.query_fields.sectionUuid = selectedSectionUuid;
            }
        }
        this.fetch();
        this.processEchoListeners();

        // console.log("padding of div em  : " + window.getComputedStyle(em).padding);
        // console.log("padding of div rem : " + window.getComputedStyle(rem).padding);
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                if (e.courseUuid != that.course.uuid) {
                    return;
                }
                if (that.tab == 'downloadables') {
                    Events.fire('downloadables_listing_refresh');
                }
            };
            let c1 = window.Echo.private(`user.${this.auth.user().uuid}`).listen('DownloadableStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'DownloadableStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.test.uuid == o.uuid;
                        });
                        if (test) {
                            test.status = e.test.status;
                            test.settings.startTime = e.test.settings.startTime;
                            test.settings.endTime = e.test.settings.endTime;
                            return;
                        }
                    }
                }
            };
            let c2 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('TestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'TestStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c3 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityUnpublished', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityUnpublished',
                handler: h3,
            });

            let h4 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c4 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityDeleted', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ActivityDeleted',
                handler: h4,
            });

            let h5 = (e) => {
                if (e.courseUuid != that.course.uuid) {
                    return;
                }
                if (that.tab == 'downloadables') {
                    Events.fire('downloadables_listing_refresh');
                }
            };
            let c5 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('DownloadablesListUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'DownloadablesListUpdated',
                handler: h5,
            });

            let h6 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.testUuid == o.uuid;
                        });
                        if (test) {
                            test.expectedEndingTime = e.expectedEndingTime;
                            test.startedEarly = true;
                            return;
                        }
                    }
                }
            };
            let c6 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('EarlyTestStarted', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'EarlyTestStarted',
                handler: h6,
            });
            let h7 = (e) => {
                for (var i = 0; i < that.moduleSearchData.data.data.data.length; i++) {
                    let module = that.moduleSearchData.data.data.data[i];
                    let activities = module.activities;

                    for (var j = 0; j < activities.length; j++) {
                        let tests = activities[j].tests;

                        let test = _.find(tests, function (o) {
                            return e.testUuid == o.uuid;
                        });
                        if (test) {
                            test.expectedEndingTime = null;
                            return;
                        }
                    }
                }
            };
            let c7 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('EarlyTestEnded', h7);
            this.echoChannels.push({
                channel: c7,
                event: 'EarlyTestEnded',
                handler: h7,
            });

            let h8 = (e) => {
                Events.fire('modules_listing_refresh');
            };
            let c8 = window.Echo.private(`course.${this.$route.params.id}.teacher`).listen('ActivityPublished', h8);
            this.echoChannels.push({
                channel: c8,
                event: 'ActivityPublished',
                handler: h8,
            });
        },
        processStatusDisplay(test) {
            if (!test) {
                return '';
            }
            if (_.get(test, 'settings.type') == 'synchronous') {
                if (_.get(test, 'status') == 'not started') {
                    return 'Not Started';
                } else if (_.get(test, 'status') == 'ongoing') {
                    if (!_.get(test, 'settings.hasTimeLimit')) {
                        return 'Opened';
                    } else {
                        //hasTimeLimit
                        return '[00:00:00]';
                    }
                } else if (_.get(test, 'status') == 'paused') {
                    return 'Paused';
                } else if (['ended', 'completed'].includes(_.get(test, 'status'))) {
                    return 'Closed';
                }
            } else if (_.get(test, 'settings.type') == 'asynchronous') {
                if (_.get(test, 'status') == 'not started') {
                    return 'Starts in {x}';
                } else if (_.get(test, 'status') == 'ongoing') {
                    return 'Opened';
                } else if (['ended', 'completed'].includes(_.get(test, 'status'))) {
                    return 'Closed';
                }
            }
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        dragFunction(e) {
            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (e.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (e.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        updateModulesAndActivitiesOrder(type = 'module', order, uuids = { uuid: '', moduleUuid: '' }) {
            axios.post(`courses/${this.course.uuid}/modules/reorder`, {
                type: type,
                order: order,
                ...uuids,
            });
        },
        dropToTest(moduleIdx, activityIdx, testIdx, offset) {
            let testUuid = this.modules[moduleIdx].activities[activityIdx].tests[testIdx].uuid;
            this.array_move(this.modules[moduleIdx].activities[activityIdx].tests, testIdx, testIdx + offset);
            this.updateModulesAndActivitiesOrder('test', testIdx + offset + 1, {
                uuid: testUuid,
            });
        },
        dropToModule(event, moduleIdx) {
            var data = JSON.parse(event.dataTransfer.getData('text'));
            this.currentDraggedItem = null;
            if (data.is != 'module' && !(data.is == 'activity' && data.moduleIdx != moduleIdx)) {
                return;
            }
            this.currentDraggedItem = null;
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            var target = $(event.target).closest('.moduleTable')[0];
            $(target).removeClass('selected-bottom');
            $(target).removeClass('selected-top');
            if (data.is == 'module') {
                this.array_move(this.modules, data.moduleIdx, moduleIdx);
            } else {
                if (this.modules[moduleIdx].activities.length == 0) {
                    this.dropToActivity(event, moduleIdx, 0);
                }
                return;
            }
            let uuids = {};
            let order = moduleIdx + 1;
            uuids.uuid = data.uuid;
            if (data.is == 'activity') {
                uuids.moduleUuid = this.modules[moduleIdx].uuid;
                order = this.modules[moduleIdx].activities.length;
            }
            this.updateModulesAndActivitiesOrder(data.is, order, uuids);
        },
        leaveDragFromModule(event) {
            var target = $(event.target).closest('.moduleTable')[0];
            if ($(target).hasClass('moduleTable')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToModule(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('moduleRow')) {
                var target = $(event.target).closest('.moduleTable')[0];
                if (!target) {
                    return;
                }
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragModule(event, moduleIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData(
                'text',
                JSON.stringify({
                    is: 'module',
                    uuid: this.modules[moduleIdx].uuid,
                    moduleIdx: moduleIdx,
                })
            );
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        dropToActivity(event, moduleIdx, activityIdx) {
            var data = JSON.parse(event.dataTransfer.getData('text'));
            this.currentDraggedItem = null;
            if (data.is != 'activity') {
                return;
            }
            if (event.preventDefault) {
                event.preventDefault();
            }

            var target = $(event.target).closest('.activityTable')[0];
            if ($(target).hasClass('selected-bottom') && moduleIdx != data.moduleIdx) {
                activityIdx++;
            }
            $(target).removeClass('selected-bottom');
            $(target).removeClass('selected-top');
            let uuids = {};
            uuids.uuid = data.uuid;
            if (moduleIdx == data.moduleIdx) {
                this.array_move(this.modules[data.moduleIdx].activities, data.activityIdx, activityIdx);
            } else {
                uuids.moduleUuid = this.modules[moduleIdx].uuid;
                let a = _.clone(this.modules[data.moduleIdx].activities[data.activityIdx]);
                this.modules[data.moduleIdx].activities.splice(data.activityIdx, 1);
                this.modules[moduleIdx].activities.push(a);
                let l = this.modules[moduleIdx].activities.length - 1;
                this.array_move(this.modules[moduleIdx].activities, l, activityIdx);
            }
            this.updateModulesAndActivitiesOrder(data.is, activityIdx + 1, uuids);
        },
        leaveDragFromActivity(event) {
            var target = $(event.target).closest('.activityTable')[0];
            if ($(target).hasClass('activityTable')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        allowDropToActivity(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('activityTable')) {
                var target = $(event.target).closest('.activityTable')[0];
                if (!target) {
                    return;
                }
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }

            var that = this;
            var scroll = function (step) {
                var scrollY = $(window).scrollTop();
                $(window).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };

            this.stopScroll = true;
            if (event.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }

            if (event.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        dragActivity(event, moduleIdx, activityIdx) {
            if (this.currentDraggedItem != null) {
                return;
            }
            event.dataTransfer.setData(
                'text',
                JSON.stringify({
                    is: 'activity',
                    uuid: this.modules[moduleIdx].activities[activityIdx].uuid,
                    moduleIdx: moduleIdx,
                    activityIdx: activityIdx,
                })
            );
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = event.target;
        },
        syncCourseObject(data) {
            this.course = data;
        },
        toggleModules() {
            this.selectedModules = [];
            if (this.selectAllModules) {
                for (var i = 0; i < this.modules.length; i++) {
                    this.selectedModules.push(this.modules[i].uuid);
                }
            }
        },
        searchModule() {
            Events.fire('modules_listing_refresh');
        },
        downloadReport(report) {
            axios({
                url: `courses/${this.course.uuid}/downloadables/${report.id}`,
                method: 'POST',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report.filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        deleteReport() {
            var that = this;
            axios
                .delete('courses/' + this.course.uuid + '/downloadables/' + this.report.id)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Report has successfully been deleted',
                    });
                    Events.fire('downloadables_listing_refresh');
                })
                .catch(function (errors) {
                    //test.isPublished = !test.isPublished;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to delete report at this moment, please try again later',
                    });
                });
        },
        openDeleteReportConfirmationModal(report) {
            this.report = report;
            $('#deleteReportModal').modal('show');
        },
        processSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/self-signin')
                .then(function (response) {
                    that.course.isSelfSignIn = !that.course.isSelfSignIn;
                })
                .catch(function (errors) {});
        },
        updateIsSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/express-sign-in', {
                    isSelfSignIn: this.form.model.isSelfSignIn,
                })
                .then(function (response) {
                    that.course.isSelfSignIn = that.form.model.isSelfSignIn;
                })
                .catch(function (errors) {});
        },
        updateOthers() {
            var that = this;

            if (this.form.model.anonymous) {
                this.form.model.allowedDomains = '';
                this.form.model.ssoGoogle = false;
                this.form.model.ssoMicrosoft = false;
            }
            if (!this.form.model.ssoGoogle) {
                this.form.model.ssoGoogleAllowedDomains = '';
            }
            if (!this.form.model.ssoMicrosoft) {
                this.form.model.ssoMicrosoftAllowedDomains = '';
            }
            let allowedDomains = [];
            if (this.form.model.allowedDomains != null && this.form.model.allowedDomains.trim().length !== 0) {
                allowedDomains = this.form.model.allowedDomains.split(',');
            }
            let ssoGoogleAllowedDomains = [];
            if (this.form.model.ssoGoogleAllowedDomains != null && this.form.model.ssoGoogleAllowedDomains.trim().length !== 0) {
                ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.split(',');
            }
            let ssoMicrosoftAllowedDomains = [];
            if (this.form.model.ssoMicrosoftAllowedDomains != null && this.form.model.ssoMicrosoftAllowedDomains.trim().length !== 0) {
                ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.split(',');
            }

            if (!this.form.model.anonymous) {
                this.form.model.isSecretPin = false;
            }
            if (!this.form.model.isSecretPin) {
                this.form.model.secretPin = '';
            }
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                return;
            } else {
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            axios
                .patch('/courses/' + this.course.uuid + '/express-sign-in', {
                    anonymous: this.form.model.anonymous,
                    allowedDomains: allowedDomains,
                    secretPin: this.form.model.secretPin,
                    ssoGoogle: this.form.model.ssoGoogle,
                    ssoGoogleAllowedDomains: ssoGoogleAllowedDomains,
                    ssoMicrosoft: this.form.model.ssoMicrosoft,
                    ssoMicrosoftAllowedDomains: ssoMicrosoftAllowedDomains,
                })
                .then(function (response) {
                    that.course.anonymous = that.form.model.isSelfSignIn;
                    that.course.allowedDomains = that.form.model.allowedDomains;
                    that.course.secretPin = that.form.model.secretPin;
                    that.course.ssoGoogle = that.form.model.ssoGoogle;
                    that.course.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains;
                    that.course.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains;
                })
                .catch(function (errors) {});
        },
        activitiesCount(module) {
            let activities = _.get(module, 'activities', []);
            if (activities.length == 0) {
                return 0;
            }
            let c = 0;
            for (var i = 0; i < activities.length; i++) {
                let tests = _.get(activities[i], 'tests', []);
                if (tests.length != 0 || !activities[i].isPublished) {
                    c++;
                }
            }
            return c;
        },
        hasStudents(activity) {
            for (var i = 0; i < activity.tests.length; i++) {
                if (activity.tests[i].students.length != 0) {
                    return true;
                }
            }
            return false;
        },
        openEmailGradesConfirmationModal() {
            $('#emailGradesConfirmationModal').modal('show');
        },
        emailGrades() {
            var that = this;
            axios({
                url: '/courses/' + this.course.uuid + '/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                /*const url = window.URL.createObjectURL(new Blob([response.data]));
	              const link = document.createElement('a');
	              link.href = url;
	              link.setAttribute('download',course.name+'_'+moment().format('YYYY_MM_DD')+'.xlsx');
	              document.body.appendChild(link);
	              link.click();
	              link.remove();*/
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'We will generate the Grades Report for this course and notify you once finished',
                });
            });
        },
        openUnpublishTestModal(test) {
            this.testToUnpublish = test;
            $('#unpublishTestModal').modal('show');
        },
        openDeleteActivityModal(activity) {
            this.activityToDelete = activity;
            $('#deleteActivityModal').modal('show');
        },
        deleteActivity() {
            var that = this;
            axios
                .delete('activities/' + this.activityToDelete.uuid)
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Activity has successfully been deleted',
                    });
                    Events.fire('modules_listing_refresh');
                })
                .catch(function (errors) {
                    //test.isPublished = !test.isPublished;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to delete activity at this moment, please try again later',
                    });
                });
        },
        canEditActivity(activity) {
            /*for(var i = 0; i<activity.tests.length;i++){
        			if(!['not started','scheduled'].includes(activity.tests[i].status)){
        				return false;
        			}
        		}
        		return true;*/

            if (activity.isPublished) {
                return false;
            }
            return true;
        },
        canDeleteActivity(activity) {
            for (var i = 0; i < activity.tests.length; i++) {
                if (!['not started', 'draft', 'scheduled', 'ended', 'completed'].includes(activity.tests[i].status)) {
                    return false;
                }
            }
            return true;
        },
        toggleTestPublish(test = this.testToUnpublish) {
            var that = this;
            axios
                .post('tests/' + test.uuid + '/publish', {})
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test publish has been successfully changed',
                    });
                    test.isPublished = !test.isPublished;
                })
                .catch(function (errors) {
                    //test.isPublished = !test.isPublished;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change publish status at this moment, please try again later',
                    });
                });
        },
        toggleModule() {
            $('#suspendModuleModal').modal('hide');
            var success = function () {
                this.moduleToCopy.isArchived = !this.moduleToCopy.isArchived;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully ' + (this.moduleToCopy.isArchived ? 'archived' : 'reinstated') + ' this module',
                });
                Events.fire('modules_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/modules/' + this.moduleToCopy.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        openArchiveModuleModal(module) {
            this.moduleToCopy = module;
            $('#suspendModuleModal').modal('show');
        },
        processRetrieveModulesData(data) {
            var that = this;
            this.courseList = data.meta.courses;
            this.sectionList = data.meta.sections;
            if (!this.query_fields.sectionUuid) {
                this.query_fields.sectionUuid = this.sectionList.length != 0 ? this.sectionList[0].uuid : '';
            }
            /*let idx = _.findIndex(this.courseList, function(o) {
        return o.uuid == that.course.uuid;
      });
      if (idx != -1) {
        this.courseList.splice(idx, 1);
      }*/
            this.moduleSearchData = data;
            let hidden_modules = [];
            let courses = this.store.courses;
            let idx = _.findIndex(courses, function (o) {
                return o.uuid == that.course.uuid;
            });
            if (idx != -1) {
                let sections = courses[idx].sections;
                let idx2 = _.findIndex(sections, function (o) {
                    return o.uuid == that.query_fields.sectionUuid;
                });
                if (idx2 != -1) {
                    hidden_modules = sections[idx2].hidden_modules;
                }
            }
            for (var i = 0; i < data.data.data.data.length; i++) {
                let tempData = data.data.data.data[i];
                //console.log(tempData);
                let idx = _.findIndex(this.modules, function (o) {
                    return o.uuid == tempData.uuid;
                });
                if (idx == -1) {
                    if (hidden_modules.includes(tempData.uuid)) {
                        tempData._display = false;
                    } else {
                        tempData._display = true;
                    }
                    this.modules.push(tempData);
                } else {
                    this.modules[idx] = tempData;
                    if (hidden_modules.includes(this.modules[idx].uuid)) {
                        this.modules[idx]._display = false;
                    } else {
                        this.modules[idx]._display = true;
                    }
                }
            }
            for (var i = this.modules.length - 1; i >= 0; i--) {
                let a = this.modules[i];
                let idx = _.findIndex(data.data.data.data, function (o) {
                    return o.uuid == a.uuid;
                });
                if (idx == -1) {
                    this.modules.splice(i, 1);
                }
            }
            for (var i = 0; i < this.modules.length; i++) {
                let a = this.modules[i];
                for (var j = a.activities.length - 1; j > -1; j--) {
                    if (a.activities[j].tests.length === 0 && a.activities[j].isPublished) {
                        a.activities.splice(j, 1);
                    }
                }
            }
            let postfix = 'd'; //desktop
            if ($(window).width() < 992) {
                postfix = 'm';
            }
            let activityUuid = _.get(this.$route, 'query.activityUuid', false);
            if (activityUuid) {
                Vue.nextTick(function () {
                    $('#' + activityUuid + '-' + postfix).focus();
                });
            }
            let moduleUuid = _.get(this.$route, 'query.moduleUuid', false);
            if (moduleUuid) {
                Vue.nextTick(function () {
                    $('#' + moduleUuid).focus();
                });
            }
        },
        openSignInFormModal() {
            $('#signInFormModal').modal('show');
        },
        openCopyModuleConfirmationModal(module) {
            // console.log(module);
            if (module) {
                this.moduleToCopy = module;
            } else {
                this.moduleToCopy = '';
                this.selectedModules.forEach((value) => {
                    const index = this.modules.findIndex((obj) => obj.uuid === value);
                    this.arrayOfModulesToCopy.push(this.modules[index]);
                });
            }

            // console.log(this.arrayOfModulesToCopy);
            $('.copyModuleModal').modal('show');
        },
        copyModule() {
            var that = this;
            axios
                .post('modules/copy', {
                    courseUuid: this.selectedCourse.uuid,
                    moduleUuids: this.selectedModules,
                })
                .then(function (response) {
                    that.selectedModules = [];
                    that.selectedCourse = false;
                    $('.copyModuleModal').modal('hide');
                    Events.fire('modules_listing_refresh');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Module(s) has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy module(s) at this moment, please try again later',
                    });
                });
        },
        openCopyActivityConfirmationModal(activity) {
            this.activityToDelete = activity;
            $('#copyActivityModal').modal('show');
        },
        copyActivity() {
            var that = this;
            axios
                .post('activities/' + this.activityToDelete.uuid + '/duplicate')
                .then(function (response) {
                    Events.fire('modules_listing_refresh');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Activity has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy activity at this moment, please try again later',
                    });
                });
        },
        openResetConfirmationModal(activity) {
            this.activityToReset = activity;
            // $("#dashboardDownloadGradeModal").modal("show");
            $('#dashboardResetModal').modal('show');
        },
        // openResetSecondStepConfirmationModal(value) {
        // 	this.downloadedGrade = value;
        //   	$("#dashboardResetModal").modal("show");
        // },
        reset() {
            var that = this;
            axios
                .post('activities/' + this.activityToReset.uuid + '/unpublish')
                .then(function (response) {
                    //that.testObj.status = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Activity has been reset',
                    });
                    that.$router.push({
                        name: 'courses.modules.activities.republish',
                        params: { id: that.activityToReset.uuid },
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to reset activity at this moment, please try again later',
                    });
                });
        },
        openGradesExportConfirmationModalActivity(test) {
            this.testToExportGrades = test;
            $('#gradesExportConfirmationModalActivity').modal('show');
        },
        emailGradesActivity() {
            var that = this;
            axios({
                url: '/tests/' + this.testToExportGrades.uuid + '/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                /*const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',course.name+'_'+moment().format('YYYY_MM_DD')+'.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();*/
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'We will generate the Grades Report for this activity and notify you once finished',
                });
            });
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
        getActivityModality(activity) {
            return _.get(activity, 'settings.' + activity.type + '.type', '');
        },
        getTestModality(test) {
            return _.get(test, 'settings.type', '');
        },
        openTransferModal() {
            $('#transferModal').modal('show');
        },
        openSuspendModal() {
            /*if(this.getPrivilege(this.auth.user().uuid,this.course)=='Owner'){
        			this.openTransferModal();
        		}else{
        		*/
            $('#suspendModal').modal('show');
        },
        transfer() {
            $('#transferModal').modal('hide');
            var success = function () {
                var that = this;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have successfully transfered ownership of this course',
                });
                let idx = _.findIndex(this.course.teachers, function (o) {
                    return o.uuid == that.auth.user().uuid;
                });
                if (idx != -1) {
                    //temporarily convert to full Access upon transfer
                    this.course.teachers[idx].accountType = 'Full Access';
                }
                this.openSuspendModal();
            }.bind(this);
            var del = function (message = false) {
                if (!message) {
                    message = 'Something went wrong, please try again later';
                }
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: message,
                });
            }.bind(this);
            this.transfer_form.post('/courses/' + this.course.uuid + '/' + 'users/' + 'transfer-ownership').then(function (response) {
                if (!response.krFormError) {
                    success();
                } else {
                    if (response.data.message) {
                        del(response.data.message);
                    } else {
                        del();
                    }
                }
            });
        },
        fetch() {
            let that = this;
            axios.get('/courses/' + this.$route.params.id).then(function (response) {
                that.course = response.data.data;
                let replaceValues = (str, params) => str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m));
                that.EXPRESS_SIGN_IN_URL = replaceValues(that.EXPRESS_SIGN_IN_URL, { courseID: that.course.urlCode });
                //Vue.set(that.course,'allowSelfSignIn',false);
                /*for(var i = 0 ;i < that.course.modules.length;i++){
     					let mod = that.course.modules[i];
     					mod.activities = _.filter(that.course.activities,function(o){
     						return o.modules_ref[0]==mod.id;
     					});
     				}*/
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Courses', link: 'courses.index' },
                        {
                            name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                        },
                        {
                            name:
                                that.tab == 'module'
                                    ? 'Module & Activities'
                                    : that.tab == 'student'
                                    ? 'Students'
                                    : that.tab == 'downloadables'
                                    ? 'Grades'
                                    : that.tab == 'overview'
                                    ? 'Settings'
                                    : '',
                        },
                    ],
                });
                that.component_done_loading = true;

                document.title = `Module & Activities | ${that.course.name} | InteDashboard | TBL Makes Teams Work`;

                that.form.setModel(that.course);

                that.form.model.isSecretPin = that.course.secretPin ? true : false;

                if (that.course.allowedDomains != null) {
                    that.form.model.allowedDomains = that.form.model.allowedDomains.toString();
                }

                if (that.course.ssoGoogleAllowedDomains != null) {
                    that.form.model.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains.toString();
                }

                if (that.course.ssoMicrosoftAllowedDomains != null) {
                    that.form.model.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains.toString();
                }
            });
        },
        init() {
            this.sortList();
        },
        sortList(moduleIdx) {
            this.modules[moduleIdx].activities = _.orderBy(this.modules[moduleIdx].activities, [this.sort], [this.order]);
        },
        toggle(column = '', moduleIdx) {
            if (this.sort == column) {
                if (this.order == 'asc') {
                    this.order = 'desc';
                } else {
                    this.order = 'asc';
                }
            } else {
                this.order = 'asc';
                this.sort = column;
            }
            this.sortList(moduleIdx);
        },
        canDisplayExportGradesButton(activity, test) {
            if (!['completed', 'ended'].includes(test.status) || test.type == 'prework') {
                return false;
            }
            if (test.type == 'application' && !_.get(activity, 'settings.application.isApplicationGraded')) {
                return false;
            }
            return true;
        },
        lookForValue(newValue) {
            return (this.updateManageValue = newValue);
        },
        getBackToModule() {
            return (this.tab = 'module');
        },
        createTemplate(activity) {
            this.$refs.createTemplateComponent.createFromActivity(activity);
        },
    },
};
</script>
<style scoped lang="scss">
.removeFlex > div {
    display: contents;
}

.removeFlex,
.removeFlex ~ div {
    margin-left: 20px;
}

.LMSRow {
    padding: 20px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    // border: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
        // border-bottom: 1px solid #d8d8d8;
        border-bottom: none;
    }

    // &:first-of-type {
    //   border: 1px solid #d8d8d8;
    // }
}

#signin {
    .LMSRow {
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;

        &:only-child,
        &:first-child {
            border-top: 1px solid #d8d8d8;
        }
    }
}

.LMSTitle {
    font-weight: bold;
}

// #viewDropdownMenuButton {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .viewDropdown {
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// }

.card-container {
    padding: 0 20px;
}

.card-body {
    padding-bottom: 20px;
}

.card-container .btn {
    margin-left: 0;
}

.card-body > .col-xs-12:last-child > .form-control-static:last-child {
    margin-bottom: 0;
}

.card-container .mainModulesBtn,
.card-container .mainActivityBtn,
#courseOverview .x_content .mainCourseBtn {
    margin-bottom: 20px;
}

.card-body .btn.subBtn {
    margin-bottom: 20px;
}

#courseOverview .x_content .btn-custom {
    background-color: #f6f8fa;
    border: solid 1px #d8d8d8;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    color: #717171;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom .fa-stack {
    // font-size: 8px;
    font-size: 0.5em;
}

#courseOverview .x_content .btn-custom .fa-circle {
    color: #d8d8d8;
}

#courseOverview .x_content .btn-custom:active,
#courseOverview .x_content .btn-custom:hover,
#courseOverview .x_content .btn-custom:visited,
#courseOverview .x_content .btn-custom:focus,
#courseOverview .x_content .btn-custom:active:hover,
#courseOverview .x_content .btn-custom:active:focus {
    border: solid 1px #1d83c7;
    background-color: rgba(255, 255, 255, 0.05);
    color: #222222;
    font-weight: bold;
}

#courseOverview .x_content .btn-custom:active .fa,
#courseOverview .x_content .btn-custom:hover .fa,
#courseOverview .x_content .btn-custom:visited .fa,
#courseOverview .x_content .btn-custom:focus .fa,
#courseOverview .x_content .btn-custom:active:hover .fa,
#courseOverview .x_content .btn-custom:active:focus .fa,
#courseOverview .x_content .btn-custom:active .far,
#courseOverview .x_content .btn-custom:hover .far,
#courseOverview .x_content .btn-custom:visited .far,
#courseOverview .x_content .btn-custom:focus .far,
#courseOverview .x_content .btn-custom:active:hover .far,
#courseOverview .x_content .btn-custom:active:focus .far,
#courseOverview .x_content .btn-custom:active .fas,
#courseOverview .x_content .btn-custom:hover .fas,
#courseOverview .x_content .btn-custom:visited .fas,
#courseOverview .x_content .btn-custom:focus .fas,
#courseOverview .x_content .btn-custom:active:hover .fas,
#courseOverview .x_content .btn-custom:active:focus .fas {
    color: #0071be;
}

@media (min-width: 992px) {
    .LMSSubCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .LMSContainer {
        display: inline-flex;
    }

    .LMSTitle {
        width: 150px;
        display: flex;
        justify-content: space-between;
    }

    .LMSContent {
        margin-left: 20px;
    }
}

@media (max-width: 991px) {
    .LMSSubCon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .LMSContainer {
        width: 100%;
        overflow: hidden;
    }

    .LMSBtn {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 100px;
        justify-content: flex-end;
        height: 100%;
    }

    .LMSBtn .hidden-md.hidden-lg {
        display: flex;
    }

    .LMSBtn .hidden-md.hidden-lg .mainBtn {
        margin-left: 0;
    }
}
.subTable {
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    th {
        position: relative;

        button {
            right: 20px;
            padding: 0;
            font-size: 0.6rem;
            border-radius: 2px;

            .fa.fa-sort {
                margin-left: 0;
            }
        }
    }
}
.btn-grey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #717171;
    display: inline-block;
    padding: 6px 12px;
    // font-size: 14px;
    font-size: 0.875em;
    line-height: 1.2;
}

.subTable .fa-ellipsis-v {
    color: #717171;
    // font-size: 12px;
    font-size: 0.75em;
}

.blueTitle {
    margin-left: 20px;
    color: #0071be;
    display: inline-block;
    // font-size: 14px;
    font-size: 0.875em;
    text-transform: capitalize;
}

.activityHeaders th {
    padding-top: 6px;
    padding-bottom: 4px;
    // font-size: 10px;
    font-size: 0.7rem;
    color: #717171;

    i {
        color: #717171;
    }
}

.subContentDetail {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.subContentDetail:last-child {
    margin: 0;
}

.subContentDetail .header {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.subContentHeader {
    padding: 20px;
    font-weight: bold;
    background: #fbfbfb;
    border-bottom: 1px solid #d8d8d8;
    text-transform: uppercase;
}

.subContentDetails {
    padding: 20px;
}

.table .table.marginBottom20 {
    margin-bottom: 20px;
}
.subContent {
    padding: 0;
    border: 0;
    background: none;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}

ul.nav-pills {
    width: 100%;
    display: flex;
    justify-content: center;
}

ul.nav-pills li {
    background-color: #f2f2f2;
    border: solid 1px #d8d8d8;
    margin-left: 0px;
    border-radius: 4px;
}

ul.nav-pills li a {
    padding: 6px 15px 6px;
}

ul.nav-pills > li:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

ul.nav-pills > li:not(:first-child):not(:last-child) {
    border-radius: 0;
}

ul.nav-pills > li:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

ul.nav-pills > li.active {
    border: solid 1px #0071be;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

ul.nav-pills > li.active,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: black;
    background-color: white;
}

// .checkboxField input[type="checkbox"]:disabled {
//   opacity: 0.3;
// }

.new-panel {
    .x_content {
        .x_side_bar {
            width: 10%;
            height: auto;
            background: #eff2f6;
            border-right: 1px solid #ddd;
            padding: 10px;

            label {
                padding: 4px 9px;
            }

            .list-group {
                margin-bottom: 0;

                .list-group-item {
                    padding: 8px;
                    line-height: 0.8;
                    background: none;
                    border: none;
                    border-left: 2px solid transparent;
                    color: #333;

                    &:first-child,
                    &:last-child {
                        border-radius: 0;
                    }

                    &.active,
                    &:hover,
                    &:active,
                    &:focus {
                        border-left: 2px solid #3399ff;
                        // background: rgba(51, 153, 255, 0.3);
                        background: #fff;
                        color: #3399ff;
                    }
                }
            }
        }

        .x_with_side_bar {
            width: calc(100% - 10%);
            padding: 1.5rem;

            &.col-12 {
                width: 100%;
            }
        }
    }
}
.activityTable {
    border-top: 0;
}

.moduleActionHeader {
    width: 70px;
}

.modal-body {
    .row {
        &:not(:first-child) {
            margin-top: 20px;
        }

        .col-md-6 {
            padding-right: 10px;

            &:last-child {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }
}
.enrollmentSetting {
    //   width: 75%;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}
</style>
