<template>
    <div>
        <div
            class="flex flexRight marginBottom20"
            :class="{
                flexSpaceBetween: allowsUpdateCorrectAnswer || allowsChangeCorrectAnswer || allowsUpdateQuestionCopywriting,
            }"
        >
            <div v-if="allowsUpdateCorrectAnswer || allowsChangeCorrectAnswer || allowsUpdateQuestionCopywriting" class="flex align-items">
                <button
                    v-if="allowsUpdateCorrectAnswer"
                    v-tooltip="changeCorrectAnswerTooltip"
                    class="btn btn-default"
                    :class="{ 'disabled hasToolTip': isLocked }"
                    @click.prevent="isLocked ? '' : $refs['updateCorrectAnswerComponent'].accept(testObj, question, activitiesStatus)"
                >
                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i> Update Correct Answer
                </button>
                <button
                    v-if="allowsChangeCorrectAnswer"
                    v-tooltip="changeCorrectAnswerTooltip"
                    class="btn btn-default"
                    :class="{ 'disabled hasToolTip': isLocked }"
                    @click.prevent="isLocked ? '' : $refs['changeCorrectAnsComponent'].accept(testObj, question)"
                >
                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i> Change Correct Answer
                </button>
                <button
                    v-if="allowsUpdateQuestionCopywriting"
                    v-tooltip="testObj.linkedTRATUuid ? 'Modify function only available in TRAT' : ''"
                    class="btn btn-default"
                    :class="{ 'disabled hasToolTip': testObj.linkedTRATUuid }"
                    @click.prevent="testObj.linkedTRATUuid ? '' : $refs['changeQuestionCopyWritingComponent'].accept(testObj, question)"
                >
                    <span class="material-symbols-outlined marginRight8" aria-hidden="true"> edit_note </span>
                    Change Copywriting
                </button>
            </div>

            <div class="hidden-xs hidden-sm listingSwitch flexRight" role="menu">
                <button
                    v-tooltip="'Stacked view'"
                    type="button"
                    role="menuitem"
                    class="alignBaseLine"
                    :class="{ active: !question.enableStackingColumns }"
                    aria-label="Stacked view"
                    @click.prevent="setColumnToggle(false)"
                >
                    <i class="fa-solid fa-layer-group" aria-hidden="true"></i>
                </button>
                <button
                    v-tooltip="'Two columns view'"
                    type="button"
                    role="menuitem"
                    class="alignBaseLine"
                    :class="{ active: question.enableStackingColumns }"
                    aria-label="Two columns view"
                    @click.prevent="setColumnToggle(true)"
                >
                    <i class="fa-solid fa-table-columns" aria-hidden="true"></i>
                </button>
            </div>
        </div>

        <div>
            <div
                :class="{
                    'flex maxWidth2000px': question.enableStackingColumns,
                }"
            >
                <div
                    class="paddingRight10"
                    :class="{
                        'col-xs-12 col-md-6': question.enableStackingColumns,
                        'col-xs-12 col-md-9': !question.enableStackingColumns,
                    }"
                >
                    <kr-math :input="question.question" :safe="!question.questionIsHTML" />
                </div>

                <div
                    class="hidden-xs hidden-sm"
                    :class="{
                        'col-xs-12 col-md-6': question.enableStackingColumns,
                        'col-xs-12 col-md-9 marginTop20': !question.enableStackingColumns,
                    }"
                >
                    <div>
                        <div class="presentationMode">
                            <template v-for="(option, option_idx) in question.options" :key="option.key + '_' + option_idx + '_md'">
                                <div
                                    :class="{
                                        correctOption: option.isCorrect && question.displayAnswer,
                                        newCorrect: optionCorrect(question.id, option.key) && question.displayAnswer,
                                        paddingLeft35px: !(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer,
                                        marginTop10: option_idx != 0,
                                    }"
                                    class="radioField flex"
                                >
                                    <caption
                                        v-if="(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer"
                                        class="visible-hidden"
                                    >
                                        option
                                        {{
                                            option.key
                                        }}
                                        is correct
                                    </caption>

                                    <i
                                        v-if="(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer"
                                        class="fa fa-check-circle marginRight10"
                                    />

                                    <label :for="question.uuid + '-' + option.key">
                                        <span class="withOptionKey" :aria-label="'option ' + option.key"> {{ option.key }}. </span>
                                        <span class="withAnswerKey">
                                            <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                                        </span>
                                    </label>
                                </div>
                            </template>
                        </div>

                        <div v-if="question.displayAnswerStatistics" class="mini-panel panel marginTop20">
                            <div class="panel-heading flexSpaceBetween">
                                <div>Answer Choices</div>
                            </div>
                            <div class="panel-body flex">
                                <table class="barChart">
                                    <tbody class="body">
                                        <template v-for="(option, option_idx) in question.optionKeys">
                                            <tr>
                                                <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                    {{ round(option.percent) }}%
                                                </div>
                                                <td
                                                    class="bars"
                                                    :class="{
                                                        correct: option.isCorrect && question.displayAnswer,
                                                        newCorrect: optionCorrect(question.id, option.key) && question.displayAnswer,
                                                        wrong: !option.isCorrect || (!optionCorrect(question.id, option.key) && question.displayAnswer),
                                                        default: !question.displayAnswerStatistics,
                                                    }"
                                                    :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                                />
                                                <td class="record">
                                                    <div>Choice {{ option.key }}</div>
                                                    <template v-for="(model, model_idx) in option.answersPerOption">
                                                        <template v-if="model.student">
                                                            <button
                                                                v-tooltip="model.student.fullname"
                                                                class="btn btnDiscussionTeams marginTop10"
                                                                @click="openModelInfoModal(model, 'student')"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label class="pointer">{{ model.student.fullname }}</label>
                                                            </button>
                                                        </template>
                                                        <template v-if="model.team">
                                                            <button
                                                                v-tooltip="model.team.name"
                                                                class="btn btnDiscussionTeams marginTop10"
                                                                @click="openModelInfoModal(model)"
                                                            >
                                                                <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                                <label class="pointer">{{ model.team.name }}</label>
                                                            </button>
                                                        </template>
                                                    </template>
                                                    <!--<button class="btn subBtn marginLeft0 fontSize12">
                                                    <span>More</span>&nbsp;<i class="fa fa-sort-down"></i>
                                                </button>-->
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="hidden-md hidden-lg marginTop20"
                    :class="{
                        'col-xs-12 col-md-6': question.enableStackingColumns,
                    }"
                >
                    <div class="presentationMode">
                        <template v-for="(option, option_idx) in question.options" :key="option.key + '_' + option_idx + '_xs'">
                            <div
                                :class="{
                                    correctOption: option.isCorrect && question.displayAnswer,
                                    newCorrect: optionCorrect(question.id, option.key) && question.displayAnswer,
                                    paddingLeft35px: !(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer,
                                }"
                                class="radioField flex marginTop10"
                            >
                                <caption v-if="(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer" class="visible-hidden">
                                    option
                                    {{
                                        option.key
                                    }}
                                    is correct
                                </caption>

                                <i
                                    v-if="(option.isCorrect || optionCorrect(question.id, option.key)) && question.displayAnswer"
                                    class="fa fa-check-circle marginRight10"
                                />

                                <label :for="question.uuid + '-' + option.key">
                                    <span class="withOptionKey" :aria-label="'option ' + option.key"> {{ option.key }}. </span>
                                    <span class="withAnswerKey">
                                        <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                                    </span>
                                </label>
                            </div>
                        </template>
                    </div>

                    <!-- <div class="mini-panel panel inner-panel marginTop20">
                        <div class="panel-heading flexSpaceBetween">
                            <div>STATISTICS</div>
                        </div>
                        <div class="panel-body height100">
                            <div class="staticDetails">
                                <div>
                                    <i class="far fa-clock greyText">&nbsp;</i><span class="fontBold">Average Time</span>
                                </div>
                                <div class="fontNormal">						
                                    <span>{{question.statistics.averageTime}}</span>
                                </div>
                            </div>
                            <div class="staticDetails marginTop20" v-if="question.type!='mcqm'">
                                <div>
                                    <i class="fas fa-chart-pie-alt greyText"></i>&nbsp;</i><span class="fontBold">% Correct</span>
                                </div>
                                <div class="fontNormal">						
                                    <span>{{round(question.statistics.percentCorrect)}}%</span>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div v-if="question.displayAnswerStatistics" class="mini-panel panel marginTop20">
                        <div class="panel-heading flexSpaceBetween">
                            <div>Answer Choices</div>
                        </div>
                        <div class="panel-body flex">
                            <table class="barChart">
                                <tbody class="body">
                                    <template v-for="(option, option_idx) in question.optionKeys">
                                        <tr>
                                            <div class="percentage" :style="'bottom:' + (option.percent.toFixed(0) / 100) * 80 + '%'">
                                                {{ round(option.percent) }}%
                                            </div>
                                            <td
                                                class="bars"
                                                :class="{
                                                    correct: option.isCorrect && question.displayAnswer,
                                                    newCorrect: optionCorrect(question.id, option.key) && question.displayAnswer,
                                                    wrong: !option.isCorrect || (!optionCorrect(question.id, option.key) && question.displayAnswer),
                                                    default: !question.displayAnswer,
                                                }"
                                                :style="'height:' + (option.percent.toFixed(0) / 100) * 80 + '%;'"
                                            />
                                            <td class="record">
                                                <div>Choice {{ option.key }}</div>
                                                <template v-for="(model, model_idx) in option.answersPerOption">
                                                    <template v-if="model.student">
                                                        <button
                                                            v-tooltip="model.student.fullname"
                                                            class="btn btnDiscussionTeams marginTop10"
                                                            @click="openModelInfoModal(model, 'student')"
                                                        >
                                                            <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                            <label class="pointer">{{ model.student.fullname }}</label>
                                                        </button>
                                                    </template>
                                                    <template v-if="model.team">
                                                        <button
                                                            v-tooltip="model.team.name"
                                                            class="btn btnDiscussionTeams marginTop10"
                                                            @click="openModelInfoModal(model)"
                                                        >
                                                            <i class="fa fa-user marginRight8" aria-hidden="true" />
                                                            <label class="pointer">{{ model.team.name }}</label>
                                                        </button>
                                                    </template>
                                                </template>
                                                <!--<button class="btn subBtn marginLeft0 fontSize12">
                                                <span>More</span>&nbsp;<i class="fa fa-sort-down"></i>
                                            </button>-->
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'defaultModelModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'defaultModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'defaultModelModal' + question.uuid + '-title'" class="modal-title">
                            <template v-if="previewModel.type == 'team'"> View {{ previewModel.team.name }} </template>
                            <template v-if="previewModel.type == 'student'"> View {{ previewModel.student.fullname }} </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Clarifications</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download>{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul v-for="(student, idx) in previewModel.team.members">
                                    <li>{{ student.displayName }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
    <updateCorrectAnswerComponent ref="updateCorrectAnswerComponent" />
    <ChangeCorrectAnsComponent ref="changeCorrectAnsComponent" />
    <ChangeQuestionCopyWritingComponent ref="changeQuestionCopyWritingComponent" />
</template>

<script>
import { testMixins } from '../../../../../../../../../../../mixins/test';
import updateCorrectAnswerComponent from '../../../../../../actions/updateCorrectAnswer';
import ChangeCorrectAnsComponent from '../../../../../../actions/changeCorrectAnswer';
import ChangeQuestionCopyWritingComponent from '../../../../../../actions/changeQuestionCopyWriting';

export default {
    components: {
        updateCorrectAnswerComponent,
        ChangeCorrectAnsComponent,
        ChangeQuestionCopyWritingComponent,
    },
    mixins: [testMixins],
    props: ['testObj', 'question', 'options', 'showRealtimeToggle', 'activitiesStatus'],
    data() {
        return {
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
                question: {},
            },
        };
    },
    computed: {
        allowsUpdateCorrectAnswer() {
            if (
                !this.showRealtimeToggle &&
                ['ended', 'completed'].includes(this.testObj.status) &&
                this.question.type == 'mcqs' &&
                !this.testObj.settings.confidenceBasedTesting &&
                ['irat', 'trat', 'application'].includes(this.testObj.type)
            ) {
                return true;
            }
            return false;
        },
        allowsChangeCorrectAnswer() {
            if (
                !this.showRealtimeToggle &&
                ['not started'].includes(this.testObj.status) &&
                this.question.type == 'mcqs' &&
                !this.testObj.settings.confidenceBasedTesting &&
                ['irat', 'trat', 'application'].includes(this.testObj.type)
            ) {
                return true;
            }
            return false;
        },
        allowsUpdateQuestionCopywriting() {
            if (
                !this.showRealtimeToggle &&
                ['not started', 'ended', 'completed'].includes(this.testObj.status) &&
                ['irat', 'trat', 'application'].includes(this.testObj.type)
            ) {
                return true;
            }
            return false;
        },
        changeCorrectAnswerTooltip() {
            if (this.testObj.linkedTRATUuid) {
                return 'Modify function only available in TRAT';
            }

            if (this.question.isLocked) {
                return 'This function has already been used.';
            }

            var notEndedOrCompleted = false;

            for (var i = 0; i < this.activitiesStatus.length; i++) {
                if (!['not started', 'ended', 'completed'].includes(this.activitiesStatus[i].status)) {
                    if (this.activitiesStatus[i].uuid === this.testObj.linkedIRATUuid) {
                        return 'IRAT is still ongoing';
                    }
                    notEndedOrCompleted = true;
                }
            }

            if (notEndedOrCompleted) {
                let sectionActivities = this.activitiesStatus
                    .filter((activityStatus) => !['not started', 'ended', 'completed'].includes(activityStatus.status))
                    .map((activity) => {
                        if (['irat', 'trat'].includes(activity.type)) {
                            activity.type = activity.type.toUpperCase();
                        }
                        return `<li>Section ${activity.sectionName} - ${activity.type}</li>`;
                    })
                    .join('');

                return `This activities still ongoing <ul> ${sectionActivities} </ul>`;
            }

            return false;
        },
        isLocked() {
            if (this.testObj.linkedTRATUuid || this.question.isLocked) {
                return true;
            }

            for (var i = 0; i < this.activitiesStatus.length; i++) {
                if (!['not started', 'ended', 'completed'].includes(this.activitiesStatus[i].status)) {
                    return true;
                }
            }

            return false;
        },
    },
    watch: {
        question: {
            handler: function () {
                if (!this.question.statistics) {
                    this.question.statistics = {};
                }
            },
            deep: true,
        },
    },
    created() {
        if (!this.options) {
            this.options = {};
        }
        if (!this.question.statistics) {
            this.question.statistics = {};
        }
    },
    methods: {
        openModelInfoModal(model, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = model.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = model.student;
            }
            this.previewModel.comment = model.comment;
            this.previewModel.attachments = model.attachments ? model.attachments : [];
            $('#defaultModelModal' + this.question.uuid).modal('show');

            $('#defaultModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        setColumnToggle(value) {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/enable-stacking-columns', { activityQuestionUuid: this.question.uuid })
                .then(function (response) {
                    that.question.enableStackingColumns = value;
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now' + (that.question.enableStackingColumns ? ' 2 Columns.' : ' Stacked.'),
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question answers at this moment, please try again later',
                    });
                });
        },
        optionCorrect(questionId, key) {
            if (!this.showRealtimeToggle) {
                if (['ended', 'completed'].includes(this.testObj.status) && this.question.type == 'mcqs') {
                    for (var i = 0; i < this.testObj.acceptedNewAnswers.length; i++) {
                        if (this.testObj.acceptedNewAnswers[i].activityQuestionId == questionId) {
                            for (var j = 0; j < this.testObj.acceptedNewAnswers[i].acceptedAnswers.length; j++) {
                                if (this.testObj.acceptedNewAnswers[i].acceptedAnswers[j] == key) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.option div:first-child {
    font-weight: bold;
}

.option div:last-child {
    margin-left: 10px;
    font-weight: normal;
}

.option {
    margin-top: 20px;
    line-height: 1.2;
}

.option:first-child {
    margin-top: 0;
}

.option.correct {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #3e7c5b;
    border-radius: 3px;
    padding: 10px;
}

.option .fa-check-circle {
    color: #3e7c5b;
    margin-right: 10px;
}
.mini-panel .panel-heading {
    font-size: 14px;
}

.mini-panel .panel-heading .link {
    /* color: #0071be; */
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

.discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.commentTeams div {
    margin-top: 15px;
}

.commentTeams div:first-child {
    margin-top: 0;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}

table.barChart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 330px;
    max-height: 330px;
    position: relative;
    overflow: auto;
    border: none;
    box-shadow: none;
}

.barChart .bars {
    display: flex;
    flex-direction: column;
}

.barChart .bars tr {
    flex-grow: 1;
}

.barChart .bars td {
    text-align: center;
    display: block;
    margin-right: 0.5em;
    line-height: 0em;
}

.barChart .bars td::after {
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    position: absolute;
}

.barChart .body {
    display: flex;
    flex-grow: 1;
    max-height: 200px;
    border-bottom: 2px solid #d8d8d8;
}

.barChart .body tr {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 2% 0 0;
    position: relative;
    width: 150px;
    max-height: 200px;
}

.barChart .body tr:first-child {
    margin-left: 2%;
}

.barChart .record {
    text-align: center;
    position: absolute;
    top: 200px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0071be;
    font-weight: bold;
    line-height: 1;
    width: 150px;
    padding: 10px;
}

.barChart .bars {
    background: #989b9c;
    display: block;
    width: 30px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0;
    max-height: 184px;
}

.barChart .percentage {
    position: absolute;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
}

.barChart {
    tr {
        flex-grow: 1;

        &:nth-child(6n + 1) .btn {
            margin: 0;
            background: #e48b3a;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 2) .btn {
            margin: 0;
            background: #4e669b;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 3) .btn {
            margin: 0;
            background: #81359f;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 4) .btn {
            margin: 0;
            background: #428459;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 5) .btn {
            margin: 0;
            background: #bfa677;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &:nth-child(6n + 6) .btn {
            margin: 0;
            background: #ba4747;
            color: #fff;
            max-width: 100%;
            display: flex;
            margin-top: 10px;
            width: 100%;

            label {
                max-width: calc(100% - 28px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

.barChart .bars.correct {
    background: #3e7c5b;
}
.barChart .bars.wrong {
    background: #ca3333;
}
</style>
