<template>
    <div class="flex align-items toggle-display">
        <template v-if="!reverse">
            <div :class="{ 'col-xs-6': halfCol, toggleLabelWidth: setWidth }">
                <label :for="id ? id : name" class="control-label fontBlack margin0" :class="{ 'fw-normal': labelFwNormal }" v-html="display" />
                <template v-if="tooltip">
                    <i class="fa fa-question-circle marginLeft8" aria-hidden="true" data-toggle="tooltip" data-placement="top" :data-original-title="tooltip" />
                </template>
            </div>
            <div class="marginLeft10 flexOnly align-items justifyCenter">
                <label class="form-switch">
                    <input
                        :id="id ? id : name"
                        v-model="value"
                        v-tooltip="tooltipContent"
                        :value="value"
                        type="checkbox"
                        :name="id ? id : name"
                        :class="{ disabled: disabled }"
                        :disabled="disabled ? true : undefined"
                        @change="emit"
                        @keydown="form.errors.clear($event.target.name)"
                    />
                    <span v-tooltip="tooltipContent" class="slider round" />
                </label>
            </div>
        </template>
        <template v-else>
            <div class="flexOnly align-items justifyCenter" :class="{ marginRight10: showLabel }">
                <label class="form-switch">
                    <input
                        :id="id ? id : name"
                        v-model="value"
                        v-tooltip="tooltipContent"
                        :value="value"
                        type="checkbox"
                        :name="id ? id : name"
                        :class="{ disabled: disabled }"
                        :disabled="disabled ? true : undefined"
                        @change="emit"
                        @keydown="form.errors.clear($event.target.name)"
                    />
                    <span v-tooltip="tooltipContent" class="slider round" />
                </label>
            </div>
            <div :class="{ 'col-xs-6': halfCol, toggleLabelWidth: setWidth }">
                <label :for="id ? id : name" class="control-label fontBlack margin0" :class="{ 'fw-normal': labelFwNormal }" v-html="display" />
                <template v-if="tooltip">
                    <i class="fa fa-question-circle marginLeft8" aria-hidden="true" data-toggle="tooltip" data-placement="top" :data-original-title="tooltip" />
                </template>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        display: {
            type: String,
            default: '',
        },
        form: {
            type: Object,
            default: function () {
                return {};
            },
        },
        name: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        ariaLabel: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        tooltipContent: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            value: false,
            falseval: false,
            trueval: true,
            tooltip: false,
            reverse: false,
            onNoff: false,
            halfCol: false,
            setWidth: false,
            showLabel: true,
            labelFwNormal: false,
        };
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                if (this.trueval == _.get(this.form.model, this.name, false)) {
                    this.value = true;
                } else {
                    this.value = false;
                }
            },
        },
    },
    created() {
        this.falseval = _.get(this.options, 'falseval', false);
        this.trueval = _.get(this.options, 'trueval', true);
        if (this.trueval == _.get(this.form.model, this.name)) {
            this.value = true;
        } else {
            this.value = false;
        }
        this.reverse = _.get(this.options, 'reverse', false);
        this.onNoff = _.get(this.options, 'onNoff', false);
        this.halfCol = _.get(this.options, 'halfCol', false);
        this.setWidth = _.get(this.options, 'setWidth', false);
        this.showLabel = _.get(this.options, 'showLabel', true);
        this.labelFwNormal = _.get(this.options, 'labelFwNormal', false);
    },
    methods: {
        emit() {
            if (this.value == true) {
                _.set(this.form.model, this.name, this.trueval);
            } else {
                _.set(this.form.model, this.name, this.falseval);
            }

            this.$emit('changed');
        },
    },
};
</script>
<style scoped>
.toggle-display label div {
    margin-top: 4px;
}

.toggleLabelWidth {
    width: calc(100% - 74px);
}
</style>
