<template>
    <div class="gradient panel width100">
        <div class="panel-heading flexSpaceBetween">
            <div>
                <label class="marginRight8">Questions</label>

                <template v-if="showWindowLink">
                    <button
                        v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status) && testObj.type == 'trat'"
                        class="btn btn-default marginLeft0"
                        @click="tratGoTo"
                    >
                        <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Presentation Mode
                    </button>
                    <button v-else class="btn btn-default marginLeft0" @click="otherGoTo">
                        <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Presentation Mode
                    </button>
                </template>
                <button
                    v-tooltip="{
                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                    }"
                    class="btn btn-primary"
                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                    :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'Export Button Disabled' : 'Export'"
                    @click="downloadQuestions"
                >
                    <i class="fa fa-download marginRight8" aria-hidden="true" />Export
                </button>
            </div>
        </div>
        <div class="panel-body eGallery-panel">
            <div class="marginBottom30">
                <question-pagination :test-obj="testObj" />
            </div>
            <div class="col-xs-12">
                <question-groups
                    :test-obj="testObj"
                    :activities-status="activitiesStatus"
                    :options="{
                        showRealtimeToggle: !fromDashboard,
                    }"
                />
            </div>
            <div class="marginTop30">
                <question-pagination :test-obj="testObj" />
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../../../../../components/auth/auth';
export default {
    props: ['testObj', 'options', 'activitiesStatus'],
    data() {
        let auth = new KrAuth();
        return {
            showWindowLink: false,
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            fromDashboard: false,
            test: {},
        };
    },
    watch: {
        testObj: {
            handler: function () {
                if (this.fromDashboard) {
                    this.test = this.testObj;
                }
            },
            deep: true,
        },
    },
    methods: {
        downloadQuestions() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: '/activities/' + this.testObj.activityUuid + '/questions/activity-questions/export',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(' + that.testObj.type + ')' + that.testObj.name + '_Questions_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        otherGoTo() {
            this.$router.push({
                name: 'activities.tests.questions',
                params: { id: this.testObj.uuid },
            });
        },
        tratGoTo() {
            this.$router.push({
                name: 'activities.tests.dashboard.trat-presentation',
                params: { id: this.testObj.uuid },
                query: { tab: 'question' },
            });
        },
    },
    components: {
        'question-pagination': require(`./question-pagination.vue`).default,
        'question-groups': require(`./questions/question-groups.vue`).default,
    },
    created() {
        this.showWindowLink = _.get(this.options, 'showWindowLink', true);
        if (this.$route.name == 'activities.tests.dashboard') {
            this.fromDashboard = true;
        }
    },
    beforeUnmount() {
        //from mixin.
        Events.off('current-question-changed');
    },
};
</script>

<style scoped>
a .fa-stack {
    width: 18px;
    height: 18px;
    line-height: 1;
}

a .fa-stack-2x {
    font-size: 16px;
}

a .fa-stack-1x {
    font-size: 10px;
    top: 12%;
}
a.mainBtn {
    line-height: 23px;
}
</style>
