<template>
    <div v-if="component_done_loading" class="test">
        <test-topbar :test-obj="test"></test-topbar>
        <div class="col-xs-12 right-container">
            <div :class="{ flexSpaceBetween: !isMobileView }">
                <div class="reportFlex" :class="{ marginBottom20: isMobileView }">
                    <div class="gradeReport">
                        <div class="control-label">Total Score</div>
                        <div class="gradientReport">
                            <div>
                                {{ test.userOrTeamScore }}<span>/{{ test.possibleTotalScore }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="gradeReport">
                        <div class="control-label">Overall</div>
                        <div class="gradientReport">
                            <div>{{ round(test.overall) }}%</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div v-if="test.allowStudentsToViewAnswer" class="border1pxGrey padding15 br-3 bg-plain-white">
                        <h4 class="fs-18px margin0 marginBottom15">
                            <b>Legend</b>
                        </h4>
                        <div class="flex">
                            <div class="flexOnly marginRight20">
                                <div class="marginRight8">
                                    <i class="fas fa-minus successColor d-block lh-0-5" aria-hidden="true" />
                                    <i class="fas fa-minus newCorrectColor d-block lh-0-5" aria-hidden="true" />
                                </div>
                                <p>100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />
                                <p>> 50% and 100%</p>
                            </div>
                            <div class="flexOnly marginRight20">
                                <i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />
                                <p>≤ 50% and > 0%</p>
                            </div>
                            <div class="flexOnly">
                                <i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />
                                <p>0%</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="test.allowStudentsToViewAnswer" class="border1pxGrey marginTop30 padding0 width100">
                <div class="report-table-header">
                    <label>Questions ({{ test.questions.length }})</label>
                </div>
                <div class="table-responsive">
                    <table class="table borderNone">
                        <thead>
                            <tr>
                                <th class="width350px minWidth350px">Question</th>
                                <th class="width100px minWidth100px">Score</th>
                                <th class="width115px minWidth115px">% Correct</th>
                                <th class="width350px minWidth350px">
                                    <template v-if="test.isTeamBased"> Team </template>
                                    <template v-else> My </template>
                                    Answer Choices In Sequence
                                </th>
                                <th class="width350px minWidth350px">Narrative Feedback</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(question, idx) in test.questions">
                                <tr>
                                    <td>
                                        <button
                                            class="btn gradient btn-default marginBottom10"
                                            :aria-label="`Question ${idx + 1}`"
                                            @click.prevent="openQuestionInfoModal(question)"
                                        >
                                            Question {{ idx + 1 }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true"></i>
                                        </button>
                                        <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML"></kr-math>
                                        <div v-if="question.attachments && question.attachments.length != 0" tabindex="0">
                                            <div class="marginTop20 fontBold">
                                                <i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>QUESTION ATTACHMENT(S)
                                            </div>
                                            <div class="textLink">
                                                <div v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename" class="flex">
                                                    <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                                                    <template v-if="test.allowStudentsToViewAttachments">
                                                        <template v-if="isPdf(file)">
                                                            <template v-if="test.settings.allowPdfDownload">
                                                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                                                    {{ file.filename }}
                                                                </a>
                                                            </template>
                                                            <template v-else>
                                                                <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{
                                                                    file.filename
                                                                }}</a>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                                file.filename
                                                            }}</a>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <a
                                                            v-tooltip="{
                                                                content: 'Viewing disabled',
                                                            }"
                                                            class="textLink disabled"
                                                            :aria-label="file.filename"
                                                            >{{ file.filename }}
                                                        </a>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {{ round(question.answer.point ? question.answer.point : 0, 2) }}
                                    </td>
                                    <td>
                                        <div
                                            class="statusTag whiteSpaceNowrap"
                                            :class="{
                                                tagNewCorrect: optionCorrect(question),
                                                tagCorrect: question.answer.percent == 100,
                                                tagPrimary: question.answer.percent >= 50 && question.answer.percent < 100,
                                                tagWarning: question.answer.percent < 50 && question.answer.percent > 0,
                                                tagIncorrect: question.answer.percent == 0,
                                            }"
                                        >
                                            {{ round(question.answer.percent) }}%
                                        </div>
                                    </td>
                                    <td>
                                        <template v-if="!test.settings.confidenceBasedTesting || ['mcqm', 'openended'].includes(question.type)">
                                            <div v-html="question.answer.answer ? question.answer.answer : '-'"></div>
                                        </template>
                                        <template v-else>
                                            {{ getPointSpreadingAttempts(question) }}
                                        </template>
                                    </td>
                                    <td class="whiteSpaceBreakSpacesText">
                                        {{ question.answer.narrativeFeedback }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div
            id="teamAnalysisQuestionInfoModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="teamAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :test-obj="test" :question="previewQuestion" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: {},
            previewQuestion: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.init(response);
            that.processEchoListener();
            that.component_done_loading = true;
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                that.test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                let allowStudentsToViewAttachments = that.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToViewAttachments = e.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                if (!e.test.allowStudentsToViewScore) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has disabled viewing of test results.',
                    });
                    that.$router.push({ name: 'tests.index' });
                } else {
                    if (allowStudentsToViewAttachments != that.test.allowStudentsToViewAttachments) {
                        this.fetchTest().then(function (response) {
                            that.init(response);
                        });
                    }
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestVisibilityUpdated',
                handler: h1,
            });
        },
        init(response) {
            var that = this;
            that.test = response.data.data;
            for (var i = 0; i < that.test.questions.length; i++) {
                if (!that.test.questions[i].answer) {
                    that.test.questions[i].answer = {
                        answer: '',
                        attempts: [],
                        isCorrect: false,
                        percent: 0,
                        point: '0.00',
                    };
                }
            }
        },
        getPointSpreadingAttempts(question) {
            let attempts = _.get(question.answer, 'attempts', []);
            if (attempts == null) {
                attempts = [];
            }
            if (attempts.length > 0) {
                let ret = '';
                let attempt = attempts[question.answer.attempts.length - 1];
                for (var k in attempt) {
                    if (attempt.hasOwnProperty(k)) {
                        ret += k + ':' + attempt[k] + ' ';
                    }
                }
                return ret;
            } else {
                return '-';
            }
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/report');
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.test.settings.allowPdfDownload,
            });
        },
        optionCorrect(question) {
            if (question.type == 'mcqs') {
                for (var i = 0; i < this.test.acceptedNewAnswers.length; i++) {
                    if (this.test.acceptedNewAnswers[i].activityQuestionId == question.id) {
                        return this.test.acceptedNewAnswers[i].acceptedAnswers.some((value) => question.answer.attempts.includes(value));
                    }
                }
            }
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
    },
};
</script>

<style scoped>
.reportFlex {
    display: flex;
}

.reportFlex .gradeReport {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.gradeReport .control-label {
    text-align: center;
}

.reportFlex .gradeReport:first-child {
    margin-left: 0;
}

.gradientReport {
    background: linear-gradient(to left, #546ea9 70%, #f4f4f4 100%);
    border-radius: 40px;
    box-sizing: border-box;
    color: #222;
    display: block;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 4px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.gradientReport div {
    align-items: center;
    background: #f8f8f8;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: bold;
}

.gradientReport span {
    color: #717171;
    font-size: 14px;
}

@media (max-width: 991px) {
    .reportFlex .gradeReport {
        padding: 10px;
    }

    .reportFlex .gradeReport {
        width: 31.33%;
        margin-left: 3%;
    }

    .gradientReport {
        height: 70px;
        width: 70px;
        font-size: 20px;
    }

    .gradeReport .control-label,
    .gradientReport span {
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .reportFlex .gradeReport {
        padding: 10px 25px;
    }

    .reportFlex .gradeReport {
        width: 140px;
        margin-left: 30px;
    }

    .gradientReport {
        height: 80px;
        width: 80px;
        font-size: 24px;
    }
}
</style>
