<template>
    <div v-if="!isMobileView" ref="matchContainer" class="flexSpaceBetween position-relative zIndex2 gap10rem">
        <div ref="leftCol" class="width40">
            <div :ref="promptsColumn" class="match-options" :class="{ 'match-correct': displayAnswer }">
                <label class="position-relative">
                    {{ promptVal }}

                    <span class="position-absolute circle">
                        <span class="visible-hidden capitalize">{{ promptTitle }}</span>
                        <b> {{ index }} </b>
                    </span>
                </label>
            </div>
        </div>
        <div ref="rightCol" class="width40">
            <div :ref="answersColumn" class="match-options right" :class="{ 'match-correct': displayAnswer }">
                <label class="position-relative">
                    {{ answerVal }}

                    <span class="position-absolute circle">
                        <i v-if="displayAnswer" class="fa fa-check" aria-hidden="true"></i>
                        <template v-else> 0 </template>
                    </span>
                </label>
            </div>
        </div>
        <canvas ref="matchCanvas"></canvas>
    </div>

    <template v-else>
        <div class="sequencing-div-student">
            <div>
                <label :id="'matching-one-to-one-instructor-mobile-prompt-label-' + index" class="control-label capitalize">
                    {{ promptTitle }} {{ index }}
                </label>
                <p>
                    {{ promptVal }}
                </p>
            </div>

            <div class="marginTop20">
                <label :id="'matching-one-to-one-instructor-mobile-answer-label-' + index" class="control-label capitalize">
                    {{ answerTitle }} for {{ promptTitle }} {{ index }}
                </label>
                <p>
                    {{ answerVal }}
                </p>
            </div>
        </div>
    </template>
</template>
<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';

const props = defineProps({
    promptVal: {
        type: String,
        default: '',
    },
    answerVal: {
        type: String,
        default: '',
    },
    displayAnswer: {
        type: Boolean,
        default: false,
    },
    index: {
        type: Number,
        default: 0,
    },
    promptTitle: {
        type: String,
        default: '',
    },
    answerTitle: {
        type: String,
        default: '',
    },
});

const promptsGroupRefs = ref([]);
const answersGroupRefs = ref([]);

const promptsColumn = (el) => promptsGroupRefs.value.push(el);
const answersColumn = (el) => answersGroupRefs.value.push(el);

const matchCanvas = ref(null);
const matchContainer = ref(null);

const drawLine = (ctx, startX, startY, endX, endY, style) => {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);

    if (props.displayAnswer) {
        ctx.strokeStyle = '#2d8659';
    } else {
        ctx.strokeStyle = 'transparent';
    }

    ctx.lineWidth = 2;

    switch (style) {
        case 'solid':
            ctx.setLineDash([]);
            break;
        case 'dotted':
            ctx.setLineDash([2, 4]);
            break;
        case 'dashed':
            ctx.setLineDash([8, 4]);
            break;
    }

    ctx.stroke();
};

const drawLines = () => {
    const ctx = matchCanvas.value.getContext('2d');
    ctx.clearRect(0, 0, matchCanvas.value.width, matchCanvas.value.height);

    const matchContainerRect = matchContainer.value.getBoundingClientRect();

    promptsGroupRefs.value.forEach((prompt, index) => {
        const answer = answersGroupRefs.value[index];

        if (prompt && answer) {
            const rectA = prompt.getBoundingClientRect();
            const rectB = answer.getBoundingClientRect();

            const startX = rectA.right - matchContainerRect.left;
            const startY = rectA.top + rectA.height / 2 - matchContainerRect.top;

            const endX = rectB.left - matchContainerRect.left;
            const endY = rectB.top + rectB.height / 2 - matchContainerRect.top;

            const style = index % 3 === 0 ? 'solid' : index % 3 === 1 ? 'dotted' : 'dashed';

            drawLine(ctx, startX, startY, endX, endY, style);
        }
    });
};

const canvasDimension = () => {
    const matchContainerElement = matchContainer.value;
    matchCanvas.value.width = matchContainerElement.offsetWidth;
    matchCanvas.value.height = matchContainerElement.offsetHeight;
};

onMounted(() => {
    nextTick(() => {
        canvasDimension();
        drawLines();
    });
});

watch([promptsGroupRefs, answersGroupRefs], () => {
    nextTick(() => {
        drawLines();
    });
});

watch(
    () => props.question,
    (question) => {
        nextTick(() => {
            canvasDimension();
            drawLines();
        });
    }
);

watch(
    () => props.displayAnswer,
    () => {
        nextTick(() => {
            canvasDimension();
            drawLines();
        });
    }
);

window.addEventListener('resize', () => {
    nextTick(() => {
        canvasDimension();
        drawLines();
    });
});
</script>
