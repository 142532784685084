<template>
    <div class="matching-display-question">
        <fieldset>
            <legend>Matching (one-to-many)</legend>

            <template v-if="!isMobileView">
                <div class="two-grid">
                    <label class="control-label">{{ question.options.labels.prompt }}</label>
                    <label class="control-label">{{ question.options.labels.answer }}</label>
                </div>
                <div v-for="(option, idx) in question.options.options" class="two-grid categorize-container">
                    <div>
                        <label class="control-label capitalize">{{ question.options.labels.prompt }} {{ idx + 1 }}</label>
                        <template v-for="prompt in filteringOptions(question.options.options, 'prompt', idx)">
                            <p>{{ prompt.content }}</p>
                        </template>
                    </div>

                    <div>
                        <dl class="margin0">
                            <dt class="control-label capitalize">{{ question.options.labels.answer }} {{ idx + 1 }}</dt>
                            <dd
                                v-for="answer in filteringOptions(question.options.options, 'answer', idx)"
                                class="flexOnly categorize-option categorize-correct"
                            >
                                <i class="fa fa-check-circle marginRight8"></i>
                                <p>{{ answer.content }}</p>
                            </dd>
                        </dl>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-for="(option, option_idx) in question.options.options">
                    <div class="sequencing-div-student">
                        <div>
                            <label :id="'matching-one-to-many-instructor-mobile-prompt-label-' + index" class="control-label capitalize">
                                {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                            </label>
                            <template v-for="prompt in filteringOptions(question.options.options, 'prompt', option_idx)">
                                <p>{{ prompt.content }}</p>
                            </template>
                        </div>

                        <div class="marginTop20">
                            <dl class="margin0">
                                <dt class="control-label capitalize">
                                    {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ option_idx + 1 }}
                                </dt>
                                <dd
                                    v-for="answer in filteringOptions(question.options.options, 'answer', option_idx)"
                                    class="flexOnly categorize-option categorize-correct"
                                >
                                    <i class="fa fa-check-circle marginRight8"></i>
                                    <p>{{ answer.content }}</p>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </template>
            </template>
        </fieldset>
    </div>
</template>

<script setup>
const props = defineProps({
    question: {
        type: Object,
        default: null,
    },
});

const filteringOptions = (options, type, idx) => {
    let arr = [];

    Object.keys(options[idx]).forEach((key) => {
        if (options[idx][key].type == type) {
            arr.push({ ...options[idx][key], key: key });
        }
    });

    return arr;
};
</script>
